import React from 'react';
import styled from 'styled-components';

const DataRenderer = ({data}) => {
    return (
        <Container>
        {data?.pillars?.length ? (
            <>
                <div>
                    <Header>Pillars:</Header>
                    <List>
                        {data.pillars.map((pillar, index) => (
                            <ListItem key={index}>
                                ID: {pillar?.id}<br />
                                Std: {pillar?.std}<br />
                                Factor: {pillar?.factor}<br />
                                Average: {pillar?.average}<br />
                                Overall: {pillar?.overall.join(', ')}<br />
                            </ListItem>
                        ))}
                    </List>
                </div>
                
                <div>
                    <Header>Questions:</Header>
                    <List>
                        {Object.entries(data.questions).map(([key, value]) => (
                            <ListItem key={key}>
                                {key}: {value}
                            </ListItem>
                        ))}
                    </List>
                </div>
                </>
        ) : 
        <Header>None</Header>}
        </Container>
    );
}


const Container = styled.div`
    padding: 5px;
    font-family: Arial, sans-serif;
`;

const Header = styled.h3`
    color: #333;
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const ListItem = styled.li`
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

export default DataRenderer;