import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
  UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER,
  SG_DELETE_GPT_RESPONSE_MAIN,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Header,
  Icon,
} from "semantic-ui-react";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";

import RowCard from "./RowCard";

const List = ({
  brief_scheduled_id,
  reloadPage,
  setReloadPage,
  noPagination = false,
}) => {
  const dispatch = useDispatch();

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [showEditAdd, setShowEditAdd] = useState(false);
  const [editRow, setEditRow] = useState(false);

  useEffect(() => {
    // if the response is ok 200
    if (showEditAdd) {
     
    }
  }, [dispatch, page, brief_scheduled_id, reloadPage, showEditAdd]);

  // console.log(selectBriefSchedule)

  const { get_gpt_response_main } = useSelector(
    (state) => ({
      get_gpt_response_main: state.gpt_response_main,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_response_main.gpt_response_main_debrief_schedule?.[brief_scheduled_id]?.results);
    setPage(get_gpt_response_main?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_response_main?.gpt_response_main?.count /
          get_gpt_response_main?.gpt_response_main?.page_size
      )
    );
    if (
      get_gpt_response_main?.gpt_response_main?.count %
      get_gpt_response_main?.gpt_response_main?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_response_main?.gpt_response_main?.count /
            get_gpt_response_main?.gpt_response_main?.page_size
        ) + 1
      );
    }
  }, [get_gpt_response_main, page, brief_scheduled_id]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

 
  function DeleteRow(row) {
    // console.log(row)
    setLoading(true);
    dispatch({
      type: SG_DELETE_GPT_RESPONSE_MAIN,
      payload: { id: row?.id },
    });
    const timer1 = setTimeout(() => {
      setReloadPage(Math.random);
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }

function ShowHideSummary() {
    if (showEditAdd) {
        setShowEditAdd(false)
        setResults([])
    } else {
        dispatch({
            type: SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
            payload: {
                brief_scheduled_id: brief_scheduled_id,
                query: `${
                    brief_scheduled_id ? `debrief_schedule=${brief_scheduled_id}` : ""
                  }`
            } ,
          });
    
        setLoading(true);
        setShowEditAdd(true)
    }
}

  return (
    <Container>
      <LeftAlign>
        <ContainerFieldSelectable onClick={ShowHideSummary}>
          {showEditAdd ? "Hide" : "Show"} Show Summarys
        </ContainerFieldSelectable>
      </LeftAlign>
      {showEditAdd && (
        <>
          <Segment id="results" basic>
            <Dimmer active={loading}>
              <Loader />
            </Dimmer>
            <Card.Group>
              {results && results?.length > 0 ? (
                <RowCard rows={results} DeleteRow={DeleteRow} />
              ) : (
                <SectionSubHeader>
                  No details found for this brief schedule, create one from
                  below
                </SectionSubHeader>
              )}
            </Card.Group>
          </Segment>

          {results && results?.length > 0 && !noPagination && (
            <SectionDiv>
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={page || 1}
                totalPages={totalPages || 1}
                onPageChange={handlePageChange}
              />
            </SectionDiv>
          )}
        </>
      )}
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  // padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SectionSubHeader = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const LeftAlign = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
