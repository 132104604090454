
import Home from "assets/images/nav/home.svg";
import Management from "assets/images/nav/management.svg";

import Gpt from "assets/images/nav/blog.svg";
import Organizations from "assets/images/nav/organizations.svg";

import Employees from "assets/images/nav/employees.svg";
import Questions from "assets/images/nav/questions.svg";
import Categories from "assets/images/nav/categories.svg";
import SettingsNav from "assets/images/nav/settings-nav.svg";
import EmailTemplates from "assets/images/nav/templates.svg";

import SurveyData from "assets/images/nav/survey-data.svg";
import AuditSurvey from "assets/images/nav/review-survey.svg";
import PdfTemplate from "assets/images/nav/notebook-template.svg"

import L360 from "assets/images/nav/360.svg";
import RecordList from "assets/images/nav/value-list.svg";
import RecordValues from "assets/images/nav/value-record.svg";
import Demostration from "assets/images/nav/demostration.svg"
import Leadership from "assets/images/nav/leadership.svg";
import Apps from "assets/images/nav/apps.svg"
import Data from "assets/images/nav/data.svg";

import Settings from "assets/images/nav/settings.svg";
import Report from "assets/images/nav/report.svg";
import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Personality from "assets/images/nav/personality.svg";

import Admin from "assets/images/nav/admin.svg";
import MyMood from "assets/images/group-12.svg";
import Survey from "assets/images/nav/survey.svg";

import Calendar from "assets/images/nav/calendar.png";
import Action from "assets/images/nav/action.png";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/settings/management",
    title: "Team Management",
    image: Management,
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },

];
export const OtpNav = [];
export const NavData = [
  {
    header: "Main Menu",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/dashboard",
    title: "Dashboard",
    image: Home,
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    image: Report,
    link: "/app/marketing-roi",
    title: "Marketing ROI",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Gpt,
    link: "/app/gpt",
    title: "Gpt",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: MyMood,
    link: "/app/gpt-web-chat",
    title: "Gpt Web Chat",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Gpt,
    link: "/app/gpt-recommendation",
    title: "Gpt Recommendation",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Culture,
    link: "/app/survey-standard",
    title: "Survey Standard",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    link: "/app/organizations",
    title: "Organizations",
    image: Organizations,
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Employees,
    link: "/app/employees",
    title: "Employees",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: Questions,
    link: "/app/questions",
    title: "Questions",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    image: PdfTemplate,
    link: "/app/pdf-templates",
    title: "Pdf Templates",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "MainMenu",
  },
  {
    link: "/app/categories",
    title: "Categories",
    image: Categories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/user-uploads",
    title: "Upload Employees",
    image: Leadership,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/brief-schedule",
    title: "Audit Surveys",
    image: AuditSurvey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/survey-schedule",
    title: "Team Tool",
    image: SurveyData,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/category-questions",
    title: "Category Questions",
    image: Categories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/category-compliance",
    title: "Category Compliance",
    image: Categories,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/ml-operations",
    title: "Knowledge Base Tagging",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/report-templates",
    title: "Report Templates",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },
  {
    link: "/app/report-evaluation",
    title: "Report Evaluation",
    image: Survey,
    role: 9,
    paid: true,
    team: false,
    name: null,
    group: "MainMenu",
  },

  {
    header: "Settings",
    role: 11,
    paid: false,
    team: false,
    group: "MainMenu",
  },
  {
    link: "/app/settings",
    title: "Settings",
    image: SettingsNav,
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },

];

export default NavData;
