import {
  UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER,
  GET_DEBRIEF_SUMMARY_PENDING,
  GET_DEBRIEF_SUMMARY,
  GET_DEBRIEF_SUMMARY_NEXT,
  DELETE_DEBRIEF_SUMMARY,
  FETCH_DEBRIEF_SUMMARY,
  EDIT_DEBRIEF_SUMMARY,
  ADD_DEBRIEF_SUMMARY,
  CLEAR_DEBRIEF_SUMMARY,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_summary: [],
  debrief_summary_next: {},
  pending: false,
  updated: false,
  pageNumber: 1,
};

export default function DebriefSummary(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        pending: false,
      };
    case GET_DEBRIEF_SUMMARY_PENDING:
      return {
        ...state,
        pending: true,
        updated: false,
      };
    case GET_DEBRIEF_SUMMARY:
      return {
        ...state,
        debrief_summary: action.payload,
        pending: false,
        updated: false,
      };
    case GET_DEBRIEF_SUMMARY_NEXT:
        return {
          ...state,
          debrief_summary_next: action.payload,
          pending: false,
          updated: false,
        };
  
    case FETCH_DEBRIEF_SUMMARY:
      return {
        ...state,
        // [action.payload.id]: action.payload,
        debrief_summary_next: action.payload,
        pending: false,
        updated: false,
      };


    case EDIT_DEBRIEF_SUMMARY:
      return {
        ...state,
        debrief_summary: [...state.debrief_summary, action.payload],
        updated:  action.payload,
        pending: false,
      };

    case DELETE_DEBRIEF_SUMMARY:
      return {
        ...state,
        debrief_summary: state.debrief_summary.filter(
          (row) => row.id !== action.payload.id
        ),
        pending: false,
      };

    case ADD_DEBRIEF_SUMMARY:
      return {
        ...state,
        created: action.payload,
        debrief_summary: [...state.debrief_summary, action.payload],
        response: action.payload.response,
        pending: false,
      };

    case CLEAR_DEBRIEF_SUMMARY:
      return {
        ...state,
        debrief_summary: [],
        debrief_summary_next: [],
        pending: false,
        updated: [],
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_summary: [],
        debrief_summary_next: [],
        pending: false,
        updated: [],
        pageNumber: 1,
      };

    default:
      return state;
  }
}
