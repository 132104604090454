import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
GET_EMPLOYEE_REPORTS,
SG_GET_EMPLOYEE_REPORTS,
  ALL_ERRORS,
} from "constants/actions";


function* loademployeereports(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_REPORTS, action.payload);

    yield put({ type: GET_EMPLOYEE_REPORTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeReportLoad() {
  yield takeLatest(SG_GET_EMPLOYEE_REPORTS, loademployeereports);
}


export default function* index() {
    yield all([
        employeeReportLoad(),
    ]);
  }
  