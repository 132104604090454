import React from 'react';

const EmployeeSummary = ({get_employees}) => {
    // console.log(get_employees)
    return (
        <div>
            
        </div>
    );
};

export default EmployeeSummary;