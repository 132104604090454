import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import data from './sample.json'
import make_call from './Request'
import { Accordion, Icon,Button,Loader } from 'semantic-ui-react'
import Slider from './Slider'
import QuestionModal from './Question'
import knowledge_base from './knowledge_base_tag.json'

// import OpenAI from 'openai';

// const openai = new OpenAI({
//     organization: "innerlogic",
//   apiKey: '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', // defaults to process.env["OPENAI_API_KEY"]
// });

// import { Configuration, OpenAIApi } from "openai";

// const configuration = new Configuration({
//     apiKey: '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',
// });

// const openai = new OpenAIApi(configuration);

import {
    SG_POST_ML_SERVER_V1,
    SG_POST_ML_SERVER_V1_XL
  } from "constants/actions";
import { set } from 'date-fns';

  const randomize_feedback = () =>{
    let random_survey = data[Math.floor(Math.random() * data.length)]
    let feedback = extract_random_feedback(random_survey)

    return feedback
}

  const extract_random_feedback = (survey) =>{
    //get random responses from the survey, between 3 and the total length of the survey responses\
    let random_responses = survey.responses.sort(() => Math.random() - Math.random()).slice(0, Math.floor(Math.random() * (survey.responses.length - 3) + 3))
    let feedback = ''
    random_responses.map((response)=>{
        response.response.feedback.map((item)=>{
            feedback+='Question:' + item.question
            feedback += 'response:'+item.feedback
            if(item.follow_up){
                feedback += 'Follow up question:'+item.follow_up_question
                feedback += 'Follow up response:'+item.follow_up
            }
            feedback+='\n\n'
        })
    })
    return feedback
}

function extractPoints(inputStr) {
    // Split the input string into lines
    const lines = inputStr.split('\n');
    
    // Filter out lines that start with a number and a dot and clean them
    const cleanedPoints = lines.filter(line => /^\d+\./.test(line)).map(point => {
        return point.replace(/^\d+\.\s*/, ''); // Remove the initial number, dot and any extra spaces
    });
    
    return cleanedPoints;
}

function getRandomPoint(pointsArray) {
    const randomIndex = Math.floor(Math.random() * pointsArray.length);
    return pointsArray[randomIndex];
}

function formatString(inputString) {
    const [boldPart, ...rest] = inputString.split(':');
    return (
      <>
        <strong>{boldPart}</strong>:{rest.join(':')}
      </>
    );
  }

function get_random_article(){
    let random_article = knowledge_base[Math.floor(Math.random() * knowledge_base.length)]
    return random_article

}
  

const RecommendationEval = () =>{
    const dispatch = useDispatch();
    const get_chat_gpt = useSelector((state) => state.chat_gpt,shallowEqual);
    const [feedback,setFeedback] = useState(randomize_feedback())
    const [loading,setLoading] = React.useState(false)
    const [message,setMessage] = React.useState('')
    const [activeIndex,setActiveIndex] = React.useState(0)
    const [response,setResponse] = React.useState(null)
    const [reset,setReset] = React.useState(false)
    const [generate,setGenerate] = React.useState(false)
    const [open,setOpen] = React.useState(false)
    const [question,setQuestion] = React.useState('')
    const [article,setArticle] = React.useState(get_random_article())
       

        
   

        useEffect(()=>{
            if(feedback && generate){

                const prompt = `
                The following are survey questions and responses from a culture survey:
                ${feedback}

                Based on the feedback, generate a list of 4 gaps for the organization.
                The gaps should focus on specific concepts, themes, or ideas that the organization can address or take action on based on this feedback. The gaps should represent clusters of consensus opinion, and not one-off opinions. Use language, context, and ideas expressed in the feedback itself.
                Here is an example of a needs assessment list:

                Example:
                1. Current lack of transparent and timely communication within and between franchises and at all organizational levels (from executive to employees). Leads to confusion and unalignment within the comm. department.

                2. Reactive and unclear approach to everchanging objectives, initiatives, and performance goals and expectations. Causes subcultures and the development of silos between teams and/or franchises.

                3. No team-based performance goals to foster collaboration, celebrate short-term wins, and hold people accountable within the commercial department. Creates little shared ownership and understanding of how to operationally grow in conjunction with Resulta’s overall strategic vision.

                4. No personal and tangible OKRs (except generating more FTDs) that are tied to clear learning outcomes relevant to the commercial department’s goals. Produces feelings of disconnect and isolation.

                ###END of Example

                Generate a needs assessment with at most 4 needs.

                Needs assessment:
                `;
               
                setLoading(true)
                  async function someFunction(prompt) {
                    const message = await make_call(prompt);
                    const message_array = extractPoints(message)
                    const random_need = getRandomPoint(message_array)
                    setMessage(formatString(random_need));
                    setLoading(false)
                  }

                  someFunction(prompt)

            }
    
        },[feedback,generate])


        const handleClick = (e, titleProps) => {
            const { index } = titleProps
            const newIndex = activeIndex === index ? -1 : index
        
           setActiveIndex(newIndex)  
          }

          const Submit = () =>{
            //This is the content we want to store each time we hit submit
            const content = {
                user:'Danielle Mercer',
                date:new Date().getTime(),
                doc_id:article.id,
                issues_text:feedback,
                rating:response,
                question
            }

            //Now lets clean things up and refresh the page content
            setFeedback(randomize_feedback())
            setArticle(get_random_article())
            setReset(true)
            setQuestion('')


          }

          const AskQuestion = () =>{
                setOpen(true)
          }

    return (
        <Container>
           
          

            <EvaluationArea>
                <Needs>
                    <Title>Needs Assessment <Button onClick={()=>setGenerate(true)} size="tiny">Generate Assessment</Button></Title>
                {loading ? <Loader inline active/> : message}

                </Needs>

                <Article>
                <Title>Article</Title>
                <T2>{article.title}</T2>
                <T2>{article.type}</T2>

                <Accordion>
                        <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Summary
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                        <P>
                            {article.summary}
                        </P>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Full Article
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                        <P>
                            {article.raw}
                        </P>
                        </Accordion.Content>

                    </Accordion>
                </Article>
            </EvaluationArea>

            <QuestionArea>
                <Question>
                    Please rate the quality of fit between the article presented, and the issues identified.
                </Question>
                <Slider saveUpdatedAnswers={(v)=>setResponse(v)} reset={reset} setReset={setReset}/>

                <div style={{width:'100%',display:'flex',justifyContent:'flex-end',marginTop:20}}>
                    <Button onClick={()=>AskQuestion()}>Submit</Button>
                </div>
            </QuestionArea>

            <QuestionModal open={open} setOpen={setOpen} setQuestion={setQuestion} message={message} submit={Submit}/>
           
        

        </Container>
    )
}

export default RecommendationEval;


const Container = styled.div`
    margin-top:50px;
    padding-left:60px;
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
    flex-direction:column;
`

const Question = styled.div`
    font-weight:bold;
`

const Needs = styled.div`
    white-space: pre-wrap;
    width:50%;
    padding:20px;
`

const Title = styled.div`
    font-size:20px;
    font-weight:600;
    margin-bottom:20px;
    display:flex;
    justify-content:space-between;
`

const EvaluationArea = styled.div`
    width:100%;
    display:flex;
    min-height:400px;
    max-height:700px;
    overflow-y:scroll;
`

const Article = styled.div`
    width:50%;
    padding:20px;
`

const QuestionArea = styled.div`
    width:50%;
    display:flex;
    flex-direction:column;
    margin-top:30px;
`

const T2 = styled.div`
    font-size:16px;
    margin-bottom:10px;
    font-weight:bold;
`

const P = styled.p`
    white-space: pre-wrap;
`