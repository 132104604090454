import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Header, Card } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_SURVEY_COMPLIANCE } from "constants/actions";
//
import TokenList from "surveyToken/List"

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [surveyComplianceId, setSurveyCompliance] = useState(null);
  const [surveyComplianceDetails, setSurveyComplianceDetails] = useState(null);
  // const [loading, setLoading] = useState(false)

  function Back() {
    navigate(-1)
  }
  useEffect(() => {
    setSurveyCompliance(id)
    // setLoading(true)
  }, [id]);

  useEffect(() => {
    if (surveyComplianceId) {
      dispatch({
        type: SG_FETCH_SURVEY_COMPLIANCE,
        payload: { id: surveyComplianceId }
      });
    }
  }, [dispatch, surveyComplianceId]);

  const { get_survey_compliance } = useSelector(
    (state) => ({
      get_survey_compliance: state.surveycompliance
    }),
    shallowEqual
  );

  useEffect(() => {
    setSurveyComplianceDetails(get_survey_compliance?.[surveyComplianceId])
  }, [get_survey_compliance, surveyComplianceId]);

  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={Back}
          name='arrow left' />
        Details of {surveyComplianceDetails?.organization_text}{" "}{surveyComplianceDetails?.survey_type_text}
      </Header>

      <Card fluid >
        <Card.Content>
          <Container>
            <ListContainer>
              <HeaderDiv>Org</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.organization_text}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Type</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.survey_type_text}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.status_text}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.created_at && format(new Date(surveyComplianceDetails?.created_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Scheduled Date</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.scheduled_at && format(new Date(surveyComplianceDetails?.scheduled_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Close Date</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.close_at && format(new Date(surveyComplianceDetails?.close_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
          </Container>
        </Card.Content>
      </Card>

      <TokenList
        survey_compliance_id={surveyComplianceId} />
    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`