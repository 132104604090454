import React from 'react'
import { Routes, Route } from 'react-router-dom';

import ForgotPassword from "account/ForgotPassword";
import Login from "account/Login";
import Logout from "account/Logout";
import ResetPassword from "account/ResetPassword";
import SelectOrganization from "account/SelectOrg";

function Account() {

  return (
    <div>

      <Routes>
      <Route path="" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="logout" element={<Logout />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="select-organization" element={<SelectOrganization />} />
      </Routes>
    </div>
  );
}

export default Account;
