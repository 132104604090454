import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Form, Button } from 'semantic-ui-react';

// CLEAR_BLOG

const Toolbar = ({ searchTerm, setSearchTerm }) => {
  const [searchBox, setSearchBox] = useState('')

//   useEffect(() => {
//     setSearchBox(searchTerm)
//   }, [searchTerm])
  
  function OnButtonClick() {
    setSearchTerm(searchBox)
  }
    return (
        <StyledForm success>
            <StyledInput 
                label='Search'
                value={searchBox}
                onChange={(e) => setSearchBox(e.target.value)}
                placeholder='Search...'
            />
            <StyledButton 
                type='submit'
                onClick={OnButtonClick}
            >
                Search
            </StyledButton>
        </StyledForm>
    );
};


Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;


// Styled components
const StyledForm = styled(Form)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #f9f9f9;
`;

const StyledInput = styled(Form.Input)`
    flex: 1;
    margin-right: 10px;
`;

const StyledButton = styled(Button)`
    background-color: #0079d3;
    color: white;
    &:hover {
        background-color: #005fa3;
    }
`;
