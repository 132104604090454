import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Card } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_RESPONSES } from "constants/actions";
// 
//
const Details = (
  {complianceId}
) => {
  const dispatch = useDispatch();
  const [surveyComplianceDetails, setSurveyComplianceDetails] = useState(null);

  useEffect(() => {
    if (complianceId) {

      dispatch({
        type: SG_GET_SURVEY_RESPONSES,
        payload: `compliance=${complianceId}`
      });
    }
  }, [dispatch, complianceId]);

  const { get_survey_responses } = useSelector(
    (state) => ({
      get_survey_responses: state.surveyresponse.survey_responses,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSurveyComplianceDetails(get_survey_responses?.results?.[0])
  }, [get_survey_responses]);

  return (
    <>
      <Card fluid >
        <Card.Content>
          <Container>
            <ListContainer>
              <HeaderDiv>Culture{" "}
                {surveyComplianceDetails?.culture_status}
              </HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.culture_survey_compliance}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Mood{" "}
                {surveyComplianceDetails?.mood_status}
              </HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.mood_survey_compliance}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Leadership{" "}
                {surveyComplianceDetails?.leadership_status}
              </HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.leadership_survey_compliance}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {surveyComplianceDetails?.created_at && format(new Date(surveyComplianceDetails?.created_at), 'dd-MMM-yyyy hh:mm')}
              </ContentDiv>
            </ListContainer>
          </Container>
          <Container>
          <ListContainer>
             <HeaderDiv>Culture Answers</HeaderDiv>
            <ContentDiv>
              <pre>
                {JSON.stringify(surveyComplianceDetails?.culture_answers, null, 2)}
              </pre>
            </ContentDiv>
          </ListContainer>
          </Container>
          <Container>
          <ListContainer>
             <HeaderDiv>Mood Answers</HeaderDiv>
            <ContentDiv>
              <pre>
                {JSON.stringify(surveyComplianceDetails?.mood_answers, null, 2)}
              </pre>
            </ContentDiv>
          </ListContainer>
          </Container>
        </Card.Content>
      </Card>

    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
`

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const surveyComplianceDetailsData = styled.div`
display: flex;
flex-direction: surveyComplianceDetails;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`