import React, { useState } from "react";
import styled from "styled-components";
import ReProcessChat from "./ReProcessChat";
import { Icon } from 'semantic-ui-react'


export const DataView = ({ data }) => {
  const CheckObject = EmptyObject(data);
  const [showLog, setShowLog] = useState(false);
  // webChatId, question,

  if (CheckObject === null) {
    return (
      <Title>
        <ErrorText>No response generated</ErrorText>
      </Title>
    );
  }

  const toggleLog = () => {

    setShowLog(!showLog);
  };

  return (
    <>
      {/* */}

      <DataContainer>
        {data &&
          data.map((item) => (
            <ItemContainer key={item?.id}>
              <ReProcessChat
                webChatId={item?.web_chat_id || item?.web_chat_message_id}
                question={item?.message}
     
              />
              <Title>{item?.name}</Title>
              <Text>
                <strong>Question:</strong> {item?.message || item?.web_chat_message_text}
              </Text>
              {item?.error ? (
                <ErrorText>
                  <strong>App Error:</strong> {item?.error}
                </ErrorText>
              ) : null}
              {/* <Text><strong>Status:</strong> {item?.status}</Text> */}
              <Text>
                <strong>Time Taken:</strong> {item?.time_taken_secs} seconds
              </Text>
              <Text>
                <strong>Response Type:</strong> {item?.server?.response_type}
              </Text>
              <Text>
                <strong>Score:</strong> {item?.server?.score}
              </Text>
              <Text>
                <strong>References:</strong> {item?.server?.references}
              </Text>
              {item?.server?.error ? (
                <ErrorText>
                  <strong>Gpt Error:</strong> {item?.server?.error}
                </ErrorText>
              ) : null}
              {item?.server?.response ? (
                <ResponseText>
                  <strong>Response:</strong> {item?.server?.response}
                </ResponseText>
              ) : null}

              {item?.server?.log ? (
                <ResponseText>
                  <ClickSpan onClick={toggleLog}>
                    <Icon  name={showLog? "angle up" :"angle down" } /> 
                  </ClickSpan>
                  <strong>Log:</strong> 
                  {showLog && item?.server?.log}
                </ResponseText>
              ) : null}
            </ItemContainer>
          ))}
      </DataContainer>
    </>
  );
};

function EmptyObject(item) {
  if (
    typeof item === "object" &&
    item !== null &&
    Object.keys(item).length === 0
  ) {
    return null; // or handle the empty object as you wish
  }
}

// Styled Components
const DataContainer = styled.div`
  padding: 20px;

  margin: 40px auto;
  border-radius: 8px;
`;

const ItemContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #e0e6ed;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
  strong {
    font-weight: 600;
    color: #333;
  }
`;

const ErrorText = styled(Text)`
  color: #e74c3c; // red
`;

const ResponseText = styled(Text)`
  color: #2ecc71; // green
`;

const ClickSpan = styled.span`
cursor: pointer;
`
export const DataRenderer = ({ data }) => {
  return (
    <Container>
      {data?.pillars?.length ? (
        <>
          <div>
            <Header>Pillars:</Header>
            <List>
              {data.pillars.map((pillar, index) => (
                <ListItem key={index}>
                  ID: {pillar?.id}
                  <br />
                  Std: {pillar?.std}
                  <br />
                  Factor: {pillar?.factor}
                  <br />
                  Average: {pillar?.average}
                  <br />
                  Overall: {pillar?.overall.join(", ")}
                  <br />
                </ListItem>
              ))}
            </List>
          </div>

          <div>
            <Header>Questions:</Header>
            <List>
              {Object.entries(data.questions).map(([key, value]) => (
                <ListItem key={key}>
                  {key}: {value}
                </ListItem>
              ))}
            </List>
          </div>
        </>
      ) : (
        <Header>None</Header>
      )}
    </Container>
  );
};
export const ReportDisplay = ({ data }) => {
  return (
    <div>
      <h2>{data?.email_template?.type}</h2>

      <h3>Preview:</h3>
      <p>{data?.email_template?.preview}</p>

      {data?.response &&
        data.response.map((item, index) => (
          <div key={index}>
            <h4>Factor Definition:</h4>
            <p>{item?.factor_definition}</p>

            <h4>Needs:</h4>
            <p>{item?.needs}</p>

            <h4>Summary:</h4>
            <p>{item?.summary}</p>

            <h4>Recommendations:</h4>
            <ul>
              {item?.recommendations &&
                item.recommendations.map((rec, recIndex) => (
                  <li key={recIndex}>{rec}</li>
                ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export const DataDisplay = ({ data }) => {
  return (
    <div>
      <h3>Organization Name:</h3>
      <p>{data?.org_name}</p>

      <h3>Template:</h3>
      <p>{data?.template}</p>

      <h3>Tags:</h3>
      <ul>
        {data?.tags &&
          data.tags.map((tag, index) => <li key={index}>{tag}</li>)}
      </ul>
    </div>
  );
};

const Container = styled.div`
  padding: 5px;
  font-family: Arial, sans-serif;
`;

const Header = styled.h3`
  color: #333;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export default DataRenderer;
