import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_PAYMENTS,
    LOAD_PAYMENTS,
    DELETE_PAYMENT,
    REMOVE_PAYMENT,
    PULL_PAYMENT,
    FETCH_PAYMENT,
    UPDATE_PAYMENT,
    EDIT_PAYMENT,
    SAVE_PAYMENT,
    ADD_PAYMENT,
    CREATE_PAYMENT_INTENT,
    SG_CREATE_PAYMENT_INTENT,
    GET_PAYMENT_METHODS,
    SG_GET_PAYMENT_METHODS,
    DELETE_PAYMENT_METHOD,
    SG_DELETE_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD,
    SG_CREATE_PAYMENT_METHOD,
    CLEAR_PAYMENT,
  ALL_ERRORS,
} from "constants/actions";


function* loadpayments(action) {
//   yield put({ type: GET_PAYMENTS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PAYMENTS, action.payload);
    yield put({ type: GET_PAYMENTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentLoad() {
  yield takeLatest(LOAD_PAYMENTS, loadpayments);
}

function* fetchpayments(action) {
  try {
    const json = yield call(api.FETCH_PAYMENT, action.payload);
    yield put({ type: FETCH_PAYMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentFetch() {
  yield takeLatest(PULL_PAYMENT, fetchpayments);
}

function* updatepayments(action) {
  try {
    const json = yield call(api.EDIT_PAYMENT, action.payload);
    yield put({ type: EDIT_PAYMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentUpdate() {
  yield takeLatest(UPDATE_PAYMENT, updatepayments);
}

function* addpayments(action) {
  try {
    const json = yield call(api.ADD_PAYMENT, action.payload);
    yield put({ type: ADD_PAYMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentAdd() {
  yield takeLatest(SAVE_PAYMENT, addpayments);
}

function* deletepayments(action) {
  try {
    const json = yield call(api.DELETE_PAYMENT, action.payload);
    yield put({ type: DELETE_PAYMENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentDelete() {
  yield takeLatest(REMOVE_PAYMENT, deletepayments);
}

// when you want oto get paid

function* addpaymentintent(action) {
  try {
    const json = yield call(api.CREATE_PAYMENT_INTENT, action.payload);
    yield put({ type: CREATE_PAYMENT_INTENT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentIntentAdd() {
  yield takeLatest(SG_CREATE_PAYMENT_INTENT, addpaymentintent);
}

// use the card for next payment

function* listPaymentMethods(action) {
  try {
    const json = yield call(api.GET_PAYMENT_METHODS, action.payload);
    yield put({ type: GET_PAYMENT_METHODS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentMethodList() {
  yield takeLatest(SG_GET_PAYMENT_METHODS, listPaymentMethods);
}

function* createPaymentMethod(action) {
  try {
    const json = yield call(api.ADD_PAYMENT_METHOD, action.payload);
    yield put({ type:CREATE_PAYMENT_METHOD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentMethodCreate() {
  yield takeLatest(SG_CREATE_PAYMENT_METHOD, createPaymentMethod);
}

function* deletePaymentMethod(action) {
  // console.log(action.payload)
  try {
   yield call(api.DELETE_PAYMENT_METHOD, action.payload);
    yield put({ type:DELETE_PAYMENT_METHOD, payload:  action.payload.id });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentMethodDelete() {
  yield takeLatest(SG_DELETE_PAYMENT_METHOD, deletePaymentMethod);
}

export default function* index() {
  yield all([
    paymentLoad(),
    paymentFetch(),
    paymentUpdate(),
    paymentAdd(),
    paymentDelete(),
    paymentIntentAdd(),
    paymentMethodList(),
    paymentMethodCreate(),
    paymentMethodDelete(),
  ]);
}
