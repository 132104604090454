import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Button,Dropdown,Input,TextArea} from 'semantic-ui-react';


const BulletPoints = ({type,saved_value,onChange,deleteTemplate,moveTemplateUp,moveTemplateDown}) =>{
    
    const [values, setValues] = React.useState(saved_value || ['']);
    const [number,setNumber] = React.useState(1)

    React.useEffect(() => {
      // Initialize with saved_values or reset to a single empty string if not provided
      setValues(saved_value || ['']);
    }, [saved_value]);
  
    const handleInputChange = (index, newValue) => {
      // Update the value at the specific index
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);
      onChange(newValues);
    };
  
    const handleAdd = () => {
      // Add a new empty string to the values array
      setValues([...values, '']);
    };
  
    const handleRemove = (index) => {
      // Remove the value at the specific index
      const newValues = values.filter((_, i) => i !== index);
      setValues(newValues);
    };

    
    return (
            <Container>
                <Label>{type} </Label>
                {values.map((value, index) => (
                    <BulletContainer key={index}>
                    <Input
                        placeholder='Describe in detail, what information should be present here.'
                        value={value}
                        style={{ width: '100%' }}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    <Button size="mini" color="red" onClick={() => handleRemove(index)}>
                        Delete
                    </Button>
                    </BulletContainer>
                ))}

                <Options>
                <Button style={{ marginRight: 10 }} onClick={handleAdd}>
                Add
                </Button>
                {/* Remove button is not needed since each input has its own delete button */}
                <Button style={{ marginRight: 10 }} onClick={moveTemplateUp}>
                Up
                </Button>
                <Button style={{ marginRight: 10 }} onClick={moveTemplateDown}>
                Down
                </Button>
                <Button size="mini" color="red" onClick={deleteTemplate}>
                Delete
                </Button>
      </Options>
            </Container>
    )
}

export default BulletPoints;


const Container = styled.div`
    margin-bottom:20px;
    font-family:'Raleway';
    width:500px;
`

const Label = styled.div`

`

const Options = styled.div`
    display:flex;
`

const BulletContainer = styled.div`
    margin-bottom:20px;
    font-family:'Raleway';
    width:500px;
    display:flex;
`