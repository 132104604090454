import {
  // PDF_TEMPLATE_SELECT,
  UPDATE_PDF_TEMPLATE_PAGE_NUMBER,
  GET_PDF_TEMPLATES,
  DELETE_PDF_TEMPLATE,
  FETCH_PDF_TEMPLATE,
  EDIT_PDF_TEMPLATE,
  ADD_PDF_TEMPLATE,
  CLEAR_PDF_TEMPLATE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  pdf_templates: [],
  pending: false,
  pageNumber: 1,
};

export default function SuveyToken(state = initialState, action) {
  switch (action.type) {
    case GET_PDF_TEMPLATES:
      return {
        ...state,
        pdf_templates: action.payload,
        pending: false,
      };

    case FETCH_PDF_TEMPLATE:
      return { 
        ...state, 
        [action.payload.id]: action.payload, 
        pending: false 
      };
      case UPDATE_PDF_TEMPLATE_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
        }
      case EDIT_PDF_TEMPLATE:
        return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false };

        
    case ADD_PDF_TEMPLATE:
        return {
          ...state,
          pdf_templates: [...state.pdf_templates, action.payload],
        };
    
    case DELETE_PDF_TEMPLATE:
      return {
        ...state,
        pdf_templates: state.pdf_templates.filter((row) => row.id !== action.payload),
      };

    case CLEAR_PDF_TEMPLATE:
      return {
        ...state,
        pdf_templates: [],
        pending: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        pdf_templates: [],
        pending: false,
        pageNumber: 1,
      };
    default:
      return state;
  }
}
