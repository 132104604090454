import styled, { keyframes } from "styled-components";
import React from "react";

import DataForm from "./DataForm";

const Create = ({ newResponse, setNewResponse }) => {
  return <DataForm newResponse={newResponse} setNewResponse={setNewResponse} />;
};

export default Create;
