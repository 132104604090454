const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.
const promptText = "Translate the following English text to French: 'Hello World'";  // Replace with your own prompt.

const elements ={
    0:'t1',
    1:'t2',
    2:'p',
    3:'b',
    4:"table",
    5: "barFactor",
    6: 't3',
    7: 'n',
}

export default async function make_call(template,description){

    const new_template = []

    template.map((t,i)=>{
        if(t.value != 3){
            new_template.push({
                type:elements[t.value],
                text:t.input_text})
        }
        else{
           t.input_text.map((b)=>{
            new_template.push({
                type:elements[t.value],
                text:b})
           })
        }
    })

    console.log(template)


    const prompt = `
    We have the following information sections we want to create a specific and actionable 
    recommendation / solution to a culture issue at an organization. I will present to you 
    the following information:

    1. The culture issue identified (ISSUE).
    2. Research content that helps to address that need. We want our recommendation to pull anecdotes and information from this content. (CONTENT).
    3. A template structure for organizing the recommendations. The template is an article structure we expect the output to follow as closely as possible. (TEMPLATE)
    4. Any additional context about the person who is receiving this solution (CONTEXT).
    5. The main task we want you to solve in the recommendation and solution output. (TASK).

    Please consider all the information here, and build the best possible solution to the culture need by repurposing the information we've included as content.
    ISSUE: 
    Lack of clarity on the organization's vision and values: Many employees expressed 
    confusion or uncertainty about the organization's vision and values. This lack of 
    clarity can lead to a disconnect between employees' daily work and the organization's
    overall goals. The organization should communicate its vision and values clearly and
    consistently to create a sense of shared purpose and direction.

    CONTENT:
    Managers will face unprecedented challenges over the next decade. Not surprisingly,
    many leaders will choose to focus on the strategic aspects of change. Just as important,
    however, is driving a skillsbased transformation that can create teams diverse enough
    to be vibrant and innovative, while remaining inclusive and cohesive enough to be effective.
    Thats easier said than done. Decades of research show that diverse teams, while often high performing,
    also encounter obstacles and face resistance. Managers who attempt to reshape their workforce without
    first acknowledging the challenges of difference risk getting mired in conflict and acrimony, 
    which can undermine effectiveness. What we have found in our work advising some of the worlds 
    most highperforming firms on how to accelerate transformation and drive growth is that successful
     leaders strive to identify shared values and build change upon common ground. This means that
     managers need to not only evaluate technical skills, but also to clearly communicate their organizations
    shared mission and hire people who will be inspired to dedicate their talents to it. What Makes A Great
    Team? In 1997, McKinsey declared a War for Talent and advised their clients to focus on recruiting and
    retaining the best and the brightest. They suggested that firms should create compelling employee value
    propositions, invest in A players, develop B players, and move quickly to get rid of C players. Insight
    Center Collection Building Tomorrows Workforce How the best companies identify and manage talent.
    Yet it soon became clear that this approach failed to yield resultsbecause of unintended consequences
    that resulted from perverse incentives. In fact, there is significant evidence that it contributed
    to the destructive culture and ultimate downfall of Enron, because the focus on individual rather
    than team performance encouraged employees to undermine their colleagues. The lesson is that it
    is not individuals, but teams, that get results especially in highvalue work such as creative
    work, complex analysis, and scientific research. Fortunately, there has been abundant research
    into how teams function best and what makes them perform most efficiently and productively.
    In one wideranging study, scientists at MIT and Carnegie Mellon found that highperforming
    teams are made up of people who have high social sensitivity, who take turns when speaking,
    and that include women in the group. But perhaps the most important trait of any team is
    that its members contribute a diversity of talents, experiences, and perspectives, which 
    maximizes the the number of possibilities the team can explore and leads to smarter, more 
    innovative solutions. However, building a diverse team that works well together is a challenge 
    that takes real effort to overcome. Leaders shouldnt underestimate it. The Diversity Paradox
    Diversity, all too often, is viewed as being in conflict with performance something that leaders
    will get around to once theyve made their quarterly numbers. However, the evidence that diversity
    improves performance is nothing less than overwhelming. One study found that diverse groups solve
    problems better than more homogenous teams of objectively highability problem solvers. Another that
    simulated markets showed that ethnic diversity deflated price bubbles. A McKinsey report that covered
    366 public companies in a variety of countries and industries found that groups that were more ethnically
    and gender diverse performed significantly better than others. The list goes on. Yet while the benefits of
    diversity are clear, so are the challenges. We are hardwired to be hostile to those we see as other. In a
    study of adults randomly assigned to leopards and tigers, fMRI studies noted enmity to outgroup members
    . Similar results were found in a study involving fiveyearold children and even in infants. To some extent,
    tribalism is unavoidable. These tensions, if not addressed, can inhibit performance. Consider that when researchers
    at Northwesterns Kellogg School of Management and Stanford Graduate School of Business put together groups of college
    students to solve a murder mystery, cohesive groups were much more able to come to consensus and feel confident in their
    solution than diverse groups, but they were also much more likely to be wrong. Leaders need to be able to square the circle
    and build teams that are diverse enough to be innovative, but cohesive enough to work together smoothly. Our work with highperforming
    organizations has shown that the best way to do that is by building a culture of strong, shared values. Building a Shared Mission 
    and Shared Values The link between values and performance isnt always immediately obvious. But consider that culture and values are 
    how an enterprise honors its mission and it becomes clear that values are a crucial component of strategic intent. For example, throughout
    his tenure, Steve Jobs commitment to fusing design with technology was a value that attracted both customers and talent. More recently,
    Tim Cook has been leveraging the value of privacy in much the same way. Similarly, signaling a shift in values can help attract new domains
    of expertise. This was very much the case in the AI community, which for decades prided itself on its meritocratic values. However, when it
    became clear that the industry was encountering serious ethical challenges, its commitment to taking those concerns seriously helped attract
    organizations such as the ACLU and Chatham House to its Partnership on AI effort. Yet what is crucial for leaders to understand is that commitment
    to values always comes with costs and constraints. Over a century, one company we worked with has provided highquality products and earned a reputation 
    for ethics and excellence. Due to technological disruption, the firm needed to hire people with more diverse skills and mindsets. Their challenge was twofold.
    First, leadership needed to have a frank discussion about how they needed to operate differently. Second, they needed to signal to outsiders that the change was
    genuine. Managing the shift required a sustained commitment from the top to achieve the desired results. A HighPerformance Culture Is a Journey, Not a Destination
    Strictly speaking, values are how an enterprise honors its mission. To achieve any significant objective, capabilities must be brought to bear, and some of the most 
    crucial capabilities are the skills embedded in an organizations talent. However, while skills enable an enterprise to achieve its mission, they are separate and distinct from it.
    Values are central. There is a fundamental difference between hiring people to do what you want and hiring people who already want what you want. The value of any particular skill
    is likely to degrade over time. On the other hand, people who share your mission and values can acquire the skills needed to achieve your shared objectives. What weve found in
    our work helping to develop highperforming organizations is that every strategy requires specific capabilities and those capabilities come with people attached. Those people,
    in turn, come with their own needs and peccadilloes, rough edges, and dreams. The art of leadership is no longer merely to plan and direct action, but to inspire and empower belief.
    In todays disruptive marketplace, every organization needs to attract, develop, and retain talent with diverse skills and perspectives. The difference between success and failure will not
    be in the formulation of job descriptions and compensation packages, but in the ability to articulate a higher purpose. That begins with a clear sense of shared mission and values. 

    TEMPLATE:
    ${JSON.stringify(new_template)}

    CONTEXT:
    This solution is for a middle manager. The solution must be actionable by that person. Please consider the level of actions that this person can achieve in their organization given their role. They can only enact solutions that work in their sphere of influence. 

    TASK:
    Build a recommendation / Solution for the identified NEED, using the solution presented in the CONTENT and filling in the TEMPLATE text tags.  The solutions need to be very actionable and based only on the information from the content provided. 
    Each template component should be filled in such a way that builds a great overall solution plan. Each output in the survey is designed to follow the accompanying prompt. 
    
    Make sure you follow the following purpose for the plan:
    ${description}

    OUTPUT:
    `;

    console.log(prompt)




        return fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer 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`
                },
                body: JSON.stringify({
                    messages: [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["},{"role":"user","content":prompt}],
                    model:'gpt-4-1106-preview'
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data.choices[0].message.content.trim()
            })
            .catch(error => {
                console.error("Error:", error);
            });
}

