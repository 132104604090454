import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Logins from './logins';
import SelectOrg from "./selectOrg"

import { Header, Card, Button } from 'semantic-ui-react'
function SettingList() {
    const navigate = useNavigate()
    return (
        <>
            <Header as='h2' style={{ marginTop: "50px" }}>
                Settings Home Page
                <Header.Subheader>
                    List of All Login and a place to manage them.
                </Header.Subheader>
            </Header>
            <Card.Group>
                {items.map((item, i) => {
                    if (item.visible === true) {
                        return (
                            <Card key={i}>
                                <Card.Content>
                                    <Card.Header>{item.header}</Card.Header>
                                    <Card.Meta>{item.meta}</Card.Meta>
                                    <Card.Description>
                                        {item.description}
                                    </Card.Description>

                                </Card.Content>
                                <Card.Content extra>

                                    <Button basic
                                        onClick={() => navigate(`${item.link}`)}
                                        color='green'>
                                        View More
                                    </Button>
                                </Card.Content>
                            </Card>
                        )
                    }
                    return null

                })}

            </Card.Group>

        </>
    );
}

export default SettingList;


export const items = [
    {
        visible: false,
        isStaff: 0,
        header: 'Home Page',
        description:
            'List of employees who can login to the app, watch out for staff accounts ',
        meta: 'Select Org',
        link: "/app/settings",
        path: "/",
        element: <SettingList />
    },
    {
        visible: false,
        isStaff: 0,
        header: 'Select Org',
        description:
            'Select the org to which you want to log in to ',
        meta: 'Select Org',
        link: "/app/settings/select-organization",
        path: "/select-organization/*",
        element: <SelectOrg />
    },
    {
        visible: true,
        isStaff: 0,
        header: 'Select Org',
        description:
            'Select the org to which you want to log in to ',
        meta: 'Select Org',
        link: "/app/settings/select-organization/select-org",
        path: "/select-organization/*",
        element: <SelectOrg />
    },
    {
        visible: true,
        isStaff: 1,
        header: 'Logins',
        description:
            'List of employees who can login to the app, watch out for staff accounts ',
        meta: 'List of Logins',
        link: "/app/settings/logins",
        path: "/logins/*",
        element: <Logins />
    },
]
