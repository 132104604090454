
import {
  UPDATE_MARKETING_ROI_PAGE_NUMBER,
  GET_MARKETING_ROI_PENDING,
  GET_MARKETING_ROIS,
  DELETE_MARKETING_ROI,
  FETCH_MARKETING_ROI,
  EDIT_MARKETING_ROI,
  ADD_MARKETING_ROI,
  CLEAR_MARKETING_ROI,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
marketing_roi: [],
pending: false,
created: false,
updated: false,
pageNumber: 1,
};

export default function GptMember(state = initialState, action) {
switch (action.type) {
  case GET_MARKETING_ROI_PENDING:
    return {
      ...state,
      pending: true,
    };
  case UPDATE_MARKETING_ROI_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.payload,
    };
  case GET_MARKETING_ROIS:
    return {
      ...state,
      marketing_roi: action.payload,
      pending: false,
    };

  case FETCH_MARKETING_ROI:
    return { 
      ...state, 
      [action.payload.id]: action.payload, 
      pending: false 
    };

  case EDIT_MARKETING_ROI:
    return {
      ...state,
      [action.payload.id]: action.payload,
      updated: action.payload,
      pending: false,
    };

  case DELETE_MARKETING_ROI:
    return {
      ...state,
      deleted: action.payload,
      pending: false,
    };

  case ADD_MARKETING_ROI:
    return {
      ...state,
      created: action.payload,
      pending: false,
    };

  case CLEAR_MARKETING_ROI:
    return {
      ...state,
      marketing_roi: [],
      pending: false,
      created: false,
      updated: false,
      pageNumber: 1,
    };

  case CLEAR_ALL:
    return {
      ...state,
      marketing_roi: [],
      pending: false,
      created: false,
      updated: false,
      pageNumber: 1,
    };

  default:
    return state;
}
}
