import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// import { Link as RouterLink, useNavigate } from "react-router-dom";

import { format } from 'date-fns'

import { Card, Modal, Header, Button, Icon } from 'semantic-ui-react'
import SurveyResponse from "surveyResponse/Details"

const RowCard = ({ row }) => {

  const [openPortal, setOpenPortal] = useState(false)
  const [complianceId, setComplianceId] = useState(false)

  function ShowResponse(id) {
    setComplianceId(id)
    setOpenPortal(true)
  }

  return (
    <>
      <Card fluid key={row?.id}>

        <Card.Content>

          <Container>

            <ListContainer>
              <div>
                <HeaderDiv>Token</HeaderDiv>
                <ContentDiv>
                  {row?.token}
                </ContentDiv>
              </div>
              <div><HeaderDiv>Delivery</HeaderDiv>
                <ContentDiv>
                  {row?.delivery_status}
                </ContentDiv>
              </div>
            </ListContainer>
          </Container>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>Survey's</HeaderDiv>
                <ContentDiv>
                  <div>
                    Culture <TypesAndStatus serviceUsed={row?.culture_survey_compliance} />
                    {row?.culture_survey_compliance ?
                      <>
                        Completed <TypesAndStatus serviceUsed={!row?.culture_survey_type} />
                        {row?.culture_survey_type === 0 && <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => ShowResponse(row?.culture_survey_compliance)}
                          color='violet' name='code' />
                        }
                      </>
                      : ''}
                  </div>
                  <div>
                    Mood <TypesAndStatus serviceUsed={row?.mood_survey_compliance} />
                    {row?.mood_survey_compliance ?
                      <>
                        Completed <TypesAndStatus serviceUsed={!row?.mood_survey_type} />
                        {row?.mood_survey_type === 0 && <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => ShowResponse(row?.mood_survey_compliance)}
                          color='violet' name='code' />
                        }
                      </>
                      : ''}
                  </div>
                  <div>
                    Leadership <TypesAndStatus serviceUsed={row?.leadership_survey_compliance} />
                    {row?.leadership_survey_compliance ?
                      <>
                        Completed <TypesAndStatus serviceUsed={!row?.leadership_survey_type} />
                        {row?.leadership_survey_type === 0 && <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => ShowResponse(row?.leadership_survey_compliance)}
                          color='violet' name='code' />
                        }
                      </>
                      : ''}

                  </div>
                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Scheduled Date</HeaderDiv>
                <ContentDiv>
                  {row?.scheduled_at && format(new Date(row?.scheduled_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Valid Upto</HeaderDiv>
                <ContentDiv>
                  {row?.valid_upto && format(new Date(row?.valid_upto), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Completed Date</HeaderDiv>
                <ContentDiv>
                  {row?.deleted_on && format(new Date(row?.deleted_on), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Completed</HeaderDiv>
                <ContentDiv>
                  <TypesAndStatus serviceUsed={row?.to_delete} />
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Cancelled</HeaderDiv>
                <ContentDiv>
                  <TypesAndStatus serviceUsed={row?.failed} />
                </ContentDiv>
              </ListContainer>

            </RowData>
          </Container>
        </Card.Content>
      </Card>

      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        complianceId={complianceId}
      />
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

function TypesAndStatus({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed ?
        <Icon color='green' name='checkmark' size='small' />
        :
        <Icon color='red' name='close' size='small' />
      }
    </>
  )
}

const MoreDetails = ({ openPortal, setOpenPortal, complianceId }) => {

  return (<Modal
    closeIcon
    open={openPortal}
    onClose={() => setOpenPortal(false)}
    onOpen={() => setOpenPortal(true)}
  >
    <Header>Detailed Response</Header>
    <Modal.Content>
      
     {complianceId && complianceId > 0 &&
     <SurveyResponse complianceId={complianceId} />
     } 
    </Modal.Content>
    <Modal.Actions>
      
      <Button color='purple' onClick={() => setOpenPortal(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`