import React from "react";
import styled from "styled-components";

export const CustomMessagesList = {
  "The fields organization, email must make a unique set": {
    cause: "The email id has already been used and cannot be used",
    userMessage: "This email id has already been used by another employee",
    nextSteps: "If you are looking to modify some information about the user, try updating the details",
    isError: true,
  },
  "Extend Survey not possible since all tokens have been deleted and you cannot extend this survey": {
    cause: "The tokens are deleted once the survey is closed and within a certain time fram",
    userMessage: "The tokens are expired, you can choose to generate new tokens, this would also send another email to user",
    nextSteps: "You can generate new tokens by following the steps below",
    isError: false,
    generateTokens: true,
  },
  "Closing date has been set in the past!": {
    cause: "The closing date has to be future date and greater than closing date this was not",
    userMessage: "The closing date has to atleast 4 hrs from now",
    nextSteps: "Change the closing date to some time / date further in future to fix this error",
    isError: false,
    generateTokens: false,
  },
  "Closing date should be at least 4 hours from the current time!": {
    cause: "The closing date has to be future date and greater than closing date this was not",
    userMessage: "The closing date has to atleast 4 hrs from now",
    nextSteps: "Change the closing date to some time / date further in future to fix this error",
    isError: false,
    generateTokens: false,
  },
  "Closing date should be at least 4 hours from the scheduled time!": {
    cause: "The closing date has to be future date and greater than scheduled date this was not",
    userMessage: "The closing date has to atleast 4 hrs from scheduled date time",
    nextSteps: "Change the closing date to some time / date further in future to fix this error",
    isError: false,
    generateTokens: false,
  },
  // Add more custom messages here
};


export function CustomMessages({ message }) {
  return <ErrorMessageContainer>{message}</ErrorMessageContainer>;
}
const ErrorMessageContainer = styled.div`

  padding: 10px;
  margin-bottom: 10px;
`;
