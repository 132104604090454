import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from 'date-fns'

import { Card, Button } from 'semantic-ui-react'


const RowCard = ({ row }) => {
  const navigate = useNavigate()

  return (
    <>
      <Card fluid key={row?.id}>

        <Card.Content>

          <Container>

            <RowData>


              <ListContainer>
                <HeaderDiv>Org</HeaderDiv>
                <ContentDiv>
                  {row?.organization_text}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Type</HeaderDiv>
                <ContentDiv>
                  {row?.survey_type_text}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Status</HeaderDiv>
                <ContentDiv>
                  {row?.status_text}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Created Date</HeaderDiv>
                <ContentDiv>
                  {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Scheduled Date</HeaderDiv>
                <ContentDiv>
                  {row?.scheduled_at && format(new Date(row?.scheduled_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Close Date</HeaderDiv>
                <ContentDiv>
                  {row?.close_at && format(new Date(row?.close_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>

            </RowData>
          </Container>


          <Card.Description>


            <Button floated='right'
              onClick={() => navigate(`/app/survey-schedule/details/${row?.id}`)}>
              More Details
            </Button>
          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;


const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`