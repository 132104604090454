import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import {
  SG_GET_DEBRIEF_SCHEDULES,
  SG_GET_ORGANIZATION_SELECT,
  DEBRIEF_SCHEDULE_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";
import MultipleScheduler from "organizations/onboarding/MultipleScheduler";

import { DEMO_ORGS } from "data/AdminData";

const List = ({ hideOrgSelect }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [organizationId, setOrganizationId] = useState(id);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [updateRows, setUpdateRows] = useState(false);

  const [createVisible, setCreateVisible] = useState(false);

  useEffect(() => {
    setOrganizationId(id);
  }, [id]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });

  }, [dispatch]);

  useEffect(() => {
    // if the response is ok 200
    if (organizationId) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${page}&organization=${organizationId}`,
      });
      setLoading(true);
    }
  }, [dispatch, page, organizationId, updateRows]);

  const { get_brief_schedules, get_organization_select } = useSelector(
    (state) => ({
      get_brief_schedules: state.debrief_schedule,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  // console.log(organizationId)

  useEffect(() => {
    setResults(get_brief_schedules?.debrief_schedule?.results);
    setPage(get_brief_schedules?.pageNumber);
    setTotalPages(
      Math.floor(
        get_brief_schedules?.debrief_schedule?.count /
          get_brief_schedules?.debrief_schedule?.page_size
      )
    );
    if (
      get_brief_schedules?.debrief_schedule?.count %
      get_brief_schedules?.debrief_schedule?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_brief_schedules?.debrief_schedule?.count /
            get_brief_schedules?.debrief_schedule?.page_size
        ) + 1
      );
    }
  }, [get_brief_schedules, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: DEBRIEF_SCHEDULE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

  function DelayedLoad() {
    //
    const timer1 = setTimeout(() => {
      setUpdateRows(Math.random());
    }, 2000);
    return () => clearTimeout(timer1);
  }


  return (
    <Container>
      <RightAlign>
        <ContainerFieldSelectable
          onClick={() => setCreateVisible(!createVisible)}
        >
          {createVisible ? "Close Create" : "Open Create"}
        </ContainerFieldSelectable>
      </RightAlign>

      {createVisible && (
        <MultipleScheduler
          organizationId={organizationId}
          DelayedLoad={DelayedLoad}
          isMulti={false}
          demo_survey={true}
          surveyStatus={4}
          allQuestions={true}
        />
      )}

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results && results?.length > 0 ? (
            results.map((result) => (
              <RowCard
                get_organization_select={get_organization_select}
                row={result}
                key={result?.id}
              />
            ))
          ) : (
            <p>No survey found create one for the org</p>
          )}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page || 0}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
