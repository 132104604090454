import React, { useState } from 'react'
import { Routes, Route, Link } from 'react-router-dom';
import List from './List';
import Details from './Details';
import OnBoarding from './onboarding';
import { Header } from 'semantic-ui-react'
function Organizations() {
  const [showHeaderTxt, setShowHeaderTxt] = useState(true)
  return (
    <div style={{ marginTop: "30px" }}>
      {showHeaderTxt &&
        <HeaderText />
      }
      <Routes>
        <Route path="/" element={<List
          setShowHeaderTxt={setShowHeaderTxt} />} />

        <Route path="details/:id/*" element={<Details

          setShowHeaderTxt={setShowHeaderTxt} />} />
        <Route
          path="details/:id/onboarding/*"
          element={<OnBoarding
            setShowHeaderTxt={setShowHeaderTxt} />} />
      </Routes>
    </div>
  );
}

export default Organizations;

function HeaderText() {
  return (
    <Header as='h2' >
      Organizations
      <Header.Subheader>
        List of All Organizations and a place to manage them.
      </Header.Subheader>
    </Header>
  )
}