import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_GET_EMPLOYEE_RECORD_LIST,
    SG_GET_EMPLOYEE_RECORD_VALUE,
    UPDATE_EMPLOYEE_RECORD_LIST_PAGE_NUMBER
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({ hideOrgSelect,
    orgId,
    employee_record_value }) => {

    // employee_record_value
    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [emRecVal, setEmRecVal] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    const [valueNames, setValueNames] = useState([])
    const [createdNew, setCreatedNew] = useState(false)

    useEffect(() => {
        if (orgId) {
            setOrganizationId(orgId)
        }
    }, [orgId]);

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        dispatch({
            type: SG_GET_EMPLOYEE_RECORD_VALUE,
        });
    }, [dispatch]);

    useEffect(() => {
        setEmRecVal(false)
        if (employee_record_value) {
            setEmRecVal(employee_record_value)
        }
    }, [employee_record_value]);

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_EMPLOYEE_RECORD_LIST,
            payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}${emRecVal ? `&employee_record_value=${emRecVal}` : ''}`,
        });

        setLoading(true)
    }, [dispatch, page, searchTerm, organizationId, emRecVal,
        resetPageLoad, createdNew]);

    const { get_employee_record_value,
        get_employee_record_list,
        get_selectedOrg } = useSelector(
            (state) => ({
                get_employee_record_value: state.employee_record_value,
                get_employee_record_list: state.employee_record_list,
                get_selectedOrg: state.selectedOrg,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationId(get_selectedOrg?.organization?.id)
        // setResetPageLoad(!resetPageLoad)
    }, [get_selectedOrg]);

    useEffect(() => {
        setValueNames(get_employee_record_value?.employee_record_value)
    }, [get_employee_record_value]);

    useEffect(() => {
        setResults(get_employee_record_list?.employee_record_list?.results);
        setPage(get_employee_record_list?.pageNumber)
        setTotalPages(Math.ceil(get_employee_record_list?.employee_record_list?.count / get_employee_record_list?.employee_record_list?.page_size))


    }, [get_employee_record_list, page, createdNew]);

    useEffect(() => {
        setCreatedNew(get_employee_record_list.created)
        setPage(1)
    }, [get_employee_record_list.created]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_EMPLOYEE_RECORD_LIST_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);


    return (

        <Container>

            {/*   <SectionDiv>
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} /> 

            </SectionDiv>*/}
            {!hideOrgSelect &&
                <OrganizationSelect
                    setResetPageLoad={setResetPageLoad} />
            }


            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results &&
                        <RowCard
                            rows={results}
                            valueNames={valueNames}
                        />
                    }
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages || 0}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`