import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
  GET_PDF_TEMPLATES,
  SG_GET_PDF_TEMPLATES,
  DELETE_PDF_TEMPLATE,
  SG_DELETE_PDF_TEMPLATE,
  FETCH_PDF_TEMPLATE,
  SG_FETCH_PDF_TEMPLATE,
  EDIT_PDF_TEMPLATE,
  SG_EDIT_PDF_TEMPLATE,
  ADD_PDF_TEMPLATE,
  SG_ADD_PDF_TEMPLATE,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
  //   yield put({ type: GET_methodS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PDF_TEMPLATES, action.payload);
    // console.log(json.data)
    yield put({ type: GET_PDF_TEMPLATES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_PDF_TEMPLATES, loadmethods);
}

function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_PDF_TEMPLATE, action.payload);
    yield put({ type: FETCH_PDF_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_PDF_TEMPLATE, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_PDF_TEMPLATE, action.payload);
    yield put({ type: EDIT_PDF_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_PDF_TEMPLATE, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_PDF_TEMPLATE
  try {
    const json = yield call(api.ADD_PDF_TEMPLATE, action.payload);
    yield put({ type: ADD_PDF_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_PDF_TEMPLATE, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_PDF_TEMPLATE, action.payload);
    yield put({ type: DELETE_PDF_TEMPLATE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_PDF_TEMPLATE, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
