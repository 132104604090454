import {
    UPDATE_GPT_WEB_CHAT_PAGE_NUMBER,
    GET_GPT_WEB_CHAT_PENDING,
    GET_GPT_WEB_CHATS,
    DELETE_GPT_WEB_CHAT,
    FETCH_GPT_WEB_CHAT,
    EDIT_GPT_WEB_CHAT,
    ADD_GPT_WEB_CHAT,
    CLEAR_GPT_WEB_CHAT,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
  gpt_web_chat: [],
  pending: false,
  created: false,
  updated: false,
  pageNumber: 1,
};

export default function GptMember(state = initialState, action) {
  switch (action.type) {
    case GET_GPT_WEB_CHAT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_GPT_WEB_CHAT_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_GPT_WEB_CHATS:
      return {
        ...state,
        gpt_web_chat: action.payload,
        pending: false,
      };

    case FETCH_GPT_WEB_CHAT:
      return { 
        ...state, 
        [action.payload.id]: action.payload, 
        pending: false 
      };

    case EDIT_GPT_WEB_CHAT:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: action.payload,
        pending: false,
      };

    case DELETE_GPT_WEB_CHAT:
      return {
        ...state,
        // gpt_members: state.gpt_members.filter((row) => row.id !== action.payload),
        deleted: action.payload,
        pending: false,
      };

    case ADD_GPT_WEB_CHAT:
      return {
        ...state,
        created: action.payload,
        pending: false,
      };

    case CLEAR_GPT_WEB_CHAT:
      return {
        ...state,
        gpt_web_chat: [],
        pending: false,
        created: false,
        updated: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        gpt_web_chat: [],
        pending: false,
        created: false,
        updated: false,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
