import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CATEGORY_VISIBILITY } from "data/AdminData";

import { Table } from "semantic-ui-react";

const CategoryTags = ({ rows }) => {
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Value</Table.HeaderCell>
            <Table.HeaderCell width={2}>Priority</Table.HeaderCell>
            <Table.HeaderCell width={2}>Visibility</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                  <ContainerBox>
                    <span>{row?.name}</span>
                    <span>{row?.name_id}</span>
                  </ContainerBox>
                  </Table.Cell>
                  <Table.Cell>
                    <ContainerBox>
                      <span>{row?.value}</span>
                      <span>{row?.value_id}</span>
                    </ContainerBox>
                  </Table.Cell>
                  <Table.Cell>{row?.priority}</Table.Cell>
                  <Table.Cell>
                    {row?.visibility
                      ? CATEGORY_VISIBILITY.find(
                          ({ value }) => value === row?.visibility
                        )?.label
                      : "Not mentioned"}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </>
  );
};

CategoryTags.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default CategoryTags;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;
