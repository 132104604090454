import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { useParams } from "react-router";

import NavigationBar from './NavigationBar';

import Category from './Category';
import CleanUp from './CleanUp';
import TokenPlaceHolder from './TokenPlaceHolder';
import EmailTemplate from './EmailTemplate';
import Questions from './Questions';
import Responses from './Responses';
import Services from './Services';
import WhiteLabel from './WhiteLabel';

function OnBoarding() {
  const { id } = useParams();

  return (
    <div>
      
        <NavigationBar organizationId={id} />
      
      <Routes>
        <Route path="category" element={<Category organizationId={id} />} />
        <Route path="clean-up" element={<CleanUp organizationId={id} />} />
        <Route path="emails" element={<TokenPlaceHolder organizationId={id} />} />
        <Route path="email-template" element={<EmailTemplate organizationId={id} />} />
        <Route path="questions" element={<Questions organizationId={id} />} />
        <Route path="responses" element={<Responses organizationId={id} />} />
        <Route path="services" element={<Services organizationId={id} />} />
        <Route path="white-label" element={<WhiteLabel organizationId={id} />} />
        
        
        
        
      </Routes>
    </div>
  );
}

export default OnBoarding;

