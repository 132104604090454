import React from 'react'
import { Routes, Route } from 'react-router-dom';

import Details from './Details';

function EmployeeLog() {

  return (
    <>
      <Routes>
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default EmployeeLog;