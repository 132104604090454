
export const ValidEntry = {
    from_email: {
        disabled: false,
        description: `You this to customize the from email 
            Hello world <innerlogic@innerlogic.com>, you leave it blakn 
            for the default`,
        sample: 'Hello world <innerlogic@innerlogic.com>',
        color: 'green'
    },
    url: {
        disabled: false,
        description: `url destination for survey https://stg-survey.innerlogic.com/<edit-this-here>/01ef36b4-34ee-4b8e-9642-4096d6a143dd/`,
        sample: 'culture-audit',
        color: 'green'
    } ,
    definition: 
    {
        disabled: true,
        description: `Dont change this, python def() in api`,
        sample: 'not recommened to change this match def in python api',
        color: 'red'
    },
    short: {
        disabled: true,
        description: `Short description no space all small`,
        sample: 'not recommened to change this',
        color: 'red'
    },
    description:{
        disabled: false,
        description: `description of this email template`,
        sample: 'describe what this does',
        color: 'green'
    }, 
    active:{
        disabled: false,
        description: `if rule is active or not`,
        sample: 'true /false',
        color: 'green'
    }, 
    name:{
        disabled: false,
        description: `Name of this template`,
        sample: 'Sample Name',
        color: 'green'
    },
    postmark_template_id:{
        disabled: false,
        description: `Postmark Template Id, (all ending with template_id) `,
        sample: '33434',
        color: 'green'
    }, 
}