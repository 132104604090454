import React from 'react'
import { Icon , Popup } from 'semantic-ui-react'
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";


const Help = () =>{


	return (
		<Popup
          trigger={
          	<Circle>
          	 <a href="https://www.innerlogic.com/support" target="_blank">
			<Icon name="question"  style={{margin:0,marginTop:5,marginLeft:-2}}/>
			</a>
		</Circle>}
          content='Contact customer support'
          position='top right'
        />
		
		)
}



export default Help



const Circle = styled.div`
	position:fixed;
	bottom:20px;
	right:20px;
	border-radius:25px;
	height:50px;
	width:50px;
	background-color:#6a00ff;
	display:flex;
	align-items:center;
	justify-content:center;
	color:white;
	font-size:25px;
	cursor:pointer;

	a{
		color:white;
	}
`