import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, Header, Card, List } from "semantic-ui-react";

import { format } from "date-fns";
//
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_DEBRIEF_SCHEDULE,
  SG_GET_ORGANIZATION_SELECT,
  SG_GET_CHAT_GPT,
} from "constants/actions";
//
//
import { ErrorWrapperCustom } from "error/component_error";
import AdditionalType from "./AdditionalType";
import EmpList from "./EmpList";
import ExtendSurvey from "./ExtendSurvey";
import MlFeedback from "./MlFeedback";
// import FixSurveyResponses from "./FixSurveyResponses"
import BasicErrorMessage from "utilities/ErrorMessages";
import SurveySummary from "./SurveySummary";
import FixAiResponse from "./FixAiResponse";
import TokenList from "briefToken/List";
import ResponseList from "briefResponse/List";
import AiSummary from "./AiSummary";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [deBriefId, setDebriefId] = useState(null);
  const [debriefDetails, setDebriefDetails] = useState(null);
  const [empUpdateRow, setEmpUpdateRow] = useState(true);
  const [searchTerm, setSearchTerm] = useState(false)

  function Back() {
    navigate(-1);
  }
  useEffect(() => {
    setDebriefId(id);
  }, [id]);

  useEffect(() => {
    // dispatch({
    //   type: SG_GET_SURVEY_QUESTION_SELECT,
    // });
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (deBriefId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: { id: deBriefId },
      });
      dispatch({
        type: SG_GET_CHAT_GPT,
        payload: `debrief-schedule-id=${deBriefId}`,
      });
    }
  }, [dispatch, deBriefId]);
  //

  const { get_brief_schedules, get_organization_select, getchat_gpt } =
    useSelector(
      (state) => ({
        get_brief_schedules: state.debrief_schedule,
        get_organization_select: state.organizations.organization_select,
        getchat_gpt: state.chat_gpt,
      }),
      shallowEqual
    );

  useEffect(() => {
    setSearchTerm(get_brief_schedules.search_term)
  }, [get_brief_schedules])

  useEffect(() => {
    setDebriefDetails(get_brief_schedules?.[deBriefId]);
  }, [get_brief_schedules, deBriefId]);

  return (
    <>
      <BasicErrorMessage />
      <Header as="h2">
        <Icon style={{ cursor: "pointer" }} onClick={Back} name="arrow left" />
        Details of {debriefDetails?.type_of_text}{" "}
        {debriefDetails?.survey_type_text}
      </Header>

      {debriefDetails?.status > 3 && (
        <ExtendSurvey debriefDetails={debriefDetails} />
      )}
      {debriefDetails?.status > 3 && (
        <MlFeedback debriefDetails={debriefDetails} />
      )}

      <FixAiResponse deBriefId={deBriefId} />

      <SurveySummary debriefDetails={debriefDetails} />

      <Card fluid key={debriefDetails?.id}>
        <Card.Content>
          <Container>
            <ListContainer>
              <HeaderDiv>Org Id</HeaderDiv>
              <ContentDiv>{debriefDetails?.organization}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Org</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.organization &&
                  get_organization_select.filter(
                    (org) => org.id === debriefDetails?.organization
                  )?.[0]?.name}
              </ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Type</HeaderDiv>
              <ContentDiv>{debriefDetails?.type_of_text}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status</HeaderDiv>
              <ContentDiv>{debriefDetails?.status_text}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Time Zone</HeaderDiv>
              <ContentDiv>{debriefDetails?.time_zone}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Question</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.brief_question?.name} (
                {debriefDetails?.brief_question?.sort_order}){" "}
                {debriefDetails?.brief_question?.status_text}{" "}
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/app/questions/details/${debriefDetails?.brief_question?.id}`
                    )
                  }
                  color="violet"
                  name="external alternate"
                />
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Name</HeaderDiv>
              <ContentDiv>{debriefDetails?.name}</ContentDiv>
            </ListContainer>
          </Container>

          <Container>
            <ListContainer>
              <List divided horizontal>
                <List.Item>
                  <HeaderDiv>Secondary</HeaderDiv>
                </List.Item>
                {debriefDetails?.secondary ? (
                  debriefDetails.secondary.map((s) => {
                    return <List.Item key={s}>{s}</List.Item>;
                  })
                ) : (
                  <List.Item>All Secondary included</List.Item>
                )}
              </List>
            </ListContainer>
          </Container>

          <Container>
            <ListContainer>
              <HeaderDiv>Survey Id</HeaderDiv>
              <ContentDiv>{debriefDetails?.id}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.created_at &&
                  format(
                    new Date(debriefDetails?.created_at),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Scheduled Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.scheduled_for &&
                  format(
                    new Date(debriefDetails?.scheduled_for),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Close Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.close_at &&
                  format(
                    new Date(debriefDetails?.close_at),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Sent To</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_requests}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Responded</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_responses}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Name UID</HeaderDiv>
              <ContentDiv>{debriefDetails?.name_uid}</ContentDiv>
            </ListContainer>
          </Container>
        </Card.Content>
      </Card>
      <SectionDiv>
        <AdditionalType data={debriefDetails?.additional_type} debriefDetailsId={debriefDetails?.id} />

      </SectionDiv>

      {!searchTerm && <ContentSplitRow>
        <ContentSplitCol>
          <Header sub>Members included and completed status</Header>
          <EmpList rows={debriefDetails?.request_list} completed={debriefDetails?.compliance} />
        </ContentSplitCol>
      </ContentSplitRow>}

      {!searchTerm && <>
        <Header as="h3">AI Summary</Header>
        <ErrorWrapperCustom>
          <AiSummary getchat_gpt={getchat_gpt} />
        </ErrorWrapperCustom>
      </>
      }
      <Header as="h3">Tokens</Header>
      <TokenList deBriefId={deBriefId} searchTerm={searchTerm} />
      <Header as="h3">Responses</Header>
      <ResponseList
        deBriefId={deBriefId}
        empUpdateRow={empUpdateRow}
        setEmpUpdateRow={setEmpUpdateRow}
        searchTerm={searchTerm}
      />
    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
`;
const ContentSplitRow = styled.div`
  display: flex;
`;

const ContentSplitCol = styled.div`
  flex: 1;

  &:first-child {
    width: 10%;
  }

  &:last-child {
    width: 90%;
  }
`;

