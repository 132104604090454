import React, { useState, useEffect } from "react";
import styled from "styled-components";

import OrgCard from "./OrgCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_ORGANIZATIONS, UPDATE_ORGANIZATION_PAGE_NUMBER } from "constants/actions";

import { SUB_DOMAIN_NAMES } from "data/AdminData"

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

const List = () => {

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: LOAD_ORGANIZATIONS,
            payload: `page=${page}&id-list=[1036,1039]`,
        });

        setLoading(true)
    }, [dispatch, page, searchTerm]);

    const { get_organizations } = useSelector(
        (state) => ({
            get_organizations: state.organizations,
        }),
        shallowEqual
    );
  

    useEffect(() => {
        setResults(get_organizations?.organizations?.results);
        setPage(get_organizations?.pageNumber)

        const _count = get_organizations?.organizations?.count
        const _page_size = get_organizations?.organizations?.page_size
       
        setTotalPages(Math.ceil(_count / _page_size))
    
    }, [get_organizations, page]);

    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_ORGANIZATION_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    // console.log(totalPages)

    // console.log(get_organizations?.organizations?.count % get_organizations?.organizations?.page_size)
    // console.log(Math.floor((get_organizations?.organizations?.count / get_organizations?.organizations?.page_size)))

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <Container>

       

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results.map((result) => (
                        <OrgCard
                            SUB_DOMAIN_NAMES={SUB_DOMAIN_NAMES}
                            row={result} key={result?.id} />
                    ))}
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page || 1}
                    totalPages={totalPages || 1}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
  margin:10px 0px;
  min-height: 110px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};

`