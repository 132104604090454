import { combineReducers } from "redux";

import errors from "./errors";

import auth from "./auth";
import account from "./accounts"
//
import blogs from './blogs';
import blogTag from './blogtag';
import blogCategory from './blogcategory';
// data for reports
import emotion_definitions from "./emotion_definitions";
import moods from "./moods";
import personality from "./personality";
import culture from "./culture";
import leadership from "./leadership";
import employee_report from "./employee_report";
import employee_category from "./employee_category";
import employee_permission from "./employee_permission";
import industry_benchmark from "./industry_benchmark";
import marketing_roi from "./marketing_roi"
import chat_gpt from "./chat_gpt";
// signup
import signupSteps from "./signup_steps";
// forms
import departments from "./departments"
import employees from "./employees"
import ambassadors from "./ambassadors"
import employee_roles from "./employee_roles"
import employee_log from "./employee_log";
import employee_record from "./employee_record";
import employee_record_list from "./employee_record_list";
import employee_record_value from "./employee_record_value";
import onboarding from "./onboarding"
import organizations from "./organizations"
import teams from "./teams"
import team_access from "./team_access"
import team_types from "./team_types"
import payments from "./payments"
import subscription from "./subscription"
// debriefs
import debrief_response from "./debrief_response";
import debrief_schedule from "./debrief_schedule";
import debrief_token from "./debrief_token";
import debrief_summary from "./debrief_summary";
import debrief_analytics from "./debrief_analytics";
import report_template from "./report_template";
// settings
import survey_standard from "./survey_standard";
import activity_log from "./activity_log";
import fileupload from "./fileupload";
import emailTemplate from "./emailTemplate";
import pdfTemplate from "./pdfTemplate";
import surveystructure from "./surveystructure";
import surveystructurecompliance from "./surveystructurecompliance";
import surveyquestion from "./surveyquestion";
import price from "./price";
import signuptoken from "./signuptoken";
import surveytoken from "./surveytoken";
import surveyresponse from "./surveyresponse";
import surveyschedule from "./surveyschedule";
import surveyschedulefrequency from "./surveyschedulefrequency";
import surveycompliance from "./surveycompliance";
import leadersinschedule from "./leadersinschedule";
import leadersurveyparticipants from "./leadersurveyparticipants";
import teamsinschedule from "./teamsinschedule";
import personalityteams from "./personalityteams";
import personalityparticipants from "./personalityparticipants";
import validateEmail from "./validateEmail";
import emaildeliverystatus from "./emaildeliverystatus";
import surveysettinglist from "./surveysettinglist";
import applisturl from "./applisturl";

import webimages from "./webimages";
// amabsaasdor switch
import selectedOrg from "./selectedOrg";
//Temp
import white_label from './white_label'
//Audit
import audit from './audit'
import admin_default from './admin_default'
// GPT
import gpt_summary from "./gpt_summary"
import gpt_model_main from "./gpt_model_main"
import gpt_response_main from "./gpt_response_main"
import gpt_response_score from "./gpt_response_score"
import gpt_members from "./gpt_members"
import gpt_web_chat from "./gpt_web_chat"
import gpt_recommendation from "./gpt_recommendation"
import gpt_web_chat_process from "./gpt_web_chat_process"

export default combineReducers({
  errors,
  auth,
  account,
  activity_log,
  selectedOrg,
  culture,
  blogs,
  blogTag,
  blogCategory,
  marketing_roi,
  emotion_definitions,
  moods,
  leadership,
  personality,
  departments,
  employees,
  ambassadors,
  employee_report,
  employee_roles,
  employee_log,
  employee_record,
  employee_record_list,
  employee_record_value,
  employee_category,
  employee_permission,
  organizations,
  industry_benchmark,
  onboarding,
  debrief_response,
  debrief_schedule,
  debrief_token,
  debrief_summary,
  debrief_analytics,
  chat_gpt,
  teams,
  team_access,
  fileupload,
  emailTemplate,
  pdfTemplate,
  price,
  subscription,
  gpt_summary,
  gpt_model_main,
  gpt_response_main,
  gpt_response_score,
  gpt_members,
  gpt_web_chat,
  gpt_web_chat_process,
  gpt_recommendation,
  payments,
  report_template,
  signupSteps,
  surveyquestion,
  surveyschedule,
  surveystructure,
  surveystructurecompliance,
  surveyschedulefrequency,
  surveycompliance,
  surveysettinglist,
  survey_standard,
  personalityteams,
  personalityparticipants,
  leadersinschedule,
  leadersurveyparticipants,
  teamsinschedule,
  signuptoken,
  surveytoken,
  surveyresponse,
  team_types,
  validateEmail,
  emaildeliverystatus,
  applisturl,

  white_label,
  audit,
  webimages,
  admin_default,
});
