import { call, put, all, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_WEB_IMAGES,
    SG_GET_WEB_IMAGES,
    DELETE_WEB_IMAGE,
    SG_DELETE_WEB_IMAGE,
    SG_FETCH_WEB_IMAGE,
    FETCH_WEB_IMAGE,
    SG_ADD_WEB_IMAGE,
    ADD_WEB_IMAGE,
    SG_EDIT_WEB_IMAGE,
    EDIT_WEB_IMAGE,
    // CLEAR_EMAIL,
    // SG_CLEAR_EMAIL,
    ALL_ERRORS,
} from "constants/actions";

function* loadWebImages(action) {
  try {
    const json = yield call(api.GET_WEB_IMAGES, action.payload);
    yield put({ type: GET_WEB_IMAGES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* webImageLoad() {
  yield takeLatest(SG_GET_WEB_IMAGES, loadWebImages);
}

function* fetchWebImage(action) {
  try {
    const json = yield call(api.FETCH_WEB_IMAGE, action.payload);
    yield put({ type: FETCH_WEB_IMAGE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* webImageFetch() {
  yield takeLatest(SG_FETCH_WEB_IMAGE, fetchWebImage);
}

function* updateWebImage(action) {
  try {
    const json = yield call(api.EDIT_WEB_IMAGE, action.payload);
    yield put({ type: EDIT_WEB_IMAGE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* webImageUpdate() {
  yield takeLatest(SG_EDIT_WEB_IMAGE, updateWebImage);
}


function* deleteWebImage(action) {
  try {
    const json = yield call(api.DELETE_WEB_IMAGE, action.payload);
    yield put({ type: DELETE_WEB_IMAGE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* webImageDelete() {
  yield takeLatest(SG_DELETE_WEB_IMAGE, deleteWebImage);
}

function* addWebImage(action) {
  try {
    const json = yield call(api.ADD_WEB_IMAGE, action.payload);
    yield put({ type: ADD_WEB_IMAGE, payload: json.data });

  } catch (e) {

    yield put({ type: ALL_ERRORS, message: e.response});
  }
}

export function* webImageAdd() {
  yield takeLatest(SG_ADD_WEB_IMAGE, addWebImage);
}


export default function* index() {
  yield all([
    webImageLoad(),
    webImageFetch(),
    webImageUpdate(),
    webImageDelete(),
    webImageAdd()
  ]);
}
