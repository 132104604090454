import React from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react'

// CLEAR_BLOG

const Toolbar = ({label, searchTerm, setSearchTerm, error }) => {
  
    return (
        <>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form success>
            <Form.Input label={label}
            value={searchTerm || ''}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search ${label}... ` }/>
            
        </Form>
        </>
        
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;

const ErrorMessage = styled.div`
    padding: 10px 15px;
    color: red;
`