import React from "react";

import styled from "styled-components";


import { Table, Header } from "semantic-ui-react";

const PermissionList = ({ header, rows }) => {
  console.log(rows)
  return (
    <>
    <SectionDiv>
        <Header as='h3'>{header}</Header>
        
      </SectionDiv>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Value</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                  <ContainerBox>
                    <span>{row?.name}</span>
                   
                  </ContainerBox>
                  </Table.Cell>
                  <Table.Cell>
                    <ContainerBox>
                      <span>{row?.value}</span>
                      
                    </ContainerBox>
                  </Table.Cell>
                  
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </>
  )
};

// PermissionList.propTypes = {
//   rows: PropTypes.array.isRequired,
// };

export default PermissionList;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
`