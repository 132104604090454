import {
  // EMAIL_TEMPLATE_SELECT,
  UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER,
  EMAIL_TEMPLATE_SELECT,
  GET_EMAIL_TEMPLATES,
  COPY_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE,
  CLEAR_EMAIL_TEMPLATE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  email_templates: [],
  email_template_select: [],
  email_template_copied: [],
  pending: false,
  pageNumber: 1,
};

export default function SuveyToken(state = initialState, action) {
  switch (action.type) {
    case EMAIL_TEMPLATE_SELECT:
      return {
        ...state,
        email_template_select: action.payload,
        pending: false,
      };
    case GET_EMAIL_TEMPLATES:
      return {
        ...state,
        email_templates: action.payload,
        pending: false,
      };
    case COPY_EMAIL_TEMPLATE:
        return {
          ...state,
          email_template_copied: action.payload,
          pending: false,
        };
    case FETCH_EMAIL_TEMPLATE:
      return { 
        ...state, 
        [action.payload.id]: action.payload, 
        pending: false 
      };
      case UPDATE_EMAIL_TEMPLATE_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
        }
      case EDIT_EMAIL_TEMPLATE:
        return { 
          ...state, 
          [action.payload.id]: action.payload, 
          pending: false };

        
    case ADD_EMAIL_TEMPLATE:
        return {
          ...state,
          email_templates: [...state.email_templates, action.payload],
        };
    
    case DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        email_templates: state.email_templates.filter((row) => row.id !== action.payload),
      };

    case CLEAR_EMAIL_TEMPLATE:
      return {
        ...state,
        email_templates: [],
        email_template_select: [],
        email_template_copied: [],
        pending: false,
        pageNumber: 1,
      };

    case CLEAR_ALL:
      return {
        ...state,
        email_templates: [],
        email_template_list: [],
        email_template_copied: [],
        pending: false,
        pageNumber: 1,
      };
    default:
      return state;
  }
}
