import {
  PRE_VALIDATE_EMAIL,
  CLEAR_PRE_VALIDATE_EMAIL,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_LIST,
  CLEAR_VALIDATE_EMAIL,
  VALIDATE_TOKEN,
  CLEAR_VALIDATE_TOKEN,
  VALIDATE_ORGANIZATION,
  CLEAR_VALIDATE_ORGANIZATION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  preValidateEmail: [],
  validateEmail: [],
  validateToken: [],
  validateOrg: [],
  pending: false,
};

export default function ValidateEmail (state = initialState, action) {
  switch (action.type) {
    case PRE_VALIDATE_EMAIL:
      return {
        ...state,
        preValidateEmail: action.payload,
        pending: false,
      };
     
    case CLEAR_PRE_VALIDATE_EMAIL:
      return {
        ...state,
        preValidateEmail: [],
        pending: false,
      };
      
    case VALIDATE_EMAIL:
      return {
        ...state,
        validateEmail: action.payload,
        pending: false,
      };
     case VALIDATE_EMAIL_LIST:
      return {
        ...state,
        [action.payload.email]: action.payload, 
        pending: false,
      };
    case CLEAR_VALIDATE_EMAIL:
      return {
        ...state,
        validateEmail: [],
        pending: false,
      };
      case VALIDATE_ORGANIZATION:
        return {
          ...state,
          validateOrg: action.payload,
          pending: false,
        };
    case CLEAR_VALIDATE_ORGANIZATION:
      return {
        ...state,
        validateOrg: [],
        pending: false,
      };
    case VALIDATE_TOKEN:
      return {
        ...state,
        validateToken: action.payload,
        pending: false,
      };
    case CLEAR_VALIDATE_TOKEN:
      return {
        ...state,
        validateToken: [],
        validateOrg: [],
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        validateEmail: [],
        validateOrg: [],
        validateToken: [],
        pending: false,
      };
    default:
      return state;
  }
}
