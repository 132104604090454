import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { Message, Modal, Input} from 'semantic-ui-react'
import { useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_ADD_SURVEY_STRUCTURE,
    GET_AM_ORGANIZATION,
} from "constants/actions";

import Select from "react-select";
const QUERY_PARAMS = "organization"

const NewCategory = ({  trigger, setOrganizationId }) => {
    const dispatch = useDispatch();
    const [pristine, setPristine] = useState(true)
    const [createCategory, setCreateCategory] = useState(false)

    const [formErrors, setFormErrors] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [organizationSelect, setOrganizationSelect] = useState([])
    const [extOrganizationList, setExtOrganizationList] = useState([])
    const [organizationSelected, setOrganizationSelected] = useState([])

    const [searchParams, setSearchParams] = useSearchParams();
    const newQueryParameters = new URLSearchParams();

    const onSubmit = () => {
        if (formErrors.length > 0) {
            setPristine(false)
            return
        }
        setPristine(true)
        dispatch({
            type: SG_ADD_SURVEY_STRUCTURE,
            payload: {
                organization: organizationSelected?.id,
                name: categoryName,
            },
        });
        setOrganizationId(organizationSelected?.id)
        dispatch({
            type: GET_AM_ORGANIZATION,
            payload: organizationSelected
        });
        setCreateCategory(false)
        setCategoryName(null)
        newQueryParameters.set(QUERY_PARAMS, organizationSelected.id)
        setSearchParams(newQueryParameters)
    }

    const { get_survey_structure,
        get_organization_select } = useSelector(
            (state) => ({
                get_survey_structure: state.surveystructure.survey_struture_select,
                get_organization_select: state.organizations.organization_select,
            }),
            shallowEqual
        );

        useEffect(() => {
            setExtOrganizationList(get_survey_structure.map(ss => ss.organization))
        }, [get_survey_structure]);

    useEffect(() => {
        setOrganizationSelect(
            get_organization_select.filter(or => !extOrganizationList.includes(or.id)))

    }, [get_organization_select, extOrganizationList]);

    useEffect(() => {
        const ErrorList = []

        if (!categoryName) {
            ErrorList.push("Enter a Name")
        }
        if (!organizationSelected?.id) {
            ErrorList.push("No Organization Found")
        }

        setFormErrors(ErrorList)
    }, [categoryName, pristine, organizationSelected]);


    return (
        <Modal
            onClose={() => setCreateCategory(false)}
            onOpen={() => setCreateCategory(true)}
            open={createCategory}
            trigger={trigger}
            size={"small"}
        >
            <Modal.Header>
                Create a category file with a Name
            </Modal.Header>
            <div style={{ display: 'flex' }}>
                <Half>
                    {formErrors.length > 0 && !pristine &&
                        <Message negative
                            //   onDismiss={clearErrors}
                            header='We need these details!'
                            list={formErrors}
                        />
                    }
                    {/* {error ? <ErrorMessage>*{error}</ErrorMessage> : ""} */}
                    <FormContainer>
                        <Label>Target Organization</Label>
                        <Select
                            name="survey_preference"
                            options={organizationSelect}
                            onChange={setOrganizationSelected}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => `${option.id}`}
                            value={organizationSelected}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Category Name</Label>
                        <Input
                            fluid
                            placeholder='Category Name'
                            name="name"
                            // defaultValue={surveyStruc?.name}
                            value={categoryName}
                            onChange={(v) => setCategoryName(v.target.value)}
                        />
                    </FormContainer>


                    <Submit
                        floated='right'
                        disable={formErrors.length > 0 && !pristine}
                        onClick={onSubmit}>Create</Submit>
                </Half>
            </div>
        </Modal >
    )
}

export default NewCategory

const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
    width: 100%;
	margin-top:20px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;

`
