import {
  GET_LEADER_SURVEY_PARTICIPANTS,
  DELETE_LEADER_SURVEY_PARTICIPANT,
  FETCH_LEADER_SURVEY_PARTICIPANT,
  EDIT_LEADER_SURVEY_PARTICIPANT,
  ADD_LEADER_SURVEY_PARTICIPANT,
  CLEAR_LEADER_SURVEY_PARTICIPANT,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  leader_survey_participants: [],
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEADER_SURVEY_PARTICIPANTS:
      return {
        ...state,
        leader_survey_participants: action.payload,
        pending: false,
      };

    case FETCH_LEADER_SURVEY_PARTICIPANT:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_LEADER_SURVEY_PARTICIPANT:
      const schedule = state.leader_survey_participants.filter(
        (row) => row.id !== action.payload.id
      );
      schedule.push(action.payload);
      return { ...state, leader_survey_participants: schedule };

    case DELETE_LEADER_SURVEY_PARTICIPANT:
      return {
        ...state,
        leader_survey_participants: state.leader_survey_participants.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_LEADER_SURVEY_PARTICIPANT:
      return {
        ...state,
        leader_survey_participants: [
          ...state.leader_survey_participants,
          action.payload,
        ],
      };

    case CLEAR_LEADER_SURVEY_PARTICIPANT:
      return {
        ...state,
        leader_survey_participants: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        leader_survey_participants: [],
      };

    default:
      return state;
  }
}
