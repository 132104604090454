import {
  SET_WHITE_LABEL,
  CLEAR_ALL
} from "constants/actions";


const initialState = {
  white_label:{}
};


export default function WhiteLabel (state = initialState, action) {
  switch (action.type) {
    case SET_WHITE_LABEL:
      return {
        ...state,
        white_label:action.payload
      };
     case CLEAR_ALL:
      return {
        ...state,
        white_label:{}
      };
    default:
      return state;
  }
}
