import React, { useState } from 'react';
import styled from "styled-components";
import { Card, Icon } from 'semantic-ui-react'

//
export const ContentDetail = ({ briefResponse }) => {
  const [showDetails, setShowDetails] = useState(false)

  const ListOfResponses = [
  {
    name: "Comments",
    response: briefResponse?.questions?.comments,
  },
  {
    name: "Category",
    response: briefResponse?.questions?.categories,
  },
  {
    name: "Employee Records",
    response: briefResponse?.questions?.employee_records,
  },
  {
    name: "Values Builder",
    response: briefResponse?.modular_response?.values_builder?.responses,
  },
  {
    name: "Outcome Question",
    response: briefResponse?.modular_response?.outcome_question?.responses,
  },
  {
    name: "Feedback",
    response: briefResponse?.feedback,
  },
  {
    name: "Personality",
    response: briefResponse?.survey_personality,
  },
  {
    name: "Questions",
    response: briefResponse?.questions?.questions,
  },
  {
    name: "Question Map",
    response: briefResponse?.total_question_map,
  }
  ]

  function SetDispVal(e) {
    if (showDetails === e) {
      setShowDetails(false)
    } else {
      setShowDetails(e)
    }
  }
  return (
    <>
      <Card fluid >
        <Card.Content>
          {ListOfResponses.map((res, i) => {
            return <Container key={i}>

              <ListContainer>
                <HeaderDiv>{res.name} <Icon onClick={() => SetDispVal(res.name)}
                  name={showDetails === res.name ? 'minus' : 'add'} /></HeaderDiv>
                <ContentDiv show={showDetails === res.name ? true : false}>
                  <pre>
                    {JSON.stringify(res.response, null, 2)}
                  </pre>
                </ContentDiv>
              </ListContainer>
            </Container>
          })}
        

           
        </Card.Content>
      </Card>
    </>
  );
};


export const Summary = ({ briefChanggeHistory }) => {
  const [showDetails, setShowDetails] = useState(false)

  const propertyNames = Object.keys(briefChanggeHistory);
  const propertyValues = Object.values(briefChanggeHistory);
  const entries = Object.entries(briefChanggeHistory);

  function SetDispVal(e) {
    if (showDetails === e) {
      setShowDetails(false)
    } else {
      setShowDetails(e)
    }
  }
  return (
    <>
      <Card fluid >
        <Card.Content>
          {entries.map((res, i) => {
            return <Container key={i}>

              <ListContainer>
                <HeaderDiv>{propertyNames[i]} <Icon onClick={() => SetDispVal(res.name)}
                  name={showDetails === res.name ? 'minus' : 'add'} /></HeaderDiv>
                <ContentDiv show={showDetails === res.name ? true : false}>
                  <pre>
                    {JSON.stringify(propertyValues[i], null, 2)}
                  </pre>
                </ContentDiv>
              </ListContainer>
            </Container>
          })}
        

           
        </Card.Content>
      </Card>

    </>
  );
};
export default ContentDetail;

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  font-size: smaller;
  display: ${(props) => (props.show ? "block" : "none")};

`