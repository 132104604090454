import {
    GET_INDUSTRY_BENCHMARK,
    // FETCH_INDUSTRY_BENCHMARK,
    CLEAR_INDUSTRY_BENCHMARK,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    industry_benchmark: [],
    pending: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_INDUSTRY_BENCHMARK:
        return {
          ...state,
          industry_benchmark: action.payload,
          pending: false,
        };

    //   case FETCH_INDUSTRY_BENCHMARK:
    //     return { 
    //         ...state,
    //         [action.payload.id]: action.payload, 
    //         pending: false 
    //     };

      case CLEAR_INDUSTRY_BENCHMARK:
        return {
          ...state,
          industry_benchmark: [],
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          industry_benchmark: [],
        };
  
      default:
        return state;
    }
  }