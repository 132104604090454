import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from 'date-fns'
import { RowCheckList, RowObjectList } from "./Components";

import { Card, Image, Button } from 'semantic-ui-react'

import {
  GET_AM_ORGANIZATION,
} from "constants/actions";

function RowCard ({ row} ) {
  const navigate = useNavigate()
  const dispatch = useDispatch();


  function SelectedOrg() {
    dispatch({
      type: GET_AM_ORGANIZATION,
      payload: row
    });
    navigate(`/app/organizations/details/${row?.id}`)
  }
  const utcDateTime = utcToZonedTime(new Date(), 'UTC')
  const [localDateTime, setLocalDateTime] = useState(zonedTimeToUtc(new Date(), 'America/Halifax'))

  useEffect(() => {
    if (row?.time_zone) {
      const date = new Date()
      const timeZone = row?.time_zone
      const zonedDate = utcToZonedTime(date, timeZone)
      // const pattern = 'dd.MMM.yyyy HH:mm XXX (z)'
      // const output = format(zonedDate, pattern)
      setLocalDateTime(zonedDate)
    }
  }, [row]);
  // console.log(stylingList)

  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header># {row?.id} - {row?.name}</Card.Header>
          <Container>
            <SingleRowContent>
              <SectionCell>
                <p>
                  Created {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy')}
                </p>
                <p>
                  Domain {row?.sub_domain_text}
                </p>
              </SectionCell>
              <SectionCell>
                <p>UTC Time {format(utcDateTime, 'dd-MMM-yyyy hh:mm')}</p>
                <p>
                  Local Time {localDateTime && format(localDateTime, 'dd-MMM-yyyy hh:mm XXX (z)')}
                </p>
              </SectionCell>
              <SectionCell>
                {row?.time_zone}
              </SectionCell>

            </SingleRowContent>
          </Container>

          <Container>

            <LeftHalf>
              <RowData>
                <RowLine>
                  {RowCheckList(row?.services_enabled)}
                </RowLine>
                <RowLine>
                  {RowObjectList(row?.styling) }
                </RowLine>
              </RowData>

            </LeftHalf>
            <RightHalf>
              <Image
                floated='right'
                size='mini'
                src={row?.logo}
              />
            </RightHalf>
          </Container>

          <Card.Description>


            <Button floated='right'
              onClick={SelectedOrg}>
              More Details
            </Button>

          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;




const Container = styled.div`
  display: flex;
`

const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`

const RowLine = styled.span`
  max-width: 400px;
  // font-size: 100%;
  margin: 0 10px;
  display: inline-block;
`

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`