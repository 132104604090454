import {
  GET_SURVEY_RESPONSES,
  SURVEY_RESPONSE_PAGE_NUMBER,
  DELETE_SURVEY_RESPONSE,
  CLEAR_SURVEY_RESPONSE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  survey_responses: [],
  pending: false,
  pageNumber: 1,
};

export default function SurveyResponse(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_RESPONSES:
      return {
        ...state,
        survey_responses: action.payload,
        pending: false,
      };
    case SURVEY_RESPONSE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      }
    case DELETE_SURVEY_RESPONSE:
      return {
        ...state,
        survey_responses: state.survey_responses.filter(
          (row) => row.id !== action.payload
        ),
      };

    case CLEAR_SURVEY_RESPONSE:
      return {
        ...state,
        survey_responses: [],
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        survey_responses: [],
        pending: false,
      };
    default:
      return state;
  }
}
