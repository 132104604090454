import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import {

  SG_GET_DEBRIEF_ANALYTICS,
  SG_GET_DEBRIEF_SUMMARY_NEXT,
} from "constants/actions";

import Update from "./Update"

const Create = ({newResponse, setNewResponse}) => {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_ANALYTICS,
    });
  }, [dispatch]);

  useEffect(() => {
      dispatch({
          type: SG_GET_DEBRIEF_SUMMARY_NEXT,
      });
  },[dispatch, newResponse])



  return ( <Update  newResponse={newResponse} setNewResponse={setNewResponse} /> );
};

export default Create;


