import {ErrorBoundary} from 'react-error-boundary'
import styled, { keyframes } from "styled-components";
import Pic from 'assets/images/empty/error.png'


function ErrorFallback({error, resetErrorBoundary}) {
	

	const safety = () =>{
		 resetErrorBoundary()
	}
  return (
    <Container>

    	<Image src={Pic} />
    	<Text>Oh dang, we can't load this for you right now.</Text>
    	 <Button onClick={()=>safety()}>Try again</Button>
    </Container>
  )
}

function ErrorFallbackCustom({error}) {
	

	const safety = () =>{
		window.location.reload();
	}
  return (
    <Container>

    	<HeaderText>
			Error Details
		</HeaderText>
    	<Text><pre>{JSON.stringify(error)}</pre></Text>
    	 <Button onClick={()=>safety()}>Reload Page </Button>
    </Container>
  )
}

const ErrorWrapper =(props)=>{

	return (
			  <ErrorBoundary
			    FallbackComponent={ErrorFallback}
			    onReset={() => {
			      // reset the state of your app so the error doesn't happen again
			    }}
			  >
			   		{props.children}
			  </ErrorBoundary>
			)

} 


export const ErrorWrapperCustom =(props)=>{

	return (
			  <ErrorBoundary
			    FallbackComponent={ErrorFallbackCustom}
			    onReset={() => {
			      // reset the state of your app so the error doesn't happen again
				  console.log("On Reset Clicked")
			    }}
			  >
			   		{props.children}
			  </ErrorBoundary>
			)

} 

export default ErrorWrapper


const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:100%;
	justify-content:center;
	min-height:400px;
	padding:30px;
`

const Text = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:30px;
	text-align:center;
	font-size:14px;
	max-width:100%;
`
const HeaderText = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:30px;
	text-align:center;
	font-weight: 600px;
	font-size:18px;
	max-width:100%;
`

const Image = styled.img`
	width:150px;
`

const Button = styled.div`
  width: 130px;
  height: 30px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;

`