import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_EMPLOYEE_RECORD_VALUE,
  SG_UPDATE_EMPLOYEE_RECORD_VALUE,
  SG_ADD_EMPLOYEE_RECORD_VALUE,
  SG_DELETE_EMPLOYEE_RECORD_VALUE,
  SG_GET_ORGANIZATION_SELECT,
} from "constants/actions";
//
import { Icon, Message, Button, Header, List, Input, 
  Confirm, Segment, Checkbox } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { EMP_VALUE_TYPES, EMP_VALUE_DATA_ACTION, EMP_VALUE_DATA_FOR } from "data/AdminData"
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"
//
import EmployeeRecordList from "employeeRecordList/List"
import EmployeeRecordAdd from "employeeRecordList/QuickAdd"

//
const AddEditValue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });
  const [orgSelected, setOrgSelected] = useState([])
  const [typeOfSelected, setTypeOfSelected] = useState([])
  const [dataActionSelected, setDataActionSelected] = useState([])
  const [dataForSelected, setDataForSelected] = useState([])


  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [visibleField, setVisibleField] = useState(0)



  function BackArrow() {
    navigate(`/app/employee-record-value`)
  }

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_EMPLOYEE_RECORD_VALUE,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  const { get_employee_record_value,
    get_organization_select, get_selectedOrg } = useSelector(
      (state) => ({
        get_employee_record_value: state.employee_record_value,
        get_organization_select: state.organizations.organization_select,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_employee_record_value?.[templateId]) {
      setTemplateDetails(get_employee_record_value?.[templateId])
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_employee_record_value?.[templateId]?.organization)?.[0])
      setTypeOfSelected(EMP_VALUE_TYPES.filter(sd => sd.value === get_employee_record_value?.[templateId]?.type_of_value)?.[0])
      setDataActionSelected(EMP_VALUE_DATA_ACTION.filter(sd => sd.value === get_employee_record_value?.[templateId]?.data_action)?.[0])
      setDataForSelected(EMP_VALUE_DATA_FOR.filter(sd => sd.value === get_employee_record_value?.[templateId]?.data_for)?.[0])
      setVisibleField(get_employee_record_value?.[templateId]?.sort_order)
    } else {
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_selectedOrg?.organization?.id)?.[0])
    }
  }, [get_employee_record_value, templateId, get_organization_select, get_selectedOrg]);


  useEffect(() => {
    if (get_employee_record_value?.created?.id) {
      navigate(`/app/employee-record-value/details/${get_employee_record_value?.created?.id}`)
    }
  }, [get_employee_record_value, navigate]);

  function ChangeVibility(){
    if (visibleField === 1) {
      setVisibleField(0)
      return
    }
    setVisibleField(1)
  }

  // console.log(visibleField)
  // console.log("updated", get_employee_record_value?.[templateId]?.sort_order)
  // console.log("templateDetails", templateDetails)

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_EMPLOYEE_RECORD_VALUE,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/employee-record-value?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_UPDATE_EMPLOYEE_RECORD_VALUE,
          payload: {
            id: templateId,
            name: templateDetails?.name,
            external_name: templateDetails?.external_name,
            cat_field_name: templateDetails?.cat_field_name,
            data_location: templateDetails?.data_location,
            type_of_value: typeOfSelected?.value,
            data_for: dataForSelected?.value,
            data_action: dataActionSelected?.value,
            organization: orgSelected?.id,
            sort_order: visibleField,
          }
        });
  
        // navigate(`/app/employee-record-value/details/${templateId}`)
      } else {

        dispatch({
          type: SG_ADD_EMPLOYEE_RECORD_VALUE,
          payload: {
            name: templateDetails?.name,
            external_name: templateDetails?.external_name,
            cat_field_name: templateDetails?.cat_field_name,
            data_location: templateDetails?.data_location,
            type_of_value: typeOfSelected?.value,
            data_for: dataForSelected?.value,
            data_action: dataActionSelected?.value,
            organization: orgSelected?.id,
            sort_order: visibleField,
          }
        });
      }
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }
    if (!typeOfSelected?.value) {
      ErrorList.push("Select a Type")
    }
    if (!dataActionSelected?.value) {
      ErrorList.push("Select a New Data Action")
    }
    if (!dataForSelected?.value) {
      ErrorList.push("Select a Data For")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    setFormErrors(ErrorList)
  }, [templateDetails, typeOfSelected, orgSelected,
    valueChanged, dataActionSelected, dataForSelected]);

  // console.log(templateDetails)
  // console.log(typeOfSelected)


  useEffect(() => {
    const SelectedVal = EMP_VALUE_TYPES.filter(em => em.value === templateDetails?.type_of_value)
    setTypeOfSelected(SelectedVal)
  }, [templateDetails, id]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails
    _ques[`${name}`] = e.target.value
    setTemplateDetails(_ques)
    setValueChanged(e.target.value + name)
  }

  function ChangeDomain(e) {
    setTypeOfSelected(e)
    setValueChanged(e)
  }

  function ChangeOrg(e) {
    setOrgSelected(e)
    setValueChanged(e)
  }

  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow} name='arrow left' />
        {templateId ? `Edit Details of ${templateDetails?.name}` : "Add New Value"}
      </Header>
      {formErrors.length > 0 && !pristine &&
        <Message negative
          //   onDismiss={clearErrors}
          header='We need these details!'
          list={formErrors}
        />
      }
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId &&
        <SectionDiv>
          <Container>
            <List horizontal>

              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>Name</List.Header>
                    {templateDetails?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>External Name</List.Header>
                    {templateDetails?.external_name}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization</List.Header>
                    {templateDetails?.organization ?
                      get_organization_select.filter(org => org.id === templateDetails?.organization)?.[0]?.name :
                      "For all Org"}
                  </ListContainer>
                </List.Content>
              </List.Item>



              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>

                </List.Content>
              </List.Item>

            </List>
          </Container>
        </SectionDiv>
      }

      <SectionDiv>
        {/*  */}
        <FormRow>
          <FormLabel>
            <LabelHead>Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}

            />
          </FormInput>
        </FormRow>
        {/*  */}

        <FormRow>
          <FormLabel>
            <LabelHead>External Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="external_name"
              defaultValue={templateDetails?.external_name}
              onChange={(e) => ChangeFormValues(e, "external_name")}

            />
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>
            <LabelHead>Category Field Name</LabelHead>
            <LabelTest>ALL SMALL this is what is used for CSV upload and api. 
            </LabelTest>
            <LabelTest>
              If you select Data For == Main then use can use this 
              <table>
                <tbody>
                <tr>
                  <td>
                    Name  
                  </td>
                  <td>
                    Category Field Name
                  </td>
                </tr>
                <tr>
                  
                  <td>
                  First Name  
                  </td>
                  <td>
                  first_name
                  </td>
                </tr>
                <tr>
                  <td>
                  Last Name 
                  </td>
                  <td>
                  last_name
                  </td>
                </tr>
                <tr>
                  <td>
                  Start Date
                  </td>
                  <td>
                  start_date
                  </td>
                </tr> 
                </tbody>
              </table>
                  
            </LabelTest>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="cat_field_name"
              defaultValue={templateDetails?.cat_field_name}
              onChange={(e) => ChangeFormValues(e, "cat_field_name")}

            />
            <label>All small => ref Categories > field_map > valid_field_name</label>
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Data Location</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="data_location"
              defaultValue={templateDetails?.data_location}
              onChange={(e) => ChangeFormValues(e, "data_location")}

            />
            <label>Seperated by dot (.) upto 5 deep</label>
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Visible</LabelHead>
          </FormLabel>
          <FormInput>
          <Checkbox toggle checked={visibleField? true : false} onClick={ChangeVibility}/>
          </FormInput>
        </FormRow>
{/*  */}

        <FormRow>
          <FormLabel>
            <LabelHead>Type Of</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="type_of_value"
              options={EMP_VALUE_TYPES}
              // isClearable
              onChange={(e) => ChangeDomain(e)}
              value={typeOfSelected}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Organization</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="org_preference"
              options={get_organization_select}
              onChange={(e) => ChangeOrg(e)}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              value={orgSelected}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>New Data Action</LabelHead>
          </FormLabel>
          <FormInput>
            <Select
              name="direction"
              options={EMP_VALUE_DATA_ACTION}
              onChange={(e) => setDataActionSelected(e)}
              value={dataActionSelected}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Data For</LabelHead>
            <LabelTest>Please make sure you have this information mapped in Surveystructure for Category</LabelTest>
            <LabelTest>Only valid values would be imported for Category</LabelTest>
          </FormLabel>
          <FormInput>
            
            <Select
              name="direction"
              options={EMP_VALUE_DATA_FOR}
              onChange={(e) => setDataForSelected(e)}
              value={dataForSelected}
            />
          </FormInput>
        </FormRow>
      </SectionDiv>



      <SectionDiv lastSection={true}>
        {templateId &&
          <>
            <Button floated='left'
              color="red"
              onClick={() => setConfirmOpen(true)}>
              Delete Value
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>}

        <Button floated='right'
          color="green"
          onClick={UpdateQuestions}>
          {templateId ? "Update Value" : "Create Value"}
        </Button>
      </SectionDiv>
      <SectionDiv>

      </SectionDiv>
      {templateId &&
        <>
          <Segment secondary>
            <Header as='h3' dividing>
              Existing Values which get replaced
            </Header>
            <EmployeeRecordList
              hideOrgSelect={templateDetails?.organization}
              employee_record_value={templateId} />
          </Segment>
          <Segment secondary>
            <Header as='h3' dividing>
              Add New values to List
            </Header>
            <EmployeeRecordAdd
              employee_record_value={templateId}
              organization_id={templateDetails?.organization}
            />
          </Segment>
        </>
      }
      <SectionDiv>

      </SectionDiv>

    </>
  );
};

export default AddEditValue;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 10px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const LabelTest = styled.label`
font-size:smaller;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`