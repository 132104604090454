import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CreateEdit from "./CreateEdit";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_GPT_MEMBERS,
  UPDATE_GPT_MEMBER_PAGE_NUMBER,
  SG_DELETE_GPT_MEMBER,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
} from "semantic-ui-react";

const List = ({  noPagination = false }) => {
  const dispatch = useDispatch();

  const [reloadPage, setReloadPage] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [editRow, setEditRow] = useState(false)

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_GPT_MEMBERS,
      payload: `page=${page}`,
    });

    setLoading(true);
  }, [dispatch, page, reloadPage]);

  const { get_gpt_members } = useSelector(
    (state) => ({
      get_gpt_members: state.gpt_members,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_members?.gpt_members?.results);
    setPage(get_gpt_members?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_members?.gpt_members?.count /
          get_gpt_members?.gpt_members?.page_size
      )
    );
    if (
      get_gpt_members?.gpt_members?.count %
      get_gpt_members?.gpt_members?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_members?.gpt_members?.count /
            get_gpt_members?.gpt_members?.page_size
        ) + 1
      );
    }
  }, [get_gpt_members, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_MEMBER_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    if(get_gpt_members?.created?.id) {
      setReloadPage(get_gpt_members?.created)
    }
  }, [get_gpt_members.created, setReloadPage])

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

  function DeleteRow(row) {
    // console.log(row)
    setLoading(true);
    dispatch({
      type: SG_DELETE_GPT_MEMBER,
      payload: {id: row?.id}
    });
    const timer1 = setTimeout(() => {
      setReloadPage(Math.random)
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
    
  }

  function EditRow(e) {
    setEditRow(e)
  }

  return (
    <Container>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results && <RowCard rows={results} EditRow={EditRow} DeleteRow={DeleteRow} />}
        </Card.Group>
      </Segment>

      {!noPagination && (
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      )}

        <CreateEdit setReloadPage={setReloadPage} gptMemberID={editRow?.id} reloadPage={reloadPage}  />
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
