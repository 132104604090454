import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Header, Image } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { useParams } from "react-router";
import {  useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_USER_EMPLOYEE } from "constants/actions";


const Details = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [employeeId, setEmployeeId] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState(null);

    function BackArrow() {
        navigate(-1)
    }
    useEffect(() => {
        setEmployeeId(id)
    }, [id]);

    useEffect(() => {
        if (employeeId) {
            dispatch({
                type: SG_FETCH_USER_EMPLOYEE,
                payload: { id: employeeId }
            });
        }
    }, [dispatch, employeeId]);

    const { get_employees } = useSelector(
        (state) => ({
            get_employees: state.employees,
        }),
        shallowEqual
    );

    useEffect(() => {
        setEmployeeDetails(get_employees?.userEmp)
        
    }, [get_employees, employeeId]);

    return (
        <>
            <Header as='h2'>
                <Icon
                style={{ cursor: "pointer" }}
                onClick={BackArrow} name='arrow left' />
                Details of {employeeDetails?.first_name}{" "}{employeeDetails?.last_name} {" "}{employeeDetails?.email}
            </Header>
            <SectionDiv>
                Created {employeeDetails?.created_at && format(new Date(employeeDetails?.created_at), 'dd-MMM-yyyy')}
                <Image
                    floated='right'
                    size='smalll'
                    src={employeeDetails?.picture}
                />
            </SectionDiv>
            <SectionDiv>
               
            </SectionDiv>
            <SectionDiv>
                <Header as='h3'></Header>
            </SectionDiv>
        </>
    );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
`
