import React, { useState, useEffect } from 'react';
import styled from 'styled-components';



const DataDisplay = ({ data }) => {

    return (data ?
        <Container>
            <DateStyled>Date: {data?.date}</DateStyled>
            <div>
                Tags:
                {data?.tags && data?.tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                ))}
            </div>
            <Factor>
                <FactorItem>Title: {data?.factors?.title}</FactorItem>
                <FactorItem>Level: {data?.factors?.level}</FactorItem>
                <FactorItem>Factor: {data?.factors?.factor}</FactorItem>
                <FactorItem>Dimension: {data?.factors?.dimension}</FactorItem>
            </Factor>
            <FeedbackLength>Feedback Length: {data?.feedback_length}</FeedbackLength>
            <FeedbackLength>Context Data Length: {data?.context_data_length}</FeedbackLength>
        </Container>
        : 
        <Container>No params</Container>
    );
};

export default DataDisplay;


const Container = styled.div`
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const DateStyled = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`;

const Tag = styled.span`
    background-color: #e0e0e0;
    padding: 5px;
    border-radius: 3px;
    margin-right: 5px;
`;

const Factor = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const FactorItem = styled.div`
    margin: 0 10px;
`;

const FeedbackLength = styled.div`
    margin-top: 10px;
`;