import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";
import Search from "./Search"
import SearchBarGeneric from "./searchBarGeneric"

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_GET_DEBRIEF_SCHEDULES,
    SG_GET_SURVEY_QUESTION_SELECT,
    DEBRIEF_SCHEDULE_PAGE_NUMBER,
    CLEAR_ERRORS,
    DEBRIEF_SCHEDULE_SEARCH_TERM
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment, Message } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({ hideOrgSelect }) => {

    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [mergeSurveyError, setMergeSurveyError] = useState()
    const [mergeSurvey, setMergeSurvey] = useState({
        org: null,
        name: null,
        original_brief: null,
        merge_brief: null
    })

    const [startDate, setStartDate] = useState(new Date());
    var someDate = new Date();
    var result = someDate.setDate(someDate.getDate() + 7);
    const [endDate, setEndDate] = useState(new Date(result));
    const [searchFor, setSearchFor] = useState(false);
    const [searchTerm, setSearchTerm] = useState(false)

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_QUESTION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        // if the response is ok 200
        const _from = startDate.toISOString()
        const _to = endDate.toISOString()
        if (searchFor) {
            dispatch({
                type: SG_GET_DEBRIEF_SCHEDULES,
                payload: `page=${page}${searchFor ? `&${searchFor}=true&from=${_from}&to=${_to}` : ''}${organizationId ? `&organization=${organizationId}` : ''}`,
            });
        } else if (searchTerm) {
            dispatch({
                type: DEBRIEF_SCHEDULE_SEARCH_TERM,
                payload: searchTerm,
            })
            dispatch({
                type: SG_GET_DEBRIEF_SCHEDULES,
                payload: `page=${page}&survey_token=${searchTerm}`,
            });
        } else {
            dispatch({
                type: SG_GET_DEBRIEF_SCHEDULES,
                payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ''}`,
            });
        }
        dispatch({type: CLEAR_ERRORS})
        setLoading(true)
    }, [dispatch, page, organizationId, searchFor, startDate, endDate, searchTerm]);


    const { 
        get_error,
        get_brief_schedules,
        get_merge_debriefs,
        get_selectedOrg,
        get_organization_select,
        get_survey_question_select
    } = useSelector(
        (state) => ({
            get_error: state.errors,
            get_brief_schedules: state.debrief_schedule,
            get_merge_debriefs: state.debrief_schedule.merge_debrief,
            get_selectedOrg: state.selectedOrg,
            get_organization_select: state.organizations.organization_select,
            get_survey_question_select: state.surveyquestion.survey_question_select,
        }),
        shallowEqual
    );

    useEffect(() => {
        setOrganizationId(get_selectedOrg?.organization?.id)
        // setResetPageLoad(!resetPageLoad)
    }, [get_selectedOrg]);


    useEffect(() => {
        setResults(get_brief_schedules?.debrief_schedule?.results);
        setPage(get_brief_schedules?.pageNumber)
        setTotalPages(Math.ceil(get_brief_schedules?.debrief_schedule?.count / get_brief_schedules?.debrief_schedule?.page_size))
        if (get_brief_schedules?.debrief_schedule?.count % get_brief_schedules?.debrief_schedule?.page_size) {
            setTotalPages(Math.ceil(get_brief_schedules?.debrief_schedule?.count / get_brief_schedules?.debrief_schedule?.page_size))
        }
        if (get_brief_schedules?.search) {
            setStartDate(get_brief_schedules?.search?.from)
            setEndDate(get_brief_schedules?.search?.to)
            setSearchFor(get_brief_schedules?.search?.search_for)
        }
    }, [get_brief_schedules, page]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: DEBRIEF_SCHEDULE_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    useEffect(() => {
        setMergeSurveyError(get_merge_debriefs.message)
    }, [get_merge_debriefs.message]);

    function reloadPage() {
        setMergeSurveyError(false)
        setPage(1)
        setMergeSurvey({
            org: null,
            name: null,
            original_brief: null,
            merge_brief: null
        })
    }

    return (

        <Container>


            {!hideOrgSelect &&
                <OrganizationSelect
                    setResetPageLoad={setResetPageLoad} />
            }
            {mergeSurveyError?.message &&
                <Message
                    color={mergeSurveyError?.error ? "red" : "green"}
                    onDismiss={reloadPage}
                    // header='Welcome back!'
                    content={mergeSurveyError?.message}
                />
            }

            <Search
                organizationId={organizationId}
                loading={loading}
                setLoading={setLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                searchFor={searchFor}
                setSearchFor={setSearchFor}
            />

            <SectionDiv>
                <SearchBarGeneric 
                label={'Employee survey token'} 
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm}
                error={get_error?.errors?.data?.detail} />
                survey_token: c8651da5-001a-4f79-9ba2-37c0ab64db2f
            </SectionDiv>

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>

                    {results && results.map((result) => (
                        <RowCard
                            get_survey_question_select={get_survey_question_select}
                            get_organization_select={get_organization_select}
                            row={result} key={result?.id}
                            mergeSurvey={mergeSurvey}
                            setMergeSurvey={setMergeSurvey}
                            searchTerm={searchTerm}
                        />
                    ))}
                </Card.Group>
            </Segment>
        
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page || 1}
                    totalPages={totalPages || 1}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`