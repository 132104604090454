import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from "semantic-ui-react";
import DataRenderer from "./DataRender";

import { format } from "date-fns";

const EmployeeRecordValue = ({ rows }) => {
  const navigate = useNavigate();

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Id</Table.HeaderCell>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Question</Table.HeaderCell>
            <Table.HeaderCell width={2}>Response</Table.HeaderCell>
            <Table.HeaderCell width={2}>Updated At</Table.HeaderCell>
            <Table.HeaderCell width={2}>Next At</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows &&
            rows.map((row, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{row?.id}</Table.Cell>
                  <Table.Cell>{row?.name}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    <ArrayDisplay items={row?.question_sort_order} />{" "}
                  </Table.Cell>
                  <Table.Cell>
                    <ResponseSpan isYes={row?.response?.pillars?.length > 0}>
                      {row?.response?.pillars?.length > 0 ? "Yes" : "No"}
                    </ResponseSpan>{" "}
                  </Table.Cell>
                  <Table.Cell>
                    {row?.created_at &&
                      format(new Date(row?.updated_at), "dd-MMM-yyyy hh:mm")}
                  </Table.Cell>
                  <Table.Cell>
                    {row?.created_at &&
                      format(new Date(row?.run_next_on), "dd-MMM-yyyy hh:mm")}
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/app/survey-standard/details/${row?.id}`)
                      }
                      name="angle double right"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </>
  );
};

EmployeeRecordValue.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default EmployeeRecordValue;

const ArrayDisplay = ({ items }) => {
  return (
    <div>
      [
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index !== items.length - 1 && ", "}
        </React.Fragment>
      ))}
      ]
    </div>
  );
};

const ResponseSpan = styled.span`
  color: ${(props) => (props.isYes ? "green" : "red")};
`;
