import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_LEADER_SURVEY_PARTICIPANTS,
  SG_GET_LEADER_SURVEY_PARTICIPANTS,
  DELETE_LEADER_SURVEY_PARTICIPANT,
  SG_DELETE_LEADER_SURVEY_PARTICIPANT,
  SG_FETCH_LEADER_SURVEY_PARTICIPANT,
  FETCH_LEADER_SURVEY_PARTICIPANT,
  SG_EDIT_LEADER_SURVEY_PARTICIPANT,
  EDIT_LEADER_SURVEY_PARTICIPANT,
  SG_ADD_LEADER_SURVEY_PARTICIPANT,
  ADD_LEADER_SURVEY_PARTICIPANT,
  CLEAR_LEADER_SURVEY_PARTICIPANT,
  SG_CLEAR_LEADER_SURVEY_PARTICIPANT,
    ALL_ERRORS,
} from "constants/actions";


function* loadleaders(action) {
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_LEADER_SURVEY_PARTICIPANTS, action.payload);
    yield put({ type: GET_LEADER_SURVEY_PARTICIPANTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderLoad() {
  yield takeLatest(SG_GET_LEADER_SURVEY_PARTICIPANTS, loadleaders);
}

function* fetchleaders(action) {
  try {
    const json = yield call(api.FETCH_LEADER_SURVEY_PARTICIPANT, action.payload);
    yield put({ type: FETCH_LEADER_SURVEY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderFetch() {
  yield takeLatest(SG_FETCH_LEADER_SURVEY_PARTICIPANT, fetchleaders);
}

function* updateleaders(action) {
  try {
    const json = yield call(api.EDIT_LEADER_SURVEY_PARTICIPANT, action.payload);
    yield put({ type: EDIT_LEADER_SURVEY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderUpdate() {
  yield takeLatest(SG_EDIT_LEADER_SURVEY_PARTICIPANT, updateleaders);
}

function* addleaders(action) {
  try {
    const json = yield call(api.ADD_LEADER_SURVEY_PARTICIPANT, action.payload);
    yield put({ type: ADD_LEADER_SURVEY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderAdd() {
  yield takeLatest(SG_ADD_LEADER_SURVEY_PARTICIPANT, addleaders);
}

function* deleteleaders(action) {
  try {
    yield call(api.DELETE_LEADER_SURVEY_PARTICIPANT, action.payload);
    yield put({ type: DELETE_LEADER_SURVEY_PARTICIPANT, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderDelete() {
  yield takeLatest(SG_DELETE_LEADER_SURVEY_PARTICIPANT, deleteleaders);
}


export default function* index() {
  yield all([
    leaderLoad(),
    leaderFetch(),
    leaderUpdate(),
    leaderAdd(),
    leaderDelete(),
  ]);
}
