import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ClickToCopy } from "utilities/ClickToCopy";

import { format } from "date-fns";

import { Card, Icon } from "semantic-ui-react";

const RowCard = ({ getchat_gpt }) => {
  const [aiSummary, setAiSummary] = useState(false);
  const [emptyObject, setEmptyObject] = useState(false);

  useEffect(() => {
    setAiSummary(getchat_gpt?.chat_gpt);
  }, [getchat_gpt]);

  useEffect(() => {
    setEmptyObject(false);
    if (aiSummary?.response?.survey_summary) {
      console.log("type of", typeof aiSummary?.response?.survey_summary);
      if (typeof aiSummary?.response?.survey_summary === "string") {
        setEmptyObject(false);
        return;
      }
      if (aiSummary?.response?.survey_summary === "{}") {
        setEmptyObject(false);
        return;
      } else if (
        Object.keys(aiSummary?.response?.survey_summary).length === 0
      ) {
        setEmptyObject(false);
      } else {
        setEmptyObject(true);
      }
    }
  }, [aiSummary.response]);

// console.log(aiSummary)
// console.log(ColorSwatch[aiSummary?.response?.status])
  
return (
    <>
      <Card fluid>
        <Card.Content>
          <RowData>
            <ContentRowDiv>
              <strong>Message</strong> : {aiSummary?.message}{" "}
            </ContentRowDiv>
            <ContentRowDiv>
              <strong>Updated </strong> :{" "}
              {aiSummary?.updated_at &&
                format(new Date(aiSummary?.updated_at), "dd-MMM-yyyy hh:mm")}
            </ContentRowDiv>
            <ContentRowDiv>
              <strong>Response Hash </strong> :{" "}
              {aiSummary?.response?.survey_data_hash}
            </ContentRowDiv>
          </RowData>
          <RowData>
            <ContentSubHeadRowColoredDiv 
            color={ColorSwatch[aiSummary?.response?.api_status]?.color}
            background={ColorSwatch[aiSummary?.response?.api_status]?.background}
            >
              Status: {aiSummary?.response?.api_status}
            </ContentSubHeadRowColoredDiv>
            <ContentSubHeadRowDiv>
              API Version: {aiSummary?.response?.api_version}
            </ContentSubHeadRowDiv>
          </RowData>
          <Container>
            <SingleColData>
              {emptyObject ? (
                aiSummary?.response?.survey_summary.map((row, i) => {
                  return (
                    <SpacedRowDiv key={i}>
                      <RowData>
                        <ContentRowDiv>
                          <strong>Dimension</strong> : {row?.dimension}{" "}
                        </ContentRowDiv>

                        <ContentRowDiv>
                          <strong>Factor</strong> : {row?.factor}{" "}
                        </ContentRowDiv>

                        <ContentRowDiv>
                          <strong>Primary Factor</strong> :{" "}
                          {row?.primary_factor}{" "}
                        </ContentRowDiv>

                        <ContentRowDiv>
                          <strong> Feedbacks: </strong> {row?.no_of_feedbacks}
                        </ContentRowDiv>
                      </RowData>
                      <RowData>
                        <ContentSubHeadRowDiv>
                          Version: {row?.version}
                        </ContentSubHeadRowDiv>

                        <ContentSubHeadRowDiv>
                          Execution Time: {row?.time_to_execute}
                        </ContentSubHeadRowDiv>

                        <ContentSubHeadRowDiv>
                          Feedback from Survey (click to copy) :{" "}
                          <ClickToCopy
                            textToCopy={row?.filtered_feedback}
                            hidetext={true}
                          />
                        </ContentSubHeadRowDiv>

                        {row?.error && (
                          <ContentSubHeadRowDiv error>
                            Error: <pre>{JSON.stringify(row?.message)} </pre> 
                          </ContentSubHeadRowDiv>
                        )}
                      </RowData>

                      {row?.version === "v1" &&
                        row?.ai_summary?.response?.length > 0 &&
                        row?.ai_summary?.response.map((res, i) => {
                          return (
                            <AiResultsRowV1
                              key={i}
                              no={res?.name}
                              prompt_uid={res?.id}
                              prompt={res?.prompt}
                              error={res?.error}
                              time_to_execute={res?.time_to_execute}
                              message={res?.message}
                              result={res?.content}
                            />
                          );
                        })}
                      {!row?.version === "v1" && (
                        <>
                          <AiResultsRow
                            no="Base"
                            prompt_uid={row?.prompt_uid_base}
                            prompt_list={row?.prompt_base}
                            prompt_text={row?.filtered_feedback}
                            result_list={row?.result_base}
                          />

                          <AiResultsRow
                            no="1"
                            prompt_uid={row?.prompt_uid_1}
                            prompt_list={row?.prompt_list_1}
                            prompt_text={row?.prompt_text_1}
                            result_list={row?.result_list_1}
                          />

                          <AiResultsRow
                            no="2"
                            prompt_uid={row?.prompt_uid_2}
                            prompt_list={row?.prompt_list_2}
                            prompt_text={row?.prompt_text_2}
                            result_list={row?.result_list_2}
                          />

                          <AiResultsRow
                            no="3"
                            prompt_uid={row?.prompt_uid_3}
                            prompt_list={row?.prompt_list_3}
                            prompt_text={row?.prompt_text_3}
                            result_list={row?.result_list_3}
                          />

                          <AiResultsRow
                            no="4"
                            prompt_uid={row?.prompt_uid_4}
                            prompt_list={row?.prompt_list_4}
                            prompt_text={row?.prompt_text_4}
                            result_list={row?.result_list_4}
                          />

                          <AiResultsRow
                            no="5"
                            prompt_uid={row?.prompt_uid_5}
                            prompt_list={row?.prompt_list_5}
                            prompt_text={row?.prompt_text_5}
                            result_list={row?.result_list_5}
                          />
                        </>
                      )}
                    </SpacedRowDiv>
                  );
                })
              ) : (
                <p>No response from AI </p>
              )}
            </SingleColData>
          </Container>
          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

// RowCard.propTypes = {
//   row: PropTypes.object.isRequired,
// };

export default RowCard;

function AiResultsRowV1({
  no,
  prompt_uid,
  time_to_execute,
  prompt,
  result,
  error,
  message,
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [showResult, setShowResult] = useState(true);
  const [showError, setShowError] = useState(true);
  return (
    <SpacedRowDiv>
      <RowData>
        <ListContainer>
          <HeaderDiv>
            Prompt {no}:
            <Icon
              onClick={() => setShowPrompt(!showPrompt)}
              link
              color={showPrompt ? "red" : "green"}
              name={showPrompt ? "eye slash" : "eye"}
            />{" "}
            {prompt_uid} in {time_to_execute}
          </HeaderDiv>
          {showPrompt && <ContentDiv>{prompt}</ContentDiv>}
        </ListContainer>
      </RowData>

      <RowData>
        <ListContainer>
          <HeaderDiv>
            Recommendation {no}:
            <Icon
              onClick={() => setShowResult(!showResult)}
              color={showResult ? "red" : "green"}
              link
              name={showResult ? "eye slash" : "eye"}
            />
          </HeaderDiv>
          {showResult && <ContentDiv>{result}</ContentDiv>}
        </ListContainer>
      </RowData>
      {error && (
        <RowData>
          <ListContainer>
            <HeaderDiv>
              Error {no}:
              <Icon
                onClick={() => setShowError(!showError)}
                color={showError ? "red" : "green"}
                link
                name={showError ? "eye slash" : "eye"}
              />
            </HeaderDiv>
            {message && <ContentDiv error>{message}</ContentDiv>}
          </ListContainer>
        </RowData>
      )}
    </SpacedRowDiv>
  );
}

function AiResultsRow({
  no,
  prompt_uid,
  prompt_text,
  prompt_list,
  result_list,
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptText, setShowPromptText] = useState(false);
  const [showResult, setShowResult] = useState(true);

  return (
    <SpacedRowDiv>
      <RowData>
        <ListContainer>
          <HeaderDiv>
            Prompt List {no}:
            <Icon
              onClick={() => setShowPrompt(!showPrompt)}
              link
              color={showPrompt ? "red" : "green"}
              name={showPrompt ? "eye slash" : "eye"}
            />{" "}
            {prompt_uid}
          </HeaderDiv>
          {showPrompt && <ContentDiv>{prompt_list}</ContentDiv>}
        </ListContainer>
      </RowData>

      <RowData>
        <ListContainer>
          <HeaderDiv>
            Prompt Text {no}:
            <Icon
              onClick={() => setShowPromptText(!showPromptText)}
              link
              color={showPromptText ? "red" : "green"}
              name={showPromptText ? "eye slash" : "eye"}
            />
          </HeaderDiv>
          {showPromptText && <ContentDiv>{prompt_text}</ContentDiv>}
        </ListContainer>
      </RowData>

      <RowData>
        <ListContainer>
          <HeaderDiv>
            Result List {no}:
            <Icon
              onClick={() => setShowResult(!showResult)}
              color={showResult ? "red" : "green"}
              link
              name={showResult ? "eye slash" : "eye"}
            />
          </HeaderDiv>
          {showResult && <ContentDiv>{result_list}</ContentDiv>}
        </ListContainer>
      </RowData>
    </SpacedRowDiv>
  );
}

const ColorSwatch = {
  1: {color: "#FFFFFF", background: "#2101A2"},
  2: {color: "#FF7400", background: "#FF7400"}, 
  3: {color: "#0C00FF", background: "#FF7400"}, 
  4: {color: "#0C00FF", background: "#FF7400"},
  9: {color: "#FFFFFF", background: "#0E8523"},
  undefined: {color: "#757070", background: "#757070"},
  false: {color: "#757070", background: "#FF7400"},
}

const ListContainer = styled.div`
  margin: 0px 25px;
  padding-top: 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`;

const SingleColData = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderDiv = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: large;
`;
const ContentRowDiv = styled.div`
  // background-color: #b6701e;
  font-weight: 600;
  font-size: large;
  padding: 0 10px;
`;

const ContentSubHeadRowDiv = styled.div`
  // background-color: #b6701e;
  color: ${(props) => (props.error === true ? "#FF3333" : "#000000")};
  font-size: small;
  padding: 0 10px;
`;

const ContentSubHeadRowColoredDiv = styled.div`
  background-color:${(props) => (props.background)};
  color: ${(props) => (props.color)};
  font-size: small;
  padding: 0 10px;
`;

const SpacedRowDiv = styled.div`
  // background-color: #b6701e;
  padding: 10px 0px;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
  color: ${(props) => (props.error === true ? "#FF3333" : "#000000")};
  white-space: pre-line;
  vertical-align: bottom;
`;
