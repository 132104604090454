import React from 'react';

const Responses = () => {
    return (
        <div>
            Responses
        </div>
    );
};

export default Responses;