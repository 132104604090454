import React, { useState } from "react";
import {
    Table,
    Button,
    Icon,
    Confirm,
    Segment,
    Dimmer, Loader,
} from "semantic-ui-react";
import styled from "styled-components";
// import NewSubFactor from './NewSubFactor'

import { CATEGORY_VISIBILITY, CATEGORY_INPUT } from "data/AdminData"


import Options from "categories/Options"
import SubFactors from "categories/Options/SubFactors"

const ListJson = ({
    surveyStrucRow,
    setRowId,
    setOpenNewCategory,
    setSurveyStruc,
    setSurveyStrucRow,
    surveyStructureId,
    loading,
    setUpdateJsonFile,
}) => {
    // const [selectedItem, setSelectedItem] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [optionItem, setOptionItem] = useState(false)
    const [showSubFactors, setShowSubFactors] = useState(false)
    const [subfactorList, setSubfactorList] = useState(false)
    const [deleteRow, setDeleteRow] = useState(false)


    function SelectRow(item) {
        setRowId(item?.id);
        setOpenNewCategory(true);
        setSurveyStruc(item);
    }

    function SelectSubFactorRow(item) {
        // console.log(item.id)
        setShowOptions(true)
        setRowId(item?.id);

        setOptionItem(item);
        // setOpenSubFactor(true);
        // setSelectedItem(item);
    }

    function SelectedDeleteRow(item){

        if (item) {
            setConfirmOpen(true)
            setDeleteRow(item)
        } else {
            setConfirmOpen(false)
            setDeleteRow(false)
        }
    }

    function RemoveFromList() {

        setConfirmOpen(false)
        // setLoading(true)
        const _ssRow = surveyStrucRow.filter(sc => sc.id !== deleteRow.id)

        setSurveyStrucRow(_ssRow)
        setUpdateJsonFile(Math.random())
        // const timer1 = setTimeout(() => {
        //     SaveSurveyStructure()
        // }, 500);
        // return () => clearTimeout(timer1);
    }

    if (showSubFactors) {
        return <SubFactors
            surveyStructureId={surveyStructureId}
            optionItem={optionItem}
            setSurveyStruc={setSurveyStruc}
            setShowOptions={setShowOptions}
            subfactorList={subfactorList}
            setShowSubFactors={setShowSubFactors}
            loading={loading}

            // save data
            surveyStrucRow={surveyStrucRow}
            setSurveyStrucRow={setSurveyStrucRow}
            setUpdateJsonFile={setUpdateJsonFile}

        />
    }
    if (showOptions) {
        return <Options
            surveyStructureId={surveyStructureId}
            optionItem={optionItem}
            setSurveyStruc={setSurveyStruc}
            loading={loading}
            // setLoading={setLoading}
            setShowOptions={setShowOptions}
            setSubfactorList={setSubfactorList}
            setShowSubFactors={setShowSubFactors}
            // save data
            surveyStrucRow={surveyStrucRow}
            setSurveyStrucRow={setSurveyStrucRow}
            setUpdateJsonFile={setUpdateJsonFile}
  
        />
    }
    
    return (
        <Segment>
            <Dimmer active={loading}>
                <Loader />
            </Dimmer>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Priority</Table.HeaderCell>
                        <Table.HeaderCell>Subtitle</Table.HeaderCell>
                        <Table.HeaderCell>Visibility</Table.HeaderCell>
                        <Table.HeaderCell>Input</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {surveyStrucRow && surveyStrucRow.map((item, i) =>
                        <Table.Row key={i}>
                            <Table.Cell>{item?.name}</Table.Cell>
                            <Table.Cell>{item?.priority}</Table.Cell>
                            <Table.Cell>{item?.subtitle}</Table.Cell>
                            <Table.Cell>
                                {CATEGORY_VISIBILITY.find(({ value }) => value === item?.visibility)?.label}
                                <br/> <small>{item?.visibility}</small>
                                </Table.Cell>
                                <Table.Cell>
                                {CATEGORY_INPUT.find(({ value }) => value === item?.input)?.label}
                                <br/> <small>{item?.input}</small>
                                </Table.Cell>
                            <Table.Cell>
                                {/* */}

                                <StyledButton
                                    // disabled={openSubFactor}
                                    onClick={() => SelectSubFactorRow(item)}
                                >
                                    + Options
                                </StyledButton>
                            </Table.Cell>
                            <Table.Cell>
                                <Icon
                                    size='large'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => SelectedDeleteRow(item)}
                                    name='trash alternate outline' />
                                <Confirm
                                    header={`Warning Irreversible decision DELETING => ${deleteRow?.name}`}
                                    open={confirmOpen}
                                    content={`This will delete ${deleteRow?.name} => ${deleteRow?.priority} = ${deleteRow?.subtitle} and its associated sub factors and cannot be reversed are you sure!`}
                                    onCancel={() => SelectedDeleteRow(false)}
                                    onConfirm={() => RemoveFromList()}
                                    size='large'
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    content="Edit"
                                    onClick={() => {
                                        SelectRow(item);
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Segment>
    );
};

export default ListJson;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(props) => (props.disabled ? "#dcdcdc" : "#2d50e2")};
    border: none;
    outline: none;
    padding: 0px 30px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
        margin-right: 3px;
    }
`;
