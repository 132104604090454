import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_EMPLOYEE_RECORD_VALUE,
  SG_GET_ORGANIZATION_SELECT,
  SG_FETCH_EMPLOYEE_RECORD_LIST,
  SG_UPDATE_EMPLOYEE_RECORD_LIST,
  SG_ADD_EMPLOYEE_RECORD_LIST,
  SG_DELETE_EMPLOYEE_RECORD_LIST,

} from "constants/actions";
//
import { Icon, Message, Button, Header, List, Input, Confirm } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"
//
const Details = ({employee_record_value}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });
  const [orgSelected, setOrgSelected] = useState([])
  const [valueSelected, setValueSelected] = useState([])

  const [valueNames, setValueNames] = useState([])
  const [valNam, setValNam] = useState(false)

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  function BackArrow() {
    navigate(`/app/employee-record-list`)
  }

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_EMPLOYEE_RECORD_LIST,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
    dispatch({
      type: SG_GET_EMPLOYEE_RECORD_VALUE,
    });
  }, [dispatch]);

  const { get_employee_record_list,
    get_employee_record_value,
    get_organization_select } = useSelector(
      (state) => ({
        get_employee_record_list: state.employee_record_list,
        get_employee_record_value: state.employee_record_value,
        get_organization_select: state.organizations.organization_select,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_employee_record_list?.[templateId]) {
      setTemplateDetails(get_employee_record_list?.[templateId])
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_employee_record_list?.[templateId]?.organization)?.[0])
      if (employee_record_value) {
        setValueSelected(valueNames.filter(sy => sy.id === employee_record_value))
      }else {
        setValueSelected(valueNames.filter(sy => sy.id === get_employee_record_list?.[templateId]?.employee_record_value))
      }
    }
  }, [get_employee_record_list, templateId, get_organization_select, valueNames, employee_record_value]);

  useEffect(() => {
    if (get_employee_record_list?.created?.id) {
      navigate(`/app/employee-record-list/details/${get_employee_record_list?.created?.id}`)
    }
  }, [get_employee_record_list, navigate]);


  useEffect(() => {
    setValNam(valueNames.filter(sy => sy.id === templateDetails?.employee_record_value)?.[0])
  }, [templateDetails, valueNames]);

  useEffect(() => {
    setValueNames(get_employee_record_value?.employee_record_value)
  }, [get_employee_record_value]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_EMPLOYEE_RECORD_LIST,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/employee-record-list?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_UPDATE_EMPLOYEE_RECORD_LIST,
          payload: {
            id: templateId,
            organization: orgSelected?.id,
            name: templateDetails?.name,
            replace_by: templateDetails?.replace_by,
            employee_record_value: valueSelected?.id,
          }
        });
        navigate(`/app/employee-record-list/detail/${templateId}`)
      } else {

        dispatch({
          type: SG_ADD_EMPLOYEE_RECORD_LIST,
          payload: {
            organization: orgSelected?.id,
            name: templateDetails?.name,
            replace_by: templateDetails?.replace_by,
            employee_record_value: valueSelected?.id,
          }
        });
      }
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }
    if (!valueSelected?.id) {
      ErrorList.push("Select a Value Name")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    
    setFormErrors(ErrorList)
  }, [templateDetails, valueSelected, orgSelected, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails
    _ques[`${name}`] = e.target.value
    setTemplateDetails(_ques)
    setValueChanged(e.target.value + name)
  }

  function ChangeDomain(e) {

    setValueSelected(e)
    setValueChanged(e)
  }

  function ChangeOrg(e) {
    setOrgSelected(e)
    setValueChanged(e)
  }


  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow} name='arrow left' />
        {templateId ? `Edit Details of ${templateDetails?.name}` : "Add New List Value"}
      </Header>
      {formErrors.length > 0 && !pristine &&
        <Message negative
          //   onDismiss={clearErrors}
          header='We need these details!'
          list={formErrors}
        />
      }
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId &&
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>Value</List.Header>
                    {valNam && valNam?.name} - {valNam && valNam?.type_of_value_text}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>Name</List.Header>
                    {templateDetails?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization</List.Header>
                    {templateDetails?.organization ?
                      get_organization_select.filter(org => org.id === templateDetails?.organization)?.[0]?.name :
                      "For all Org"}
                  </ListContainer>
                </List.Content>
              </List.Item>



              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                  </ListContainer>

                </List.Content>
              </List.Item>
              
            </List>
          </Container>
        </SectionDiv>
      }

      <SectionDiv>
      
      <FormRow>
        <FormLabel>
          <LabelHead>Name</LabelHead>
        </FormLabel>
        <FormInput>
        <Input
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}

            />
        </FormInput>
      </FormRow>

      <FormRow>
        <FormLabel>
        <LabelHead>Replace By</LabelHead>
        </FormLabel>
        <FormInput>
        <Input
              name="replace_by"
              defaultValue={templateDetails?.replace_by}
              onChange={(e) => ChangeFormValues(e, "replace_by")}

            />
        </FormInput>
      </FormRow>
      
      <FormRow>
        <FormLabel>
          <LabelHead>Value</LabelHead>
        </FormLabel>
        <FormInput>
        <Select
              name="employee_record_value"
              options={valueNames}
              isClearable
              onChange={(e) => ChangeDomain(e)}
              getOptionLabel={(option) => `${option.name} (${option.type_of_value_text})`}
              getOptionValue={(option) => `${option.id}`}
              value={valueSelected}
            />
        </FormInput>
      </FormRow>
            
      <FormRow>
        <FormLabel>
          <LabelHead>Organization</LabelHead>
        </FormLabel>
        <FormInput>
          <Select
            name="organization"
            options={get_organization_select}
            isClearable
            onChange={(e) => ChangeOrg(e)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={orgSelected}
          />
        </FormInput>
      </FormRow>

        
         

      </SectionDiv>



      <SectionDiv lastSection={true}>
        {templateId &&
          <>
            <Button floated='left'
              color="red"
              onClick={() => setConfirmOpen(true)}>
              Delete List
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>}

        <Button floated='right'
          color="green"
          onClick={UpdateQuestions}>
          {templateId ? "Update List" : "Create List"}
        </Button>
      </SectionDiv>
 
      
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`
const SectionCell = styled.div`
    padding: 10px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: row;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
