import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns'

const ObjectDisplay = ({ data, url }) => {
    const {extra_information, query_string, response} = data
  return (
    <Container>
      <Title>Details</Title>
      <Field>
        <Label>hubspot:</Label>
        <Value>
          {extra_information?.hubspot?.error ? 'Error' : 'Success'} - {extra_information?.hubspot?.message}
        </Value>
        <Label>Response:</Label>
        <Value>
        <Value>{Array.isArray(extra_information?.hubspot?.response) ? JSON.stringify(extra_information?.hubspot?.response) : extra_information?.hubspot?.response}</Value>
          
        </Value>
      </Field>
      <Field>
        <Label>postmark:</Label>
        <Value>
          To: {extra_information?.postmark?.To}
        </Value>
        <Value>
        Message: {extra_information?.postmark?.Message}
        </Value>
        <Value>
        Submitted At:
        {extra_information?.postmark?.SubmittedAt && format(new Date(extra_information?.postmark?.SubmittedAt), 'dd-MMM-yyyy hh:mm a')}
        </Value>
      </Field>

      <Field>
        <Label>Url:</Label>
        <Value>
          {query_string}
        </Value>
      </Field>

      <Title>Survey Response</Title>

      {response && Object.entries(response).map(([key, value]) => (
        <Field key={key}>
          <Label>{key}:</Label>
          <Value>{Array.isArray(value) ? JSON.stringify(value) : value}</Value>
        </Field>
      ))}
    </Container>
  );
};

export default ObjectDisplay;

const Container = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
`;

const Title = styled.h2`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Field = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.p``;