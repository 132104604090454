import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {

  SG_FETCH_MARKETING_ROI,
  SG_EDIT_MARKETING_ROI,
  SG_DELETE_MARKETING_ROI,

} from "constants/actions";
//
import { Icon, Message, Button, Header, List, Input, Confirm } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"
import ObjectDisplay from './showResponse';

//
const Details = ({employee_record_value}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });
  const [orgSelected, setOrgSelected] = useState([])
  const [valueSelected, setValueSelected] = useState([])



  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  function BackArrow() {
    navigate(`/app/marketing-roi`)
  }

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_MARKETING_ROI,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);



  const { get_marketing_roi } = useSelector(
      (state) => ({
        get_marketing_roi: state.marketing_roi,
        
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_marketing_roi?.[templateId]) {
      setTemplateDetails(get_marketing_roi?.[templateId])
     
    }
  }, [get_marketing_roi, templateId,]);

  useEffect(() => {
    if (get_marketing_roi?.created?.id) {
      navigate(`/app/marketing-roi/details/${get_marketing_roi?.created?.id}`)
    }
  }, [get_marketing_roi, navigate]);




  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_MARKETING_ROI,
        payload: {
          id: templateId,
        }
      });
      navigate(`/app/marketing-roi?reload=true`)
    }
  }
  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_EDIT_MARKETING_ROI,
          payload: {
            id: templateId,
            organization: orgSelected?.id,
            name: templateDetails?.name,
            replace_by: templateDetails?.replace_by,
            employee_record_value: valueSelected?.id,
          }
        });
        navigate(`/app/marketing-roi/detail/${templateId}`)
      } 
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name")
    }
    if (!valueSelected?.id) {
      ErrorList.push("Select a Value Name")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }
    
    setFormErrors(ErrorList)
  }, [templateDetails, valueSelected, orgSelected, valueChanged]);

  // function ChangeFormValues(e, name) {
  //   // console.log(name, e.target.value)
  //   let _ques = templateDetails
  //   _ques[`${name}`] = e.target.value
  //   setTemplateDetails(_ques)
  //   setValueChanged(e.target.value + name)
  // }

  // function ChangeDomain(e) {

  //   setValueSelected(e)
  //   setValueChanged(e)
  // }

  // function ChangeOrg(e) {
  //   setOrgSelected(e)
  //   setValueChanged(e)
  // }

  // console.log(templateDetails)

  return (
    <>
      <Header as='h2'>
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow} name='arrow left' />
        {templateId ? `Edit Details of ${templateDetails?.email}` : "Add New List Value"}
      </Header>
      {formErrors.length > 0 && !pristine &&
        <Message negative
          //   onDismiss={clearErrors}
          header='We need these details!'
          list={formErrors}
        />
      }
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId &&
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>Email</List.Header>
                    {templateDetails?.email}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>

                <List.Content>
                  <ListContainer>
                    <List.Header>Token</List.Header>
                    {templateDetails?.token}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Status</List.Header>
                    {templateDetails?.status_text}
                  </ListContainer>
                </List.Content>
              </List.Item>



              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy hh:mm a')}
                  </ListContainer>

                </List.Content>
              </List.Item>
              
            </List>
          </Container>
        </SectionDiv>
      }

  <SectionDiv>
  <ObjectDisplay data={templateDetails} />
  </SectionDiv>
      <SectionDiv>
      
      {/* <FormRow>
        <FormLabel>
          <LabelHead>Employee Id</LabelHead>
        </FormLabel>
        <FormInput>
        <Input
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}

            />
        </FormInput>
      </FormRow>

      <FormRow>
        <FormLabel>
        <LabelHead>Organization Id</LabelHead>
        </FormLabel>
        <FormInput>
        <Input
              name="replace_by"
              defaultValue={templateDetails?.replace_by}
              onChange={(e) => ChangeFormValues(e, "replace_by")}

            />
        </FormInput>
      </FormRow> */}
      
      

        
         

      </SectionDiv>



      {/* <SectionDiv lastSection={true}>
        {templateId &&
          <>
            <Button floated='left'
              color="red"
              onClick={() => setConfirmOpen(true)}>
              Delete
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>}

        <Button floated='right'
          color="green"
          onClick={UpdateQuestions}>
          {templateId ? "Update" : "Create"}
        </Button>
      </SectionDiv> */}
 
      
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`
const SectionCell = styled.div`
    padding: 10px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: row;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
