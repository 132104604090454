import React from 'react';

import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react'

// CLEAR_BLOG

const Toolbar = ({searchTerm, setSearchTerm }) => {
  
    return (
        <Form success>
            <Form.Input label='Search'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search...' />
            
        </Form>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
