import React from 'react'
import { Routes, Route } from 'react-router-dom';

import { useNavigate, useLocation } from "react-router-dom";

import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';
import { Header, Grid, Button } from 'semantic-ui-react'

function EmployeeRecordList() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as='h2' style={{ marginTop: "50px" }}>
            Employee Records List
            <Header.Subheader>
              Actual Employee Records are in Employee Data, but you can manage the related information
              This list is specific to an Org

              So you can map custom values for value that comes 
              within each value, the content would be replaced with
              this name
              
              works for api and csv upload
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          {(location.pathname !== '/app/employee-record-list/new') &&
            <Button primary
              onClick={() => navigate(`/app/employee-record-list/new`)}
            >Add New</Button>
          }

        </Grid.Column>
      </Grid.Row>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default EmployeeRecordList;
