import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_EDIT_DEBRIEF_SCHEDULE, CLEAR_ERRORS } from "constants/actions";

import BasicErrorMessage from "utilities/ErrorMessages";

import { CustomMessagesList } from "Messages/Errors/Message";

import {
  Button,
  Header,
  Modal,
  Radio,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";

export default function ExtendSurvey({ debriefDetails }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [loading, setLoading] = useState(false);
  const [generateTokens, setGenerateTokens] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const { get_brief_schedules, get_errors } = useSelector(
    (state) => ({
      get_brief_schedules: state.debrief_schedule,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  useEffect(() => {
    setEndDate(new Date(debriefDetails?.close_at));
  }, [debriefDetails]);

  useEffect(() => {
    if (get_errors?.errors?.request?.response) {
      let RESP = JSON.parse(get_errors?.errors?.request?.response);

      let errors = Object.values(RESP).map((message) => {
        if (CustomMessagesList.hasOwnProperty(message)) {
          return {
            message,
            isError: CustomMessagesList[message].isError,
            generateTokens: CustomMessagesList[message].generateTokens,
          };
        } else {
          return { message, isError: true }; // Default to error if message not found in CustomMessagesList
        }
      });
      errors.map((se) => {
        if (se?.generateTokens) {
          setShowSlider(true);
        }
        return null;
      });
      if (errors?.length > 0) {
        setLoading(false);
      }
    }
  }, [get_errors]);

  useEffect(() => {
    if (debriefDetails?.id === get_brief_schedules?.updated) {
      setOpen(false);
    }
    setLoading(get_brief_schedules?.pending);
  }, [get_brief_schedules, debriefDetails]);

  function AddTokenResponse() {
    if (!generateTokens) {
      dispatch({
        type: CLEAR_ERRORS,
      });
    }
    setGenerateTokens(!generateTokens);
  }

  function Submit() {
    dispatch({
      type: SG_EDIT_DEBRIEF_SCHEDULE,
      payload: {
        id: debriefDetails?.id,
        status: 3,
        close_at: endDate,
        extend_survey: "yes",
        ...(generateTokens ? { generate_survey_tokens: "yes" } : {}), // Include generateTokens if it has a value
      },
    });
  }

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color='violet'>Extend Survey</Button>}
    >
      <BasicErrorMessage />

      <Modal.Content>
        <Dimmer active={loading}>
          <Loader>Updating ...</Loader>
        </Dimmer>
        <Header>Select New closing date and time </Header>
        <WarningText>Caution: Extending survey will disable reports and would be visible once closed</WarningText>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showTimeSelect
          minDate={endDate}
          maxDate={addDays(debriefDetails?.created_at, 120)}
          dateFormat="MMMM d, yyyy h:mm aa"
        />

        {showSlider && (
          <SliderWithLabel
            label="Generate Token"
            value={generateTokens}
            onChange={AddTokenResponse}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Extend Survey" negative onClick={Submit} />
      </Modal.Actions>
    </Modal>
  );
}

const SliderWithLabel = ({ label, value, onChange }) => (
  <StyledSliderContainer>
    <SliderLabel>{label}</SliderLabel>
    <StyledSlider slider checked={value} onChange={onChange} />
  </StyledSliderContainer>
);

const StyledSliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const SliderLabel = styled.span`
  margin-right: 10px;
`;

const StyledSlider = styled(Radio)`
  &&&& input[type="checkbox"]:before {
    width: 48px;
    height: 24px;
  }

  &&&& input[type="checkbox"]:after {
    width: 18px;
    height: 18px;
  }
`;


const WarningText = styled.div`
    padding: 20px 5px;
    color: red;
    font-size: large;
    font-weight: 600;
`