import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  SG_ADD_EMPLOYEE_RECORD_LIST,

} from "constants/actions";
//
import { Message, Button, Input, Confirm } from 'semantic-ui-react'

import { BasicErrorMessage } from "utilities/ErrorMessages"
//
const Details = ({ employee_record_value, organization_id }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [replaceBy, setReplaceBy] = useState('');

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)

  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      dispatch({
        type: SG_ADD_EMPLOYEE_RECORD_LIST,
        payload: {
          organization: organization_id,
          name: name,
          replace_by: replaceBy,
          employee_record_value,
        }
      });
      setPristine(true)
      setName('')
      setReplaceBy('')
    }
  }



  useEffect(() => {
    const ErrorList = []
    if (!name) {
      ErrorList.push("Enter a Name")
    }
    if (!replaceBy) {
      ErrorList.push("Enter a Replacement Name")
    }
    setFormErrors(ErrorList)
  }, [name, replaceBy, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
 
    if (name === 'name') {
      setName(e.target.value)
  
    }
    if (name === 'replace_by') {
      setReplaceBy(e.target.value)
    }
 
    setValueChanged(e.target.value + name)
  }

  return (
    <>
      {formErrors.length > 0 && !pristine &&
        <Message negative
          //   onDismiss={clearErrors}
          header='We need these details!'
          list={formErrors}
        />
      }


      <SectionDiv>
        <BasicErrorMessage />
        <FormRow>
          <FormLabel>
            <LabelHead>Name</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="name"
              defaultValue={name}
              onChange={(e) => ChangeFormValues(e, "name")}
            />
          </FormInput>
        </FormRow>

        <FormRow>
          <FormLabel>
            <LabelHead>Replace By</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="replace_by"
              defaultValue={replaceBy}
              onChange={(e) => ChangeFormValues(e, "replace_by")}
            />
          </FormInput>
        </FormRow>


        <Button floated='right'
          color="green"
          onClick={UpdateQuestions}>
          Create List
        </Button>
      </SectionDiv>


    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const LabelHead = styled.label`
font-weight: 700;
display: block;
`

const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
