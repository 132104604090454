import {
    UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
    GET_GPT_MODEL_MAIN_PENDING,
    GET_GPT_MODEL_MAINS,
    GET_GPT_MODEL_MAIN_SELECT,
    DELETE_GPT_MODEL_MAIN,
    FETCH_GPT_MODEL_MAIN,
    EDIT_GPT_MODEL_MAIN,
    ADD_GPT_MODEL_MAIN,
    CLEAR_GPT_MODEL_MAIN,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    gpt_model_main: [],
    gpt_model_main_select: [],
    created: [],
    updated: [],
    deleted: [],
    pending: false,
    pageNumber: 1,
  };
  
  export default function GptModelMain (state = initialState, action) {
    switch (action.type) {
        case GET_GPT_MODEL_MAIN_PENDING:
            return {
                ...state,
                pending: true,
              };
        case UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER:
                return {
                  ...state,
                  pageNumber: action.payload
                }
        case GET_GPT_MODEL_MAINS:
            return {
            ...state,
            gpt_model_main: action.payload,
            pending: false,
            };
        case GET_GPT_MODEL_MAIN_SELECT:
            return {
                ...state,
                gpt_model_main_select: action.payload,
                pending: false,
                };
        case FETCH_GPT_MODEL_MAIN:
            return { ...state, 
                [action.payload.id]: action.payload, 
                pending: false };
    
        case EDIT_GPT_MODEL_MAIN:
            return { ...state, 
                [action.payload.id]: action.payload,
                updated: action.payload,
                pending: false};
    
        case DELETE_GPT_MODEL_MAIN:
            return {
            ...state,
            // gpt_model_main: state.gpt_model_main.filter((row) => row.id !== action.payload),
            deleted: action.payload,
            pending: false
            };
    
        case ADD_GPT_MODEL_MAIN:
            return {
            ...state,
            // gpt_model_main: [...state.gpt_model_main, action.payload],
            created: action.payload,
            pending: false
            };
    
        case CLEAR_GPT_MODEL_MAIN:
            return {
            ...state,
            gpt_model_main: [],
            gpt_model_main_select: [],
            created: [],
            updated: [],
            deleted: [],
            pending: false,
            pageNumber: 1,
            };
    
        case CLEAR_ALL:
            return {
            ...state,
            gpt_model_main: [],
            gpt_model_main_select: [],
            created: [],
            updated: [],
            deleted: [],
            pending: false,
            pageNumber: 1,
            };
    
        default:
            return state;
    }
  }
