import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import { useParams } from "react-router";
import RowCard from "./RowCard";
import CreateEditModel from "./Details";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_GPT_RESPONSE_SCORES,
  SG_FETCH_GPT_MODEL_MAIN,
  UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Header,
} from "semantic-ui-react";

const List = ({ noPagination = false }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [reloadPage, setReloadPage] = useState(false);
  const [modelId, setModelId] = useState(id);

  const [gptMain, setGptMain] = useState();
  const [editModelMain, setEditModelMain] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_GPT_RESPONSE_SCORES,
      payload: `page=${page}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reloadPage]);

  const { get_gpt_response_score } = useSelector(
    (state) => ({
      get_gpt_response_score: state.gpt_response_score,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_response_score?.gpt_response_score?.results);
    setPage(get_gpt_response_score?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_response_score?.gpt_response_score?.count /
          get_gpt_response_score?.gpt_response_score?.page_size
      )
    );
    if (
      get_gpt_response_score?.gpt_response_score?.count %
      get_gpt_response_score?.gpt_response_score?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_response_score?.gpt_response_score?.count /
            get_gpt_response_score?.gpt_response_score?.page_size
        ) + 1
      );
    }
  }, [get_gpt_response_score, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);





  useEffect(() => {
    if (modelId) {
      setGptMain(get_gpt_response_score[modelId]);
      return;
    }
    if (get_gpt_response_score?.created?.id) {
      setGptMain(get_gpt_response_score?.created);
      return;
    }
  }, [get_gpt_response_score, modelId]);



  return (
    <Container>
     
     <Header as="h3" block>
        Score any Pending Model, Select any Survey to get started
      </Header>
    
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => 
            
            <RowCard row={result} key={result?.id}
            setEditModelMain={setEditModelMain}
            />)}
        </Card.Group>
      </Segment>
      {!noPagination && (
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      )}

      
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SectionHeader = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

const SectionExplain = styled.span`
  font-weight: 400;
  font-size: 12px;
`;
