import React, {useEffect} from "react";
import styled, { keyframes } from "styled-components";
import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
import BasicInput from "./BasicInput";
import BulletPoints from "./BulletPoints";
import NumberedItems from "./NumberedItems";
import make_call from "./Request";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_CREATE_REPORT_TEMPLATE,
  SG_EDIT_REPORT_TEMPLATE,
  SG_ADD_REPORT_TEMPLATE,
  SG_DELETE_REPORT_TEMPLATE,
  SG_CLEAR_REPORT_TEMPLATE
} from "constants/actions";

const sample_outpout = `[{
	"type": "t1",
	"text": "Vision & Values Alignment Plan"
}, {
	"type": "p",
	"text": "The plan aims to enhance clarity around the organization's vision and values. By establishing a shared purpose, we increase team cohesion and innovation. We leverage diverse skills and shared goals to drive performance and growth."
}, {
	"type": "t2",
	"text": "Cultivating Team Cohesion"
}, {
	"type": "p",
	"text": "To address cultural ambiguity, initiate a 'Values Integration Program'. This will target the alignment of daily functions with broader organizational goals. Emphasize the importance of shared values and mission commitment. Create forums for discussion to establish common ground, leading to a more cohesive and united workforce."
}, {
	"type": "b",
	"text": "Facilitate regular team meetings to discuss how daily activities contribute to the organization's vision. Encourage open conversation about the relevance of workloads to shared objectives."
}, {
	"type": "b",
	"text": "Devise team-building activities that focus on recognizing diverse skills and perspectives. These should celebrate contributions that align with the company's values and advance its mission."
}, {
	"type": "b",
	"text": "Invite guest speakers and leaders to share stories of how adherence to values led to success, reinforcing the significance of a unified organizational culture."
}, {
	"type": "t2",
	"text": "Promoting Skills-Based Transformation"
}, {
	"type": "p",
	"text": "Shift the emphasis from individual performance to team-based accomplishments. Specifically recognize efforts that illustrate the company’s values, fostering an environment where diverse talents converge on shared goals. Address diversity-related challenges by introducing initiatives that respect differing viewpoints."
}, {
	"type": "b",
	"text": "Implement a 'Recognition Program' that highlights teams for collective achievements, especially when they reflect the organization's vision. Acknowledge their creative solutions and innovative approaches."
}, {
	"type": "b",
	"text": "Conduct workshops on social sensitivity and effective communication to harness the full advantage of diversity. Equip your team with the understanding and the ability to resolve conflicts constructively."
}, {
	"type": "b",
	"text": "Develop a mentoring program where employees share skills and insights relevant to the organization's mission. This will help bridge skill gaps and align personal growth with company objectives."
}]`;

const description_sample =
  "Build a plan which is aimed at providing 1 on 1 meetings. This plan should help solve the need identified but in the context of 1 on 1 meetings.";

const URL = 'report-templates'

const TemplateCreator = ({
  newResponse,
  setNewResponse,
  reportTemplate=null, // existing report template
  templateId = null,
}) => {
  const dispatch = useDispatch();
  const [template, setTemplate] = React.useState(default_template);
  const [selected, setSelected] = React.useState("");
  const [message, setMessage] = React.useState(sample_outpout);
  const [loading, setLoading] = React.useState(false);
  const [template_name, setTemplateName] = React.useState("");
  const [template_description, setTemplateDescription] =
    React.useState(description_sample);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
const [savedId, setSavedId] = React.useState();


  const { newTemplate, errors } = useSelector(
    (state) => ({
      newTemplate: state.report_template?.created,
      errors: state.errors,
    })
  );


  useEffect(() => {
    dispatch({
      type: SG_CLEAR_REPORT_TEMPLATE,
      payload: {},
    })
  }, []);

  useEffect(() => {
    if (newTemplate && newTemplate?.id) {
      setSavedId(newTemplate.id);
    }

  }, [newTemplate])

  useEffect(() => {
    if (errors?.message) {
      toast.error('Something went wrong while saving the template');
    }
  }, [])


  const addTemplate = () => {
    if (selected !== "") {
      const newTemplateItem = { ...template_options[selected] };

      // Add the new object to the template array
      setTemplate([...template, newTemplateItem]);

      // Clear the selected option
      setSelected("");
    }
  };

  useEffect(() => {
    if (reportTemplate) {
      setTemplate(reportTemplate.template?.[0]?.template || reportTemplate.template)
      setTemplateName(reportTemplate.name)
      setTemplateDescription(reportTemplate.template?.[0]?.description)
    }
  }, [reportTemplate])
  
  function deleteReportTemplate() {
    dispatch({
      type: SG_DELETE_REPORT_TEMPLATE,
      payload: {
        id: templateId,
      },
    });
    setNewResponse(Math.random());
    navigate(`/app/${URL}`);
  }

  // fields
  // "id => auto created
  // "name = > required string 250
  // "status => number (1, "New"),(9, "Live"), (99, "Archived"),
  // "template", => json file
  // "sort_order", => required and needs to be unique

  function SaveReportTemplate() {
    // need to add validations
    if (templateId || newTemplate?.id) {
      // SG_EDIT_REPORT_TEMPLATE
      const updateId = templateId || newTemplate?.id;
      dispatch({
        type: SG_EDIT_REPORT_TEMPLATE,
        payload: {
          id: updateId,
          name: template_name,
          status: 9,
          template: [{
            description: template_description,
            template: template
          }],
        },
      });
      toast.success('Template updated successfully');
    } else {
      dispatch({
        type: SG_ADD_REPORT_TEMPLATE,
        payload: {
          name: template_name,
          status: 9,
          template: [{
            description: template_description,
            template: template
          }],
        },
      });
      toast.success('Template created successfully');
    }

    setNewResponse(Math.random());
  }

  const Save = (text, index) => {
    const _template = [...template];
    _template[index].input_text = text;
    setTemplate(_template);
  };

  const deleteTemplate = (index) => {
    console.log(`Deleting item at index: ${index}`); // Debug log
    const _template = [...template];
    _template.splice(index, 1);
    setTemplate(_template);
  };

  const moveTemplateUp = (index) => {
    console.log(`Moving item at index: ${index} up`); // Debug log
    const _template = [...template];
    const temp = _template[index];
    _template[index] = _template[index - 1];
    _template[index - 1] = temp;
    setTemplate(_template);
  };

  const moveTemplateDown = (index) => {
    console.log(`Moving item at index: ${index} down`); // Debug log
    const _template = [...template];
    const temp = _template[index];
    _template[index] = _template[index + 1];
    _template[index + 1] = temp;
    setTemplate(_template);
  };

  const handleTemplatePromptChange = (value) => {
    setTemplateDescription(value);
  };

  const make_gpt_call = () => {
    setLoading(true);
    SaveReportTemplate();
    async function someFunction(prompt) {
      try {
      const message = await make_call(prompt, template_description);
      setMessage(message);
      setLoading(false);
      } catch (error) {
        console.log(error);
      }


    }

    someFunction(template);
  };

  //   const save_template = () => {
  //     const data = {
  //       template_name: template_name,
  //       template_description: template_description,
  //       template: template,
  //     };
  //     onSave(data);
  //   };

  const deleteTemplateConfirm = (
    <Modal
      onClose={() => setShowDeleteConfirm(false)}
      onOpen={() => setShowDeleteConfirm(true)}
      open={showDeleteConfirm}
      size="mini"
      closeOnDimmerClick
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Delete Template?</Header>
          <p>Are you sure you want to delete this template?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ActionWrapper>
          <CancelText color="black" onClick={() => setShowDeleteConfirm(false)}>
            Cancel
          </CancelText>
          <DeleteConfirm onClick={() => deleteReportTemplate()}>
            Delete
          </DeleteConfirm>
        </ActionWrapper>
      </Modal.Actions>
    </Modal>
  );

  const getPlaceholder = (value) => {
    switch (value) {
      case 4:
        return "Enter the ID of the data anchor that goes into this table.";
      case 5:
        return "Enter a factor title";
      default:
        return "Describe in detail, what information should be present here.";
    }
  };

  return (
    <Container>
      {deleteTemplateConfirm}
      <Left>
        <Title>Build a Template</Title>

        <InputArea>
          <Label>Template name</Label>
          <Input
            placeholder="Enter a title"
            value={template_name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </InputArea>

        <InputArea>
          <Label>Template prompt</Label>
          <TextArea
            placeholder="Enter a prompt that gives this template its goal and purpose..."
            value={template_description}
            onChange={(e) => handleTemplatePromptChange(e.target.value)}
          />
        </InputArea>

        <Label>Template element</Label>
        <Dropdown
          placeholder="Select a template"
          fluid
          selection
          value={selected}
          onChange={(e, { value }) => setSelected(value)}
          options={template_options}
        />

        <Button onClick={() => addTemplate()}>Add element</Button>

        <TemplateContainer>
          {template?.map((t, i) => {
            const placeholder = getPlaceholder(t?.value);
            if (t?.value === 3) {
              return (
                <BulletPoints
                  key={i}
                  type={t?.text}
                  index={t?.value}
                  deleteTemplate={() => deleteTemplate(i)}
                  moveTemplateUp={() => moveTemplateUp(i)}
                  moveTemplateDown={() => moveTemplateDown(i)}
                  saved_value={t?.input_text}
                  placeholder={placeholder}
                  onChange={(text) => Save(text, i)}
                />
              );
            }

            if (t?.value === 7) {
              return (
                <NumberedItems
                  key={i}
                  type={t?.text}
                  index={t?.value}
                  deleteTemplate={() => deleteTemplate(i)}
                  moveTemplateUp={() => moveTemplateUp(i)}
                  moveTemplateDown={() => moveTemplateDown(i)}
                  saved_value={t?.input_text}
                  placeholder={placeholder}
                  onChange={(text) => Save(text, i)}
                />
              )
            }

            return (
              <BasicInput
                key={i}
                type={t?.text}
                index={t?.value}
                deleteTemplate={() => deleteTemplate(i)}
                moveTemplateUp={() => moveTemplateUp(i)}
                moveTemplateDown={() => moveTemplateDown(i)}
                saved_value={t?.input_text}
                placeholder={placeholder}
                onChange={(text) => Save(text, i)}
              />
            );
          })}
        </TemplateContainer>
      </Left>

      <Right>
        <Toaster />
        <Title>Preview Template</Title>
        <Row>
          <Button onClick={() => make_gpt_call()}>Generate Preview</Button>
          <Button onClick={() => SaveReportTemplate()}>Save Template</Button>
          {templateId && <Button onClick={() => setShowDeleteConfirm(true)}>Delete Template</Button>}
        </Row>

        {loading ? "Loading..." : ""}
        {message ? <FormatData jsonString={message} /> : ""}
      </Right>
    </Container>
  );
};

export default TemplateCreator;

const FormatData = ({ jsonString }) => {
  let dataArray;
  try {
    dataArray = JSON.parse(jsonString);
  } catch (error) {
    return <p>Error parsing JSON data: {error.message}</p>;
  }

  const renderElements = (items) => {
    const elements = [];
    let keyCounter = 0;

    items.forEach((item) => {
      switch (item.type) {
        case "t1":
          elements.push(<h1 key={keyCounter++}>{item.text}</h1>);
          break;
        case "t2":
          elements.push(<h2 key={keyCounter++}>{item.text}</h2>);
          break;
        case "p":
          elements.push(<p key={keyCounter++}>{item.text}</p>);
          break;
        case "b":
          // If the previous element is not a list, start a new list
          if (!elements.length || elements[elements.length - 1].type !== "ul") {
            elements.push(
              <ul key={keyCounter++}>
                {[<li key={keyCounter++}>{item.text}</li>]}
              </ul>
            );
          } else {
            // If the previous element is a list, add the bullet point to it
            let lastElement = elements.pop();
            lastElement = React.cloneElement(lastElement, {
              children: [
                ...lastElement.props.children,
                <li key={keyCounter++}>{item.text}</li>,
              ],
            });
            elements.push(lastElement);
          }
          break;
        case "t3":
          elements.push(<h3 key={keyCounter++}>{item.text}</h3>);
          break;
        case "n":
            // If the previous element is not a list, start a new list
            if (!elements.length || elements[elements.length - 1].type !== "ol") {
              elements.push(
                <ol key={keyCounter++}>
                  {[<li key={keyCounter++}>{item.text}</li>]}
                </ol>
              );
            } else {
              // If the previous element is a list, add the bullet point to it
              let lastElement = elements.pop();
              lastElement = React.cloneElement(lastElement, {
                children: [
                  ...lastElement.props.children,
                  <li key={keyCounter++}>{item.text}</li>,
                ],
              });
              elements.push(lastElement);
            }
            break;
        default:
          break;
      }
    });

    return elements;
  };

  return <div style={{ marginTop: 30 }}>{renderElements(dataArray)}</div>;
};

const Container = styled.div`
  margin-top: 30px;
  padding-left: 58px;
  font-family: "Raleway";
  display: flex;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: "Raleway";
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  font-family: "Raleway";
  color: #666d79;
`;
const TemplateContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const TextArea = styled.textarea`
  height: 100px;
  width: 100%;
  padding: 10px;
  font-family: "Raleway";
  line-height: 1.3;
  border: 1px solid #e0e0e0;
`;
const InputArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Left = styled.div`
  width: 50%;
  padding-right: 20px;
`;

const Right = styled.div`
  width: 50%;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const CancelText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d79;
  cursor: pointer;
`;

const DeleteConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;


const template_options = [
  {
    key: "Title",
    text: "Title",
    value: 0,
  },
  {
    key: "Secondary Title",
    text: "Secondary Title",
    value: 1,
  },
  {
    key: "Paragraph",
    text: "Paragraph",
    value: 2,
  },
  {
    key: "Bullets",
    text: "Bullets",
    value: 3,
  },
  {
    key: "Data Table",
    text: "Data Table",
    value: 4,
  },
  {
    key: "Factor Bar",
    text: "Factor Bar",
    value: 5,
  },
  {
    key: "Tertiary Title",
    text: "Tertiary Title",
    value: 6,
  },
  {
    key: "Numbered List",
    text: "Numbered List",
    value: 7,
  }
];

const default_template = [
  {
    key: "Title",
    text: "Title",
    value: 0,
    input_text: "Title for the plan, 50 characters max.",
  },
  {
    key: "Paragraph",
    text: "Paragraph",
    value: 2,
    input_text: "A summary of the overall plan details. 3-4 sentences.",
  },
  {
    key: "Secondary Title",
    text: "Secondary Title",
    value: 1,
    input_text: "Title for the first solution",
  },
  {
    key: "Paragraph",
    text: "Paragraph",
    value: 2,
    input_text: "A description of the first solution. 5 sentences long.",
  },
  {
    key: "Bullets",
    text: "Bullets",
    value: 3,
    input_text: [
      "Action step 1 for the solution described above. 2-3 sentences.",
      "Action step 2, adds to step 1  in. 2-3 sentences",
      "Action step 3, adds to step 2. 2-3 sentences.",
    ],
  },
  {
    key: "Secondary Title",
    text: "Secondary Title",
    value: 1,
    input_text: "Title for the 2nd solution",
  },
  {
    key: "Paragraph",
    text: "Paragraph",
    value: 2,
    input_text: "A description of the second solution. 5 sentences long.",
  },
  {
    key: "Bullets",
    text: "Bullets",
    value: 3,
    input_text: [
      "Action step 1 for the solution described above. 3-4 sentences.",
      "Action step 2, build on step 1  in. 3-4 sentences",
      "Action step 3, builds on step 2. 3-4 sentences.",
    ],
  },
];
