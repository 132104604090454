import {
  GET_SURVEY_STRUCTURE_COMPLIANCES,
  UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
  SURVEY_STRUCTURE_COMPLIANCE_SELECT,
  FETCH_SURVEY_STRUCTURE_COMPLIANCE,
  EDIT_SURVEY_STRUCTURE_COMPLIANCE,
  ADD_SURVEY_STRUCTURE_COMPLIANCE,
  DELETE_SURVEY_STRUCTURE_COMPLIANCE,
  CLEAR_SURVEY_STRUCTURE_COMPLIANCE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  survey_structure_compliance: [],
  deleted: false,
  created: false,
  pageNumber: 1,
  survey_structure_compliance_select: [],
  survey_structure_compliance_copied: false,
  pending: false,
};

export default function FileUpload(state = initialState, action) {
  switch (action.type) {

    case GET_SURVEY_STRUCTURE_COMPLIANCES:
      return {
        ...state,
        survey_structure_compliance: action.payload,
        pending: false,
        deleted: false,
        created: false,
        survey_structure_copied: false
      };
    case UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };

    case SURVEY_STRUCTURE_COMPLIANCE_SELECT:
        return {
          ...state,
          survey_struture_select: action.payload,
          pending: false,
          deleted: false,
          created: false,
        };
    case FETCH_SURVEY_STRUCTURE_COMPLIANCE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        deleted: false,
        created: false,
      };
    case EDIT_SURVEY_STRUCTURE_COMPLIANCE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        survey_structure_copied: false,
        deleted: false,
        created: false,
        // survey_structure: [...state.survey_structure, action.payload],
      };
    case DELETE_SURVEY_STRUCTURE_COMPLIANCE:
      return {
        ...state,
        deleted: action.payload.id,
      };
    case ADD_SURVEY_STRUCTURE_COMPLIANCE:
      return {
        ...state,
        created: action.payload,
        [action.payload.id]: action.payload,
        survey_structure_copied: false
        // survey_structure: [...state.survey_structure, action.payload],
      };

    case CLEAR_SURVEY_STRUCTURE_COMPLIANCE:
      return {
        ...state,
        survey_structure_compliance: [],
        deleted: false,
        created: false,
        pageNumber: 1,
        survey_structure_compliance_select: [],
        survey_structure_compliance_copied: false,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        survey_structure_compliance: [],
        deleted: false,
        created: false,
        pageNumber: 1,
        survey_structure_compliance_select: [],
        survey_structure_compliance_copied: false,
        pending: false,
      };

    default:
      return state;
  }
}