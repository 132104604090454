import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import List from './List.js';
import CreateDetail from './Detail';
import { Header } from 'semantic-ui-react'

function CategoryQuestion() {

  return (
    <>
      {ShowArrow()}
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<CreateDetail />} />
        <Route path="create" element={<CreateDetail />} />
      </Routes>
    </>
  );
}

export default CategoryQuestion;

function ShowArrow() {
  const navigate = useNavigate();
  function BackArrow() {
    navigate('/app/category-questions/')
  }

  return (
    <Header as='h2' style={{
       marginTop: "20px", 
       cursor: "pointer"
       }} 
    onClick={BackArrow}>
      Category Questions (NOT WORKING )
      <Header.Subheader>
          Manage Category Questions for Survey.
        </Header.Subheader>
    </Header>



  )
}