import React from "react";
import { Navigate } from "react-router-dom";

import AuthLayout from "layouts/AuthLayout";
import HomeLayout from "layouts/HomeLayout";
//
import Health from "health";
import Lost from "404/index";
import NoAccess from "404/NoAccess";
import Login from "account/Login";
import Logout from "account/Logout";
//
import SwitchOrg from "account/SwitchOrg";
//
import Account from "account";

import DemoSurvey from "demosurvey";
import Dashboard from "dashboard";
import Organizations from "organizations";
import Employees from "employees";
import EmployeeRecord from "employeeRecord";
import EmployeeLog from "employeeLog";
import EmployeeRecordList from "employeeRecordList";
import EmployeeRecordValue from "employeeRecordValue";
import Questions from "questions";
import Categories from "categories";
import CategoryCompliance from "categorycompliance";
import CategoryQuestions from "categoryQuestions";
import UserUpload from "UserUpload";
import EmailTemplate from "EmailTemplate";
import PdfTemplate from "PdfTemplate";
import Settings from "settings";

import BriefScheduled from "briefScheduled";
import SurveyScheduled from "surveyScheduled";
import MarketingRoi from "marketingRoi";
import Gpt from "Gpt";
import SurveyStandard from "SurveyStandard"
import GptRecommendation from "GptRecommendation";
import GptWebChat from "GptWebChat";
import MLOperations from "MLOperations"
import ReportTemplates from "ReportTemplates"
import ReportEvaluation from "RecommendationEval"

const routes = [
  {
    path: "app",
    element: <AuthLayout />,
    children: [
      { path: "dashboard/*", element: <Dashboard /> },
      { path: "organizations/*", element: <Organizations /> },
      { path: "employees/*", element: <Employees /> },
      { path: "employee-record/*", element: <EmployeeRecord /> },
      { path: "employee-log/*", element: <EmployeeLog /> },
      { path: "employee-record-list/*", element: <EmployeeRecordList /> },
      { path: "employee-record-value/*", element: <EmployeeRecordValue /> },
      { path: "questions/*", element: <Questions /> },
      { path: "categories/*", element: <Categories /> },
      { path: "gpt/*", element: <Gpt /> },
      { path: "user-uploads/*", element: <UserUpload /> },
      { path: "demo-survey-response/*", element: <DemoSurvey /> },
      { path: "settings/*", element: <Settings /> },
      { path: "email-templates/*", element: <EmailTemplate /> },
      { path: "pdf-templates/*", element: <PdfTemplate /> },
      { path: "brief-schedule/*", element: <BriefScheduled /> },
      { path: "category-compliance/*", element: <CategoryCompliance /> },
      { path: "category-questions/*", element: <CategoryQuestions /> },
      { path: "survey-schedule/*", element: <SurveyScheduled /> },
      { path: "marketing-roi/*", element: <MarketingRoi /> },
      { path: "survey-standard/*", element: <SurveyStandard /> },
      { path: "gpt-recommendation/*", element: <GptRecommendation /> },
      { path: "gpt-web-chat/*", element: <GptWebChat /> },
      { path: "ml-operations/*", element: <MLOperations /> },
      { path: "report-templates/*", element: <ReportTemplates /> },
      { path: "report-evaluation/*", element: <ReportEvaluation /> },
      { path: "404", element: <Lost /> },
      { path: "switch-org", element: <SwitchOrg /> },
      { path: "no-access", element: <NoAccess /> },
      { path: "*", element: <Navigate to="/app/404" /> },
    ],
  },
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "", element: <Login /> },
      { path: "logout", element: <Logout /> },
      { path: "account/*", element: <Account /> },
      { path: "404", element: <Lost /> },
      { path: "no-access", element: <NoAccess /> },
      { path: "health", element: <Health /> },
      // { path: '*', element: <Navigate to="/404" /> }
    ],
  },
];

export default routes;
