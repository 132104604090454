import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
//
import {
    Message, Button, Input
} from 'semantic-ui-react'
//
import Select from "react-select";
import ClickToCopy from 'utilities/ClickToCopy';
// const PriorityList = [
//     { value: 'primary', label: "Primary" },
//     { value: 'secondary', label: "Secondary" },
//     { value: 'tertiary', label: "Tertiary" },
// ]

const CategoryQuestions = ({setCategoryList, returnValue=false}) => {
    const [surveyQuestion, setSurveyQuestion] = useState([])

    const [sliderValue, setSliderValue] = useState(0)
    const [sliderValueMax, setSliderValueMax] = useState(1)
    const [selectedQuestion, setSelectedQuestion] = useState(false)
    const [answerSelected, setAnswerSelected] = useState(false)

    const { get_survey_structure } = useSelector(
        (state) => ({
            get_survey_structure: state.surveystructure.survey_structure,
        }),
        shallowEqual
    );

    useEffect(() => {
        setSurveyQuestion(get_survey_structure?.results?.[0]?.survey_questions)
        setSliderValueMax(get_survey_structure?.results?.[0]?.survey_questions?.length - 1)
    }, [get_survey_structure]);


    function SliderChange(e) {
        const QNo = Number(e.target.value)
        setSliderValue(QNo)
    }
    
    useEffect(() => {
        if (returnValue ) {
            setCategoryList(surveyQuestion)
        }
    }, [returnValue, setCategoryList, surveyQuestion]);

    useEffect(() => {
        if (surveyQuestion) {
            setSelectedQuestion(surveyQuestion[sliderValue])
            setSliderValueMax(surveyQuestion?.length)
        }
    }, [surveyQuestion, sliderValue]);

    function PrevQuestion() {
        if (sliderValue > 0) {
            setSliderValue(sliderValue - 1)
            setAnswerSelected(false)
        }
    }

    function NextQuestion() {
        if ( sliderValue + 1 < sliderValueMax) {
            setSliderValue(sliderValue + 1)
            setAnswerSelected(false)
        }
    }


    return (
        <div>

            {selectedQuestion &&
                <>
                    <SlideContainer>
                        <SliderInput
                            type="range"
                            min="1"
                            max={sliderValueMax}
                            onChange={SliderChange}
                            value={sliderValue + 1} id="myRange" />
                    </SlideContainer>
                    <Message info>
                    <Message.Header>Visibility: {selectedQuestion?.visibility? selectedQuestion?.visibility : "Not set"}</Message.Header>
                        <p>Category Question No {sliderValue + 1} </p>
                        <p>English</p>
                        <Message.Header>{selectedQuestion?.name}</Message.Header>
                        <p>French</p>
                        <Message.Header>{selectedQuestion?.fr}</Message.Header>
                        <p>Answer</p>
                        <Select
                            name="answers"
                            options={selectedQuestion?.answers}
                            isClearable
                            onChange={setAnswerSelected}
                            value={answerSelected}
                            getOptionLabel={(answers) => `${answers.name} ${answers.id}`}
                            getOptionValue={(answers) => answers.id}
                        />

                        <p>
                        {answerSelected?.id&& <>
                       { `ID #  ${answerSelected?.id} - Value # ${answerSelected?.name}`}
                       <br/>
                       <ClickToCopy  textToCopy={answerSelected?.id} />
                        </> }
                        </p>
                    </Message>

                </>
            }

            <EvenlySpaced>
                <Button onClick={PrevQuestion}>{"< "}Prev</Button>
                <Input type='number'
                    onChange={SliderChange}
                    placeholder='specifc Question...' value={sliderValue + 1} />
                <Button onClick={NextQuestion} positive>Next{" >"}</Button>
            </EvenlySpaced>

        </div>
    );
};

export default CategoryQuestions;


const EvenlySpaced = styled.div`
    display: flex;
    justify-content: space-between;
`
const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`
const SlideContainer = styled.div`
    width: 100%; /* Width of the outside container */
`

const SliderInput = styled.input`
-webkit-appearance: none;  /* Override default CSS styles */
appearance: none;
width: 100%; /* Full-width */
height: 25px; /* Specified height */
background: #d3d3d3; /* Grey background */
outline: none; /* Remove outline */
opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
-webkit-transition: .2s; /* 0.2 seconds transition on hover */
&:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
transition: opacity .2s;
&::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
 }
 &::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
 `

const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`
