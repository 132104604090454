import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_LEADERS_IN_SCHEDULES, 
    SG_GET_LEADERS_IN_SCHEDULES, 
    DELETE_LEADERS_IN_SCHEDULE, 
    SG_DELETE_LEADERS_IN_SCHEDULE, 
    SG_FETCH_LEADERS_IN_SCHEDULE, 
    FETCH_LEADERS_IN_SCHEDULE, 
    SG_EDIT_LEADERS_IN_SCHEDULE, 
    EDIT_LEADERS_IN_SCHEDULE, 
    SG_ADD_LEADERS_IN_SCHEDULE, 
    ADD_LEADERS_IN_SCHEDULE, 
    CLEAR_LEADERS_IN_SCHEDULE, 
    SG_CLEAR_LEADERS_IN_SCHEDULE, 
    ALL_ERRORS,
} from "constants/actions";



function* loadleaders(action) {
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_LEADERS_IN_SCHEDULES, action.payload);
    yield put({ type: GET_LEADERS_IN_SCHEDULES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderLoad() {
  yield takeLatest(SG_GET_LEADERS_IN_SCHEDULES, loadleaders);
}

function* fetchleaders(action) {
  try {
    const json = yield call(api.FETCH_LEADERS_IN_SCHEDULE, action.payload);
    yield put({ type: FETCH_LEADERS_IN_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderFetch() {
  yield takeLatest(SG_FETCH_LEADERS_IN_SCHEDULE, fetchleaders);
}

function* updateleaders(action) {
  try {
    const json = yield call(api.EDIT_LEADERS_IN_SCHEDULE, action.payload);
    yield put({ type: EDIT_LEADERS_IN_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderUpdate() {
  yield takeLatest(SG_EDIT_LEADERS_IN_SCHEDULE, updateleaders);
}

function* addleaders(action) {
  try {
    const json = yield call(api.ADD_LEADERS_IN_SCHEDULE, action.payload);
    yield put({ type: ADD_LEADERS_IN_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderAdd() {
  yield takeLatest(SG_ADD_LEADERS_IN_SCHEDULE, addleaders);
}

function* deleteleaders(action) {
  try {
    yield call(api.DELETE_LEADERS_IN_SCHEDULE, action.payload);
    yield put({ type: DELETE_LEADERS_IN_SCHEDULE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* leaderDelete() {
  yield takeLatest(SG_DELETE_LEADERS_IN_SCHEDULE, deleteleaders);
}


export default function* index() {
  yield all([
    leaderLoad(),
    leaderFetch(),
    leaderUpdate(),
    leaderAdd(),
    leaderDelete(),
  ]);
}
