import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import AddEdit from './AddEdit';
import { Header } from 'semantic-ui-react'

function PdfTemplates() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "20px" }}>
        PDF Templates List
        <Header.Subheader>
          Manage pdf templates
        </Header.Subheader>
       
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="edit/:id" element={<AddEdit />} />
      </Routes>
    </>
  );
}

export default PdfTemplates;
