import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Table, Form, Header } from 'semantic-ui-react'

import Select from "react-select";

function Summary(props) {
    const { surveyStrucRow, surveyStructure } = props

    const [errorList, setErrorList] = useState([])
    const [primaryRow, setPrimaryRow] = useState([])
    const [secondaryRow, setSecondaryRow] = useState({})
    const [tertiaryRow, setTertiaryRow] = useState([])

    // const [fieldMap, setFieldMap] = useState([])
    const [fieldNames, setFieldNames] = useState([])
    // const [validFields, setValidFields] = useState([])
    const [showExtra, setShowExtra] = useState(false)
    const [questions, setQuestion] = useState([])


    useEffect(() => {
        // const _primaryList = [];
        // const _secondaryList = [];
        if (surveyStructure?.length > 0) {
            // setFieldMap(surveyStructure?.[0]?.field_map)
            setQuestion(surveyStructure?.[0]?.survey_questions)
            setFieldNames(surveyStructure?.[0]?.field_map?.field_name)
            // setValidFields(surveyStructure?.[0]?.field_map?.valid_fields)
        }
    }, [surveyStructure]);

    useEffect(() => {
        if (surveyStrucRow) {
            // check for the following
            const _PrimaryRow = []
            const _SecondaryRow = []
            const _TertiaryRow = []
            // one primary exists
            const ErrorMessage = []
            let PrimaryFound = false
            let SecondaryFound = false
            surveyStrucRow.map(row => {

                if (row.priority !== "primary" && !PrimaryFound) {
                    PrimaryFound = true

                }
                if (row.priority === "primary") {
                    _PrimaryRow.push(row)
                }
                if (row.priority !== "secondary" && !SecondaryFound) {
                    SecondaryFound = true

                }
                if (row.priority === "secondary") {
                    _SecondaryRow.push(row)
                }
                if (row.priority === "tertiary") {
                    _TertiaryRow.push(row)
                }
                return null
            })

            if (!PrimaryFound) {
                ErrorMessage.push("No row with Priority Primary Found, atleast one row with Priority Primary is essenstial")
            }
            if (!SecondaryFound) {
                ErrorMessage.push("No row with Priority Secondary Found, atleast one row with Priority Secondary is essenstial")
            }
            // one secondary exists
            // console.log(ErrorMessage)
            setPrimaryRow(_PrimaryRow)
            setSecondaryRow(_SecondaryRow)
            setTertiaryRow(_TertiaryRow)

            setErrorList(ErrorMessage)
        }
    }, [surveyStrucRow]);



    function ShowMoreInfo(inx) {
        if (showExtra === inx) {
            setShowExtra(null)
        } else {
            setShowExtra(inx)
        }

    }
    // console.log(showExtra)

    return (
        <>
            {errorList && errorList.length > 0 && <Message
                color='red'
                icon='warning sign'
                header='Changes Required'
                content={
                    `Looks like there are some errors and changes maybe required.
        `}
            />}
            {errorList && errorList.length > 0 && <Message floating color='red'>
                <Message.Header>Errors</Message.Header>
                <Message.List items={errorList} />

            </Message>}
            <Header as='h2'>
                Priority List
                <Header.Subheader>
                    Summary of Priority
                </Header.Subheader>
            </Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Priority</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Question</Table.HeaderCell>
                        <Table.HeaderCell>Rows</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {primaryRow ?
                        <Table.Row>
                            <Table.Cell positive>
                                <Icon name='checkmark' />
                                Primary</Table.Cell>
                            <Table.Cell>
                                {primaryRow?.[0]?.name}
                            </Table.Cell>
                            <Table.Cell>
                                {primaryRow?.[0]?.question}
                            </Table.Cell>
                            <Table.Cell>
                                {primaryRow?.length}
                            </Table.Cell>
                        </Table.Row>
                        :
                        <Table.Row error>
                            <Table.Cell negative>
                                <Icon name='close' />
                                Primary</Table.Cell>
                            <Table.Cell colSpan="2">No Primary Row Found</Table.Cell>

                        </Table.Row>
                    }

                    {secondaryRow ?
                        <Table.Row>
                            <Table.Cell positive>
                                <Icon name='checkmark' />
                                Secondary</Table.Cell>
                            <Table.Cell>
                                {secondaryRow?.[0]?.name}
                            </Table.Cell>
                            <Table.Cell>
                                {secondaryRow?.[0]?.question}
                            </Table.Cell>
                            <Table.Cell>
                                {secondaryRow?.length}
                            </Table.Cell>
                        </Table.Row>
                        :
                        <Table.Row error>
                            <Table.Cell>
                                <Icon name='close' />
                                Secondary</Table.Cell>
                            <Table.Cell colSpan="2">No Secondary Row Found</Table.Cell>

                        </Table.Row>
                    }

                    {tertiaryRow ?
                        tertiaryRow.map((tr, i) => {

                            return (
                                <Table.Row key={i}>
                                    <Table.Cell positive>
                                        <Icon name='checkmark' />
                                        Tertiary</Table.Cell>
                                    <Table.Cell>
                                        {tr?.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {tr?.question}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {tertiaryRow.length}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })

                        :
                        <Table.Row error>
                            <Table.Cell>
                                <Icon name='close' />
                                Tertiary</Table.Cell>
                            <Table.Cell colSpan="2">
                                No Tertiary Row Found</Table.Cell>

                        </Table.Row>
                    }
                </Table.Body>
            </Table>
            <Header as='h2'>
                Question with Answer
                <Header.Subheader>
                    Select List
                </Header.Subheader>
            </Header>
            <Form>
                {questions && questions.sort((a, b) => a?.id - b?.id).map((item, i) => {
                    // console.log(rowData.filter(rd => rd[0] === item))
                    // console.log(item)

                    const { answers } = item
                    return (<Form.Field key={i}>
                        <label>{item?.question}</label>

                        <Select
                            placeholder={`Select ${item.name}`}
                            // onChange={(e) => ChangeSelectedValue(variable, e)}
                            // value={SetValue}
                            options={answers}
                            // isLoading={isLoading === variable ? isLoading : false}
                            getOptionLabel={(answers) => answers.name}
                            getOptionValue={(answers) => answers.name}
                        />
                    </Form.Field>
                    )


                })}

            </Form>


            <Header as='h2'>
                User Profile Questions
                <Header.Subheader>
                    Primary Category
                </Header.Subheader>
            </Header>
            <Form>
                {fieldNames && fieldNames.filter(ff => ff.priority === "primary").map((item, i) => {
                    // console.log(rowData.filter(rd => rd[0] === item))
                    // console.log(item)

                    const { answers } = item
                    return (<Form.Field key={i}>
                        <label>{item?.question}</label>

                        <Select
                            placeholder={`Select ${item.name}`}
                            // onChange={(e) => ChangeSelectedValue(variable, e)}
                            // value={SetValue}
                            options={answers}
                            // isLoading={isLoading === variable ? isLoading : false}
                            getOptionLabel={(answers) => answers.name}
                            getOptionValue={(answers) => answers.name}
                        />
                    </Form.Field>
                    )


                })}

            </Form>

            <Header as='h2'>
                <Header.Subheader>
                    Secondary Category
                </Header.Subheader>
            </Header>
            <Form>
                {fieldNames && fieldNames.filter(ff => ff.priority === "secondary").map((item, i) => {
                    // console.log(rowData.filter(rd => rd[0] === item))
                    // console.log(item)

                    const { answers } = item
                    return (<Form.Field key={i}>
                        <label>{item?.question}</label>

                        <Select
                            placeholder={`Select ${item.name}`}
                            // onChange={(e) => ChangeSelectedValue(variable, e)}
                            // value={SetValue}
                            options={answers}
                            // isLoading={isLoading === variable ? isLoading : false}
                            getOptionLabel={(answers) => answers.name}
                            getOptionValue={(answers) => answers.name}
                        />
                    </Form.Field>
                    )


                })}

            </Form>

            <Header as='h2'>
                Complete List
                <Header.Subheader>
                    JSON list in table
                </Header.Subheader>
            </Header>
            <Table celled>
                <Table.Body>
                    {surveyStructure?.[0]?.categories && surveyStructure?.[0]?.categories.map(
                        (rowData, i) => {
                            const ObjRow = Object.entries(rowData)
                            return (
                                ObjRow && ObjRow.map((rwi, inx) => {
                                    // console.log(rwi[0], rwi[1])
                                    if (rwi[1] instanceof Array) {
                                        // console.log(rwi[1])
                                        return (
                                            <Table.Row key={inx}>
                                                <Table.Cell>
                                                    {rwi[0]}
                                                    <Icon
                                                        onClick={() => ShowMoreInfo(inx)}
                                                        name='plus' size='small' />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {showExtra === inx &&
                                                        rwi[1].map(
                                                            rr => {
                                                                return <RowWithData rowData={rr} />
                                                            }
                                                        )}
                                                </Table.Cell>
                                            </Table.Row>)
                                    }
                                    return (
                                        <Table.Row key={inx}>
                                            <Table.Cell>
                                                <Text sub>{rwi[0]}</Text>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Text sub>{rwi[1]}</Text>
                                            </Table.Cell>

                                        </Table.Row>)
                                })
                            )
                        }

                    )}
                </Table.Body>
            </Table>
        </>

    )
}

function RowWithData({ rowData }) {
    const ObjRow = Object.entries(rowData)
    // console.log(rowData)
    // console.log(ObjRow)
    const [showExtra, setShowExtra] = useState(false)
    function ShowMoreInfo(inx) {
        if (showExtra === inx) {
            setShowExtra(null)
        } else {
            setShowExtra(inx)
        }

    }
    return (
        <TextRow>
            {ObjRow && ObjRow.map((rwi, inx) => {
                if (rwi[1] instanceof Array) {
                    return (
                        <Table.Row key={inx}>
                            <Table.Cell>
                                {rwi[0]}
                                <Icon
                                    onClick={() => ShowMoreInfo(inx)}
                                    name='plus' size='small' />
                            </Table.Cell>
                            <Table.Cell>
                                {showExtra === inx && rwi[1].map(
                                    rr => {
                                        return <RowWithData rowData={rr} />
                                    }
                                )}

                            </Table.Cell>
                        </Table.Row>)
                }
                return (
                    <LongSpan key={inx}>
                        {rwi[0]} : {rwi[1]}

                    </LongSpan>)
            }
            )}
        </TextRow>
    )


}



export default Summary

const Text = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  margin-left: 15px;
  color: black;
`;

const TextRow = styled.div`
  border-style: solid;
  border-width: 1px;
`;

const LongSpan = styled.span`
    margin-left: 15px
`