import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Icon,
  Header,
  Card,
  List,
  Button,
  Confirm,
  Input,
  Message,
} from "semantic-ui-react";

import { format } from "date-fns";
//
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_DEBRIEF_SCHEDULE,
  SG_EDIT_DEBRIEF_SCHEDULE,
  SG_POST_GOOGLE_DEMO_SHEET,
  SG_GET_SURVEY_QUESTION_SELECT,
  SG_GET_SURVEY_STRUCTURES,
  PULL_ORGANIZATION,
  SG_DELETE_DEBRIEF_SCHEDULE,
} from "constants/actions";
//
import Select from "react-select";
//
import { TOTAL_NOS } from "data/AdminData";

//
import EmpList from "../briefScheduled/EmpList";
import ExtendSurvey from "../briefScheduled/ExtendSurvey";
import TokenList from "briefToken/List";
import ResponseList from "briefResponse/List";
import CategoryQuestions from "organizations/CategoryQuestions";
import Questions from "organizations/onboarding/Questions";
import ClickToCopy from "utilities/ClickToCopy";
import BasicErrorMessage from "utilities/ErrorMessages";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [deBriefId, setDebriefId] = useState(null);
  const [debriefDetails, setDebriefDetails] = useState(null);
  const [briefQuestion, setBriefQuestion] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [organization, setOrganization] = useState(false);

  const [gsheetId, setGsheetId] = useState(null);
  const [totalResponses, setTotalResponses] = useState(1);
  const [spacedMonths, setSpacedMonths] = useState(1);
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);

  const [sentList, setSentList] = useState(false);

  const [additionalType, setAdditionalType] = useState([]);

  const [showMessage, setShowMessage] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateRows, setUpdateRows] = useState(false);

  const [gsheetList, setGsheetList] = useState([]);
  const [gsheetError, setGsheetError] = useState([]);
  const [gsheetAccess, setGsheetAccess] = useState([]);

  function Back() {
    navigate(-1);
  }
  useEffect(() => {
    setDebriefId(id);
  }, [id]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTION_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (deBriefId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: { id: deBriefId },
      });
    }
  }, [dispatch, deBriefId, updateRows]);

  const {
    get_brief_schedules,
    get_organization,
    get_survey_question_select,
    get_survey_structure,
    get_organization_select,
  } = useSelector(
    (state) => ({
      get_brief_schedules: state.debrief_schedule,
      get_organization: state.organizations,
      get_survey_question_select: state.surveyquestion.survey_question_select,
      get_survey_structure: state.surveystructure,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (debriefDetails) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: debriefDetails?.organization },
      });
    }
  }, [dispatch, debriefDetails]);

  useEffect(() => {
    if (debriefDetails) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${debriefDetails?.organization}`,
      });
    }
  }, [dispatch, debriefDetails]);

  useEffect(() => {
    if (debriefDetails?.brief_question) {
      setBriefQuestion(
        get_survey_question_select?.filter(
          (qu) => qu.id === debriefDetails?.brief_question
        )?.[0]
      );
    }
  }, [debriefDetails, get_survey_question_select]);

  function DeleteBriefSchedule(id) {
    setConfirmDelete(false);
    dispatch({
      type: SG_DELETE_DEBRIEF_SCHEDULE,
      payload: { id },
    });
    setShowMessage({
      message: "Survey and Responses Deleted",
      color: "red",
      header: "Deleted",
    });
    DelayedLoad();
    navigate(-1);
  }

  useEffect(() => {
    if (get_organization && debriefDetails?.organization) {
      setOrganization(get_organization[debriefDetails?.organization]);
    }
  }, [get_organization, debriefDetails?.organization]);

  useEffect(() => {
    setDebriefDetails(null);
    if (get_brief_schedules?.[deBriefId]?.id) {
      setDebriefDetails(get_brief_schedules?.[deBriefId]);
      setTotalResponses(
        TOTAL_NOS.filter(
          (t) =>
            t.value ===
            get_brief_schedules?.[deBriefId]?.additional_type?.total_responses
        )?.[0]
      );
      setSpacedMonths(
        TOTAL_NOS.filter(
          (t) =>
            t.value ===
            get_brief_schedules?.[deBriefId]?.additional_type?.spaced_months
        )?.[0]
      );
      setGsheetId(get_brief_schedules?.[deBriefId]?.additional_type?.gsheet_id);
    }
  }, [get_brief_schedules, deBriefId]);

  useEffect(() => {
    const _addType = {};
    const _allQuestionList = [];
    const _fullQuestions = [];
    const _questionType = {
      0: "employee_id",
    };

    let _catQues = [];
    let _regQues = [];
    let _catLen = 0;

    if (categoryList) {
      _catLen = categoryList?.length;
      _catQues = categoryList.map((ct) => {
        return [`${ct.name} ${ct.question ? ct.question : ""}`];
      });
      categoryList.map((ct, i) => {
        _fullQuestions.push({
          id: ct?.id,
          priority: ct?.priority,
          response: null,
          question_order: i + 1,
        });
        _questionType[i + 1] = "categories";
        return null;
      });
    }
    if (questionList) {
      _regQues = questionList.map((ct) => [ct.question]);
      questionList.map((question, i) => {
        _fullQuestions.push({
          id: question?.dimension,
          factor: question?.factor,
          q: question?.q,
          ques_order: question.order,
          response: null,
          role: question?.role,
          reverse: question?.reverse,
          question_order: i + 1 + _catLen,
        });
        _questionType[i + 1 + _catLen] = "questions";
        return null;
      });
    }

    _allQuestionList.push(..._catQues, ..._regQues);
    const _SurveyDetails = [];
    _SurveyDetails.push([`organization_id ${debriefDetails?.organization}`]);
    _SurveyDetails.push([`debrief_schedule_id ${debriefDetails?.id}`]);
    _SurveyDetails.push(..._allQuestionList);

    const _name =
      get_survey_structure?.survey_structure?.results?.[0]?.categories.filter(
        (ss) => ss.priority === "primary"
      )?.[0];

    const _value = _name?.options.filter(
      (oo) => oo.name === debriefDetails?.name
    )?.[0];

    const _empCat = {
      name: _name?.name,
      name_id: _name?.id,
      value: _value?.name,
      value_id: _value?.id,
      priority: _name?.priority,
    };

    _addType["debrief_schedule_id"] = debriefDetails?.id;
    _addType["organization_id"] = debriefDetails?.organization;
    _addType["questions"] = _SurveyDetails;
    _addType["full_questions"] = _fullQuestions;
    _addType["employee_category"] = _empCat;
    _addType["gsheet_id"] = gsheetId;
    _addType["total_questions"] = _allQuestionList?.length;
    _addType["total_responses"] = totalResponses?.value;
    _addType["spaced_months"] = spacedMonths?.value;
    _addType["question_type"] = _questionType;
    _addType["category_list"] = categoryList;

    setAdditionalType(_addType);
  }, [
    categoryList,
    questionList,
    get_survey_structure,
    debriefDetails,
    gsheetId,
    totalResponses,
    spacedMonths,
  ]);

  useEffect(() => {
    if (deBriefId && additionalType?.gsheet_id) {
      dispatch({
        type: SG_POST_GOOGLE_DEMO_SHEET,
        payload: {
          debrief_schedule_id: deBriefId,
          additional_type: additionalType,
        },
      });
    }
  }, [dispatch, additionalType, deBriefId]);

  function UpdateDebrief() {
    setPristine(false);
    if (formErrors.length === 0) {
      dispatch({
        type: SG_POST_GOOGLE_DEMO_SHEET,
        payload: {
          debrief_schedule_id: debriefDetails?.id,
          additional_type: additionalType,
          action: "create_gsheet",
        },
      });
      setPristine(true);
    }
  }
  //

  function ProcessEmployees() {
    if (debriefDetails.additional_type?.gsheet_id) {
      dispatch({
        type: SG_POST_GOOGLE_DEMO_SHEET,
        payload: {
          debrief_schedule_id: debriefDetails?.id,
          additional_type: additionalType,
          action: "update_employees",
        },
      });
    }
    //
  }

  function ProcessDebrief() {
    if (debriefDetails.additional_type?.gsheet_id) {
      dispatch({
        type: SG_POST_GOOGLE_DEMO_SHEET,
        payload: {
          debrief_schedule_id: debriefDetails?.id,
          additional_type: additionalType,
          action: "update_responses",
        },
      });
    }
    //
  }
    // outcome_question
    function UpdateOutcomeQuestion() {
      if (debriefDetails.additional_type?.gsheet_id) {
        dispatch({
          type: SG_POST_GOOGLE_DEMO_SHEET,
          payload: {
            debrief_schedule_id: debriefDetails?.id,
            additional_type: additionalType,
            action: "outcome_question",
          },
        });
      }
      //
    }

  // update_feedback
  function UpdateFeedback() {
    if (debriefDetails.additional_type?.gsheet_id) {
      dispatch({
        type: SG_POST_GOOGLE_DEMO_SHEET,
        payload: {
          debrief_schedule_id: debriefDetails?.id,
          additional_type: additionalType,
          action: "update_feedback",
        },
      });
    }
    //
  }

  useEffect(() => {
    setGsheetList([]);
    if (get_brief_schedules.ghseet_summary?.response?.worksheets && additionalType?.gsheet_id) {
      setGsheetList(get_brief_schedules.ghseet_summary?.response?.worksheets);
    }

    setGsheetAccess(get_brief_schedules.ghseet_summary?.response?.access);
    setGsheetError(get_brief_schedules.ghseet_summary?.response?.errors);
  }, [get_brief_schedules.ghseet_summary, deBriefId, additionalType.gsheet_id]);

  // console.log(get_brief_schedules.ghseet_summary);

  useEffect(() => {
    const ErrorList = [];
    if (!gsheetId) {
      ErrorList.push("Enter a Gheet Id");
    }
    if (!debriefDetails?.id) {
      ErrorList.push("No Survey Selected");
    }
    // if (!spacedMonths?.value) {
    //   ErrorList.push("No Spaced Months Selected");
    // }
    // if (!totalResponses?.value) {
    //   ErrorList.push("No total responses Selected");
    // }
    setFormErrors(ErrorList);
  }, [
    gsheetId,
    valueChanged,
    debriefDetails,
    // spacedMonths, totalResponses
  ]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)

    if (name === "gsheet_id") {
      setGsheetId(e.target.value);
    }
    setValueChanged(e.target.value + name);
  }
  // console.log(debriefDetails)
  // console.log(debriefDetails?.additional_type)
  console.log(gsheetList);

  function DelayedLoad() {
    //
    const timer1 = setTimeout(() => {
      setUpdateRows(Math.random());
    }, 2000);
    return () => clearTimeout(timer1);
  }

  return (
    <>
      <BasicErrorMessage />
      <Header as="h2">
        <Icon style={{ cursor: "pointer" }} onClick={Back} name="arrow left" />
        Details of {debriefDetails?.type_of_text}{" "}
        {debriefDetails?.survey_type_text}
      </Header>

      {debriefDetails?.status === 4 && (
        <ExtendSurvey debriefDetails={debriefDetails} />
      )}
      <Card fluid key={debriefDetails?.id}>
        <Card.Content>
          <Container>
            <ListContainer>
              <HeaderDiv>Org Id</HeaderDiv>
              <ContentDiv>{debriefDetails?.organization}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Org</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.organization &&
                  get_organization_select.filter(
                    (org) => org.id === debriefDetails?.organization
                  )?.[0]?.name}
              </ContentDiv>
            </ListContainer>

            <ListContainer>
              <HeaderDiv>Type</HeaderDiv>
              <ContentDiv>{debriefDetails?.type_of_text}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Status</HeaderDiv>
              <ContentDiv>{debriefDetails?.status_text}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Time Zone</HeaderDiv>
              <ContentDiv>{debriefDetails?.time_zone}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Question</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.brief_question?.name} (
                {debriefDetails?.brief_question?.sort_order})
                {debriefDetails?.brief_question?.status_text}
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/app/questions/details/${debriefDetails?.brief_question?.id}`
                    )
                  }
                  color="violet"
                  name="external alternate"
                />
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Name</HeaderDiv>
              <ContentDiv>{debriefDetails?.name}</ContentDiv>
            </ListContainer>
          </Container>

          <Container>
            <ListContainer>
              <List divided horizontal>
                <List.Item>
                  <HeaderDiv>Secondary</HeaderDiv>
                </List.Item>
                {debriefDetails?.secondary ? (
                  debriefDetails.secondary.map((s) => {
                    return <List.Item key={s}>{s}</List.Item>;
                  })
                ) : (
                  <List.Item>All Secondary included</List.Item>
                )}
              </List>
            </ListContainer>
          </Container>

          <Container>
            <ListContainer>
              <HeaderDiv>Survey Id</HeaderDiv>
              <ContentDiv>{debriefDetails?.id}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Created Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.created_at &&
                  format(
                    new Date(debriefDetails?.created_at),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Scheduled Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.scheduled_for &&
                  format(
                    new Date(debriefDetails?.scheduled_for),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Close Date</HeaderDiv>
              <ContentDiv>
                {debriefDetails?.close_at &&
                  format(
                    new Date(debriefDetails?.close_at),
                    "dd-MMM-yyyy hh:mm"
                  )}
              </ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Sent To</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_requests}</ContentDiv>
            </ListContainer>
            <ListContainer>
              <HeaderDiv>Responded</HeaderDiv>
              <ContentDiv>{debriefDetails?.total_responses}</ContentDiv>
            </ListContainer>
          </Container>
        </Card.Content>
      </Card>
      <RightAlign>
        <ContainerFieldSelectable onClick={() => setSentList(!sentList)}>
          {sentList ? "Close" : "Open"}
        </ContainerFieldSelectable>
      </RightAlign>
      <Container>
        {sentList && (
          <ContentSplitRow>
            <ContentSplitCol>
              <Header sub>Sent to Members</Header>
              <EmpList rows={debriefDetails?.request_list} />
            </ContentSplitCol>
            <ContentSplitCol>
              <Header sub>Completed by Members</Header>
              <EmpList rows={debriefDetails?.compliance} />
            </ContentSplitCol>
          </ContentSplitRow>
        )}
      </Container>

      <SectionDiv>
        <Header as="h3">Survey Questions</Header>
        <Header.Subheader>
          List of questions for any given survey
        </Header.Subheader>
        <CategoryQuestions
          setCategoryList={setCategoryList}
          returnValue={true}
        />
        <Questions
          organizationId={debriefDetails?.organization}
          surveyTypeId={debriefDetails?.brief_question?.survey_type}
          setQuestionList={setQuestionList}
          returnValue={true}
        />
      </SectionDiv>

      <Header as="h3">Generate Google Sheet with questions</Header>
      <span>
        Share the google worksheet with
        survey-response-creating@deep-lore-365516.iam.gserviceaccount.com
        <ClickToCopy
          textToCopy={
            "survey-response-creating@deep-lore-365516.iam.gserviceaccount.com"
          }
        />
      </span>
      <span>
        <a
          href={`https://docs.google.com/spreadsheets/d/${gsheetId}`}
          target="_blank"
          rel="noreferrer"
        >
          View Gsheet
        </a>
      </span>
      <p>Copy paste the google sheet id below </p>
      <div>
        {formErrors.length > 0 && !pristine && (
          <Message
            negative
            //   onDismiss={clearErrors}
            header="We need these details!"
            list={formErrors}
          />
        )}
        <FormRow>
          <FormLabel>
            <LabelHead>Google Sheet Id</LabelHead>
          </FormLabel>
          <FormInput>
            <Input
              fluid
              name="gsheet_id"
              defaultValue={gsheetId}
              onChange={(e) => ChangeFormValues(e, "gsheet_id")}
            />
          </FormInput>
        </FormRow>

        <Button floated="right" onClick={UpdateDebrief} positive>
          Update
        </Button>
      </div>
      <RowInLine>
        <Button onClick={() => setConfirmDelete(true)} negative>
          Delete Survey
        </Button>
        <Confirm
          content="This would delete all tokens and responses for this survey"
          open={confirmDelete}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={() => DeleteBriefSchedule(debriefDetails?.id)}
        />
      </RowInLine>
      <RowInLine>
        <Header as="h3">
          Create Employees from the list added in Google Sheet
        </Header>
        {/* {gsheetList.includes("employees") && } */}
        <Button
          floated="right"
          onClick={ProcessEmployees}
          disabled={gsheetList?.includes("questions") ? false : true}
          color={gsheetList?.includes("questions") ? "green" : "grey"}
        >
          Create Employees
        </Button>
      </RowInLine>

      <RowInLine>
        <Header as="h3">Convert Google Sheet information to responses</Header>
        {/* {gsheetList.includes("employees") && } */}
        <Button
          floated="right"
          onClick={ProcessDebrief}
          disabled={gsheetList?.includes("responses") ? false : true}
          color={gsheetList?.includes("responses") ? "green" : "grey"}
        >
          Create Responses
        </Button>
      </RowInLine>

      <RowInLine>
        <Header as="h3">
          Process Outcome Question (Outcome Question needs to be manually imported in Gsheet)
        </Header>
        {/* {gsheetList.includes("employees") && } */}
        <Button
          floated="right"
          onClick={UpdateOutcomeQuestion}
          disabled={gsheetList?.includes("outcome_question") ? false : true}
          color={gsheetList?.includes("outcome_question") ? "green" : "grey"}
        >
          Process Outcome Question
        </Button>
      </RowInLine>

      <RowInLine>
        <Header as="h3">
          Process Feedback (Feedback needs to be manually imported in Gsheet)
        </Header>
        {/* {gsheetList.includes("employees") && } */}
        <Button
          floated="right"
          onClick={UpdateFeedback}
          disabled={gsheetList?.includes("employee_feedback") ? false : true}
          color={gsheetList?.includes("employee_feedback") ? "green" : "grey"}
        >
          Process Feedback
        </Button>
      </RowInLine>

      {showMessage && (
        <Message
          onDismiss={() => setShowMessage(false)}
          color={showMessage?.color}
          header={showMessage?.header}
          content={showMessage?.message}
        />
      )}
      <Header as="h3">Responses</Header>
      <ResponseList deBriefId={deBriefId} />
    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`;
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  min-height: 110px;
  margin-bottom: ${(props) => (props.lastRow ? "100px" : "0px")};
  ${(props) => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${(props) => props.showBorder && "padding: 15px"};
`;

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
`;
const ContentSplitRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ContentSplitCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const RowInLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 10px 0px;
`;
