import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from 'date-fns'

import { Card, Button, List } from 'semantic-ui-react'

const RowCard = ({ row, CATEGORY_COMPLIANCE_HEADERS }) => {
  const navigate = useNavigate()

  return (
    <>
      <Card fluid key={row?.id}>

        <Card.Content>
          <Card.Header>{row?.first_name}{" "}{row?.last_name}</Card.Header>
          <Card.Meta>{row?.email}{" "}{row?.mobiie}</Card.Meta>

          <Container>
            <List horizontal>
              {Item("Id", row?.id)}
              {Item("H Id", row?.priority)}
              {Item("Header", CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.value === row?.priority)?.[0]?.label)}
              
              {Item("Name", row?.name)}
              {Item("Created", row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy'))}
              {Item("Last Updated", row?.created_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy'))}

            </List>
          </Container>

          <Card.Description>

            <Button floated='right'
              onClick={() => navigate(`/app/category-compliance/details/${row?.id}`)}>
              More Details
            </Button>
          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

function Item(name, value) {
  return (
    <List.Item>
      
        <ListContainer>
          <List.Header>{name}</List.Header>
          {value}
        </ListContainer>
     
    </List.Item>
  )
}
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`


const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

