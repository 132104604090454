import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  // UPDATE_GPT_RESPONSE_MAIN_PAGE_NUMBER,
  GET_GPT_RESPONSE_MAIN_PENDING,
  GET_GPT_RESPONSE_MAINS,
  SG_GET_GPT_RESPONSE_MAINS,
  GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
  SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
  SG_BRIEF_AI_SUMMARY,
  BRIEF_AI_SUMMARY,
  DELETE_GPT_RESPONSE_MAIN,
  SG_DELETE_GPT_RESPONSE_MAIN,
  FETCH_GPT_RESPONSE_MAIN,
  SG_FETCH_GPT_RESPONSE_MAIN,
  EDIT_GPT_RESPONSE_MAIN,
  SG_EDIT_GPT_RESPONSE_MAIN,
  ADD_GPT_RESPONSE_MAIN,
  SG_ADD_GPT_RESPONSE_MAIN,
  // CLEAR_GPT_RESPONSE_MAIN,
  ALL_ERRORS,
} from "constants/actions";

function* loadmethods(action) {
  yield put({ type: GET_GPT_RESPONSE_MAIN_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_GPT_RESPONSE_MAINS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_GPT_RESPONSE_MAINS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_GPT_RESPONSE_MAINS, loadmethods);
}

function* loadDebriefmethods(action) {
  yield put({ type: GET_GPT_RESPONSE_MAIN_PENDING });
  // console.log(
  //   "action.payload",
  //   action.payload,
  //   action.payload?.brief_scheduled_id
  // );
  try {
    const json = yield call(api.GET_GPT_RESPONSE_MAINS, action.payload.query);
    // console.log(json.data)
    yield put({
      type: GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
      payload: {
        brief_scheduled_id: action.payload.brief_scheduled_id,
        data: json.data,
      },
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDebriefLoad() {
  yield takeEvery(
    SG_GET_GPT_RESPONSE_MAIN_DEBRIEF_SCHEDULE,
    loadDebriefmethods
  );
}

//
function* addbulkmethods(action) {
  yield put({ type: GET_GPT_RESPONSE_MAIN_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.BRIEF_AI_SUMMARY, action.payload);
    // console.log(json.data)
    yield put({ type: BRIEF_AI_SUMMARY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodBulkAdd() {
  yield takeEvery(SG_BRIEF_AI_SUMMARY, addbulkmethods);
}

function* fetchmethods(action) {
  yield put({ type: GET_GPT_RESPONSE_MAIN_PENDING });
  try {
    const json = yield call(api.FETCH_GPT_RESPONSE_MAIN, action.payload);
    yield put({ type: FETCH_GPT_RESPONSE_MAIN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_GPT_RESPONSE_MAIN, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_GPT_RESPONSE_MAIN, action.payload);
    yield put({ type: EDIT_GPT_RESPONSE_MAIN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_GPT_RESPONSE_MAIN, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_GPT_RESPONSE_MAIN
  try {
    const json = yield call(api.ADD_GPT_RESPONSE_MAIN, action.payload);
    yield put({ type: ADD_GPT_RESPONSE_MAIN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_GPT_RESPONSE_MAIN, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_GPT_RESPONSE_MAIN, action.payload);
    yield put({ type: DELETE_GPT_RESPONSE_MAIN, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_GPT_RESPONSE_MAIN, deletemethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodDebriefLoad(),
    methodBulkAdd(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
