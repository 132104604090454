import React, { useState, useEffect } from "react";
import {
  Icon,
  Checkbox,
  Button,
  Input,
  Message,
  Confirm,
} from "semantic-ui-react";
import styled from "styled-components";

import Select from "react-select";

import ServicesEnabled from "data/ServicesEnabled.json";

import {
  SURVEY_SEQUENCE_LIST,
  ORG_CATEGORY,
  QUESTION_ASK_AGAIN,
} from "data/AdminData";

const StatusList = [
  { value: "min", label: "Min" },
  { value: "max", label: "Max" },
];

export function RowCheckList(services) {
  const ServiceList = ["Teamtool", "EDI", "Audit", "OTP"];
  return (
    services &&
    services.map((sr, i) => {
      if (ServiceList.includes(sr?.name)) {
        if (sr?.enabled === true) {
          return (
            <div key={i}>
              <Icon color="green" name="checkmark" size="small" />
              <SectionHeader>{sr.name}</SectionHeader>

              <SectionHeader>
                {sr.question_sort_order > 0
                  ? `Specific Question ${sr.question_sort_order}`
                  : "Generic Question"}
              </SectionHeader>
            </div>
          );
        } else {
          return (
            <div key={i}>
              <Icon color="red" name="close" size="small" />
              <SectionHeader>{sr.name}</SectionHeader>

              <SectionHeader>
                {sr.question_sort_order > 0
                  ? `Specific Question ${sr.question_sort_order}`
                  : "Generic Question"}
              </SectionHeader>
            </div>
          );
        }
      }
      return null;
    })
  );
}

export function RowObjectList(services) {
  const [objectList, setObjectList] = useState([]);
  useEffect(() => {
    // setObjectList(Object.entries(services).filter(i => i[0] !== 'rules'))
    setObjectList(
      Object.entries(services).map((item, i) => {
        if (typeof item[1] !== "object") {
          return item;
        }
        return null;
      })
    );
  }, [services]);

  return (
    <>
      <SectionHeader>Styling</SectionHeader>
      {objectList.map((item, i) => {
        if (item) {
          return (
            <div key={i}>
              <RowData>
                <RowLine> {item[0]}</RowLine>
                <RowLine> {item[1]}</RowLine>
              </RowData>
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

export function RowObjectInputEdit({
  stylingList,
  setStylingList,
  setUpdateValue,
}) {
  const [objectList, setObjectList] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [errorList, SetErrorList] = useState([]);
  const [isDirty, SetIsDirty] = useState(false);
  const [refreshPage, SetRefreshPage] = useState(false);
  const [onSubmit, SetOnSubmit] = useState(false);

  useEffect(() => {
    if (stylingList) {
      setObjectList(Object.entries(stylingList));
    }
  }, [stylingList, refreshPage]);

  function DeleteRow(e) {
    let _newList = stylingList;
    delete _newList[e];
    setStylingList(_newList);
    SetRefreshPage(_newList);
    SetRefreshPage(Math.random());
    setUpdateValue(`${e} + ${true}`);
  }

  function AddRow() {
    SetOnSubmit(Math.random());
    SetIsDirty(true);
    if (errorList?.length === 0) {
      let _newList = stylingList;
      _newList[newValue] = newLabel;

      setStylingList(_newList);
      SetRefreshPage(Math.random());
      setNewValue("");
      setNewLabel("");
      SetErrorList(false);
      SetIsDirty(false);
      setUpdateValue(`${Math.random()} + ${true}`);
    }
  }

  function DismissError() {
    SetErrorList(false);
    SetIsDirty(false);
  }

  useEffect(() => {
    SetErrorList([]);
    let _errList = [];

    if (!newValue) {
      _errList.push("Please add a Key");
    }
    if (!newLabel) {
      _errList.push("Please add a value");
    }
    if (_errList?.length > 0) {
      SetErrorList(_errList);
    }
  }, [newLabel, newValue, onSubmit]);

  return (
    <>
      {errorList?.length > 0 && isDirty && (
        <Message
          color="red"
          onDismiss={DismissError}
          header={"There were some errors"}
          content={JSON.stringify(errorList)}
        />
      )}

      {objectList.map((item, i) => {
        if ((item[1], typeof item[1] === "object")) {
          return null;
        }

        return (
          <div key={i}>
            <RowData>
              <RowInputLine>{item[0]}</RowInputLine>
              <RowInputLine>
                <Input value={item[1]} />
              </RowInputLine>
              <RowInputLine>
                <Icon
                  style={{
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                  onClick={() => DeleteRow(item[0])}
                  name="trash alternate"
                />
              </RowInputLine>
            </RowData>
          </div>
        );
      })}
      <RowData>
        <RowInputLine>
          <Input
            value={newValue}
            onChange={(e) => {
              SetIsDirty(true);
              setNewValue(e.target.value);
            }}
          />
        </RowInputLine>
        <RowInputLine>
          <Input
            value={newLabel}
            onChange={(e) => {
              SetIsDirty(true);
              setNewLabel(e.target.value);
            }}
          />
        </RowInputLine>
        <RowInputLine>
          <Icon
            style={{
              cursor: "pointer",
              marginTop: "5px",
            }}
            onClick={AddRow}
            name="save"
          />
        </RowInputLine>
      </RowData>
    </>
  );
}

export function RulesSubList({ stylingList, setStylingList, setUpdateValue }) {
  const [refreshPage, SetRefreshPage] = useState(false);
  const [onSubmit, SetOnSubmit] = useState(false);
  const [isDirty, SetIsDirty] = useState(false);
  const [errorList, SetErrorList] = useState([]);

  const [newValue, setNewValue] = useState("");
  const [newLabel, setNewLabel] = useState("");

  function DismissError() {
    SetErrorList(false);
    SetIsDirty(false);
  }

  function AddRuletoSurvey() {
    SetOnSubmit(Math.random());
    let _newList = stylingList;
    _newList["rules"] = [];
    setStylingList(_newList);
  }

  function DeleteRuleRow(e) {
    let _newList = stylingList.rules;
    const RowList = _newList.filter((fl) => fl.type !== e.type);
    setStylingList({ ...stylingList, rules: RowList });
    // SetRefreshPage(_newList)
    // SetRefreshPage(Math.random())
    // setUpdateValue(`${e} + ${true}`)
  }

  function AddRuleRow() {
    SetOnSubmit(Math.random());
    SetIsDirty(true);

    if (errorList?.length === 0) {
      let _newList = stylingList;
      const _found = _newList.rules.filter(
        (fl) => fl.type === newLabel.value
      )?.[0];
      if (!_found) {
        _newList.rules.push({
          type: newLabel.value,
          rows: newValue,
        });
      } else {
        let _newList = stylingList.rules;

        const RowList = _newList.map((k) => {
          if (k.type === newLabel.value) {
            k.rows = newValue;
          }
          return k;
        });
        setStylingList({ ...stylingList, rules: { ..._newList, ...RowList } });
      }

      setStylingList(_newList);
      SetRefreshPage(Math.random());
      setNewValue("");
      setNewLabel(false);
      SetErrorList(false);
      SetIsDirty(false);
      setUpdateValue(`${Math.random()} + ${true}`);
    }
  }

  useEffect(() => {
    SetErrorList([]);
    let _errList = [];

    if (!newValue) {
      _errList.push("Please add a Key");
    }
    if (!newLabel) {
      _errList.push("Please add a value");
    }
    if (_errList?.length > 0) {
      SetErrorList(_errList);
    }
  }, [newLabel, newValue, onSubmit]);

  return stylingList?.rules ? (
    <>
      {errorList?.length > 0 && isDirty && (
        <Message
          color="red"
          onDismiss={DismissError}
          header={"There were some errors"}
          content={JSON.stringify(errorList)}
        />
      )}
      {stylingList?.rules.map((item, i) => {
        return (
          <div key={i}>
            <RowData>
              <RowInputLine>{item.type}</RowInputLine>
              <RowInputLine>
                <Input value={item.rows} />
              </RowInputLine>
              <RowInputLine>
                <Icon
                  style={{
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                  onClick={() => DeleteRuleRow(item)}
                  name="trash alternate"
                />
              </RowInputLine>
            </RowData>
          </div>
        );
      })}
      <RowData>
        <RowInputLine>
          <Select
            placeholder="Select Key"
            onChange={setNewLabel}
            value={newLabel}
            options={StatusList}
          />
        </RowInputLine>
        <RowInputLine>
          <Input
            value={newValue}
            onChange={(e) => {
              SetIsDirty(true);
              setNewValue(e.target.value);
            }}
          />
        </RowInputLine>
        <RowInputLine>
          <Icon
            style={{
              cursor: "pointer",
              marginTop: "5px",
            }}
            onClick={AddRuleRow}
            name="save"
          />
        </RowInputLine>
      </RowData>
    </>
  ) : (
    <Button onClick={AddRuletoSurvey}>Add Survey Rules</Button>
  );
}

export function SurveySequenceList({
  stylingList,
  setStylingList,
  setUpdateValue,
  get_survey_question_all_select,
}) {
  // const [onSubmit, SetOnSubmit] = useState(false)
  // const [isDirty, SetIsDirty] = useState(false)
  // const [errorList, SetErrorList] = useState([])

  // const [newValue, setNewValue] = useState('')
  // const [newLabel, setNewLabel] = useState('')

  // const [questionSelected, setQuestionSelected] = useState([])
  const [questionSelect, setQuestionSelect] = useState([]);
  const [questionAsk, setQuestionAsk] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);

  useEffect(() => {
    const _SelectList = [];
    get_survey_question_all_select.map((sr) => {
      _SelectList.push({
        value: sr.sort_order,
        label: `${sr.name} (sort order ${sr.sort_order})`,
        survey_type: sr.survey_type,
        sort_order: sr.sort_order,
      });
      return null;
    });
    setQuestionSelect(_SelectList);
  }, [get_survey_question_all_select]);

  // console.log(serviceList)

  // useEffect(() => {
  //   setQuestionSelected(questionSelect.filter(slt => slt.value === sr.question_sort_order))
  // }, [questionSelect, sr]);

  // function DismissError() {
  //   SetErrorList(false)
  //   SetIsDirty(false)
  // }

  function AddSurveySequencetoSurvey() {
    // SetOnSubmit(Math.random())
    setStylingList({ ...stylingList, survey_sequence: SURVEY_SEQUENCE_LIST });
    setUpdateValue(Math.random());
  }

  function AddRowSurveySequencetoSurvey(newRowValue) {
    // SetOnSubmit(Math.random())
    setStylingList(prevState => ({
      ...prevState,
      survey_sequence: [...prevState.survey_sequence, newRowValue]
    }));
    setUpdateValue(Math.random());
  }

  // console.log(stylingList)

  function DeleteRuleRow(e) {
    let _newList = stylingList.survey_sequence;
    const RowList = _newList.filter((fl) => fl.id !== e.id);
    setStylingList({ ...stylingList, survey_sequence: RowList });

    // SetRefreshPage(_newList)
    // SetRefreshPage(Math.random())
    setUpdateValue(`${e} + ${true}`);
  }

  function DeleteAllRuleRows() {
    setStylingList({ ...stylingList, survey_sequence: [] });
    setUpdateValue(`${Math.random} + ${true}`);
    setConfirmDel(false);
  }

  // function AddRuleRow() {
  //   SetOnSubmit(Math.random())
  //   SetIsDirty(true)

  //   if (errorList?.length === 0) {
  //     let _newList = stylingList
  //     const _found = _newList.survey_sequence.filter(fl => fl.type === newLabel.value)?.[0]
  //     if (!_found) {
  //       _newList.survey_sequence.push({
  //         type: newLabel.value,
  //         rows: newValue
  //       })
  //     } else {
  //       let _newList = stylingList.survey_sequence

  //       const RowList = _newList.map(k => {
  //         if (k.type === newLabel.value) {
  //           k.rows = newValue
  //         }
  //         return k
  //       })
  //       setStylingList({ ...stylingList, survey_sequence: { ..._newList, ...RowList } })

  //     }

  //     setStylingList(_newList)
  //     // SetRefreshPage(Math.random())
  //     setNewValue('')
  //     setNewLabel(false)
  //     SetErrorList(false)
  //     SetIsDirty(false)
  //     setUpdateValue(`${Math.random()} + ${true}`)
  //   }
  // }

  function ChangeSelection(sr) {
    // console.log(sr)
    // let _newList = stylingList
    let _srList = stylingList.survey_sequence.filter((ss) => ss.id !== sr.id);
    // console.log(_srList)
    sr["enabled"] = !sr.enabled;
    _srList.push(sr);
    _srList.sort((a, b) => a.sort_order - b.sort_order);

    setStylingList({ ...stylingList, survey_sequence: _srList });
    setUpdateValue(`${sr} + ${true}`);
  }

  function ChangedQuestionSort(e, item) {
    //  console.log(e)
    //  console.log(item)
    let _newList = stylingList.survey_sequence;
    const RowList = _newList.filter((fl) => fl.id !== item.id);
    item.question = e;
    RowList.push(item);
    RowList.sort((a, b) => a.sort_order - b.sort_order);
    setStylingList({ ...stylingList, survey_sequence: RowList });

    setUpdateValue(`${e} + ${true}`);
  }

  function ChangedQuestionAsk(e, item) {
    //  console.log(e)
    //  console.log(item)
    let _newList = stylingList.survey_sequence;
    const RowList = _newList.filter((fl) => fl.id !== item.id);
    item.ask_again = e;
    RowList.push(item);
    RowList.sort((a, b) => a.sort_order - b.sort_order);
    setStylingList({ ...stylingList, survey_sequence: RowList });

    setUpdateValue(`${e} + ${true}`);
  }
  // useEffect(() => {
  //   SetErrorList([])
  //   let _errList = []
  //   if (!newValue) {
  //     _errList.push("Please add a Value")
  //   }
  //   if (!newLabel) {
  //     _errList.push("Please add a Key")
  //   }
  //   if (_errList?.length > 0) {
  //     SetErrorList(_errList)
  //   }
  // }, [newLabel, newValue, onSubmit]);
  // console.log(questionSelect)
  // console.log(stylingList?.survey_sequence)

  // Function to check if an item with a given ID exists in the stylingList
function itemExists(id) {
  // console.log(id)
  return stylingList?.survey_sequence.some(item => item.id === id);
}

// Function to add a new row with the missing item
function AddRowIfMissing({item}) {
  if (!itemExists(item?.id)) {
    // Create a new row with the missing item and return the JSX
    return (
      <RowData>
        <SmallRowInputLine width={150}>{item?.id}</SmallRowInputLine>
        <SmallRowInputLine width={150}>{item?.label}</SmallRowInputLine>
        <SmallRowInputLine width={350}>{item?.description}</SmallRowInputLine>
        <Button color="orange" onClick={() => AddRowSurveySequencetoSurvey(item)}>Add Survey Sequence</Button>
      </RowData>
    );
  }
  return null; // Return null if the item exists
}

  return stylingList?.survey_sequence?.length > 0 ? (
    <>
      {/* {errorList?.length > 0 && isDirty && <Message
          color='red'
          onDismiss={DismissError}
          header={"There were some errors"}
          content={JSON.stringify(errorList)}
        />} */}
         {/* // Iterate through SURVEY_SEQUENCE_LIST and check if each item exists in stylingList */}

      {stylingList?.survey_sequence.map((item, i) => {
        
        return (
          <div key={i}>
            <RowData showBorder={!item.editable}>
              <SmallRowInputLine width={250}>{item.label}</SmallRowInputLine>
              <SmallRowInputLine>
                <Input value={item.sort_order} fluid disabled={true} />
              </SmallRowInputLine>
              <SmallRowInputLine>
                <Checkbox
                  onClick={() => ChangeSelection(item)}
                  toggle
                  checked={item.editable ? item?.enabled === true : true}
                />
              </SmallRowInputLine>
              <RowInputLine>
                {/* select list */}
                {item?.editable ? (
                  <Select
                    style={{ zIndex: 999 }}
                    name="survey"
                    options={questionSelect.filter(
                      (qs) => qs?.survey_type === item?.survey_type
                    )}
                    onChange={(e) => ChangedQuestionSort(e, item)}
                    value={item?.question || {}}
                  />
                ) : (
                  <p>{item?.description}</p>
                )}
              </RowInputLine>
              <RowInputLine>
                {/* select list */}
                {item?.editable ? (
                  <Select
                    style={{ zIndex: 999 }}
                    name="ask_again"
                    options={QUESTION_ASK_AGAIN}
                    onChange={(e) => ChangedQuestionAsk(e, item)}
                    value={item?.ask_again || {}}
                  />
                ) : (
                  ""
                )}
              </RowInputLine>
              <SectionDiv>{item?.ask_again?.description}</SectionDiv>
            </RowData>
            
          </div>
        );
      })}
      
      {SURVEY_SEQUENCE_LIST.map(item => {
      return (
         <React.Fragment key={item.id}>
    
          {/* Render the JSX for the current item */}
          {/* ... */}
          {/* Call AddRowIfMissing and render the result */}
          <AddRowIfMissing item={item} />
        </React.Fragment>
      )
     
    })}

      <Button color="red" onClick={() => setConfirmDel(true)}>
        Remove Survey Sequence
      </Button>

      <Confirm
        open={confirmDel}
        content="This would remove the survey sequence you have set i.e. No. 4 onwards, the default app driven sequences would still work"
        onCancel={() => setConfirmDel(false)}
        onConfirm={DeleteAllRuleRows}
      />
    </>
  ) : (
    <Button onClick={AddSurveySequencetoSurvey}>Add Survey Sequence</Button>
  );
}




export function RowEditList({
  serviceList,
  setServiceList,
  setUpdateValue,
  get_survey_question_select,
}) {
  const ServiceListing = [
    "Teamtool",
    "EDI",
    "Audit",
    "OTP",
    "Prototype",
    "In Survey Personality",
  ];

  function SelectQuestionList({ sr, sl }) {
    // console.log(get_survey_question_select.filter(sqs => sqs.survey_type_text === sl))
    const [questionSelected, setQuestionSelected] = useState([]);
    const [questionSelect, setQuestionSelect] = useState([]);

    useEffect(() => {
      const _SelectList = [{ value: 0, label: "Default Question" }];
      get_survey_question_select
        .filter((sqs) => sqs.survey_type_text === sl)
        .map((sr) => {
          _SelectList.push({
            value: sr.sort_order,
            label: `${sr.name} (sort order ${sr.sort_order})`,
          });
          return null;
        });
      setQuestionSelect(_SelectList);
    }, [sl]);

    // console.log(serviceList)

    useEffect(() => {
      setQuestionSelected(
        questionSelect.filter((slt) => slt.value === sr.question_sort_order)
      );
    }, [questionSelect, sr]);

    function ChangedQuestionSort(e) {
      const _old_srList = serviceList.filter((ss) => ss.name !== sr.name);
      const _srList = serviceList.filter((ss) => ss.name === sr.name)[0];
      _srList.question_sort_order = e.value;

      setQuestionSelected(e);
      _old_srList.push(_srList);
      setServiceList(_old_srList);
      setUpdateValue(`${e} + ${true}`);
    }

    return (
      <Select
        style={{ zIndex: 999 }}
        name="survey_preference"
        options={questionSelect}
        onChange={ChangedQuestionSort}
        value={questionSelected}
      />
    );
  }

  function ChangeSelection(sr) {
    let _srList = serviceList.filter((ss) => ss.name !== sr.name);
    sr["enabled"] = !sr.enabled;
    _srList.push(sr);
    _srList.sort((a, b) => a.id - b.id);
    setServiceList(_srList);
    setUpdateValue(`${sr} + ${true}`);
  }

  function AddService(e) {
    let _srList = serviceList;
    if (!_srList.filter((ss) => ss.name === e)?.[0]) {
      _srList.push(ServicesEnabled.filter((se) => se.name === e)?.[0]);
    }
    setServiceList(_srList);
    setUpdateValue(`${e} + ${true}`);
  }

  // return (<p>nothing</p>)
  if (!serviceList) {
    return null;
  }
  return (
    // serviceList?.length > 0 && serviceList.map((sr, i) => {
    ServiceListing.map((sl, i) => {
      const sr = serviceList.filter((sr) => sr?.name === sl)?.[0];
      if (sr) {
        return (
          <SectionDiv key={i}>
            <Checkbox
              onClick={() => ChangeSelection(sr)}
              toggle
              checked={sr?.enabled === true}
            />
            <SectionHeader>{sr.name}</SectionHeader>

            <SectionHeader>
              <SelectQuestionList sr={sr} sl={sl} />
            </SectionHeader>
          </SectionDiv>
        );
      }
      return (
        <SectionDiv key={i}>
          <Button
            onClick={() => AddService(sl)}
          >{`${sl} Not Found Click to Add`}</Button>
        </SectionDiv>
      );
    })
  );
}

export function OrgCategoryObjectInputEdit({
  stylingList,
  setStylingList,
  setUpdateValue,
}) {
  const [newValue, setNewValue] = useState("");

  function DeleteRow(e) {
    let _newList = stylingList;
    delete _newList[e];
    setStylingList(_newList);
    setUpdateValue(`${e} + ${true}`);
  }

  function AddRow(row) {
    let _newList = stylingList;
    _newList[row.value] = row.defaultValue;
    setStylingList(_newList);
    setUpdateValue(`${Math.random()} + ${true}`);
  }

  function ChangeSelection(sr, value) {
    let _newList = stylingList;
    _newList[sr?.value] = value;
    setStylingList(_newList);
    setUpdateValue(`${sr} + ${true}`);
    setNewValue(Math.random);
  }

  return (
    <>
      {ORG_CATEGORY.map((orc, i) => {
        if (stylingList?.[orc?.value] !== undefined) {
          return (
            <div key={i}>
              <RowData>
                <RowInputLine>{orc?.label}</RowInputLine>

                <RowInputLine>
                  {orc?.type === "input" && (
                    <Input
                      value={stylingList[orc?.value]}
                      onChange={(e) => ChangeSelection(orc, e.target.value)}
                    />
                  )}

                  {orc?.type === "toggle" && (
                    <Checkbox
                      onClick={() =>
                        ChangeSelection(orc, !stylingList[orc?.value])
                      }
                      toggle
                      checked={stylingList[orc?.value] === true}
                    />
                  )}
                </RowInputLine>
                <RowInputLine>
                  <Icon
                    style={{
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={() => DeleteRow(orc?.value)}
                    name="trash alternate"
                  />
                </RowInputLine>
              </RowData>
            </div>
          );
        }

        return (
          <SectionDiv key={i}>
            <Button
              onClick={() => AddRow(orc)}
            >{`Not Found Click to Add ${orc?.label}`}</Button>
          </SectionDiv>
        );
      })}
    </>
  );
}

const SectionDiv = styled.div`
  padding: 5px;
  margin: 5px;
`;

const SectionHeader = styled.span`
  min-width: 300px;
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowInputLine = styled.span`
  min-width: 300px;
  font-size: 110%;
  margin: 10px;
  display: inline-block;
`;

const SmallRowInputLine = styled.span`
  width: ${(props) => (props?.width ? `${props.width}px` : "100px")};
  // min-width: 100px;
  font-size: 110%;
  margin: 10px;
  display: inline-block;
`;

const RowLine = styled.span`
  max-width: 300px;
  font-size: 110%;
  margin: 0 10px;
  display: inline-block;
`;

const RowData = styled.div`
  display: flex;
  flex-direction: row;
  border: ${(props) => (props.showBorder ? "solid 0.1pt grey" : "none")};
  // border-color: red;
  background-color: ${(props) => (props.showBorder ? "#dcdcdc" : "")};
`;
