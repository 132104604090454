import React from 'react';
import styled from "styled-components";
import { Table, Icon } from 'semantic-ui-react';

const TableList = ({ rows, deleteRow, EditSelectRow }) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>Id</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={4}>French</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Description</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Color</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {rows && rows.map((m, i) => {

                    return (
                        <Table.Row key={i}>
                            <Table.Cell>{m.id}</Table.Cell>
                            <Table.Cell>{m.name}</Table.Cell>
                            <Table.Cell>{m?.fr}</Table.Cell>
                            <Table.Cell>{m?.description}</Table.Cell>
                            <Table.Cell><DivColor color={m.color} /></Table.Cell>
                            <Table.Cell>
                                <SelectIcon
                                onClick={() => EditSelectRow(i, m)}
                                name='edit' />
                                
                                </Table.Cell>
                            <Table.Cell>
                                <SelectIcon
                                onClick={() => deleteRow(m.id)}
                                name='trash' />
                                
                                </Table.Cell>
                        </Table.Row>
                    )
                })}


            </Table.Body>
        </Table>
    );
};

export default TableList;

const DivColor = styled.div`
    background-color:${props => props.color};
    height: 20px;
    width: 20px;
`

const SelectIcon = styled(Icon)`
    cursor: pointer;
`
