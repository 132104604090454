const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.
const promptText = "Translate the following English text to French: 'Hello World'";  // Replace with your own prompt.

export default async function make_call(prompt){

        return fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer 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`
                },
                body: JSON.stringify({
                    messages: [{"role":"user","content":prompt}],
                    model:'gpt-3.5-turbo-16k'
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data.choices[0].message.content.trim()
            })
            .catch(error => {
                console.error("Error:", error);
            });
}

