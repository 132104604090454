import {
  GET_EMPLOYEE_ROLES, 
  FETCH_EMPLOYEE_ROLE, 
  EDIT_EMPLOYEE_ROLE,  
  CLEAR_EMPLOYEE_ROLE, 
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    employee_roles: [],
    pending: false,
  };
  
  export default function EmployeeRoles(state = initialState, action) {
    switch (action.type) {
      case GET_EMPLOYEE_ROLES:
        return {
          ...state,
          employee_roles: action.payload,
          pending: false,
        };
      case FETCH_EMPLOYEE_ROLE:
        return { ...state, 
          [action.payload.id]: action.payload, 
          pending: false };
  
      case EDIT_EMPLOYEE_ROLE:
      if (state.employee_roles.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          employee_roles: state.employee_roles.filter(
            (rw) => rw.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        employee_roles: [...state.employee_roles, action.payload],
      };
  

      case CLEAR_EMPLOYEE_ROLE:
        return {
          ...state,
          employee_roles: [],
        };
      case CLEAR_ALL:
        return {
          ...state,
          employee_roles: [],
        };
      default:
        return state;
    }
  }
  