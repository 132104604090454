import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_BLOGS,
  SG_GET_BLOGS,
  DELETE_BLOG,
  SG_DELETE_BLOG,
  FETCH_BLOG,
  SG_FETCH_BLOG,
  EDIT_BLOG,
  SG_EDIT_BLOG,
  UPDATE_BLOG_PIC,
  SG_UPDATE_BLOG_PIC,
  ADD_BLOG,
  SG_ADD_BLOG,
  CLEAR_BLOG,
    ALL_ERRORS,
} from "constants/actions";



function* loadblogs(action) {
//   yield put({ type: GET_blogS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_BLOGS, action.payload);
    yield put({ type: GET_BLOGS, payload: json.data });

  } catch (e) {
   
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogLoad() {
  yield takeLatest(SG_GET_BLOGS, loadblogs);
}

function* fetchblogs(action) {
  try {
    const json = yield call(api.FETCH_BLOG, action.payload);
    yield put({ type: FETCH_BLOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogFetch() {
  yield takeLatest(SG_FETCH_BLOG, fetchblogs);
}

function* updateblogs(action) {
  try {
    const json = yield call(api.EDIT_BLOG, action.payload);
    yield put({ type: EDIT_BLOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogUpdate() {
  yield takeLatest(SG_EDIT_BLOG, updateblogs);
}

function* addblogs(action) {
  try {
    const json = yield call(api.ADD_BLOG, action.payload);
    yield put({ type: ADD_BLOG, payload: json.data });

  } catch (e) {

    yield put({ type: ALL_ERRORS, message: e.response});
  }
}

export function* blogAdd() {
  yield takeLatest(SG_ADD_BLOG, addblogs);
}

function* updateblogimage(action) {
  try {
    const json = yield call(api.ADD_BLOG_IMAGE, action.payload);
    yield put({ type: UPDATE_BLOG_PIC, payload: json.data });

  } catch (e) {

    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogImageUpdate() {
  yield takeLatest(SG_UPDATE_BLOG_PIC, updateblogimage);
}




function* deleteblogs(action) {
  try {
    const json = yield call(api.DELETE_BLOG, action.payload);
    yield put({ type: DELETE_BLOG, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* blogDelete() {
  yield takeLatest(SG_DELETE_BLOG, deleteblogs);
}


export default function* index() {
  yield all([
    blogLoad(),
    blogFetch(),
    blogUpdate(),
    blogImageUpdate(),
    blogAdd(),
    blogDelete(),
  ]);
}
