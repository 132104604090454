import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';
import { Header, Grid, Button } from 'semantic-ui-react'
import { useNavigate, useLocation } from "react-router-dom";

function EmployeeRecordValue() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Header as='h2' style={{ marginTop: "50px" }}>
                        Employee Records Values
                        <Header.Subheader>
                            <p>
                                Actual Employee Records are in Employee Data,
                                You can add / update only these kinds of data
                            </p>
                            <p>
                                The external name is what the system would look for
                                and if a match is found would map it with the name in [Name] field
                            </p>
                            <p>
                                If both the names are same just use the name field alone, which would
                                be the fallback check if no match is found in external name
                            </p>
                            <p>
                                works for api and csv upload
                            </p>
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    {(location.pathname !== '/app/employee-record-value/new') &&
                        <Button primary
                            onClick={() => navigate(`/app/employee-record-value/new`)}
                        >Add New</Button>
                    }

                </Grid.Column>
            </Grid.Row>

            <Routes>
                <Route path="/" element={<List />} />
                <Route path="new" element={<AddEdit />} />
                <Route path="details/:id" element={<Details />} />
            </Routes>
        </>
    );
}

export default EmployeeRecordValue;
