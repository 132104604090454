
import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_QUESTIONS } from "constants/actions";
//
import {
    Message, Button, Header, List, Input
} from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import { SURVEY_TYPE, STATUS } from "data/AdminData"
//
import Select from "react-select";

// const PriorityList = [
//     { value: 'primary', label: "Primary" },
//     { value: 'secondary', label: "Secondary" },
//     { value: 'tertiary', label: "Tertiary" },
// ]

const OnBoardingQuestions = ({
     organizationId, surveyTypeId, setQuestionList, returnValue=false }) => {
    const dispatch = useDispatch();

    const [organizationDetails, setOrganizationDetails] = useState(null);
    const [servicesEnabled, setServicesEnabled] = useState([])
    const [serviceSelected, setServiceSelected] = useState([])
    const [questionDetails, setQuestionDetails] = useState(null);
    const [surveyQuestion, setSurveyQuestion] = useState([])
    const [askComments, setAskComments] = useState([])
    const [sliderValue, setSliderValue] = useState(1)
    const [sliderValueMax, setSliderValueMax] = useState(1)
    const [selectedQuestion, setSelectedQuestion] = useState(false)

    function GetSurveyQuestion() {
        if (serviceSelected?.sortOrder > 0) {
            dispatch({
                type: SG_GET_SURVEY_QUESTIONS,
                payload: `status=2&sort_order=${serviceSelected?.sortOrder}`,
            });
        }
        else if (serviceSelected?.value) {
            dispatch({
                type: SG_GET_SURVEY_QUESTIONS,
                payload: `status=2&survey_type=${serviceSelected?.value}`,
            });
        }
    }

    useEffect(() => {
        if (returnValue ) {
            setQuestionList(surveyQuestion)
        }
    }, [returnValue, setQuestionList, surveyQuestion]);

    useEffect(() => {
        if (surveyTypeId) {
            // setServiceSelected(servicesEnabled.filter(ss => ss.value === surveyTypeId)?.[0])
            if (servicesEnabled) {
                const stypeId = servicesEnabled.filter(ss => ss.value === surveyTypeId)?.[0]?.value
                if (stypeId) {
                    dispatch({
                        type: SG_GET_SURVEY_QUESTIONS,
                        payload: `status=2&survey_type=${stypeId}`,
                    });
                }
            }
        }
    }, [dispatch, surveyTypeId, servicesEnabled]);

    const { get_questions,
        get_organization } = useSelector(
            (state) => ({
                get_questions: state.surveyquestion,
                get_organization: state.organizations,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationDetails(get_organization[organizationId]?.services_enabled)
    }, [get_organization, organizationId]);

    useEffect(() => {
        if (serviceSelected?.value === 7) {
            setSurveyQuestion(edi_arrange_Questions(get_questions?.survey_question?.results?.[0]?.questions))
        } else {
            setSurveyQuestion(arrange_Questions(get_questions?.survey_question?.results?.[0]?.questions))
        }

        setAskComments(get_questions?.survey_question?.results?.[0]?.questions?.comments || [])
        setQuestionDetails(get_questions?.survey_question?.results?.[0])
    }, [get_questions, serviceSelected]);


    useEffect(() => {
        const _ServiceList = []
        if (organizationDetails) {
            SURVEY_TYPE.map(sr => {
                const Found = organizationDetails.filter(od => od.name === sr.label)?.[0]
                if (Found?.id && Found.enabled === true) {
                    _ServiceList.push(
                        {
                            value: sr.value,
                            label: Found.name,
                            sortOrder: Found.question_sort_order
                        }
                    )
                }
                return null
            })
        }
        setServicesEnabled(_ServiceList)
    }, [organizationDetails]);

    function SliderChange(e) {
        const QNo = Number(e.target.value)
        setSliderValue(QNo)
    }

    useEffect(() => {
        if (surveyQuestion) {
            setSelectedQuestion(surveyQuestion.filter(sq => sq.order === sliderValue)?.[0])
            setSliderValueMax(surveyQuestion?.length)
        }
    }, [surveyQuestion, sliderValue]);

    function PrevQuestion() {
        if (sliderValue > 1) {
            setSliderValue(sliderValue - 1)
        }
    }

    function NextQuestion() {
        if (sliderValue < sliderValueMax) {
            setSliderValue(sliderValue + 1)
        }
    }
    // console.log(surveyQuestion)

    return (
        <div>
            {!surveyTypeId &&
            <SingleRowContent>
                <SectionCell>
                    <Header.Subheader>
                        Fetch Question based on Service Subscribed
                    </Header.Subheader>

                </SectionCell>
                <SectionCell>
                    <Select
                        name="ServicesEnabled"
                        options={servicesEnabled}
                        isClearable
                        onChange={setServiceSelected}
                        value={serviceSelected}
                    // getOptionLabel={(answers) => answers.name}
                    // getOptionValue={(answers) => answers.id}
                    />
                </SectionCell>
                <SectionCell>
                    <Button
                        onClick={GetSurveyQuestion}>
                        Fetch Question
                    </Button>
                </SectionCell>
            </SingleRowContent>
}

            <SectionDiv>
                <Container>
                    <List horizontal>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Id (sort_ORDER)</List.Header>
                                    {questionDetails?.sort_order}
                                </ListContainer>
                            </List.Content>
                        </List.Item>
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Name</List.Header>
                                    {questionDetails?.name}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>URL Location (for survey)</List.Header>
                                    {questionDetails?.url_location}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Type</List.Header>
                                    {questionDetails?.survey_type && SURVEY_TYPE.filter(sy => sy.value === questionDetails?.survey_type)?.[0]?.label}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Status</List.Header>
                                    {questionDetails?.status && STATUS.filter(sy => sy.value === questionDetails?.status)?.[0]?.label}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Created</List.Header>
                                    {questionDetails?.created_at && format(new Date(questionDetails?.created_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Last Updated</List.Header>
                                    {questionDetails?.updated_at && format(new Date(questionDetails?.updated_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                    </List>
                </Container>
            </SectionDiv>
            {questionDetails?.id &&
                <>
                    <SlideContainer>
                        <SliderInput
                            type="range"
                            min="1"
                            max={sliderValueMax}
                            onChange={SliderChange}
                            value={sliderValue} id="myRange" />
                    </SlideContainer>
                    <Message info>
                        <p>Question No {sliderValue} </p>
                        <p>English</p>
                        <Message.Header>{selectedQuestion?.question}</Message.Header>
                        <p>French</p>
                        <Message.Header>{selectedQuestion?.fr}</Message.Header>
                        <p>Factor # {selectedQuestion?.factor}</p>
                        <p>Q # {selectedQuestion?.q}</p>
                        <p>Role # {selectedQuestion?.role}</p>
                        <p>Reverse # {selectedQuestion?.reverse}</p>
                        <p>Order # {selectedQuestion?.order}</p>
                    </Message>

                </>
            }

            <EvenlySpaced>
                <Button onClick={PrevQuestion}>{"< "}Prev</Button>
                <Input type='number'
                    onChange={SliderChange}
                    placeholder='specifc Question...' value={sliderValue} />
                <Button onClick={NextQuestion} positive>Next{" >"}</Button>
            </EvenlySpaced>

            <SectionDiv>
                <pre>{JSON.stringify(askComments, null, 3)}</pre>
            </SectionDiv>




        </div>
    );
};

export default OnBoardingQuestions;


const arrange_Questions = (questions) => {

    if (questions?.dimensions) {
        return questions.dimensions.map((item, i) => {
            return item.factors.map((factor, f) => {
                return factor.questions.map((q, idx) => {
                    return {
                        question: q.q,
                        dimension: i,
                        factor: f,
                        q: idx,
                        role: q.role,
                        reverse: q.reverse,
                        order: q.id,
                        fr: 'fr' in q ? q.fr : null
                    }
                })
            })
        }).flat().flat().sort((a, b) => a.order - b.order)
    }
}

const edi_arrange_Questions = (questions) => {
    if (questions) {
        return questions?.factors.map((factor, f) => {
            return factor?.questions.map((q, idx) => {
                return {
                    question: q.q,
                    factor: f,
                    q: idx,
                    role: q.role,
                    reverse: q.reverse,
                    order: q.id
                }
            })
        }).flat().flat().sort((a, b) => a.order - b.order)
    }
}

const EvenlySpaced = styled.div`
    display: flex;
    justify-content: space-between;
`
const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`
const SlideContainer = styled.div`
    width: 100%; /* Width of the outside container */
`

const SliderInput = styled.input`
-webkit-appearance: none;  /* Override default CSS styles */
appearance: none;
width: 100%; /* Full-width */
height: 25px; /* Specified height */
background: #d3d3d3; /* Grey background */
outline: none; /* Remove outline */
opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
-webkit-transition: .2s; /* 0.2 seconds transition on hover */
&:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
transition: opacity .2s;
&::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
 }
 &::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
 `

const LeftHalf = styled.div`
  // background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
`

const RightHalf = styled.div`
  // background-color: #b6701e;
  flex: 1;
  padding: 1rem;
`
