import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_RECORD, UPDATE_EMPLOYEE_RECORD_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

const EmployeeLog = ({ employeeId }) => {

    const dispatch = useDispatch();

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        // if the response is ok 200
        
        if (employeeId) {
            dispatch({
                type: SG_GET_EMPLOYEE_RECORD,
                payload: `page=${page}${employeeId ? `&employee=${employeeId}` : ''}`,
            });
            
        }
        setLoading(true)
    }, [dispatch, page, employeeId]);

    const { get_emp_records } = useSelector(
        (state) => ({
            get_emp_records: state.employee_record,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_emp_records?.employee_record?.results);
        setPage(get_emp_records?.pageNumber)
        setTotalPages(Math.ceil(get_emp_records?.employee_record?.count / get_emp_records?.employee_record?.page_size))
        if (get_emp_records?.employee_record?.count % get_emp_records?.employee_record?.page_size) {
            setTotalPages(Math.ceil(get_emp_records?.employee_record?.count / get_emp_records?.employee_record?.page_size) + 1)
        }
    }, [get_emp_records, page]);


    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_EMPLOYEE_RECORD_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <Container>
            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    <RowCard rows={results} />
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default EmployeeLog;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`