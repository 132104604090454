import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_FETCH_PDF_TEMPLATE,
    SG_GET_ORGANIZATION_SELECT,
} from "constants/actions";
//
import { Icon, Label, Button, Header, List } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
//
const Details = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [templateId, setTemplateId] = useState(false)
    const [templateDetails, setTemplateDetails] = useState(null);
    const [emailRef, setEmailRef] = useState({})

    function BackArrow() {
        navigate(`/app/pdf-templates`)
    }

    useEffect(() => {
        setTemplateId(id)
    }, [id]);

    useEffect(() => {
        dispatch({
            type: SG_GET_ORGANIZATION_SELECT,
        });
    }, [dispatch]);

    useEffect(() => {
        if (templateId) {
            dispatch({
                type: SG_FETCH_PDF_TEMPLATE,
                payload: { id: templateId }
            });
        }
    }, [dispatch, templateId]);

    const { get_pdf_template,
        get_organization_select } = useSelector(
            (state) => ({
                get_pdf_template: state.pdfTemplate,
                get_organization_select: state.organizations.organization_select,
            }),
            shallowEqual
        );

    useEffect(() => {
        setTemplateDetails(get_pdf_template?.[templateId])
    }, [get_pdf_template, templateId]);

    useEffect(() => {
        setEmailRef(templateDetails?.data)
      
    }, [templateDetails]);

    function EditTemplate() {
        navigate(`/app/pdf-templates/edit/${templateId}`)
    }


    return (
        <>
            <Header as='h2'>
                <Icon
                    style={{ cursor: "pointer" }}
                    onClick={BackArrow} name='arrow left' />
                Details of {templateDetails?.name}
            </Header>
            <SectionDiv>
                Created {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}

            </SectionDiv>
            <SectionDiv>
                <Container>
                    <List horizontal>
                        
                        <List.Item>

                            <List.Content>
                                <ListContainer>
                                    <List.Header>Version</List.Header>
                                    {templateDetails?.name}
                                </ListContainer>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Organization</List.Header>
                                    {templateDetails?.organization ?
                                        get_organization_select.filter(org => org.id === templateDetails?.organization)?.[0]?.name :
                                        "For all Org"}
                                </ListContainer>
                            </List.Content>
                        </List.Item>



                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Created</List.Header>
                                    {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <ListContainer>
                                    <List.Header>Last Updated</List.Header>
                                    {templateDetails?.updated_at && format(new Date(templateDetails?.updated_at), 'dd-MMM-yyyy')}
                                </ListContainer>

                            </List.Content>
                        </List.Item>
                    </List>
                </Container>
            </SectionDiv>
      
            <SectionDiv>
                
                    <pre>{JSON.stringify(emailRef, null, 3)}</pre>
               
            </SectionDiv>

            <SectionDiv lastSection={true}>
                <Button floated='right'
                    color="blue"
                    onClick={EditTemplate}>
                    Edit Template JSON
                </Button>
            </SectionDiv>
        </>
    );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 30px 15px;
    min-width: 350px
`