import React, { useState, useEffect } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_EDIT_DEBRIEF_SCHEDULE } from "constants/actions";
import BasicErrorMessage from "utilities/ErrorMessages";


import {
  Button,
  Header,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";


export default function ExtendSurvey({ debriefDetails }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { get_brief_schedules, get_errors } = useSelector(
    (state) => ({
      get_brief_schedules: state.debrief_schedule,
      get_errors: state.errors,
    }),
    shallowEqual
  );


  useEffect(() => {
    if (get_errors?.errors?.request?.response) {

      if (get_errors?.errors?.request?.response) {
        setLoading(false);
      }
    }
  }, [get_errors]);

  useEffect(() => {
    if (debriefDetails?.id === get_brief_schedules?.updated) {
      setOpen(false);
    }
    setLoading(get_brief_schedules?.pending);
  }, [get_brief_schedules, debriefDetails]);

  console.log(get_brief_schedules?.[get_brief_schedules?.updated]?.activity_response)

  function Submit() {
    dispatch({
      type: SG_EDIT_DEBRIEF_SCHEDULE,
      payload: {
        id: debriefDetails?.id,
        generate_feedback_ml: "yes",
       
      },
    });
  }

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color='purple'>MI Feedback</Button>}
    >
      <BasicErrorMessage />
      <Modal.Header>Generate MI Feedback for all Survey Responses</Modal.Header>
      <Modal.Content>
      
        <Dimmer active={loading}>
          <Loader>Updating ...</Loader>
        </Dimmer>
        <p>Waring this can take very long time and so please be patient once you have clicked generate,
          this would get done quicker very soon as a delayed task! </p>  


      </Modal.Content>
      <Modal.Actions>
        <Button content="Generate" negative onClick={Submit} />
      </Modal.Actions>
    </Modal>
  );
}

