export const FullDateTimePattern = "dd.MMM.yyyy HH:mm:ss.SSS 'GMT' XXX (z)";

export const DatePattern = "dd.MMM.yyyy";

export const STATUS = [
  { value: 1, label: "New" },
  { value: 2, label: "Live" },
  { value: 3, label: "Retired" },
];

export const SURVEY_TYPE = [
  { value: 5, label: "Debrief" },
  { value: 6, label: "Audit" },
  { value: 7, label: "EDI" },
  { value: 8, label: "OTP" },
  { value: 9, label: "Values" },
  { value: 10, label: "Outcomes" },
  { value: 11, label: "Feedback" },
  { value: 4, label: "Personality" },
  { value: 1, label: "Culture - Deprecated" },
  { value: 2, label: "Mood - Deprecated" },
  { value: 3, label: "Leadership - Deprecated" },
];

export const EMAIL_SURVEY_TYPES = [
  { value: 1, label: "Performance" },
  { value: 2, label: "Training" },
  { value: 3, label: "Audit" },
  { value: 4, label: "EDI" },
  { value: 5, label: "OTP" },
];

export const SUB_DOMAIN_NAMES = [
  { value: 1, label: "app.innerlogic.com" },
  { value: 2, label: "caatcanada.ca" },
  { value: 3, label: "nulogic.ca" },
];

export const ORG_PAID_STATUS = [
  { value: 1, label: "Trial" },
  { value: 2, label: "Paid" },
  { value: 3, label: "Manual Override" },
  { value: 5, label: "Payment Errored" },
  { value: 6, label: "Payment Failed" },
  { value: 9, label: "Un-Paid - Trial Expired" },
  { value: 11, label: "Cancelled" },
  { value: 100, label: "On Hold" },
];

export const API_CRED_NAMES = [
  { value: "Hibob", label: "Hibob" },
  { value: "Other", label: "Other" },
];

export const EMAIL_DEFINITIONS = [
  { value: "trial_invite_emails", label: "trial_invite_emails" },
  { value: "user_invite_emails", label: "user_invite_emails" },
  { value: "ambassador_invite_emails", label: "ambassador_invite_emails" },
  { value: "password_reset_email", label: "password_reset_email" },
  { value: "send_all_survey_emails", label: "send_all_survey_emails" },
  { value: "closing_survey_email", label: "closing_survey_email" },
  {
    value: "send_pending_personality_survey_emails",
    label: "send_pending_personality_survey_emails",
  },
  {
    value: "personality_completed_emails",
    label: "personality_completed_emails",
  },
  { value: "survey_report_ready", label: "survey_report_ready" },
  { value: "coach_survey_report", label: "coach_survey_report" },
  { value: "admins_survey_report", label: "admins_survey_report" },
  { value: "nlp_reports_ready", label: "nlp_reports_ready" },
  { value: "send_leader_report", label: "send_leader_report" },
  {
    value: "organization_not_paid_emails",
    label: "organization_not_paid_emails",
  },
  { value: "send_brief_survey_emails", label: "send_brief_survey_emails" },
  { value: "brief_survey_report", label: "brief_survey_report" },
];

export const CATEGORY_COMPLIANCE_HEADERS = [
  { value: 1, label: "Primary", small: "primary", level: 0 },
  { value: 2, label: "Secondary", small: "secondary", level: 0 },
  { value: 3, label: "Tertiary", small: "tertiary", level: 0 },
  { value: 4, label: "League", small: "league", level: 0 },
];

export const PriorityList = [
  { value: "primary", label: "Primary" },
  { value: "secondary", label: "Secondary" },
  { value: "tertiary", label: "Tertiary" },
];
// fbadfbe6
export const CATEGORY_VISIBILITY = [
  { value: "private", label: "Private - never show, never upload" },
  { value: "public", label: "Public - show and upload" },
];

export const CATEGORY_INPUT = [
  { value: "never_ask", label: "Never Ask - never this question in survey" },
  {
    value: "always_ask",
    label: "Always Ask - always ask this question in survey",
  },
  { value: "ask_if_not_found", label: "Ask this question in survey if blank" },
];

export const EMP_VALUE_TYPES = [
  { value: 1, label: "Text Character" },
  { value: 2, label: "Number" },
  { value: 3, label: "Decimal" },
  { value: 4, label: "Date" },
  { value: 5, label: "Object" },
  { value: 9, label: "Unknown" },
  {
    value: 11,
    label: "Replace Processed (runs custom scripts to convert to value)",
  },
  {
    value: 12,
    label: "Replace Processed (runs custom scripts to convert to object)",
  },
];

export const EMP_VALUE_DATA_FOR = [
  { value: 1, label: "Category" },
  { value: 2, label: "Record" },
  { value: 3, label: "Main" },
];

export const EMP_VALUE_DATA_ACTION = [
  { value: 1, label: "Replace" },
  { value: 2, label: "Append / Add new Row" },
];

export const WEB_API_STATUS = [
  { value: 1, label: "New" },
  { value: 5, label: "Live" },
  { value: 9, label: "Archived" },
];

export const WEB_API_DIRECTION = [
  { value: 1, label: "Outward GET w/o params" },
  { value: 2, label: "Outward POST w/o params" },
  { value: 11, label: "Outward GET w params" },
  { value: 12, label: "Outward POST w params " },
  { value: 21, label: "Inward POST" },
];

export const DEMO_ORGS = [
  { value: 218, label: "Org 218" },
  { value: 219, label: "Org 219" },
  { value: 220, label: "Org 220" },
];

export const TOTAL_NOS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

export const SURVEY_SEQUENCE_LIST = [
  {
    id: "8825be23-87e5-4b75-95ed-89fdcca19aa4",
    sort_order: 1,
    value: "category_question",
    label: "Category Question",
    enabled: true,
    description:
      "Category affirmation question based on privacy levels and is managed at the app level and cannot be changed",
    editable: false,
  },
  {
    id: "1048f767-7eb1-45da-a8bb-0206f73ebda8",
    sort_order: 2,
    value: "audit_question",
    label: "Audit Question",
    enabled: true,
    description:
      "Culture Audit question based on 5 dimensions and 5 pillars this cannot be changed but you can choose the version",
    editable: false,
  },
  {
    id: "ab5f806d-8482-4a07-a350-f3dc6b026159",
    sort_order: 3,
    value: "comments_feeback",
    label: "Comments Feedback",
    enabled: true,
    description:
      "comments feedback ask for more info based on rules set for min - max for each question, cannot be changed here set in app",
    editable: false,
  },
  {
    id: "c2106063-5833-4c76-91b1-c681b56a8ced",
    sort_order: 4,
    value: "outcome_question",
    label: "Outcome question",
    survey_id: "outcomeQuestion",
    enabled: true,
    description: "Outcome questions",
    survey_type: 10,
    question: {},
    ask_again: {
      id: "b80da8e8-a77e-4ce1-80fa-ad3dc8554ad9",
      value: 1,
      label: "Once",
      description:
        "Questions can be toggled on and off, and would be asked once per employee",
    },
    editable: true,
    completed: false,
  },
  {
    id: "8e8a6a60-168a-452a-8179-514812615908",
    sort_order: 5,
    value: "values_builder",
    label: "Values Builder",
    survey_id: "valuesQuestion",
    enabled: true,
    description:
      "Values build questions, can be toggled on and off, and would be asked each time enabled",
    survey_type: 9,
    question: {},
    editable: true,
    ask_again: {
      id: "b80da8e8-a77e-4ce1-80fa-ad3dc8554ad9",
      value: 1,
      label: "Once",
      description:
        "Questions can be toggled on and off, and would be asked once per employee",
    },
    completed: false,
  },
  {
    id: "72c3cf9f-3795-44f9-9eae-d734641112ab",
    sort_order: 6,
    value: "feedback_builder",
    label: "Feedback Builder",
    survey_id: "feedbackQuestion",
    enabled: true,
    description:
      "Feedback build questions, can be toggled on and off, and would be asked each time enabled",
    survey_type: 11,
    question: {},
    editable: true,
    ask_again: {
      id: "70e19e1a-7ad3-4b59-a7b6-51e4e6c363d6",
      value: 1,
      label: "Once",
      description:
        "Questions can be toggled on and off, and would be asked once per employee",
    },
    completed: false,
  },
];

export const ORG_CATEGORY = [
  {
    id: "93aea919-6bf8-4fd3-96e5-8eaa581adfb8",
    value: "code",
    label: "Code",
    type: "input",
    defaultValue: "",
  },
  {
    id: "7e6b5313-1412-45dc-ad40-e132597e93ff",
    value: "industry",
    label: "Industry",
    type: "input",
    defaultValue: "",
  },
  {
    id: "c0c5a937-5248-459d-b79b-cc2dc4c67a41",
    value: "sport",
    label: "Sport",
    type: "toggle",
    defaultValue: true,
  },
  {
    id: "e2a60756-6764-46cb-a47f-07a5bf2543ab",
    value: "benchmark",
    label: "Benchmark",
    type: "toggle",
    defaultValue: true,
  },
];

export const QUESTION_ASK_AGAIN = [
  {
    id: "b80da8e8-a77e-4ce1-80fa-ad3dc8554ad9",
    value: 1,
    label: "Once",
    description:
      "Questions can be toggled on and off, and would be asked once per employee",
  },
  {
    id: "d92254fb-73e9-473b-aaa5-37fe327a70ad",
    value: 2,
    label: "Always",
    description:
      "Questions can be toggled on and off, and would be asked each time enabled and appended to earlier response",
  },
];

export const RESPONSE_CHOICES = [
  { id: 1, value: 1, label: "All" },
  { id: 2, value: 2, label: "ChatGpt" },
  { id: 3, value: 3, label: "All Members" },
  { id: 4, value: 4, label: "Selected Members" },
];

export const API_CHOICES = [
  { id: 1, value: 1, label: "Ver 1" },
  { id: 2, value: 2, label: "Ver 2" },
];

export const AI_MODEL_STATUS = [
  { id: 1, value: 1, label: "New" },
  { id: 2, value: 2, label: "Live" },
  { id: 11, value: 11, label: "Archived" },
];

export const GPT_EMPLOYEE_STATUS = [
  { id: 1, value: 0, label: "In-Active" },
  { id: 2, value: 1, label: "Active" },
  { id: 3, value: 99, label: "Archived" },
];

export const GPT_SUMMARY_STATUS = [
  { id: 1, value: 1, label: "New" },
  { id: 2, value: 2, label: "In Progress" },
  { id: 3, value: 3, label: "Completed" },
  { id: 4, value: 11, label: "Archived" },
];
