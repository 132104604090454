import React from "react";

import styled from "styled-components";


import { Header } from "semantic-ui-react";

const PermissionList = ({ header, rows }) => {
  // console.log(rows)
  return (
    <>
    <SectionDiv>
        <Header as='h3'>{header}</Header>
        
      </SectionDiv>
      <Container>{rows.map(([key, value], index) => (
      <div key={index}>
        <Box>
          <Key>{key}: </Key>
        </Box>
        <Box>
          <Value>{value}</Value>
        </Box>
      </div>))}
    </Container>
    </>
  )
};

// PermissionList.propTypes = {
//   rows: PropTypes.array.isRequired,
// };

export default PermissionList;

const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
`

const Container = styled.div`
  display: flex;
`;

const Box = styled.div`
  flex: 1;
  padding: 10px;
  background-color: #f2f2f2;
  margin-right: 10px;
`;

const Key = styled.strong`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: 5px;
`;
