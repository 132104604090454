import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_PRICES,
    SG_GET_PRICES,
    FETCH_PRICE,
    SG_FETCH_PRICE,
    ALL_ERRORS,
} from "constants/actions";

function* loadpayments(action) {
  try {
    const json = yield call(api.GET_PRICES, action.payload);
    yield put({ type: GET_PRICES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentLoad() {
  yield takeLatest(SG_GET_PRICES, loadpayments);
}

function* fetchpayments(action) {
  try {
    const json = yield call(api.FETCH_PRICE, action.payload);
    yield put({ type: FETCH_PRICE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* paymentFetch() {
  yield takeLatest(SG_FETCH_PRICE, fetchpayments);
}

export default function* index() {
  yield all([
    paymentLoad(),
    paymentFetch(),
  ]);
}
