import {
    GET_SURVEY_SETTING_LIST,
    DELETE_SURVEY_SETTING_LIST,
    UPDATE_SURVEY_SETTING_LIST,
    CLEAR_SURVEY_SETTING_LIST,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    survey_setting_list: [],
    pending: false,
};

export default function SurveySettingList(state = initialState, action) {
    switch (action.type) {
        case GET_SURVEY_SETTING_LIST:
            return {
                ...state,
                survey_setting_list: action.payload,
                pending: false,
            };

        case UPDATE_SURVEY_SETTING_LIST:
            return {
                ...state,
                survey_setting_list: action.payload,
                pending: false,
            };

        case DELETE_SURVEY_SETTING_LIST:
            return {
                ...state,
                survey_setting_list: state.survey_setting_list.filter(
                    (row) => row.id !== action.payload.id
                ),
            };


        case CLEAR_SURVEY_SETTING_LIST:
            return {
                ...state,
                survey_setting_list: [],
            };

        case CLEAR_ALL:
            return {
                ...state,
                survey_setting_list: [],
            };

        default:
            return state;
    }
}
