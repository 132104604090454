import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  Header,
} from "semantic-ui-react";


import CreateResponseForSurvey from "./CreateEdit";


import BasicErrorMessage from "utilities/ErrorMessages";

const List = ({
  selectBriefSchedule,
  modelID,
  reloadPage,
  setReloadPage,
  modelMainID,
  noPagination = false,
}) => {
  const dispatch = useDispatch();

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

 
  const [editRow, setEditRow] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");


  const { get_gpt_response_main } = useSelector(
    (state) => ({
      get_gpt_response_main: state.gpt_response_main,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_gpt_response_main?.gpt_response_main?.results);
    setPage(get_gpt_response_main?.pageNumber);
    setTotalPages(
      Math.floor(
        get_gpt_response_main?.gpt_response_main?.count /
          get_gpt_response_main?.gpt_response_main?.page_size
      )
    );
    if (
      get_gpt_response_main?.gpt_response_main?.count %
      get_gpt_response_main?.gpt_response_main?.page_size
    ) {
      setTotalPages(
        Math.floor(
          get_gpt_response_main?.gpt_response_main?.count /
            get_gpt_response_main?.gpt_response_main?.page_size
        ) + 1
      );
    }
  }, [get_gpt_response_main, page]);


  useEffect(() => {
    //
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [loading]);

  // console.log(editRow);


  return (
    <Container>
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
    

      <Header as="h3" block>
        Step 2 - Create AI Summary for Surveys for AI Model Select any Survey to get started
      </Header>
    

      {/* Response Main */}
      {selectBriefSchedule?.length > 0 && (
        <CreateResponseForSurvey
          selectBriefSchedule={selectBriefSchedule}
          modelID={modelID}
          reloadPage={reloadPage}
          modelMainID={modelMainID}
          responseMainId={editRow?.id}
          setReloadPage={setReloadPage}
          // EditPromptListRow={setSelectPromptList}
        />
      )}

     
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  // padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const SectionSubHeader = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;
