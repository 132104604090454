import {ErrorBoundary} from 'react-error-boundary'
import styled from "styled-components";
import Pic from 'assets/images/empty/error.png'
import { useNavigate } from "react-router-dom"

function ErrorFallback({resetErrorBoundary}) {
	let navigate = useNavigate();

	const safety = () =>{
		navigate(-1)
		 resetErrorBoundary()
	}
  return (
    <Container>

    	<Image src={Pic} />
    	<Text>Oh Dang... I think we broke something.<br/> You can try to load this again by refreshing your browser.
    	 If this error persists, don't hesistate to contact our support team.  </Text>
    	 <Button onClick={()=>safety()}>Bring me to safety</Button>
    </Container>
  )
}



const ErrorWrapper =(props)=>{

	return (
			  <ErrorBoundary
			    FallbackComponent={ErrorFallback}
			    onReset={() => {
			      // reset the state of your app so the error doesn't happen again
			    }}
			  >
			   		{props.children}
			  </ErrorBoundary>
			)

} 


export default ErrorWrapper


const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:100%;
	justify-content:center;
	min-height:400px;
	margin-top:50px;
`

const Text = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:30px;
	text-align:center;
	font-size:14px;
	max-width:400px;
`

const Image = styled.img`
	width:300px;
`

const Button = styled.div`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;

`