import {
    UPDATE_GPT_SUMMARY_PAGE_NUMBER,
    GET_GPT_SUMMARY_PENDING,
    GET_GPT_SUMMARY,
    DELETE_GPT_SUMMARY,
    FETCH_GPT_SUMMARY,
    EDIT_GPT_SUMMARY,
    ADD_GPT_SUMMARY,
    CLEAR_GPT_SUMMARY,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    gpt_summary: [],
    created: [],
    updated: [],
    deleted: [],
    pending: false,
    pageNumber: 1,
};

export default function GptSummary(state = initialState, action) {
    switch (action.type) {
        case GET_GPT_SUMMARY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case UPDATE_GPT_SUMMARY_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            }
        case GET_GPT_SUMMARY:
            return {
                ...state,
                gpt_summary: action.payload,
                pending: false,
            };

        case FETCH_GPT_SUMMARY:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false
            };

        case EDIT_GPT_SUMMARY:
            return {
                ...state,
                [action.payload.id]: action.payload,
                updated: action.payload,
                pending: false
            };

        case DELETE_GPT_SUMMARY:
            return {
                ...state,
                // gpt_summary: state.gpt_summary.filter((row) => row.id !== action.payload),
                deleted: action.payload,
                pending: false
            };

        case ADD_GPT_SUMMARY:
            return {
                ...state,
                created: action.payload,
                pending: false
            };

        case CLEAR_GPT_SUMMARY:
            return {
                ...state,
                gpt_summary: [],
                created: [],
                updated: [],
                deleted: [],
                pending: false,
                pageNumber: 1,
            };

        case CLEAR_ALL:
            return {
                ...state,
                gpt_summary: [],
                created: [],
                updated: [],
                deleted: [],
                pending: false,
                pageNumber: 1,
            };

        default:
            return state;
    }
}
