import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icon, Button, Card, Message, Confirm } from 'semantic-ui-react'

import { format } from 'date-fns'

import {
    SG_GET_DEBRIEF_SCHEDULES,
    SG_DELETE_DEBRIEF_SCHEDULE,
    SG_GET_SURVEY_QUESTION_SELECT,
    SG_GENERATE_DEBRIEF_TOKEN,
    SG_GET_DEBRIEF_TOKENS,
} from "constants/actions";

import MultipleScheduler from "./MultipleScheduler"
import TokenList from "briefToken/List"

const TokenPlaceHolder = ({ organizationId }) => {
    const dispatch = useDispatch();
    const [updateRows, setUpdateRows] = useState(false)
    const [debriefSchedule, setDebriefSchedule] = useState(false);
    useEffect(() => {
        if (organizationId) {
            dispatch({
                type: SG_GET_DEBRIEF_SCHEDULES,
                payload: `status=21&organization=${organizationId}`,
            });

        }
    }, [dispatch, organizationId, updateRows]);

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_QUESTION_SELECT,
        });

    }, [dispatch]);
    const {
        get_debrief_schedules,
    } = useSelector(
        (state) => ({
            get_debrief_schedules: state.debrief_schedule,
        }),
        shallowEqual
    );

    useEffect(() => {
        setDebriefSchedule(get_debrief_schedules.debrief_schedule?.results)
    }, [get_debrief_schedules]);

    function DelayedLoad() {
        // 
        const timer1 = setTimeout(() => {
            setUpdateRows(Math.random())
        }, 2000);
        return () => clearTimeout(timer1);
    }

    return (
        <div>
            {debriefSchedule?.length > 0 ?
                debriefSchedule.map(db => {
                    return <DebriefScheduled
                        key={db.id}
                        debriefDetails={db}
                        DelayedLoad={DelayedLoad}
                    />
                })
                :
                <MultipleScheduler
                    organizationId={organizationId}
                    DelayedLoad={DelayedLoad} />
            }

        </div>
    );
};

export default TokenPlaceHolder;

function DebriefScheduled({ debriefDetails, DelayedLoad }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [briefQuestion, setBriefQuestion] = useState(false)
    const [deBriefId, setDebriefId] = useState(null);
    const [showMessage, setShowMessage] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)

    const {
        get_survey_question_select
    } = useSelector(
        (state) => ({
            get_survey_question_select: state.surveyquestion.survey_question_select,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (debriefDetails?.brief_question) {
            setBriefQuestion(get_survey_question_select?.filter(qu => qu.id === debriefDetails?.brief_question)?.[0])
        }
    }, [debriefDetails, get_survey_question_select]);

    function DeleteBriefSchedule(id) {
        dispatch({
            type: SG_DELETE_DEBRIEF_SCHEDULE,
            payload: { id },
        });
        setShowMessage({
            message: "Survey and Responses Deleted",
            color: "red",
            header: "Deleted"
        })
        DelayedLoad()
    }

    function DelayedTokenLoad() {
        // 
        const timer1 = setTimeout(() => {
            dispatch({
                type: SG_GET_DEBRIEF_TOKENS,
                payload: `page=1${deBriefId ? `&debrief_schedule=${deBriefId}` : ''}`,
            });
        }, 2000);
        return () => clearTimeout(timer1);
    }



    function GenerateTokens(id) {
        dispatch({
            type: SG_GENERATE_DEBRIEF_TOKEN,
            payload: { brief_id: id },
        });
        setShowMessage({
            message: "Tokens Generated, please refresh in a while and click on view tokens to view them",
            color: "green",
            header: "Tokens Generated"
        })
        DelayedLoad()
        DelayedTokenLoad()
    }

    return (
        <>
            {showMessage &&
                <Message
                    onDismiss={() => setShowMessage(false)}
                    color={showMessage?.color}
                    header={showMessage?.header}
                    content={showMessage?.message}
                />
            }

            <Card fluid key={debriefDetails?.id}>

                <Card.Content>

                    <Container>
                        <ListContainer>
                            <HeaderDiv>Org</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.organization}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Id</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.id}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Type</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.type_of_text}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Status</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.status_text}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Time Zone</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.time_zone}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Question</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.brief_question?.name}{" "}
                                ({debriefDetails?.brief_question?.sort_order})
                                {debriefDetails?.brief_question?.status_text}
                                <Icon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/app/questions/details/${debriefDetails?.brief_question}`)}
                                    color='violet' name='external alternate' />
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Name</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.name}
                            </ContentDiv>
                        </ListContainer>
                    </Container>

                    <Container>
                        <ListContainer>
                            <HeaderDiv>Created Date</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.created_at && format(new Date(debriefDetails?.created_at), 'dd-MMM-yyyy hh:mm')}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Scheduled Date</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.scheduled_for && format(new Date(debriefDetails?.scheduled_for), 'dd-MMM-yyyy hh:mm')}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Close Date</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.close_at && format(new Date(debriefDetails?.close_at), 'dd-MMM-yyyy hh:mm')}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Employees</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.total_requests}
                            </ContentDiv>
                        </ListContainer>
                        <ListContainer>
                            <HeaderDiv>Responded</HeaderDiv>
                            <ContentDiv>
                                {debriefDetails?.total_responses}
                            </ContentDiv>
                        </ListContainer>
                    </Container>


                    <EvenlySpaced>
                        <Button onClick={() => setDebriefId(debriefDetails?.id)} positive>Show Tokens</Button>

                        <Button onClick={() => GenerateTokens(debriefDetails?.id)} color="orange">Generate Tokens</Button>

                        <Button onClick={() => setConfirmDelete(true)} negative>Delete</Button>
                    </EvenlySpaced>
                    <Confirm
                        content='This would delete all tokens and responses for this survey'
                        open={confirmDelete}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => DeleteBriefSchedule(debriefDetails?.id)} />
                </Card.Content>
            </Card>

            {deBriefId &&
                <TokenList
                    deBriefId={deBriefId}
                    isDemo={true}
                    DelayedLoad={DelayedLoad}
                />
            }

        </>
    )
}

const Container = styled.div`
  display: flex;
`
const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`
const EvenlySpaced = styled.div`
    display: flex;
    justify-content: space-between;
`