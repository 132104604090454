import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE,
    SG_DELETE_SURVEY_STRUCTURE_COMPLIANCE,
} from "constants/actions";
//
import { Icon, Label, Button, Header, List } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import ComplianceForm from "./Components/Form"
//
const Details = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [categoryComplianceId, setCategoryComplianceId] = useState(false)
    const [categoryComplianceDetails, setCategoryComplianceDetails] = useState(null);
    const [emailRef, setEmailRef] = useState({})

    function BackArrow() {
        navigate(`/app/category-questions`)
    }

    useEffect(() => {
        setCategoryComplianceId(id)
    }, [id]);

    useEffect(() => {
        if (categoryComplianceId) {
            dispatch({
                type: SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE,
                payload: { id: categoryComplianceId }
            });
        }
    }, [dispatch, categoryComplianceId]);

    const { get_category_compliance } = useSelector(
        (state) => ({
            get_category_compliance: state.surveystructurecompliance,
        }),
        shallowEqual
    );

    useEffect(() => {
        setCategoryComplianceDetails(get_category_compliance?.[categoryComplianceId])
    }, [get_category_compliance, categoryComplianceId]);

    useEffect(() => {
        setEmailRef(categoryComplianceDetails?.data)

    }, [categoryComplianceDetails]);

    // console.log(get_category_compliance)
    // function EditTemplate() {
    //     navigate(`/app/category-compliance/edit/${categoryComplianceId}`)
    // }
    if (categoryComplianceId) {
        return (
            <>
                <Header as='h2'>
                    <Icon
                        style={{ cursor: "pointer" }}
                        onClick={BackArrow} name='arrow left' />
                    Details of {categoryComplianceDetails?.name}
                </Header>
                <SingleRowContent>
                    <SectionCell>
                        Created {categoryComplianceDetails?.created_at && format(new Date(categoryComplianceDetails?.created_at), 'dd-MMM-yyyy')}
                    </SectionCell>
                    <SectionCell>
                        Last Updated {categoryComplianceDetails?.updated_at && format(new Date(categoryComplianceDetails?.updated_at), 'dd-MMM-yyyy')}
                    </SectionCell>
                </SingleRowContent>
                <SectionDiv>
                    <ComplianceForm
                        row={categoryComplianceDetails}
                        detailLevel={2}
                    />
                </SectionDiv>
                <SectionDiv>
                    <pre>{JSON.stringify(emailRef, null, 3)}</pre>
                </SectionDiv>

                {/* <SectionDiv lastSection={true}>
                    <Button floated='right'
                        color="blue"
                        onClick={EditTemplate}>
                        Edit Template JSON
                    </Button>
                </SectionDiv> */}
            </>
        );
    } else {
        return (
            <ComplianceForm detailLevel={2} />
        )
    }
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;

`
const SectionCell = styled.div`
    padding: 0px 15px;
    min-width: 250px
`