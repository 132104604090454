import Logo from "assets/images/logo_blue_main.png";
import FullLogo from "assets/images/logo_blue.png";
import NuWave from "assets/images/nav/nuwave_black.png";
import OtpLogo from "assets/images/logo_blue.png";

// SUB_DOMAIN_NAMES = [
//     (1, 'app.innerlogic.com'),  # game
//     (2, 'caatcanada.ca'),  # Specific Org
//     (3, 'app.nulogic.co'),  # External Partner
// ]

export const Domain = {
    id: 1,
    name: "Innerlogic",
    url: "app.innerlogic.com",
    image: Logo
}

export const DomainList = {
    "app.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '444691808577-2ok712r0f1umjrgl5ndo0s3rt08p7lii.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",

    },
    "admin.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '444691808577-2ok712r0f1umjrgl5ndo0s3rt08p7lii.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",

    },
    "localhost:3000": {
        id: 1,
        name: "Innerlogic",
        url: "localhost:3000",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Local Host)",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "localhost:3001": {
        id: 1,
        name: "Innerlogic",
        url: "localhost:3000",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Local Host)",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "stg-app.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Staging App)",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "stg-admin.innerlogic.com": {
        id: 1,
        name: "Innerlogic",
        url: "app.innerlogic.com",
        image: Logo,
        fullImage: FullLogo,
        loginText: "Login to access your innerlogic account (Staging App)",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: true,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "demo-app.innerlogic.ca": {
        id: 1,
        name: "Innerlogic",
        url: "demo-app.innerlogic.ca",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to access your innerlogic account (Production Login)",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: false,
        homePage: "https://innerlogic.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '942534009574-tstt7q5h02ug1qsbg9rfp1uts7add27g.apps.googleusercontent.com',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "caatcanada.ca": {
        id: 2,
        name: "CAAT",
        url: "caatcanada.ca",
        image: OtpLogo,
        fullImage: OtpLogo,
        loginText: "Login to access your CAAT account",
        allowRegister: false,
        registerText: "Donot have a login",
        notRegisterText: "Please contact support@innerlogic.com to signup",
        showGoogleSso: false,
        homePage: "https://caatcanada.ca/",
        privacyPolicy: "https://survey.innerlogic.com/otp/privacy",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "www.caatcanada.ca": {
        id: 2,
        name: "CAAT",
        url: "caatcanada.ca",
        image: OtpLogo,
        fullImage: OtpLogo,
        loginText: "Login to access your CAAT account",
        allowRegister: false,
        registerText: "Donot have a login",
        notRegisterText: "Please contact support@innerlogic.com to signup",
        showGoogleSso: false,
        homePage: "https://caatcanada.ca/",
        privacyPolicy: "https://survey.innerlogic.com/otp/privacy",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",

    },
    "app.nulogic.co": {
        id: 3,
        name: "NuLogic",
        url: "app.nulogic.co",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to access your nuLogic account",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: false,
        homePage: "https://nulogic.co/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
    "app.nulogic.ca": {
        id: 3,
        name: "NuLogic",
        url: "app.nulogic.ca",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to access your nuLogic account",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "To signup please contact your manager",
        showGoogleSso: false,
        homePage: "https://nulogic.co/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    },
}