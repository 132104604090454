import React, { useState, useEffect } from 'react'
import styled from "styled-components";

import { SG_POST_CHAT_GPT, CLEAR_CHAT_GPT } from "constants/actions";


import { Button, Header, Modal, Icon } from 'semantic-ui-react'
import { useSelector, shallowEqual, useDispatch } from "react-redux";


export default function UpdateResponses({ deBriefId }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [existResponse, setExistingResponse] = useState(false)
    const [row, setRow] = useState(false)


    // debrief_fix_for_survey_scheduled
    const { getchat_gpt } = useSelector(
        (state) => ({
            getchat_gpt: state.chat_gpt,
        }),
        shallowEqual
    );


    useEffect(() => {
        setRow(getchat_gpt?.updated)
        setExistingResponse(getchat_gpt?.chat_gpt?.response)
    }, [getchat_gpt]);

    function Submit() {
        // 
        if (existResponse) {
            dispatch({
                type: SG_POST_CHAT_GPT,
                payload: {
                    debrief_schedule_id: deBriefId,
                    status: 1,
                },
            });
        } else {
            dispatch({
                type: SG_POST_CHAT_GPT,
                payload: {
                    debrief_schedule_id: deBriefId,
                },
            });
        }

    }

    function CloseModal() {
        setOpen(false)
        dispatch({
            type: CLEAR_CHAT_GPT,
            payload: '',
        });

    }

    return (<>
        <Modal
            closeIcon
            onClose={CloseModal}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button positive>AI Summary</Button>}
        >
            <Modal.Content>
                <Header>Update / Fetch AI Response</Header>
                <p>
                    Create or Update AI response for survey, the procvess is queued and runs every 5 minutes

                </p>
                <p>The survey requires min of 3 feedback to fetch the AI summary and
                    works only on production server.</p>

                <p>The AI summary would NOT rerun if the last attempt was made less than 10 mins ago, some queries can take time and can be in the queue</p>
                
                <Container>

                    {row?.message && <ListContainer highlight>
                        <HeaderDiv> Response</HeaderDiv>
                        <ContentDiv>
                            {row.message}
                        </ContentDiv>

                    </ListContainer>}
                </Container>
            </Modal.Content>
            <Modal.Actions>
                {row?.updated_response ? <Button
                    content='Close'
                    negative
                    onClick={CloseModal}
                />
                    :
                    !row.message && <Button
                        content={existResponse ? 'Update AI Summary' : "generate summary"}
                        color={existResponse ? 'red' : "green"}
                        onClick={Submit}
                    />}
            </Modal.Actions>
        </Modal>

    </>
    )

}




const ListContainer = styled.div`
  margin: 0px 25px;
  padding: ${(props) => props.highlight ? '5px' : '0px'};
//   background-color: #F7E800;
  background-color: ${(props) => props.highlight ? '#F7E800' : ''};
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`