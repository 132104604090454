import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import { Header } from 'semantic-ui-react'
function Blogs() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Employee List
        <Header.Subheader>
          List of All Employees and a place to manage them.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default Blogs;
