import {
  GET_MOODS,
  GET_MOOD_TEAMS,
  GET_MOOD_PER,
  GET_MOOD_PER_CLEAR,
  GET_MOOD_ORG,
  GET_MOOD_ORG_REPORT,
  UPDATE_POSITION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  moods: [],
  org: [],
  org_report: [],
  per: false,
  teams: [],
  pending: false,
  position: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MOODS:
      return {
        ...state,
        moods: action.payload,
        pending: false,
      };
    case GET_MOOD_TEAMS:
      return {
        ...state,
        teams: action.payload,
        pending: false,
      };
    case GET_MOOD_PER:
      return {
        ...state,
        per: action.payload,
        pending: false,
      };
      case GET_MOOD_PER_CLEAR:
        return {
          ...state,
          per: false,
          pending: false,
        };
    case GET_MOOD_ORG:
      return {
        ...state,
        org: action.payload,
        pending: false,
      };
    case GET_MOOD_ORG_REPORT:
      return {
        ...state,
        org_report: action.payload,
        pending: false,
      };
    case UPDATE_POSITION:
      return {
        ...state,
        position: action.payload,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        moods: [],
        org: [],
        org_report: [],
        per: false,
        teams: [],
        pending: false,
        position: 0,
      };
    default:
      return state;
  }
}
