import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Select from "react-select";

import {
  //   SG_DELETE_GPT_response_MAIN,
  SG_GET_GPT_MODEL_MAIN_SELECT,
  SG_GET_GPT_MEMBER_SELECT,
  SG_BRIEF_AI_SUMMARY,
  SG_FETCH_GPT_RESPONSE_MAIN,
} from "constants/actions";

import { Button, Message, Header, Icon } from "semantic-ui-react";

import CreatedListDetails from "./CreatedListDetails";

const CreateEdit = ({ selectBriefSchedule, responseMainId, setReloadPage }) => {
  const dispatch = useDispatch();

  const [modelA, setModelA] = useState(false);
  const [modelB, setModelB] = useState(false);
  const [teamSelected, setTeamSelected] = useState(false);

  const [modelAList, setModelAList] = useState([]);
  const [modelBList, setModelBList] = useState([]);

  const [disbleCreate, setDisableCreate] = useState(false);

  const [pristine, setPristine] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [createdList, setCreatedList] = useState([]);

  useEffect(() => {
    dispatch({
      type: SG_GET_GPT_MODEL_MAIN_SELECT,
    });
    dispatch({
      type: SG_GET_GPT_MEMBER_SELECT,
    });
  }, [dispatch]);

  const { get_gpt_model_main, get_gpt_members, get_gpt_response_main } =
    useSelector(
      (state) => ({
        get_gpt_model_main: state.gpt_model_main.gpt_model_main_select,
        get_gpt_members: state.gpt_members.gpt_member_select,
        get_gpt_response_main: state.gpt_response_main,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (responseMainId) {
      dispatch({
        type: SG_FETCH_GPT_RESPONSE_MAIN,
        payload: { id: responseMainId },
      });
    }
  }, [dispatch, responseMainId]);

  function CreateEdit() {
    if (formErrors.length > 0) {
      setPristine(false);
      return;
    }
    setPristine(true);

    // bulk
    dispatch({
      type: SG_BRIEF_AI_SUMMARY,
      payload: {
        model_a: modelA?.id,
        model_b: modelB?.id,
        debrief_schedule_list: selectBriefSchedule,
        team_selected: teamSelected ? teamSelected.map((ts) => ts?.id) : "",
      },
    });
    setDisableCreate(true);
  }
  // reloadPage

  useEffect(() => {
    setModelAList(get_gpt_model_main);
    setModelBList(get_gpt_model_main);
    if (modelB?.id) {
      setModelAList(get_gpt_model_main.filter((md) => md.id !== modelB.id));
    }
    if (modelA?.id) {
      setModelBList(get_gpt_model_main.filter((md) => md.id !== modelA.id));
    }
  }, [get_gpt_model_main, modelA, modelB]);

  useEffect(() => {
    if (get_gpt_response_main.gpt_response_main_bulk) {
      setReloadPage(Math.random);
      // setModelName("");

      setModelA(false);
      setModelB(false);
      setTeamSelected(false);
      setDisableCreate(false);

      setCreatedList(get_gpt_response_main.gpt_response_main_bulk);

      const timer1 = setTimeout(() => {
        setReloadPage(Math.random);
      }, 1000);
      return () => clearTimeout(timer1);
    }
  }, [get_gpt_response_main.gpt_response_main_bulk, setReloadPage]);

  useEffect(() => {
    const ErrorList = [];
    if (selectBriefSchedule?.length === 0) {
      ErrorList.push(
        "No debrief survey selected, select one from the list above"
      );
    }
    if (!modelA?.id) {
      ErrorList.push("No model A selected, select one from the list below");
    }
    if (!modelB?.id) {
      ErrorList.push("No model B selected, select one from the list below");
    }

    if (modelA?.id && modelB?.id) {
      if (modelA?.id === modelB?.id) {
        ErrorList.push("Model A is same as Model B, select a different one");
      }
    }
    setFormErrors(ErrorList);
  }, [pristine, selectBriefSchedule, modelA, modelB]);



  return (
    <>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      {createdList?.length > 0 && (
        <RightAlign>
          <ContainerFieldSelectable onClick={() => setCreatedList([])}>
            Close <Icon name="close" />
          </ContainerFieldSelectable>
        </RightAlign>
      )}
      {selectBriefSchedule?.length > 0 && (
        <SpacedOutAlign>
          <Header size="medium">
            Compare Survey Summary # {selectBriefSchedule?.length || 0} Survey's{" "}
          </Header>
          <SectionSubHeader>
            If the survey does not have summary it would create that and assign
            them to the selected users for scoring. It would also not allow same
            survey with same model to be evaluted, it can only be evaluted once.
          </SectionSubHeader>
          {createdList?.length > 0 ? (
            <Container>
              <RightAlign>
                <ContainerFieldSelectable onClick={() => setCreatedList([])}>
                  Close <Icon name="close" />
                </ContainerFieldSelectable>
              </RightAlign>
              <SectionDiv>
                <CreatedListDetails rows={createdList} />
              </SectionDiv>
            </Container>
          ) : (
            <>
              <Container>
                <SectionDiv>
                  <FormRow>
                    <FormLabel>
                      <LabelHead>Model A</LabelHead>
                    </FormLabel>
                    <FormInput>
                      <Select
                        name="model_a"
                        options={modelAList}
                        onChange={setModelA}
                        getOptionLabel={(option) => `${option?.name}`}
                        getOptionValue={(option) => `${option?.id}`}
                        value={modelA}
                      />
                    </FormInput>
                  </FormRow>

                  <FormRow>
                    <FormLabel>
                      <LabelHead>Model B</LabelHead>
                    </FormLabel>
                    <FormInput>
                      <Select
                        name="model_b"
                        options={modelBList}
                        onChange={setModelB}
                        getOptionLabel={(option) => `${option?.name}`}
                        getOptionValue={(option) => `${option?.id}`}
                        value={modelB}
                      />
                    </FormInput>
                  </FormRow>

                  <FormRow>
                    <FormLabel>
                      <LabelHead>Team</LabelHead>
                    </FormLabel>
                    <FormInput>
                      <Select
                        name="members"
                        placeholder="All Employees"
                        isClearable
                        options={get_gpt_members}
                        onChange={setTeamSelected}
                        getOptionLabel={(option) =>
                          `${option?.employee_full_name} - ${option?.employee_email}`
                        }
                        getOptionValue={(option) => `${option?.id}`}
                        value={teamSelected}
                      />
                    </FormInput>
                  </FormRow>
                </SectionDiv>
              </Container>
              <ListContainer>
                <Button
                  color="green"
                  disabled={disbleCreate}
                  onClick={CreateEdit}
                >
                  Create
                </Button>
              </ListContainer>
            </>
          )}
        </SpacedOutAlign>
      )}

      <SectionDiv lastSection={true}></SectionDiv>
    </>
  );
};

export default CreateEdit;

const Container = styled.div`
  display: flex;
`;

const SpacedOutAlign = styled.div`
  padding: 30px 15px;
  background-color: #dcdcdc;
  margin-top: 20px;
`;

const LeftBox = styled.div`
  flex: 1 0;
`;

const RightBox = styled.div`
  flex: 0 0;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: #dcdcdc;
  cursor: pointer;
`;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormRow = styled.div`
  display: flex;
  // justify-content: start;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  min-width: 200px;
  flex: 1 1 auto;
`;

const FormInput = styled.div`
  min-width: 600px;
  flex: 1 1 auto;
`;

const SectionSubHeader = styled.span`
  font-weight: 500;
  font-size: 16px;
`;
