import React, { useEffect, useState } from 'react';
import { useSelector,  shallowEqual } from "react-redux";
//
import { Icon,  Header, List } from 'semantic-ui-react'

const ServicesEnabled = ({ organizationId }) => {

     const [organizationDetails, setOrganizationDetails] = useState(null);

    
    const { 
        get_organization } = useSelector(
            (state) => ({

                get_organization: state.organizations,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationDetails(get_organization[organizationId])
    }, [get_organization, organizationId]);

    return (
        <>
            <Header as='h2'>
                White Label Data
            </Header>
            
            <List celled  size='large'>
                {organizationDetails?.styling && Object.keys(organizationDetails?.styling).map(item => (
                    <List.Item key={item}>
                        <span style={{marginLeft: "10px"}}>{item} </span>
                        <span style={{marginLeft: "10px"}}>{organizationDetails?.styling[item]} </span>
                        </List.Item>
                ))}
            </List>

        </>
    );
};

export default ServicesEnabled;

