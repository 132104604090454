import React, { useState, useEffect } from "react";
import styled from "styled-components";

import "./style.css"
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import { useDispatch } from "react-redux";
import {
    SG_GET_DEBRIEF_SCHEDULES,
    DEBRIEF_SCHEDULE_PAGE_NUMBER,
    DEBRIEF_SCHEDULE_SEARCH
} from "constants/actions";

import {
    Dimmer, Loader, Grid, Segment, Dropdown,
    Button, Message
} from 'semantic-ui-react'

const searchOptions = [
    {
        key: 1,
        text: 'Start Date Between',
        value: 'start_dates',
    },
    {
        key: 2,
        text: 'Close Date Between',
        value: 'close_dates',
    },
]

const SearchList = ({ organizationId, setLoading,
    startDate, setStartDate,
    endDate, setEndDate,
    searchFor, setSearchFor
}) => {

    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = useState(false)
    const [page, setPage] = React.useState(1);

    function handleSearchFor(v) {
        setSearchFor(false)
        if (v) {
            setSearchFor(v)   
        } else {
            dispatch({
                type: DEBRIEF_SCHEDULE_SEARCH,
                payload: {
                    search_for: false,
                    from: startDate,
                    to: endDate
                },
            });
        }

    }

    useEffect(() => {
        if (searchFor) {
            dispatch({
                type: DEBRIEF_SCHEDULE_SEARCH,
                payload: {
                    search_for: searchFor,
                    from: startDate,
                    to: endDate
                },
            });
        } 

    }, [dispatch, searchFor, startDate, endDate]);

    function handleSubmit() {
        if (searchFor && startDate && endDate) {
            setLoading(true)
            setErrMsg(false)
            const _from = startDate.toISOString()
            const _to = endDate.toISOString()
            console.log(_from)
            console.log(_to)
            setPage(1);
            dispatch({
                type: DEBRIEF_SCHEDULE_PAGE_NUMBER,
                payload: 1,
            });
            dispatch({
                type: DEBRIEF_SCHEDULE_SEARCH,
                payload: {
                    search_for: searchFor,
                    from: startDate,
                    to: endDate
                },
            });
            dispatch({
                type: SG_GET_DEBRIEF_SCHEDULES,
                payload: `page=${page}&${searchFor}=true&from=${_from}&to=${_to}${organizationId ? `&organization=${organizationId}` : ''}`,
            });
        } else {
            setErrMsg(1)
        }
    }


    return (
        <Segment id="results" basic>
            {errMsg &&
                <Message
                    warning
                    onDismiss={() => setErrMsg(false)}
                    header='Need All Details'
                    content='You need to select Search For, Start Date and End date'
                />
            }


            <Grid divided='vertically'>


                <Grid.Row columns={4}>
                    <Grid.Column>
                        <p>Search For</p>
                        <Dropdown
                            placeholder='Search for'
                            fluid
                            selection
                            clearable
                            options={searchOptions}
                            onChange={(e, t) => {
                                handleSearchFor(t.value);
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <p>Start Date</p>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)} />
                    </Grid.Column>
                    <Grid.Column>
                        <p>End Date</p>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                        />
                    </Grid.Column>
                    <Grid.Column>
                            <p>{ " " }</p>
                        <Button 
                        color={searchFor? "green": "grey"}
                        onClick={handleSubmit}>
                            Show</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>




        </Segment>
    );
};

export default SearchList;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`