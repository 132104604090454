import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";

import {
  SG_FETCH_REPORT_TEMPLATE,
} from "constants/actions";

import DataForm from "./DataForm"

const Matcher = ({newResponse, setNewResponse}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
 
  const [templateId, setTemplateId] = useState(false);
  const [reportTemplate, setReportTemplate] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);


  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_REPORT_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId,]);

  const { get_report_template } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    setReportTemplate(get_report_template?.[templateId])
  }, [templateId,get_report_template ])

  return (
    <Container>
      <DataForm templateId={templateId} 
      reportTemplate={reportTemplate} 
      newResponse={newResponse} 
      setNewResponse={setNewResponse} />
    </Container>
  );
};

export default Matcher;

const Container = styled.div`
  margin-top: 50px;
  padding-left: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

