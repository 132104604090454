import {
  GET_SURVEY_TOKENS,
  SURVEY_TOKEN_PAGE_NUMBER,
  DELETE_SURVEY_TOKEN,
  CLEAR_SURVEY_TOKEN,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  survey_tokens: [],
  pending: false,
  pageNumber: 1,
};

export default function SurveyToken (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_TOKENS:
      return {
        ...state,
        survey_tokens: action.payload,
        pending: false,
      };

      case SURVEY_TOKEN_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        }
    case DELETE_SURVEY_TOKEN:
      return {
        ...state,
        payments: state.survey_tokens.filter(
          (row) => row.id !== action.payload
        ),
      };

    case CLEAR_SURVEY_TOKEN:
      return {
        ...state,
        survey_tokens: [],
        pageNumber: 1,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        survey_tokens: [],
        pageNumber: 1,
        pending: false,
      };
    default:
      return state;
  }
}
