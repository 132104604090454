

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'

const EmployeeLogValue = ({ rows }) => {
    const navigate = useNavigate()

    return (

        <>
            <Table>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>Type Of</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                    
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i}>
                            <Table.Cell>{row?.type_of_log_text}</Table.Cell>
                            <Table.Cell>{row?.created_at}</Table.Cell>

                            <Table.Cell>
                            <Icon style={{cursor: "pointer"}} 
                            onClick={() => navigate(`/app/employee-log/details/${row?.id}`)}
                            name='angle double right' />

                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeLogValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeLogValue;

