import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Table, Icon } from 'semantic-ui-react'

import { format } from 'date-fns'

const EmployeeRecordValue = ({ rows }) => {
    const navigate = useNavigate()

    return (

        <>
            <Table>
                <Table.Header>
                <Table.Row>
                <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Email</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Token</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Created At</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows && rows.map((row, i) => {
                        return (<Table.Row key={i}>
                            <Table.Cell>{row?.id}</Table.Cell>
                            <Table.Cell>{row?.email}</Table.Cell>
                            <Table.Cell>{row?.token}</Table.Cell>
                            <Table.Cell>{row?.status_text}</Table.Cell>
                            <Table.Cell>
                            {row?.created_at && format(new Date(row?.created_at), 'dd-MMM-yyyy hh:mm')}
                            </Table.Cell>
                            <Table.Cell>
                            <Icon style={{cursor: "pointer"}} 
                            onClick={() => navigate(`/app/marketing-roi/details/${row?.id}`)}
                            name='angle double right' />

                            </Table.Cell>
                        </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

EmployeeRecordValue.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default EmployeeRecordValue;

