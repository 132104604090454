import {
    GET_EMPLOYEE_LOG,
    ADD_EMPLOYEE_LOG,
    UPDATE_EMPLOYEE_LOG_PAGE_NUMBER,
    DELETE_EMPLOYEE_LOG,
    UPDATE_EMPLOYEE_LOG,
    FETCH_EMPLOYEE_LOG,
    CLEAR_EMPLOYEE_LOG,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    employee_log: [],
    pageNumber: 1,
    pending: false,
    created: false,
};

export default function EmployeeCategories(state = initialState, action) {
    switch (action.type) {
        case GET_EMPLOYEE_LOG:
            return {
                ...state,
                employee_log: action.payload,
                pending: false,
                created: false,
            };
       case UPDATE_EMPLOYEE_LOG_PAGE_NUMBER:
                return {
                  ...state,
                  pageNumber: action.payload
                }
        case FETCH_EMPLOYEE_LOG:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false,
                created: false,

            };

        case UPDATE_EMPLOYEE_LOG:
            if (state.employee_log.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                    ...state,
                    employee_log: state.employee_log.filter(
                        (rw) => rw.id !== action.payload.id
                    ),
                };
            }
            return {
                ...state,
                created: false,
                employee_log: [...state.employee_log, action.payload],
            };

        case DELETE_EMPLOYEE_LOG:
            // console.log(action.payload)
            return {
                ...state,
                employee_log: state.employee_log.filter(
                    (row) => row.id !== action.payload.id
                ),
            };

        case ADD_EMPLOYEE_LOG:
            // const newState = state.employee_log
            // newState.push(action.payload)
            return {
                ...state,
                created: action.payload,
                employee_log: [...state.employee_log, action.payload],
            };

        case CLEAR_EMPLOYEE_LOG:
            return {
                ...state,
                employee_log: [],
                created: false,
            };

        case CLEAR_ALL:
            return {
                ...state,
                employee_log: [],
                created: false,
            };

        default:
            return state;
    }
}