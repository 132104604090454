import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_EMPLOYEE_RECORD,
  SG_GET_EMPLOYEE_RECORD,
  ADD_EMPLOYEE_RECORD,
  SG_ADD_EMPLOYEE_RECORD,
  DELETE_EMPLOYEE_RECORD,
  SG_DELETE_EMPLOYEE_RECORD,
  UPDATE_EMPLOYEE_RECORD,
  SG_UPDATE_EMPLOYEE_RECORD,
  FETCH_EMPLOYEE_RECORD,
  SG_FETCH_EMPLOYEE_RECORD,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
  //   yield put({ type: GET_methodS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_EMPLOYEE_RECORD, action.payload);
    yield put({ type: GET_EMPLOYEE_RECORD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_EMPLOYEE_RECORD, loadmethods);
}

function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_EMPLOYEE_RECORD, action.payload);
    yield put({ type: FETCH_EMPLOYEE_RECORD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_EMPLOYEE_RECORD, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.UPDATE_EMPLOYEE_RECORD, action.payload);
    yield put({ type: UPDATE_EMPLOYEE_RECORD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_UPDATE_EMPLOYEE_RECORD, updatemethods);
}

function* addmethods(action) {
  try {
    const json = yield call(api.ADD_EMPLOYEE_RECORD, action.payload);       
    yield put({ type: ADD_EMPLOYEE_RECORD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_EMPLOYEE_RECORD, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_EMPLOYEE_RECORD, action.payload);
    yield put({ type: DELETE_EMPLOYEE_RECORD, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_EMPLOYEE_RECORD, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
