import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  APP_LIST_URL_PENDING,
GET_APP_LIST_URLS,
SG_GET_APP_LIST_URLS,
DELETE_APP_LIST_URL,
SG_DELETE_APP_LIST_URL,
FETCH_APP_LIST_URL,
SG_FETCH_APP_LIST_URL,
EDIT_APP_LIST_URL,
SG_EDIT_APP_LIST_URL,
ADD_APP_LIST_URL,
SG_ADD_APP_LIST_URL,
ALL_ERRORS,
} from "constants/actions";


function* loadTemplate(action) {
  try {
    yield put({ type: APP_LIST_URL_PENDING });
    const json = yield call(api.GET_APP_LIST_URLS, action.payload);
    yield put({ type: GET_APP_LIST_URLS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionLoad() {
  yield takeLatest(SG_GET_APP_LIST_URLS, loadTemplate);
}

function* fetchTemplate(action) {
  try {
    yield put({ type: APP_LIST_URL_PENDING });
    const json = yield call(api.FETCH_APP_LIST_URL, action.payload);
    yield put({ type: FETCH_APP_LIST_URL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionFetch() {
  yield takeLatest(SG_FETCH_APP_LIST_URL, fetchTemplate);
}

function* updateTemplate(action) {
  try {
    yield put({ type: APP_LIST_URL_PENDING });
    const json = yield call(api.EDIT_APP_LIST_URL, action.payload);
    yield put({ type: EDIT_APP_LIST_URL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionUpdate() {
  yield takeLatest(SG_EDIT_APP_LIST_URL, updateTemplate);
}

function* addTemplate(action) {
  try {
    yield put({ type: APP_LIST_URL_PENDING });
    const json = yield call(api.ADD_APP_LIST_URL, action.payload);
    yield put({ type: ADD_APP_LIST_URL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionAdd() {
  yield takeLatest(SG_ADD_APP_LIST_URL, addTemplate);
}


function* deleteTemplate(action) {
  try {
    yield put({ type: APP_LIST_URL_PENDING });
    const json = yield call(api.DELETE_APP_LIST_URL, action.payload);
    yield put({ type: DELETE_APP_LIST_URL, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* functionDelete() {
  yield takeLatest(SG_DELETE_APP_LIST_URL, deleteTemplate);
}


export default function* index() {
  yield all([
    functionLoad(),
    functionFetch(),
    functionUpdate(),
    functionAdd(),
    functionDelete(),
  ]);
}
