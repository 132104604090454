import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, List } from 'semantic-ui-react'

const RowCard = ({ row }) => {

  return (
    <>
      <Card fluid key={row?.id}>

        <Card.Content>
          <Card.Header>{row?.first_name}{" "}{row?.last_name}</Card.Header>
          <Card.Meta>{row?.email}{" "}{row?.mobile}</Card.Meta>
          {/* <Button floated='right'
            onClick={() => navigate(`/app/settings/logins/details/${row?.id}`)}>
            More Details
          </Button> */}
          <Container>

            <List horizontal>
            <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Id</List.Header>
                    {row?.id}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Is Admin</List.Header>
                    {row?.is_admin ? "Yes" : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Is Staff</List.Header>
                    {row?.is_staff ? "Yes" : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Super User</List.Header>
                    {row?.is_superuser ? "Yes" : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Employee Id</List.Header>
                    {row?.employee_id ? row?.employee_id : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization Id</List.Header>
                    {row?.organization_id ? row?.organization_id : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Ambassador Id</List.Header>
                    {row?.ambassador_id ? row?.ambassador_id : "No"}
                  </ListContainer>

                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description>

          </Card.Description>
        </Card.Content>

      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;


const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`
