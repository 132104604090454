import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import TableRows from "./TableRows";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_RECORD_VALUE,
    // UPDATE_EMPLOYEE_RECORD_VALUE_PAGE_NUMBER 
} from "constants/actions";

import { Dimmer, Loader, Card, Segment } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({hideOrgSelect, orgId}) => {

    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    // const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        if (orgId) {
            setOrganizationId(orgId)
        }
    }, [orgId]);

    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_EMPLOYEE_RECORD_VALUE,
            payload: `page=${page}${organizationId?`&organization=${organizationId}`:''}${searchTerm?`&search=${searchTerm}` : ''}`,
        });
   
        setLoading(true)
    }, [dispatch, page, searchTerm, organizationId,
        resetPageLoad]);

    const { get_employee_record_value,
        get_selectedOrg } = useSelector(
        (state) => ({
            get_employee_record_value: state.employee_record_value,
            get_selectedOrg: state.selectedOrg,
        }),
        shallowEqual
    );

    useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
		// setResetPageLoad(!resetPageLoad)
	}, [get_selectedOrg]);


    useEffect(() => {
        setResults(get_employee_record_value?.employee_record_value);
    }, [get_employee_record_value, page]);


    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (

        <Container>
            
            {/*   <SectionDiv>
              <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} />
            </SectionDiv> */}
        {!hideOrgSelect &&
            <OrganizationSelect
				setResetPageLoad={setResetPageLoad}  />
            }
            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    
                     <TableRows rows={results}  />
                    
                </Card.Group>
            </Segment>
            

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`