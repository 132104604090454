import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Message, Icon } from "semantic-ui-react";

import { BasicErrorMessage } from "utilities/ErrorMessages"
import Upload from "./Upload";
import List from "./List";
import Details from "./Details";

import { saveAs } from "file-saver";

import OrganizationSelect from "organizations/OrganizationSelect";

import {
	LOAD_TEAMS,
	PULL_ORGANIZATION,
	SG_GET_FILE_UPLOADS,
	SG_GET_SURVEY_STRUCTURES,
	SG_GET_ORGANIZATION_SELECT,
	CLEAR_ERRORS,
} from "constants/actions";


const AllPageOptions = [
	{
		id: 1,
		name: "Member upload lists",
	},
	{
		id: 2,
		name: "View Details",
	},
	{
		id: 3,
		name: "Upload a new member list",
	},
	{
		id: 4,
		name: "Preview Upload",
	},
];

const FileUploads = () => {
	const dispatch = useDispatch();

	const [organizationId, setOrganizationId] = useState(false)
	const [showReportId, setShowReportId] = useState(false);
	const [showErrors, setShowErrors] = React.useState(false);
	const [fetchAgain, setFetchAgain] = useState(0);
	const [deletedRecord, setDeletedRecord] = useState(0);
	const [createEdit, setCreateEdit] = useState(0);
	const [showPage, setShowPage] = useState(AllPageOptions[0]);
	const [surveyStructure, setSurveyStructure] = useState(false)
	const [resetPageLoad, setResetPageLoad] = useState(false)

	const [loading, setLoading] = useState(false)

	const EmployeeId = useSelector(
		(state) => Number(state.auth?.employee_id),
		shallowEqual
	);

	const {
		get_employee,
		get_survey_structure,
		get_selectedOrg,
		get_errors,
		get_file_uploads,
	} = useSelector(
		(state) => ({
			get_errors: state.errors,
			get_auth: state.auth,
			get_employee: state.employees,
			get_selectedOrg: state.selectedOrg,
			get_survey_structure: state.surveystructure.survey_structure,
			get_file_uploads: state.fileupload,
		}),
		shallowEqual
	);

	useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
		// setResetPageLoad(!resetPageLoad)
	}, [get_selectedOrg]);

	useEffect(() => {
		if (organizationId) {
			dispatch({
				type: SG_GET_ORGANIZATION_SELECT
			})
			dispatch({
				type: LOAD_TEAMS,
				payload: `organization=${organizationId}`,
			});
			dispatch({
				type: PULL_ORGANIZATION,
				payload: { id: organizationId },
			});
			dispatch({
				type: SG_GET_SURVEY_STRUCTURES,
				payload: `organization=${organizationId}`,
			});
			return;
		}
	}, [dispatch, organizationId]);

	useEffect(() => {
		if (organizationId) {
			dispatch({
				type: SG_GET_FILE_UPLOADS,
				payload: `organization=${organizationId}`,
			});
		}

	}, [dispatch, organizationId, loading]);

	useEffect(() => {
		const timer1 = setTimeout(() => {
			setLoading(resetPageLoad)
		}, 2000)
		return () => clearTimeout(timer1)
	}, [resetPageLoad]);

	useEffect(() => {
		setSurveyStructure(get_survey_structure?.results?.[0])
	}, [get_survey_structure]);

	useEffect(() => {
		if (get_employee[EmployeeId]?.account_type === 5) {
			setCreateEdit(true);
			return;
		}

		if (get_employee[EmployeeId]?.role < 7) {
			setCreateEdit(true);
			return;
		}
		setCreateEdit(false);
	}, [get_employee, EmployeeId]);

	useEffect(() => {
		if (get_errors?.status === 403) {
			setShowErrors(get_errors?.data?.detail);
		}
	}, [get_errors]);

	function clearErrors() {
		dispatch({
			type: CLEAR_ERRORS,
		});
		setShowErrors(false);
	}

	const DemoFile = () => {
		saveAs(surveyStructure?.csv_template);
	};

	function BackToHomePage() {
		setShowPage(AllPageOptions[0])
		// setResetPageLoad(Math.random())
	}

	return (
		<Container>
			{showErrors && (
				<Message
					negative
					onDismiss={clearErrors}
					header="There was an Error!"
					content={showErrors}
				/>
			)}
			<BasicErrorMessage />
			<>
				<OrganizationSelect
					setResetPageLoad={setResetPageLoad} />

				{organizationId ?

					<>
						{showPage?.id !== 1 && (
							<Back onClick={BackToHomePage}>
								<Icon name="chevron left" />
								Back to List
							</Back>
						)}

						<Title>{showPage?.name}</Title>

						<Container>
							{/* Add New Modals */}

							{showPage?.id === 3 && organizationId && (
								<Upload
									setFetchAgain={setFetchAgain}
									setShowReportId={setShowReportId}
									setResetPageLoad={setResetPageLoad}
									// new
									organizationId={organizationId}
									setShowPage={setShowPage}
									AllPageOptions={AllPageOptions}
									BackToHomePage={BackToHomePage}
								/>
							)}

							{/* Show details of uploaded rows */}
							{showPage?.id === 2 ? (
								showReportId ? (
									<Details
										showReportId={showReportId}
										setShowReportId={setShowReportId}
										setFetchAgain={setFetchAgain}
										setDeletedRecord={setDeletedRecord}
										// new
										organizationId={organizationId}
										setShowPage={setShowPage}
										AllPageOptions={AllPageOptions}
										get_survey_structure={get_survey_structure}
									/>
								) : (
									<p>Not a Relevant Upload File Selected</p>
								)
							) : (
								""
							)}

							{showPage?.id === 1 && (
								<>
									{!surveyStructure?.csv_template ?
										<Message
											color="red"
											icon="warning sign"
											header="No Survey Structure File Found"
											content={`We were unable to find a matching survey structure. This
											information needs to be uploaded before we start the uploads
											`}
										/>
										:
										<>
											<Buttons>
												<StyledButtonGrey onClick={() => DemoFile()}>
													<Icon name="download" />
													Download Sample
												</StyledButtonGrey>

												<StyledButton onClick={() => setShowPage(AllPageOptions[2])}>
													<Icon name="upload" />
													Upload File
												</StyledButton>
											</Buttons>
											<Container>
												<List
													get_file_uploads={get_file_uploads}
													resetPageLoad={resetPageLoad}
													setResetPageLoad={setResetPageLoad}
													fetchAgain={fetchAgain}
													setFetchAgain={setFetchAgain}
													showErrors={showErrors}
													deletedRecord={deletedRecord}
													createEdit={createEdit}
													setDeletedRecord={setDeletedRecord}
													setShowReportId={setShowReportId}
													setShowPage={setShowPage}
													AllPageOptions={AllPageOptions}
												/>
											</Container>
										</>
									}


								</>
							)}
						</Container>


					</> :
					<Message
						color="orange"
						icon="warning sign"
						header="No Organization Selected"
						content={`Please select an organization to view the list of files
			`}
					/>
				}
			</>
		</Container>
	);
};

export default FileUploads;

const Container = styled.div`
	margin-top: 20px;
`;

const Title = styled.div`
	font-size: 26px;
	font-weight: bold;
	margin-right: 20px;
	display: block;
	font-family: "Barlow", sans-serif;
	margin-top: 30px;
`;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	margin-top: 30px;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const StyledButtonGrey = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #595959;
	border: none;
	outline: none;
	margin-top: 30px;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #595959;
		color: #595959;
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Back = styled.div`
	font-family: "Barlow", sans-serif;
	color: #595959;
	margin-top: 30px;
	font-size: 16px;
	cursor: pointer;
`;
