import {
  GET_CULTURE_DATA,
  GET_CULTURE_DATA_TEAMS,
  GET_CULTURE_DATA_ORG,
  GET_CULTURE_ORG_REPORT,
  FETCH_CULTURE_DATA,
  UPDATE_CULTURE_COMMENTS,
  CLEAR_UPDATE_CULTURE_COMMENTS,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  culture: [],
  org: [],
  org_report: [],
  teams: [],
  pending: false,
  reportComments: [],
  updatedComments: false,
};

export default function Culture (state = initialState, action) {
  switch (action.type) {
    case GET_CULTURE_DATA:
      return {
        ...state,
        culture: action.payload,
        pending: false,
      };
    case GET_CULTURE_DATA_TEAMS:
      return {
        ...state,
        teams: action.payload,
        pending: false,
      };
    case GET_CULTURE_DATA_ORG:
      return {
        ...state,
        org: action.payload,
        pending: false,
      };
    case GET_CULTURE_ORG_REPORT:
      return {
        ...state,
        org_report: action.payload,
        pending: false,
      };
    case UPDATE_CULTURE_COMMENTS:
      return {
        ...state,
        reportComments: action.payload,
        updatedComments: true,
      };
    case CLEAR_UPDATE_CULTURE_COMMENTS:
      return {
        ...state,
        reportComments: [],
        updatedComments: false,
      };
    case FETCH_CULTURE_DATA:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        culture: [],
        org: [],
        teams: [],
        pending: false,
      };
    default:
      return state;
  }
}
