import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useLocation, Route, Outlet } from "react-router-dom";

import SideBar from "./SideBar";

import MobileNav from "./MobileNav";
import TopBar from "./TopBar";
import Help from "./Help";

import ErrorWrapper from "error/index";

import { SG_FETCH_USER_EMPLOYEE, CLEAR_PASSWORD_DATA } from "constants/actions";

import { DomainContext } from 'layouts/LayoutContext';
import { DomainList } from "data/domain"

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});


const ComplianceCheck = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [employeeId, setEmployeeId] = useState(false);
  const [employee, setEmployee] = useState(false);


  const { get_auth, get_employees, get_error, get_organizations } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
      // get_organizations: state.organizations
    }),
    shallowEqual
  );

  // const [checkForStaff, setCheckForStaff] = React.useState(false);

  const IsStaff = useSelector((state) => state.auth?.user?.is_staff, shallowEqual)

  // React.useEffect(() => {
  //   if (typeof IsStaff == "boolean") {
  //     // variable is a boolean
  //     if (IsStaff) {
  //       setCheckForStaff(true)
  //     }
  //     // console.log("IsStaff Boolean")
  //   }
  //   if (typeof IsStaff == "string") {
  //     // variable is a boolean
  //     if (IsStaff === 'true') {
  //       setCheckForStaff(true)
  //     }
  //     // console.log("IsStaff Boolean")
  //   }
  // }, [IsStaff]);


  // console.log("checkForStaff", checkForStaff)
  // console.log("IsStaff", IsStaff)
  // console.log("get_auth?.is_staff", get_auth?.is_staff)
  // console.log(get_auth)
  // console.log(get_auth?.token)
  // console.log(get_auth?.account_default?.verify_token)

  // console.log(NavList)
  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!IsStaff) {
        return navigate('/app/switch-org')

      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [IsStaff, navigate]);

  useEffect(() => {
    if (get_auth?.token) {
      setEmployeeId(get_auth.employee_id);
      // setOrganization(get_organizations[get_auth.organization_id])
    }
  }, [get_auth, get_organizations]);

  useEffect(() => {
    if (
      get_error?.status == 401 ||
      get_error?.msg == "Request failed with status code 401" ||
      get_error?.msg?.message == "Request failed with status code 401"
    ) {
      dispatch({
        type: CLEAR_PASSWORD_DATA,
      });
      if (location.pathname !== "/account/login") {
        return navigate("/account/login");
      }
    }
  }, [dispatch, get_error, navigate, location]);

  useEffect(() => {
    if (get_auth?.token) {
      setEmployeeId(get_auth.employee_id);
    }
  }, [get_auth]);

  useEffect(() => {
    // if the response is ok 200
    // console.log("SG_FETCH_USER_EMPLOYEE here", employeeId)
    // console.log(employee)
    if (!employee) {
      if (Number(employeeId) > 0) {
        dispatch({
          type: SG_FETCH_USER_EMPLOYEE,
          payload: { id: employeeId },
        });
      }
    }
  }, [dispatch, employeeId, employee]);

  useEffect(() => {
    if (get_auth?.token) {
      if (employeeId) {
        setEmployee(get_employees?.userEmp);
        // props.setEmployee1(get_employees[employeeId])
      }
    }
  }, [dispatch, get_employees, employeeId, get_auth]);


  // navigate('/app/no-access')
  if (get_auth?.token) {

    if (employee?.email_status === 9) {
      if (location.pathname !== "/app/dashboard") {
        return navigate("/app/dashboard");
      }
    }

  }

  return null
  //return get_auth?.token? <WebSite props={props} /> :  <WebSite props={props} />
};
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */


const MobileContainer = () => {

  return (
    <>
      {/* Desktop View */}
      <Media
        greaterThan="mobile"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SideBar />
        <TopBar />

        <div
          style={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: 1400,
              width: "100vw",
              paddingLeft: 68,
              paddingTop: 37,
              paddingRight: 30,
            }}

          >

            <ErrorWrapper>
              <Outlet />
            </ErrorWrapper>
          </div>
        </div>
      </Media>

      {/* Mobile View */}
      <Media at="mobile" style={{ position: "relative" }}>
        <MobileNav />
        <div
          style={{
            width: "100%",
            marginTop: "100px",
            maxWidth: "100%",
            overflowX: "hidden",
            padding: 10,
          }}
        >
          <Outlet />
        </div>
        <Help />
      </Media>
    </>
  )
};


const ResponsiveContainer = ({ children }) => {
  const domain = window.location.host;
  return (
    <MediaContextProvider>
      <DomainContext.Provider value={
        DomainList?.[domain] ? DomainList?.[domain] : Object.values(DomainList)[0]}>
        <ComplianceCheck />

        <MobileContainer>{children}</MobileContainer>
      </DomainContext.Provider>
    </MediaContextProvider>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;