import React, { useEffect, useState } from 'react'
import {
  Form,
  Grid,
  Header,
  Segment,
  TransitionablePortal,
} from 'semantic-ui-react'

const transitions = [
  'browse',
  'browse right',
  'drop',
  'fade',
  'fade up',
  'fade down',
  'fade left',
  'fade right',
  'fly up',
  'fly down',
  'fly left',
  'fly right',
  'horizontal flip',
  'vertical flip',
  'scale',
  'slide up',
  'slide down',
  'slide left',
  'slide right',
  'swing up',
  'swing down',
  'swing left',
  'swing right',
  'zoom',
]



function AnimationPortal({id, fullContent, durationVal=500, animationVal=0}) {
    if (animationVal < 0 || animationVal > 23) {
        animationVal = 0
    }
    const {contentId, header, content} = fullContent
    const [animation, setAnimation] = useState(transitions[animationVal])
    const [duration, setDuration] = useState(durationVal)
    const [open, setOpen] = useState(false)

      useEffect(() => {
        setDuration(durationVal)
      },[durationVal, id])

      useEffect(() => {
        setAnimation(transitions[animationVal])
      },[animationVal, id])

      useEffect(() => {
        if (contentId !== id) {
            setOpen(false)
        }
      },[id, contentId])


    return (
      <Grid columns={2}>
        <Grid.Column>
          <Form>
           
            <Form.Button
              content={open ? 'Close' : 'View'}
              negative={open}
              positive={!open}
              onClick={() => setOpen(!open)}
            />
          </Form>

          <TransitionablePortal
            open={open}
            transition={{ animation, duration }}
          >
            <Segment
              style={{
                left: '40%',
                transform: 'translateX(-50%)',
                position: 'fixed',
                top: '50%',
                zIndex: 1000,
              }}
            >
            <Header>{header}</Header>
                <p>{content}</p>
            </Segment>
          </TransitionablePortal>
        </Grid.Column>
      </Grid>
    )
  
}

export default AnimationPortal