import React, { useState, useEffect, useRef } from "react";
import Dropzone from "react-dropzone";

import styled from 'styled-components';
import { useDispatch } from "react-redux";

import { Dimmer, Loader, Segment, Button } from 'semantic-ui-react'
import { Icon, Message, Image } from "semantic-ui-react";

import { UPDATE_ORGANIZATION_LOGO, UPDATE_ORGANIZATION } from "constants/actions";

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>

            {files.length > 0 && submitButton}
        </div>
    )
}

const ImageUpload = ({ organization }) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()

    useEffect(() => {
        if (organization) {
            // setEmployee(organization);
            if (organization?.logo) {
                setPreview(organization?.logo)
            }
        }
    }, [organization]);

    // console.log(organization.logo)
    // console.log(image)

    const onClickSave = () => {
        if (organization?.id) {
            if (selectedFile) {
                dispatch({
                    type: UPDATE_ORGANIZATION_LOGO,
                    payload: {
                        id: organization.id,
                        logo: selectedFile,
                    },
                });
            } else {
                console.log("cleared")
                dispatch({
                    type: UPDATE_ORGANIZATION,
                    payload: {
                        id: organization.id,
                        logo: null,
                    },
                });
            }

            //     setUploadedFiles(false);
            //     // addToast("File Successfully Uploaded", {
            //     //     appearance: "success",
            //     //     autoDismiss: true,
            // });
        }
    };

    const handleDrop = (dropped) => {
        if (!dropped === 0) {
            setSelectedFile(undefined)
            return
        }
        setSelectedFile(dropped[0])
        if (!dropped[0]) {
            setPreview(undefined)
            return
        } else {
            const objectUrl = URL.createObjectURL(dropped[0])
            setPreview(objectUrl)
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    };

    return (
        <>
            <Segment>

                <div aria-labelledby="max-width-dialog-title" style={{ paddingLeft: 30 }}>
                    {preview ? <Button floated="right" icon="remove" onClick={() => {

                        setPreview(undefined)
                        setSelectedFile(undefined)
                    }
                    } /> : ''}
                    <P id="max-width-dialog-title">Upload Company Logo</P>

                    <div className="dialog">
                        <P>Click the image or Drag &amp; Drop the Image here</P>

                        <Dropzone
                            onDrop={handleDrop}
                            noKeyboard
                            maxFiles={1}
                            multiple={false}
                            accept="image/jpeg,image/png,image/gif"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Box
                                    {...getRootProps()}
                                    file={preview?.length > 0}
                                >
                                    {preview ? (
                                        <InnerBox
                                        // onClick={() => setUploadedFiles([])}
                                        >
                                            <Image src={preview} />


                                        </InnerBox>
                                    ) : (
                                        <p>
                                            {" "}
                                            <Icon name="upload" /> Drag 'n' drop Image
                                            files here, or click to select Image files
                                            to upload
                                        </p>
                                    )}
                                    {<input {...getInputProps()} />}
                                </Box>
                            )}
                        </Dropzone>

                        <div>
                            <StyledButton
                           
                                onClick={() => onClickSave()} color="grey">
                                Save Logo
                            </StyledButton>
                        </div>

                    </div>
                </div>
            </Segment>
        </>
    );
};

export default ImageUpload;

const P = styled.p`
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
`

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2d50e2;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-right: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;


const Info = styled.div`
    font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;
`

const Box = styled.div`
  border-radius:10px;
  border:1px solid #e9e9e9;
  display:flex;
  align-items:center;
  padding:20px;
  width:340px;
  position:relative;
  margin-bottom:30px;
`

const Rotate = styled.div`
  position:absolute;
  bottom:5px;
  right:-30px;
  width:100px;
  cursor:pointer;
  color:#6a00ff;
`
const InnerBox = styled.div`
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 15px;
    cursor: pointer;
    background-color: #dcdcdc;
`;
