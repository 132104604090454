import React, { useState } from "react";
import styled from "styled-components";
import { SketchPicker } from 'react-color';
import ClickAwayListener from "react-click-away-listener";

export const ColorPicker = ({ color, setColor }) => {
	const [open, setOpen] = useState(false)
	return (
		<div style={{ position: 'relative' }}>
			<ClickAwayListener onClickAway={() => setOpen(false)}>
				<ColorBlock color={color} onClick={() => setOpen(!open)}>

				</ColorBlock>

				<Container open={open}>
					<SketchPicker
						color={color}
						onChangeComplete={(c) => setColor(c)}
					/>
				</Container>
			</ClickAwayListener>
		</div>
	)
}

export function stringToHexColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return hslToHex(h, s, l);
}

export function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

const ColorBlock = styled.div`
	width:40px;
	height:40px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Container = styled.div`
	position:absolute;
	left:0;
	bottom:10px;
	display:${props => props.open ? 'flex' : 'none'};
`