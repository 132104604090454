import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_PERSONALITY_PARTICIPANTS, 
    SG_GET_PERSONALITY_PARTICIPANTS, 
    DELETE_PERSONALITY_PARTICIPANT, 
    SG_DELETE_PERSONALITY_PARTICIPANT, 
    SG_FETCH_PERSONALITY_PARTICIPANT, 
    FETCH_PERSONALITY_PARTICIPANT, 
    SG_EDIT_PERSONALITY_PARTICIPANT, 
    EDIT_PERSONALITY_PARTICIPANT, 
    SG_ADD_PERSONALITY_PARTICIPANT, 
    ADD_PERSONALITY_PARTICIPANT, 
    CLEAR_PERSONALITY_PARTICIPANT, 
    SG_CLEAR_PERSONALITY_PARTICIPANT, 
    ALL_ERRORS,
} from "constants/actions";



function* loadReducer(action) {
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PERSONALITY_PARTICIPANTS, action.payload);
    yield put({ type: GET_PERSONALITY_PARTICIPANTS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaLoad() {
  yield takeLatest(SG_GET_PERSONALITY_PARTICIPANTS, loadReducer);
}

function* fetchReducer(action) {
  try {
    const json = yield call(api.FETCH_PERSONALITY_PARTICIPANT, action.payload);
    yield put({ type: FETCH_PERSONALITY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaFetch() {
  yield takeLatest(SG_FETCH_PERSONALITY_PARTICIPANT, fetchReducer);
}

function* updateReducer(action) {
  try {
    const json = yield call(api.EDIT_PERSONALITY_PARTICIPANT, action.payload);
    yield put({ type: EDIT_PERSONALITY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaUpdate() {
  yield takeLatest(SG_EDIT_PERSONALITY_PARTICIPANT, updateReducer);
}

function* addReducer(action) {
  try {
    const json = yield call(api.ADD_PERSONALITY_PARTICIPANT, action.payload);
    yield put({ type: ADD_PERSONALITY_PARTICIPANT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaAdd() {
  yield takeLatest(SG_ADD_PERSONALITY_PARTICIPANT, addReducer);
}

function* deleteReducer(action) {
  try {
    yield call(api.DELETE_PERSONALITY_PARTICIPANT, action.payload);
    yield put({ type: DELETE_PERSONALITY_PARTICIPANT, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaDelete() {
  yield takeLatest(SG_DELETE_PERSONALITY_PARTICIPANT, deleteReducer);
}


export default function* index() {
  yield all([
    sagaLoad(),
    sagaFetch(),
    sagaUpdate(),
    sagaAdd(),
    sagaDelete(),
  ]);
}
