import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Button, Header, Modal, Segment, Grid, Divider, List } from 'semantic-ui-react'

import { SG_MERGE_DEBRIEF_SCHEDULE } from "constants/actions";

import { format } from 'date-fns'

export default function MergeSurvey({
    debriefSchedule,
    setMergeSurvey,
    mergeSurvey }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [disAllowSecond, setDisAllowSecond] = useState(true)
    const [resetMerge, setResetMerge] = useState(true)

    function Submit() {
        setOpen(false)
        dispatch({
            type: SG_MERGE_DEBRIEF_SCHEDULE,
            payload: {
                original_brief_id: mergeSurvey?.original_brief?.id,
                merge_brief_id: mergeSurvey?.merge_brief?.id
            }
        });
    }
    useEffect(() => {
        if (mergeSurvey?.original_brief?.id && mergeSurvey?.merge_brief?.id) {
            setOpen(true)
        }
    }, [mergeSurvey]);

    function AddDebriefSchedule() {
        setMergeSurvey({
            original_brief: debriefSchedule,
            org: debriefSchedule?.organization,
            name: debriefSchedule?.name,
        })
    }

    function UpdateDebriefSchedule() {
        const _merged = mergeSurvey
        _merged.merge_brief = debriefSchedule
        if (_merged?.original_brief?.id && _merged?.merge_brief?.id) {
            setOpen(true)
        }
        setMergeSurvey(_merged)
    }

    function CloseMerge() {
        setOpen(false)
    }

    function ResetMerge() {
        setOpen(false)
        setMergeSurvey(
            {
                org: null,
                name: null,
                original_brief: null,
                merge_brief: null
            }
        )
    }

    useEffect(() => {
        if (mergeSurvey?.original_brief) {
            setResetMerge(false)
            if (debriefSchedule?.id === mergeSurvey?.original_brief?.id) {
                setResetMerge(true)
                return
            }
            if (debriefSchedule.organization === mergeSurvey?.original_brief?.organization) {
                if (debriefSchedule.name === mergeSurvey?.original_brief?.name) {
                    setDisAllowSecond(false)
                }
            }
        }
    }, [debriefSchedule, mergeSurvey]);

    // console.log(debriefSchedule)

    return (
        <>
            {mergeSurvey?.original_brief?.id ?
                resetMerge ?
                    <Button
                        icon='shuffle'
                        content='Reset'
                        color='purple'
                        onClick={ResetMerge}
                    />
                    :
                    <Button
                        icon='shuffle' content='Merge'
                        color='red'
                        disabled={open || disAllowSecond}
                        onClick={UpdateDebriefSchedule}
                    />
                :
                <Button
                    icon='shuffle' content='Merge'
                    color='black'
                    onClick={AddDebriefSchedule}
                />
            }


            <Modal
                closeIcon
                onClose={CloseMerge}
                open={open}>
                <Modal.Content>
                    <Header>You are about to merge these two surveys</Header>
                    <Segment>
                        <Grid columns={2} relaxed='very'>
                            <Grid.Column>
                                <List divided verticalAlign='middle'>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>ID # {mergeSurvey?.original_brief?.id}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Org Id # {mergeSurvey?.original_brief?.organization}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Primary # {mergeSurvey?.original_brief?.name}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Requests # {mergeSurvey?.original_brief?.total_requests}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Responses # {mergeSurvey?.original_brief?.total_responses}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Scheduled # {mergeSurvey?.original_brief?.scheduled_for && format(new Date(mergeSurvey?.original_brief?.scheduled_for), 'dd-MMM-yyyy hh:mm')}
                                            </List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Close At #
                                                {mergeSurvey?.original_brief?.close_at && format(new Date(mergeSurvey?.original_brief?.close_at), 'dd-MMM-yyyy hh:mm')}
                                            </List.Header>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>

                            <Grid.Column>
                                <List divided verticalAlign='middle'>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>ID #{mergeSurvey?.merge_brief?.id}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Org Id #{mergeSurvey?.merge_brief?.organization}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Primary # {mergeSurvey?.merge_brief?.name}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Requests # {mergeSurvey?.merge_brief?.total_requests}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Responses # {mergeSurvey?.merge_brief?.total_responses}</List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Scheduled # {mergeSurvey?.merge_brief?.scheduled_for && format(new Date(mergeSurvey?.merge_brief?.scheduled_for), 'dd-MMM-yyyy hh:mm')}
                                            </List.Header>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>Close At #
                                                {mergeSurvey?.merge_brief?.close_at && format(new Date(mergeSurvey?.merge_brief?.close_at), 'dd-MMM-yyyy hh:mm')}
                                            </List.Header>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>

                        <Divider vertical>And</Divider>
                    </Segment>


                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Merge Survey'
                        negative
                        onClick={Submit}
                    />
                </Modal.Actions>

            </Modal>
        </>


    )

}