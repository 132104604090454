import {
    USER_LOADED,
    USER_LOADING,
    SET_AUTH_ORGANIZATION,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    REGISTER_USER_TOKEN,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    CLEAR_PASSWORD_DATA,
} from "constants/actions";;

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


const initialState = {
    access_token: false,
    refresh_token: false,
    employee_permission: {},
    token: localStorage.getItem("token"),
    key: localStorage.getItem("token"),
    chat_token: localStorage.getItem("chat_token"),
    
    is_authenticated: localStorage.getItem("is_authenticated"),
    is_staff: localStorage.getItem("is_staff"),
    is_active: localStorage.getItem("is_active"),
    is_admin: localStorage.getItem("is_admin"),
    user_id: localStorage.getItem("user_id"),
    role_id: localStorage.getItem("role_id"),
    organization_id: localStorage.getItem("organization_id"),
    employee_id: localStorage.getItem("employee_id"),
    ambassador_id: localStorage.getItem("ambassador_id"),
    expires_in: localStorage.getItem("expires_in"),

    message: false,
    error: false,
    next_steps: 0,
    access_token_expires_at: localStorage.getItem("access_token_expires_at"),
    refresh_token_expires_at: localStorage.getItem("refresh_token_expires_at"),
    session_id: localStorage.getItem("session_id"),
    url: false,
    isLoading: false,
    pending: false,
    organization_list: isJsonString(localStorage.getItem("organization_list")) ? JSON.parse(localStorage.getItem("organization_list")): [],
    // organization_list: [],
    user: isJsonString(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")): [],
    employee: isJsonString(localStorage.getItem("employee")) ? JSON.parse(localStorage.getItem("employee")): [],
    organization: isJsonString(localStorage.getItem("organization")) ? JSON.parse(localStorage.getItem("organization")): [],
    account_default: isJsonString(localStorage.getItem("account_default")) ? JSON.parse(localStorage.getItem("account_default")): [],
    registered: [],
    forgot_password: [],
    reset_password: [],
    changed_password: [],
    register_org: [],
    register_token: [],

};

const removeLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("chat_token");
    // expires_in
    localStorage.removeItem("is_authenticated");
    localStorage.removeItem("is_staff")
    localStorage.removeItem("user_id");
    localStorage.removeItem("role_id");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("organization_id")
    localStorage.removeItem("employee_id")
    localStorage.removeItem("ambassador_id")
    localStorage.removeItem("access_token_expires_at")
    localStorage.removeItem("refresh_token_expires_at")
    localStorage.removeItem("session_id")

    localStorage.removeItem("organization_list")
    localStorage.removeItem("user")
    localStorage.removeItem("employee")
    localStorage.removeItem("organization")
    localStorage.removeItem("account_default")
}
export default function Auth(state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            // console.log(`User Loaded reducer ${JSON.stringify(action.payload)} now check state`)
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user_id: action.payload.user_id,
                employee_id: action.payload.employee_id,
                organization_id: action.payload.company_id,
                is_staff: action.payload.is_staff,
                role_id: action.payload.role_id,
                ambassador_id: action.payload.ambassador_id
            };
        case SET_AUTH_ORGANIZATION:
            return {
                ...state,
                organization_id: action.payload
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgot_password: action.payload
            };
        case RESET_PASSWORD:
                return {
                    ...state,
                    next_steps: 3,
                    reset_password: action.payload
                };
                case LOGIN_SUCCESS:
                    localStorage.setItem("token", action.payload.token);
                    localStorage.setItem("chat_token", action.payload.chat_token);
                    localStorage.setItem("is_authenticated", JSON.stringify(action.payload.is_authenticated))
                    localStorage.setItem("is_staff", JSON.stringify(action.payload.user.is_staff));
                    localStorage.setItem("is_active", JSON.stringify(action.payload.user.is_active));
                    localStorage.setItem("is_admin", JSON.stringify(action.payload.user.is_admin));
                    localStorage.setItem("expires_in", action.payload.expires_in);
                    localStorage.setItem("access_token_expires_at", action.payload.access_token_expires_at);
                    localStorage.setItem("refresh_token_expires_at", action.payload.refresh_token_expires_at);
                    localStorage.setItem("session_id", action.payload.session_id);
                    localStorage.setItem("role_id", action.payload.role_id);
                    localStorage.setItem("organization_id", action.payload.organization_id);
                    localStorage.setItem("ambassador_id", action.payload.ambassador_id);
                    localStorage.setItem("employee_id", action.payload.employee_id);

                    localStorage.setItem("user_id", action.payload.user_id)
                    localStorage.setItem("organization_list", JSON.stringify(action.payload.organization_list))
                    localStorage.setItem("user", JSON.stringify(action.payload.user))
                    localStorage.setItem("employee", JSON.stringify(action.payload.employee))
                    localStorage.setItem("organization", JSON.stringify(action.payload.organization))
                    localStorage.setItem("account_default", JSON.stringify(action.payload.account_default))
        
                    return {
                        ...state,
                        ...action.payload,
                        isLoading: false,
                        pending: false,
                    };
                case REGISTER_SUCCESS:
                    localStorage.setItem("token", action.payload.token);
                    localStorage.setItem("is_authenticated", JSON.stringify(action.payload.is_authenticated))
                    localStorage.setItem("is_staff", JSON.stringify(action.payload.user.is_staff));
                    localStorage.setItem("is_active", JSON.stringify(action.payload.user.is_active));
                    localStorage.setItem("is_admin", JSON.stringify(action.payload.user.is_admin));
                    localStorage.setItem("expires_in", action.payload.expires_in);
                    localStorage.setItem("access_token_expires_at", action.payload.access_token_expires_at);
                    localStorage.setItem("refresh_token_expires_at", action.payload.refresh_token_expires_at);
                    localStorage.setItem("session_id", action.payload.session_id);
                    localStorage.setItem("role_id", action.payload.role_id);
                    localStorage.setItem("organization_id", action.payload.organization_id);
                    localStorage.setItem("employee_id", action.payload.employee_id);
                    localStorage.setItem("ambassador_id", action.payload.ambassador_id);
                    localStorage.setItem("organization_list", JSON.stringify(action.payload.organization_list))
                    localStorage.setItem("user", JSON.stringify(action.payload.user))
                    localStorage.setItem("employee", JSON.stringify(action.payload.employee))
                    localStorage.setItem("organization", JSON.stringify(action.payload.organization))
                    localStorage.setItem("account_default", JSON.stringify(action.payload.account_default))
                    return {
                        ...state,
                        ...action.payload,
                        register_org: action.payload,
                        isLoading: false,
                        pending: false,
                    }
        case REGISTER_USER_TOKEN:
            return {
                ...state,
                register_token: action.payload
            }
            case AUTH_ERROR:
                removeLocalStorage()
                return {
                    ...state,
                    pending: false,
                    access_token: false,
                    refresh_token: false,
                    employee_permission: {},
                    token: false,
                    key: false,
                    is_authenticated: false,
                    is_staff: false,
                    is_active: false,
                    is_admin: false,
                    user_id: false,
                    role_id: false,
                    organization_id: false,
                    employee_id: false,
                    ambassador_id: false,
                    expires_in: false,
                    message: false,
                    error: false,
                    next_steps: 0,
                    access_token_expires_at: false,
                    refresh_token_expires_at: false,
                    session_id: false,
                    url: false,
                    isLoading: false,
                    organization_list: [],
                    user: [],
                    employee: [],
                    organization: [],
                    registered: [],
                    forgot_password: [],
                    changed_password: [],
                    register_org: [],
                    register_token: [],
                    account_default: [],
                };
            case LOGIN_FAIL:
                removeLocalStorage()
                return {
                    ...state,
                    pending: false,
                    access_token: false,
                    refresh_token: false,
                    employee_permission: {},
                    token: false,
                    key: false,
                    is_authenticated: false,
                    is_staff: false,
                    is_active: false,
                    is_admin: false,
                    user_id: false,
                    role_id: false,
                    organization_id: false,
                    employee_id: false,
                    ambassador_id: false,
                    expires_in: false,
                    message: false,
                    error: false,
                    next_steps: 0,
                    access_token_expires_at: false,
                    refresh_token_expires_at: false,
                    session_id: false,
                    url: false,
                    isLoading: false,
                    organization_list: [],
                    user: [],
                    employee: [],
                    organization: [],
                    registered: [],
                    forgot_password: [],
                    changed_password: [],
                    register_org: [],
                    register_token: [],
                    account_default: [],
                };
            case LOGOUT_SUCCESS:
                removeLocalStorage()
                return {
                    ...state,
                    pending: false,
                    access_token: false,
                    refresh_token: false,
                    employee_permission: {},
                    token: false,
                    key: false,
                    is_authenticated: false,
                    is_staff: false,
                    is_active: false,
                    is_admin: false,
                    user_id: false,
                    role_id: false,
                    organization_id: false,
                    employee_id: false,
                    ambassador_id: false,
                    expires_in: false,
                    message: false,
                    error: false,
                    next_steps: 0,
                    access_token_expires_at: false,
                    refresh_token_expires_at: false,
                    session_id: false,
                    url: false,
                    isLoading: false,
                    organization_list: [],
                    user: [],
                    employee: [],
                    organization: [],
                    registered: [],
                    forgot_password: [],
                    changed_password: [],
                    register_org: [],
                    register_token: [],
                    account_default: [],
                };
            case REGISTER_FAIL:
                removeLocalStorage()
                return {
                    ...state,
                    pending: false,
                    access_token: false,
                    refresh_token: false,
                    employee_permission: {},
                    token: false,
                    key: false,
                    is_authenticated: false,
                    is_staff: false,
                    is_active: false,
                    is_admin: false,
                    user_id: false,
                    role_id: false,
                    organization_id: false,
                    employee_id: false,
                    ambassador_id: false,
                    expires_in: false,
                    message: false,
                    error: false,
                    next_steps: 0,
                    access_token_expires_at: false,
                    refresh_token_expires_at: false,
                    session_id: false,
                    url: false,
                    isLoading: false,
                    organization_list: [],
                    user: [],
                    employee: [],
                    organization: [],
                    registered: [],
                    forgot_password: [],
                    changed_password: [],
                    register_org: [],
                    register_token: [],
                    account_default: [],
                };
            case CHANGE_PASSWORD:
                // removeLocalStorage()
                
                return {
                    ...state,
                    pending: false,
                    changed_password: action.payload,
                };
    
            case CLEAR_PASSWORD_DATA:
                removeLocalStorage()
                return {
                    ...state,
                    pending: false,
                    access_token: false,
                    refresh_token: false,
                    employee_permission: {},
                    token: false,
                    key: false,
                    is_authenticated: false,
                    is_staff: false,
                    is_active: false,
                    is_admin: false,
                    user_id: false,
                    role_id: false,
                    organization_id: false,
                    employee_id: false,
                    ambassador_id: false,
                    expires_in: false,
                    message: false,
                    error: false,
                    next_steps: 0,
                    access_token_expires_at: false,
                    refresh_token_expires_at: false,
                    session_id: false,
                    url: false,
                    isLoading: false,
                    organization_list: [],
                    user: [],
                    employee: [],
                    organization: [],
                    registered: [],
                    forgot_password: [],
                    changed_password: [],
                    register_org: [],
                    register_token: [],
                    account_default: [],
                };
            default:
                return state;
        }
}