import {
  PENDING_GLOBAL_SCHEDULES,
  UPDATED_GLOBAL_SCHEDULES,
  REFRESHED_GLOBAL_SCHEDULES,
  GET_GLOBAL_SCHEDULES,
  DELETE_GLOBAL_SCHEDULE,
  FETCH_GLOBAL_SCHEDULE,
  EDIT_GLOBAL_SCHEDULE,
  ADD_GLOBAL_SCHEDULE,
  CLEAR_GLOBAL_SCHEDULE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  global_schedules: [],
  pending: false,
  updated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PENDING_GLOBAL_SCHEDULES:
      return {
        ...state,
        pending: true,
      };
    case UPDATED_GLOBAL_SCHEDULES:
      return {
        ...state,
        updated: true,
      };
      case REFRESHED_GLOBAL_SCHEDULES:
        return {
          ...state,
          updated: false,
          
        };
    case GET_GLOBAL_SCHEDULES:
      return {
        ...state,
        global_schedules: action.payload[0],
        pending: false,
        updated: false,
      };

    case FETCH_GLOBAL_SCHEDULE:
      return { ...state, 
        global_schedules: action.payload, 
        pending: false,
        updated: false,
      };

    case EDIT_GLOBAL_SCHEDULE:
      return { ...state, 
        global_schedules: action.payload, 
        pending: false,
        updated: false,
      };

    case DELETE_GLOBAL_SCHEDULE:
      return {
        ...state,
        global_schedules: state.global_schedules.filter(
          (row) => row.id !== action.payload
        ),
        pending: false,
        updated: false,
      };

    case ADD_GLOBAL_SCHEDULE:
      return {
        ...state,
        global_schedules:  action.payload,
        pending: false,
        updated: false,
      };

    case CLEAR_GLOBAL_SCHEDULE:
      return {
        ...state,
        global_schedules: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        global_schedules: [],
      };

    default:
      return state;
  }
}
