import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_TOKENS, SURVEY_TOKEN_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'


const TokenList = ({survey_compliance_id}) => {

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_SURVEY_TOKENS,
            payload: `page=${page}${survey_compliance_id?`&compliance=${survey_compliance_id}`:''}`,
        });
   
        setLoading(true)
    }, [dispatch, page, survey_compliance_id]);

    const { get_survey_tokens,
         } = useSelector(
        (state) => ({
            get_survey_tokens: state.surveytoken,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_survey_tokens?.survey_tokens?.results);
        setPage(get_survey_tokens?.pageNumber)
        setTotalPages(Math.ceil(get_survey_tokens?.survey_tokens?.count / get_survey_tokens?.survey_tokens?.page_size)) 
        if (get_survey_tokens?.survey_tokens?.count % get_survey_tokens?.survey_tokens?.page_size) {
            setTotalPages(Math.ceil(get_survey_tokens?.survey_tokens?.count / get_survey_tokens?.survey_tokens?.page_size))   
        }
        
    }, [get_survey_tokens, page]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: SURVEY_TOKEN_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    // console.log(get_survey_compliance)

    return (

        <Container>
            
          
            

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results.map((result) => (
                        <RowCard row={result} key={result?.id} />
                    ))}
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default TokenList;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`