import React from 'react';
import styled from 'styled-components';

export const DataRenderer = ({data}) => {
    return (
        <Container>
        {data?.pillars?.length ? (
            <>
                <div>
                    <Header>Pillars:</Header>
                    <List>
                        {data.pillars.map((pillar, index) => (
                            <ListItem key={index}>
                                ID: {pillar?.id}<br />
                                Std: {pillar?.std}<br />
                                Factor: {pillar?.factor}<br />
                                Average: {pillar?.average}<br />
                                Overall: {pillar?.overall.join(', ')}<br />
                            </ListItem>
                        ))}
                    </List>
                </div>
                
                <div>
                    <Header>Questions:</Header>
                    <List>
                        {Object.entries(data.questions).map(([key, value]) => (
                            <ListItem key={key}>
                                {key}: {value}
                            </ListItem>
                        ))}
                    </List>
                </div>
                </>
        ) : 
        <Header>None</Header>}
        </Container>
    );
}
export const ReportDisplay = ({data}) => {
    return (
        <div>
            <h2>{data?.email_template?.type}</h2>

           

            <h3>Preview:</h3>
            <p>{data?.email_template?.preview}</p>

            {data?.response && data.response.map((item, index) => (
                <div key={index}>
                    <h4>Factor Definition:</h4>
                    <p>{item?.factor_definition}</p>
                    
                    <h4>Needs:</h4>
                    <p>{item?.needs}</p>

                    <h4>Summary:</h4>
                    <p>{item?.summary}</p>

                    <h4>Recommendations:</h4>
                    <ul>
                        {item?.recommendations && item.recommendations.map((rec, recIndex) => (
                            <li key={recIndex}>{rec}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}


export const DataDisplay = ({data}) => {

    return (
        <div>
            <h3>Organization Name:</h3>
            <p>{data?.org_name}</p>

            <h3>Template:</h3>
            <p>{data?.template}</p>

            <h3>Tags:</h3>
            <ul>
                {data?.tags && data.tags.map((tag, index) => (
                    <li key={index}>{tag}</li>
                ))}
            </ul>
        </div>
    );
}


const Container = styled.div`
    padding: 5px;
    font-family: Arial, sans-serif;
`;

const Header = styled.h3`
    color: #333;
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const ListItem = styled.li`
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

export default DataRenderer;