import React from "react";
import {
  useRoutes,
} from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';

import 'semantic-ui-css/semantic.min.css'
import routes from "routes/index";

import { Provider } from "react-redux";

import store from "./store";

const App = () => {
  const routing = useRoutes(routes);

  return (
    <ToastProvider>
      <Provider store={store}>
          {routing}
      </Provider>
    </ToastProvider>
      
   
  );
};

export default App;
