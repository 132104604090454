import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  throttle,
} from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SURVEY_RESPONSES,
  SG_GET_SURVEY_RESPONSES,
  DELETE_SURVEY_RESPONSE,
  SG_DELETE_SURVEY_RESPONSE,
  ALL_ERRORS,
} from "constants/actions";

function* loadTokens(action) {
  // console.log(action.payload)
  try {
    const json = yield call(api.GET_SURVEY_RESPONSES, action.payload);
    yield put({ type: GET_SURVEY_RESPONSES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* tokenLoad() {
  yield takeLatest(SG_GET_SURVEY_RESPONSES, loadTokens);
}

function* deleteToken(action) {
  // try {
  //   const json = yield call(api.DELETE_SURVEY_RESPONSE, action.payload);
  //   yield put({ type: DELETE_SURVEY_RESPONSE, payload: json.data });
  // } catch (e) {
  //   yield put({ type: ALL_ERRORS, payload: e.response });
  // }
}

export function* tokenDelete() {
  yield takeLatest(SG_DELETE_SURVEY_RESPONSE, deleteToken);
}

export default function* index() {
  yield all([tokenLoad(), tokenDelete()]);
}
