import React from 'react';
import styled from 'styled-components';


const ChatResponse = ({ data }) => {
  
    return (
        data ?
        <Container>
            <Title>{data?.message || 'N/A'}</Title>
            
            <Details>
                <DetailRow><strong>Web Chat ID:</strong> {data?.web_chat_id || 'N/A'}</DetailRow>
                <DetailRow><strong>Web Chat Message ID:</strong> {data?.web_chat_message_id || 'N/A'}</DetailRow>
                <DetailRow><strong>Message:</strong> {data?.web_chat_message_text || 'N/A'}</DetailRow>
            </Details>

            <Section>
                <h3>Server Response</h3>
                <DetailRow><strong>ID:</strong> {data?.response?.id || 'N/A'}</DetailRow>
                <DetailRow><strong>Message:</strong> {data?.response?.message || 'N/A'}</DetailRow>
                <DetailRow><strong>Response:</strong> {data?.response?.server?.response || 'N/A'}</DetailRow>
                <DetailRow><strong>Response Type:</strong> {data?.response?.server?.response_type || 'N/A'}</DetailRow>
                <DetailRow><strong>References:</strong> {data?.response?.server?.references || 'N/A'}</DetailRow>
                <DetailRow><strong>Score:</strong> {data?.response?.server?.score || 'N/A'}</DetailRow>
                <DetailRow><strong>Tokens:</strong> {data?.response?.server?.tokens || 'N/A'}</DetailRow>
                <DetailRow><strong>Error:</strong> {data?.response?.server?.error || 'No error'}</DetailRow>
            </Section>

            <Section>
                <DetailRow><strong>Overall Error:</strong> {data?.error || 'No error'}</DetailRow>
            </Section>
            
            <Section>
                <DetailRow><strong>Time Taken:</strong> {data?.time_taken_secs || 'N/A'} seconds</DetailRow>
            </Section>
        </Container>
        :
        <Title>No response yet</Title> 
    );
}

export default ChatResponse;


const Container = styled.div`
    
    border: 1px solid #e0e0e0;
    border-radius: 8px;
`;

const Title = styled.h2`
    color: #333;
`;

const Details = styled.div`
    margin-bottom: 20px;
`;

const DetailRow = styled.p`
    margin-bottom: 10px;
`;

const Section = styled.div`
    margin-top: 20px;
`;