import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
    Button,
    Icon,
    Table,
    Segment,
    Dimmer,
    Confirm,
    Popup,
    Loader,
} from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_FETCH_FILE_UPLOAD,
    SG_EDIT_FILE_UPLOAD,
    SG_VALIDATE_EMAIL_LIST,
} from "constants/actions";

import format from "date-fns/format";
import Update from "./Update";

function ValidateCheckEmail({ k, item, head,
    fieldValues }) {

    const { get_validate_email } = useSelector(
        (state) => ({
            get_validate_email: state.validateEmail,
        }),
        shallowEqual
    );

    if (head === "email") {
        const Found = get_validate_email[item?.email]?.code === 200;
        if (Found) {
            return (
                <Table.Cell key={k} positive>
                    <Popup
                        content="This email can be used"
                        trigger={<Icon color="green" name="checkmark" />}
                    />
                    {item[head]}{" "}
                </Table.Cell>
            );
        } else {
            return (
                <Table.Cell key={k} negative>
                    <Popup
                        content="This email id has been used"
                        trigger={<Icon name="attention" />}
                    />
                    {item[head]}
                </Table.Cell>
            );
        }
    }

    if (fieldValues?.[head]) {
        const Found1 = fieldValues[head].find(({ name }) => name === item[head])
        // console.log(Found1)
        if (!item[head]) {
            return <Table.Cell key={k}>{item[head]} </Table.Cell>;
        }
        if (Found1) {
            return (
                <Table.Cell key={k} positive>
                    <Popup
                        content="This is a valid sport choice"
                        trigger={<Icon color="green" name="checkmark" />}
                    />
                    {item[head]}{" "}
                </Table.Cell>
            );
        } else {
            return (
                <Table.Cell key={k} negative>
                    <Popup
                        content="This is not a valid sport choice"
                        trigger={<Icon name="attention" />}
                    />
                    {item[head]}
                </Table.Cell>
            );
        }
    }

    return <Table.Cell key={k}>{item[head]} </Table.Cell>;
}

const DebriefScheduleDetail = ({ showReportId, get_survey_structure }) => {
    const dispatch = useDispatch();

    const [fileUpload, setfileUpload] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [validFields, setValidFields] = useState([])
    const [validatedValues, setValidatedValues] = useState([])
    const [controlledBy, setControlledBy] = useState({})
    const [fieldValues, setFieldValues] = useState({})
    const [parentField, setParentField] = useState({})
    const [jsonData, setJsonData] = useState([])

    const [rowInfo, setRowInfo] = useState([]);

    const [rowId, setRowId] = useState(false);
    const [openPortal, setopenPortal] = useState(false);
    const [updateEmailData, setUpdateEmailData] = useState(true);
    const [deleteId, setDeleteId] = useState(false);
    const [invalidRows, setInvalidRows] = useState(0);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const {
        get_file_uploads,
        get_validate_email,
    } = useSelector(
        (state) => ({
            get_file_uploads: state.fileupload,
            get_validate_email: state.validateEmail,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (showReportId) {
            dispatch({
                type: SG_FETCH_FILE_UPLOAD,
                payload: { id: showReportId },
            });
        }
    }, [dispatch, showReportId, rowId, openPortal]);

    useEffect(() => {
        setfileUpload(get_file_uploads?.[showReportId]);
    }, [showReportId, get_file_uploads]);

    // console.log(get_file_uploads)
    // console.log(fileUpload)
    // console.log(showReportId)

    useEffect(() => {
        if (updateEmailData) {
            if (fileUpload?.review_data && fileUpload?.status < 3) {
                fileUpload?.review_data.map((fl) => {
                    // console.log(fl)
                    return ValidateEmail(fl.email);
                    // return null
                });
                setUpdateEmailData(false);
            }
            function ValidateEmail(emailid) {
                dispatch({
                    type: SG_VALIDATE_EMAIL_LIST,
                    payload: `email=${emailid}`,
                });
            }
        }
    }, [dispatch, fileUpload, updateEmailData]);

    useEffect(() => {
        const _UserList = [];

        let _total = 0
        if (fileUpload?.review_data) {
            setFileHeaders(Object.keys(fileUpload?.review_data?.[0]))
            fileUpload?.review_data.map((fl, i) => {
                const Found = get_validate_email[fl?.email]?.code === 200;
                if (!Found) {
                    // console.log("Invalid")
                    _total += 1;
                }
                let Found2 = 0
                let EmptyRow = 0
                validFields.map(fld => {
                    if (!fl[fld]) {
                        EmptyRow = 1;
                        return null
                    }
                    if (fieldValues?.[fld]?.length > 0) {
                        // console.log(fieldValues[fld])
                        Found2 = fieldValues?.[fld].find(({ name }) => name === fl[fld])
                    }
                    return null
                })
                if (!Found2) {
                    // console.log("Invalid")
                    _total += 1;
                }
                if (EmptyRow) {
                    // console.log("Invalid")
                    _total += 1;
                }

                // console.log(Found2)
                // console.log(_total)

                return _UserList.push({
                    ...fl,
                    valid: Found,
                });
            });
            if (fileUpload?.status > 2) {
                _total = 0;
            }
        }
        setJsonData(_UserList)
        setInvalidRows(_total)
    }, [fileUpload, get_validate_email, fieldValues, validFields, parentField, validatedValues]);

    // console.log(get_survey_structure?.results?.[0]?.field_map)

    useEffect(() => {
        if (get_survey_structure?.results?.length > 0) {
            setValidFields(get_survey_structure?.results?.[0]?.field_map?.fields)
            setControlledBy(get_survey_structure?.results?.[0]?.field_map?.controlled_by)
            setFieldValues(get_survey_structure?.results?.[0]?.field_map?.values)
            setValidatedValues(get_survey_structure?.results?.[0]?.field_map?.validated_values)
            setParentField(get_survey_structure?.results?.[0]?.field_map?.parent_field)
        }
    }, [get_survey_structure]);

    function GetDetails(item, id) {
        console.log(item)
        setopenPortal(true);
        setRowInfo(item);
        setRowId(id);
    }

    function DeleteRow(id) {
        setDeleteId(id);
        setConfirmDelete(true);
    }

    function DeleteFileUpload() {
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: showReportId,
                delete_row: deleteId,
            },
        });
        const _jsonData = jsonData;
        _jsonData.splice(deleteId, 1);
        setJsonData(_jsonData);

        setConfirmDelete(false);
        setDeleteId(null);
    }

    function ImportToDb() {
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: showReportId,
                import_to_db: 1,
                review_data: jsonData,
            },
        });
    }

    // console.log(validFields)
    // console.log(fieldValues)

    return (
        <>
            {openPortal && (
                <Update
                    setopenPortal={setopenPortal}
                    openPortal={openPortal}
                    rowInfo={rowInfo}
                    setRowInfo={setRowInfo}
                    id={showReportId}
                    rowId={rowId}
                    setJsonData={setJsonData}
                    jsonData={jsonData}
                    fieldValues={fieldValues}
                    validFields={validFields}
                    validatedValues={validatedValues}
                    parentField={parentField}
                    controlledBy={controlledBy}

                />
            )}
            <Segment basic padded>
                <Dimmer active={openPortal} />
                <Loader>Loading</Loader>
                <TopContainer>
                    <div>
                        {fileUpload?.created_at && (
                            <Information>
                                {fileUpload?.status_text} -{" "}
                                {format(
                                    new Date(
                                        fileUpload?.created_at?.toLocaleString()
                                    ),
                                    "EEEE, MMM do"
                                )}
                            </Information>
                        )}
                        <Information>
                            {invalidRows > 0 &&
                                `${invalidRows} Invalid Rows Found please fix them to complete the upload`}
                        </Information>
                    </div>
                    {fileUpload?.status < 3 && (
                        <StyledButton
                            disabled={invalidRows > 0}
                            onClick={ImportToDb}
                        >
                            Import Data Listed Below
                        </StyledButton>
                    )}
                </TopContainer>

                <ScrollContainer>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                {fileHeaders &&
                                    fileHeaders.map((head, i) => {

                                        const Result = validFields.includes(head);
                                        if (head === 'valid') {
                                            return null
                                        }

                                        if (Result) {
                                            return (
                                                <Table.HeaderCell key={i}>
                                                    {head}
                                                </Table.HeaderCell>
                                            );
                                        }

                                        return (
                                            <Table.HeaderCell key={i} error>
                                                <Popup
                                                    content="Unable to import data in this column"
                                                    trigger={
                                                        <Icon name="attention" />
                                                    }
                                                />
                                                {head}
                                            </Table.HeaderCell>
                                        );
                                    })}
                                {fileUpload?.status < 3 && (
                                    <Table.HeaderCell></Table.HeaderCell>
                                )}
                                {fileUpload?.status < 3 && (
                                    <Table.HeaderCell></Table.HeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {jsonData &&
                                jsonData.map((item, i) => {
                                    return (
                                        <Table.Row
                                            key={i}
                                            error={
                                                item.valid !== true &&
                                                fileUpload?.status < 3
                                            }
                                        >
                                            {fileHeaders &&
                                                fileHeaders.map((head, k) => {
                                                    const Result = validFields.includes(head);
                                                    if (head === 'valid') {
                                                        return null
                                                    }
                                                    if (
                                                        fileUpload?.status > 2
                                                    ) {
                                                        return (
                                                            <Table.Cell key={k}>
                                                                {item[head]}
                                                            </Table.Cell>
                                                        );
                                                    }
                                                    if (Result) {
                                                        return <ValidateCheckEmail key={k}
                                                            item={item}
                                                            head={head}
                                                            uploadStatus={fileUpload?.status}
                                                            fieldValues={fieldValues}


                                                        />
                                                    }
                                                    
                                                    return (
                                                        <Table.Cell
                                                            key={k}
                                                            error
                                                        >
                                                            <Popup
                                                                content="Unable to import this data, not supported field"
                                                                trigger={
                                                                    <Icon name="attention" />
                                                                }
                                                            />
                                                            {item[head]}
                                                        </Table.Cell>
                                                    );
                                                })}

                                            {fileUpload?.status < 3 && (
                                                <Table.Cell>
                                                    <Button
                                                        icon
                                                        onClick={() =>
                                                            GetDetails(item, i)
                                                        }
                                                    >
                                                        <Icon name="edit" />
                                                    </Button>
                                                </Table.Cell>
                                            )}
                                            {fileUpload?.status < 3 && (
                                                <Table.Cell>
                                                    <Button
                                                        icon
                                                        onClick={() =>
                                                            DeleteRow(i)
                                                        }
                                                    >
                                                        <Icon name="trash alternate" />
                                                    </Button>
                                                    <Confirm
                                                        open={confirmDelete}
                                                        onCancel={() =>
                                                            setConfirmDelete(
                                                                false
                                                            )
                                                        }
                                                        onConfirm={() =>
                                                            DeleteFileUpload(i)
                                                        }
                                                    />
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>
                </ScrollContainer>
            </Segment>
        </>
    );
};

export default DebriefScheduleDetail;

const ScrollContainer = styled.div`
    overflow-x: auto;
    // display:flex;
    // width:100%;
    // align-items:center;
    // justify-content:space-between;
`;

const Information = styled.div`
    font-family: "Barlow", sans-serif;
    font-size: 20px;
`;



const TopContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2d50e2;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-right: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;
