import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  SG_UPDATE_DEBRIEF_RESPONSE,
  CLEAR_UPDATE_DEBRIEF_RESPONSE,
} from "constants/actions";

import { Button, Header, Modal, Icon } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { ContentDetail, Summary } from "./ContentDetail";

export default function UpdateResponses({ responseId, setEmpUpdateRow }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPortal, setOpenPortal] = useState(false);

  const [row, setRow] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [briefSummary, setBriefSummary] = useState(false);
  // const [briefToken, setbriefToken] = useState(false)

  function ShowResponse(resp) {
    setOpenPortal(true);
    setShowResponse(resp);
  }

  function ShowSummary(resp) {
    setOpenPortal(true);
    setBriefSummary(resp);
  }

  // debrief_fix_for_survey_scheduled
  const { get_debrief_responses } = useSelector(
    (state) => ({
      get_debrief_responses: state.debrief_response,
    }),
    shallowEqual
  );

  useEffect(() => {
    setRow(get_debrief_responses?.updated);
  }, [get_debrief_responses]);

//   SubmitEmployeeRecordChange
function SubmitEmployeeRecordChange() {
    //
    dispatch({
      type: SG_UPDATE_DEBRIEF_RESPONSE,
      payload: {
        id: responseId,
        update_employee_record_response: responseId,
      },
    });
  }
// SubmitCategoryChange
  function SubmitCategoryChange() {
    //
    dispatch({
      type: SG_UPDATE_DEBRIEF_RESPONSE,
      payload: {
        id: responseId,
        update_survey_response: responseId,
      },
    });
  }

  function CloseModal() {
    setOpen(false);
    dispatch({
      type: CLEAR_UPDATE_DEBRIEF_RESPONSE,
      payload: "",
    });
    setEmpUpdateRow(Math.random);
  }

  return (
    <>
      <Modal
        closeIcon
        onClose={CloseModal}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button positive>Update Survey Responses</Button>}
      >
        <Modal.Content>
          <Header>Update Survey Responses</Header>
          <p>
            Use this once you have changed, updated the category and would like
            the response to be remapped to the new category.
          </p>
          <p>
            This does not change the existing reponses rather new responses are
            created in another field.
          </p>
          <Container>
            <RowData>
              <ListContainer>
                {row?.response && <HeaderDiv>Original Response</HeaderDiv>}
                <ContentDiv>
                  {row?.response && (
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowResponse(row?.response)}
                      color="violet"
                      name="closed captioning"
                    />
                  )}
                </ContentDiv>
              </ListContainer>

              <ListContainer></ListContainer>

              {row?.updated_response && (
                <ListContainer highlight>
                  <HeaderDiv>Modified Response</HeaderDiv>
                  <ContentDiv>
                    {row?.updated_response ? (
                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => ShowResponse(row?.updated_response)}
                        color="green"
                        name="closed captioning"
                      />
                    ) : (
                      "No updated response"
                    )}
                  </ContentDiv>
                </ListContainer>
              )}

              {row?.update_summary && (
                <ListContainer highlight>
                  <HeaderDiv>Modified History</HeaderDiv>
                  <ContentDiv>
                    {row?.update_summary ? (
                      <Icon
                        style={{ cursor: "pointer" }}
                        onClick={() => ShowSummary(row?.update_summary)}
                        color="orange"
                        name="closed captioning"
                      />
                    ) : (
                      "No update summary"
                    )}
                  </ContentDiv>
                </ListContainer>
              )}
            </RowData>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          {row?.updated_response ? (
            <Button content="Close" negative onClick={CloseModal} />
          ) : (
            <RowButtons>
              <Button
                content="Update Employee Record"
                color="violet"
                onClick={SubmitEmployeeRecordChange}
              />
              <Button
                content="Update Category"
                color="blue"
                onClick={SubmitCategoryChange}
              />
            </RowButtons>
          )}
        </Modal.Actions>
      </Modal>
      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        briefResponse={showResponse}
        briefSummary={briefSummary}
      />
    </>
  );
}

const MoreDetails = ({
  openPortal,
  setOpenPortal,
  briefResponse,
  briefSummary,
}) => {
  return (
    <Modal
      closeIcon
      open={openPortal}
      onClose={() => setOpenPortal(false)}
      onOpen={() => setOpenPortal(true)}
    >
      <Header>Detailed Response</Header>
      <Modal.Content>
        {briefResponse && <ContentDetail briefResponse={briefResponse} />}
        {briefSummary && <Summary briefChanggeHistory={briefSummary} />}
      </Modal.Content>
      <Modal.Actions>
        <Button color="purple" onClick={() => setOpenPortal(false)}>
          <Icon name="close" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  padding: ${(props) => (props.highlight ? "5px" : "0px")};
  // background-color: #dcdcdcdc;
  background-color: ${(props) => (props.highlight ? "#dcdcdcdc" : "")};
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between  ;
`;

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
`;
