import { all } from 'redux-saga/effects';

import Auth from "./Auth"
import GoogleAuth from "./GoogleAuth"
import AccountDefault from "./AccountDefault"
import Account from './Accounts';
import AdminDefault from "./AdminDefault"
// reports
import EmotionDefinitions from "./EmotionDefinitions"
import Moods from "./Moods"
import Personality from "./Personality"
import Culture from "./Culture"
import Leadership from "./Leadership"
import EmployeeReport from "./EmployeeReport"
import Ambassador from "./Ambassadors"
import EmployeeCategory from "./EmployeeCategory"
import EmployeePermission from "./EmployeePermission"
import IndustryBenchmark from "./IndustryBenchmark"
import ChatGpt from './ChatGpt';
// forms 
import Departments from "./Departments"
import Organization from "./Organization"
import Employee from "./Employee"
import EmployeeRole from "./EmployeeRole"
import EmployeeLog from "./EmployeeLog";
import EmployeeRecord from "./EmployeeRecord";
import EmployeeRecordList from "./EmployeeRecordList";
import EmployeeRecordValue from "./EmployeeRecordValue";
import Payment from "./Payment"
import Price from "./Price"
import Teams from "./Teams"
import TeamType from "./TeamType"
import TeamAccess from './TeamAccess';
// questions
import SurveyQuestion from './SurveyQuestions';
// de brief
import DebriefSchedule from "./DebriefSchedule"
import DebriefSummary from "./DebriefSummary"
import DebriefResponse from "./DebriefResponse"
import DebriefToken from './DebriefToken';
import DebriefAnalytics from './DebriefAnalytics';
// gpt
import GptSummary from "./GptSummary"
import GptMembers from "./GptMembers"
import GptModelMain from "./GptModelMain"
import GptResponseMain from './GptResponseMain';
import GptResponseScore from './GptResponseScore';
import GptWebChat from "./GptWebchat";
import GptRecommendation from './GptRecommendation';
import GptWebChatProcess from "./GptWebchatProcess";
import ReportTemplate  from './ReportTemplate';
//
import Blog from "./Blog"
import BlogCategory from "./BlogCategory"
import BlogTag from "./BlogTag"
import WebImage from "./WebImage"
// marketing
import MarketingRoi from "./MarketingRoi"
// settings
import SurveyStandard from "./SurveyStandard"
import ActivityLog from "./ActivityLog"
import FileUpload from "./FileUpload"
import EmailTemplate from "./EmailTemplate"
import PdfTemplate from "./PdfTemplate"
import SurveyStructure from "./SurveyStructure"
import SurveyStructureCompliance from "./SurveyStructureCompliance"
import SurveyToken from "./SurveyToken"
import SignUpToken from "./SignUpToken"
import SurveyResponses from "./SurveyResponses"
import GlobalSchedule from "./GlobalSchedule"
import SurveyScheduleFrequency from "./SurveyScheduleFrequency"
import TeamInSchedule from "./TeamInSchedule"
import LeadersInSchedule from "./LeadersInSchedule"
import LeadersSurveyParticipants from "./LeadersSurveyParticipants"
import SurveyCompliance from "./SurveyCompliance"
import PersonalityTeams from "./PersonalityTeams"
import PersonalityParticipants from "./PersonalityParticipants"
import ValidateEmail from "./ValidateEmail"
import OnBoarding from "./OnBoarding"
import EmailDeliveryStatus from "./EmailDeliveryStatus"
import Subscription from "./Subscription"
import AppListUrl from "./AppListUrl"
import WebHookCredential from "./WebApiCredential"
import WebHookLog from "./WebApiLog"

// list
import SurveySettingList from "./SurveySettingList" 

export function* rootSaga() {
  yield all([
    Auth(),
    GoogleAuth(),
    AccountDefault(),
    Account(),
    AdminDefault(),
    ActivityLog(),
    EmotionDefinitions(),
    Moods(),
    Personality(),
    Culture(),
    Leadership(),
    Departments(),
    SurveyQuestion(),
    Organization(),
    Ambassador(),
    EmployeeReport(),
    IndustryBenchmark(),
    MarketingRoi(),
    ChatGpt(),
    Employee(),
    EmployeeRole(),
    EmployeeLog(),
    EmployeeRecord(),
    EmployeeRecordList(),
    EmployeeRecordValue(),
    EmployeeCategory(),
    EmployeePermission(),
    Blog(),
    BlogCategory(),
    BlogTag(),
    WebImage(),
    Payment(),
    Price(),
    Teams(),
    DebriefSchedule(),
    DebriefSummary(),
    DebriefResponse(),
    DebriefToken(),
    DebriefAnalytics(),
    GptSummary(),
    GptMembers(),
    GptModelMain(),
    GptResponseMain(),
    GptResponseScore(),
    GptWebChat(),
    GptWebChatProcess(),
    GptRecommendation(),
    ReportTemplate(),
    TeamAccess(),
    EmailTemplate(),
    PdfTemplate(),
    FileUpload(),
    SurveyStructure(),
    SurveyStructureCompliance(),
    TeamType(),
    SurveyToken(),
    SignUpToken(),
    SurveyResponses(),
    SurveyCompliance(),
    SurveyStandard(),
    GlobalSchedule(),
    SurveyScheduleFrequency(),
    TeamInSchedule(),
    LeadersInSchedule(),
    LeadersSurveyParticipants(),
    PersonalityTeams(),
    PersonalityParticipants(),
    ValidateEmail(),
    OnBoarding(),
    EmailDeliveryStatus(),
    Subscription(),
    SurveySettingList(),
    AppListUrl(),
    WebHookCredential(),
    WebHookLog(),
  ]);
}

export default rootSaga