import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_PDF_TEMPLATE,
  SG_EDIT_PDF_TEMPLATE,
  SG_ADD_PDF_TEMPLATE,
  SG_DELETE_PDF_TEMPLATE,
  SG_GET_ORGANIZATION_SELECT,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import ReactJson from "react-json-view";
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages";
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [orgSelected, setOrgSelected] = useState([]);

  const [emailRef, setEmailRef] = useState({});

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  function BackArrow() {
    navigate(`/app/pdf-templates`);
  }

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_PDF_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  const { get_pdf_template, get_organization_select } = useSelector(
    (state) => ({
      get_pdf_template: state.pdfTemplate,
      get_organization_select: state.organizations.organization_select,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_pdf_template?.[templateId]) {
      setTemplateDetails(get_pdf_template?.[templateId]);
      setOrgSelected(
        get_organization_select.filter(
          (sy) => sy.id === get_pdf_template?.[templateId]?.organization
        )?.[0]
      );
    }
  }, [get_pdf_template, templateId, get_organization_select]);

  useEffect(() => {
    if (get_pdf_template?.created?.id) {
      navigate(`/app/email-templates/details/${get_pdf_template?.created?.id}`);
    }
  }, [get_pdf_template, navigate]);

  function EditTemplate(e) {
    setEmailRef(e?.jsObject);
  }

  useEffect(() => {
    setEmailRef(templateDetails?.data);
  }, [templateDetails]);

  function DeleteQuestion() {
    if (templateId) {
      dispatch({
        type: SG_DELETE_PDF_TEMPLATE,
        payload: {
          id: templateId,
        },
      });
      navigate(`/app/email-templates?reload=true`);
    }
  }
  function UpdateQuestions() {
    setPristine(false);
    if (formErrors.length === 0) {
      if (templateId) {
        dispatch({
          type: SG_EDIT_PDF_TEMPLATE,
          payload: {
            id: templateId,
            organization: orgSelected?.id ? orgSelected.id : null,
            name: templateDetails?.name,
            data: emailRef,
          },
        });
        navigate(`/app/pdf-templates/detail/${templateId}`);
      } else {
        dispatch({
          type: SG_ADD_PDF_TEMPLATE,
          payload: {
            organization: orgSelected?.id ? orgSelected.id : null,
            name: templateDetails?.name,
            data: emailRef,
          },
        });
      }
      setPristine(true);
    }
  }

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a name Version Number");
    }
    setFormErrors(ErrorList);
  }, [templateDetails, valueChanged]);

  function ChangeFormValues(e, name) {
    // console.log(name, e.target.value)
    let _ques = templateDetails;
    _ques[`${name}`] = e.target.value;
    setTemplateDetails(_ques);
    setValueChanged(e.target.value + name);
  }

  function ChangeOrg(e) {
    setOrgSelected(e);
    setValueChanged(e);
  }

  const handleJsonEdit = (edit) => {
    // Handle JSON edits here
    setEmailRef(edit.updated_src);
  };

  // Function to handle node addition
  function handleNodeAdd(data) {
    setEmailRef(data.updated_src);
  }
  // Function to handle node deletion
  const handleNodeDelete = (path) => {
    setEmailRef(path.updated_src);
  };

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New Template"}
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}
      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId && (
        <SectionDiv>
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Name (Version)</List.Header>
                    {templateDetails?.name}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Organization</List.Header>
                    {templateDetails?.organization
                      ? get_organization_select.filter(
                          (org) => org.id === templateDetails?.organization
                        )?.[0]?.name
                      : "For all Org"}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Created</List.Header>
                    {templateDetails?.created_at &&
                      format(
                        new Date(templateDetails?.created_at),
                        "dd-MMM-yyyy"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Last Updated</List.Header>
                    {templateDetails?.updated_at &&
                      format(
                        new Date(templateDetails?.updated_at),
                        "dd-MMM-yyyy"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>
        </SectionDiv>
      )}

      <SectionDiv>
        <SingleRowContent>
          <SectionCell>
            <LabelHead>Name (Version)</LabelHead>
            <Input
              name="name"
              defaultValue={templateDetails?.name}
              onChange={(e) => ChangeFormValues(e, "name")}
            />
          </SectionCell>

          <SectionCell>
            <LabelHead>Organization</LabelHead>
            <Select
              name="org_preference"
              options={get_organization_select}
              isClearable
              onChange={(e) => ChangeOrg(e)}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              value={orgSelected}
            />
          </SectionCell>
        </SingleRowContent>
      </SectionDiv>

      <SectionDiv>
        <ReactJson
          src={emailRef}
          onEdit={handleJsonEdit}
          onDelete={handleNodeDelete}
          onAdd={handleNodeAdd}
        />
      </SectionDiv>
      <SectionDiv lastSection={true}>
        {templateId && (
          <>
            <Button
              floated="left"
              color="red"
              onClick={() => setConfirmOpen(true)}
            >
              Delete Pdf Template
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={DeleteQuestion}
            />
          </>
        )}

        <Button floated="right" color="green" onClick={UpdateQuestions}>
          {templateId ? "Update PDF Template" : "Create PDF Template"}
        </Button>
      </SectionDiv>
      <SectionDiv></SectionDiv>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
