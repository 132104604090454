import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import { Header } from 'semantic-ui-react'
function SurveyScheduled() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Survey Scheduled List
        <Header.Subheader>
          List of All Survey's Scheduled and a place to view them.
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default SurveyScheduled;
