import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Table, Input, Message } from 'semantic-ui-react'
import styled from "styled-components";


function AddEditComment(props) {

    const {
        totalQuestions,
        askComments,
        setAskComments,
        updated, 
        setUpdated,
        UpdateComments } = props

    const [pristine, setPristine] = useState(true)
    const [listQuestion, setListQuestion] = useState('')
    const [formErrors, setFormErrors] = useState([]);

    const onSubmit = () => {
        if (formErrors.length > 0) {
            setPristine(false)
            return
        }
        setPristine(true)
        // setOpenNewCategory(false)
        // const _strucRows = []
        let NewRow = askComments

        // console.log("New Rows")
        NewRow.push(
            {
                "id": uuidv4(),
                "question": listQuestion,
                "order": totalQuestions + 1
            }
        )
        setAskComments(NewRow)
 
        UpdateComments()
        setListQuestion('')
        setUpdated(Math.random())
    }

    useEffect(() => {
        const ErrorList = []
        if (!listQuestion) {
            ErrorList.push("Enter a question")
        }
        // if (!surveyStruc?.category) {
        //     ErrorList.push("Enter a category")
        // }
        // if (!surveyStruc?.color) {
        //     ErrorList.push("Select a Color")
        // }

        setFormErrors(ErrorList)
    }, [listQuestion, updated]);



    function RemoveFromList(id) {
       
        const _survey = askComments.filter(ss => ss.id !== id)
        setAskComments(_survey)
        setUpdated(Math.random())
    }

    function EditDetails(id, name, value) {
        const _EditRow = askComments.filter(ss => ss.id === id)?.[0]
        const _survey = askComments.filter(ss => ss.id !== id)
      
        _EditRow[name] = value
        _survey.push(_EditRow)
        _survey.sort((a,b) => a.order - b.order)
        setAskComments(_survey)
        setUpdated(Math.random())
    }





    return (
        <>
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No</Table.HeaderCell>
                        <Table.HeaderCell>Comment Questions</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {askComments.map(scr =>
                    (
                        <Table.Row key={scr.id}>
                            
                            <Table.Cell collapsing textAlign='left'>
                              
                              <Input
                                  style={{ textAlign: 'right' }}
                                //   fluid
                                  placeholder='order'
                                  name="order"
                                  type='number'
                                  value={scr?.order}
                                  onChange={(v) => EditDetails(
                                    scr.id,
                                    "order", 
                                    v.target.value)}
                              />
                          </Table.Cell>
                            <Table.Cell>
                                
                                <Input
                                    style={{ textAlign: 'right' }}
                                    fluid
                                    placeholder='question'
                                    name="question"
                                    type='text'
                                    value={scr?.question}
                                    onChange={(v) => EditDetails(
                                        scr.id,
                                        "question", 
                                        v.target.value)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Icon name='trash'
                                style={{ cursor: 'pointer' }} 
                                onClick={() => RemoveFromList(scr.id)}/>
                                
                            </Table.Cell>
                        </Table.Row>
                    )
                    )}

                </Table.Body>
            </Table>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {formErrors.length > 0 && !pristine &&
                    <Message negative
                        //   onDismiss={clearErrors}
                        header='We need these details!'
                        list={formErrors}
                    />
                }
                <Half>
                    <FormContainer>
                        <Label>Question</Label>
                        <Input
                            fluid
                            placeholder='question'
                            name="question"
                            value={listQuestion || ''}
                            onChange={(e) => setListQuestion(e.target.value)}
                        />
                    </FormContainer>

                    <Submit
                        floated='right'
                        // disable={formErrors.length > 0 && !pristine}
                        onClick={onSubmit}>Add</Submit>
                </Half>
            </div>
        </>
    )


}

export default AddEditComment;

const Half = styled.div`
	width:100%;
	padding:10px 10px;
    display: flex;

`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
    width: 100%;
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
  border-radius: 5px;
background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
font-size: 14px;
font-weight: bold;
color:white;
padding: 10px;
display:flex;
align-items:center;
justify-content:center;
width:100px;
height: 40px;
margin-top:46px;
cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
border:none;
`
