import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch} from "react-redux";

import { Grid, Header, Segment, Portal, Form } from "semantic-ui-react";


import Select from "react-select";

import { SG_EDIT_FILE_UPLOAD } from "constants/actions";

const Update = ({
    rowInfo,
    setRowInfo,
    rowId,
    id,
    setopenPortal,
    openPortal,
    jsonData,
    setJsonData,
    fieldValues,
    validFields,
    controlledBy,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    function UpdateRow() {
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: id,
                row_info: rowInfo,
                row_id: rowId,
            },
        });
        setopenPortal(false);
    }


    function ChangeRow(key, value) {
        setIsLoading(key)
        const _rowData = rowInfo;
        _rowData[key] = value;

        const _full_data = jsonData;
        _full_data[rowId] = _rowData;
        setRowInfo(_rowData);

        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: id,
                row_info: rowInfo,
                row_id: rowId,
            },
        });

        setJsonData(_full_data);
    }

    // problme here
    function ChangeSelectedValue(key, e) {
        ChangeRow(key, e.name); 
        // if (controlledBy[key])
        // {
        //     ChangeRow(controlledBy[key], null);
        // }
    }


    useEffect(() => {
        if (!isLoading === false) {
            const timer1 = setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            return () => clearTimeout(timer1);
        }
        
    }, [rowInfo, dispatch, isLoading]);

    function SelectedLists({ variable, optionList, value }) {
        const SetValue = optionList.filter(op => op.name === value)[0]
        let SelectionListValues = fieldValues[variable]
        
        return <Select
            placeholder={`Select ${variable}`}
            onChange={(e) => ChangeSelectedValue(variable, e)}
            value={SetValue}
            options={SelectionListValues}
            isLoading={isLoading === variable ? isLoading: false}
            getOptionLabel={(SelectionListValues) => SelectionListValues.name}
            getOptionValue={(SelectionListValues) => SelectionListValues.name}
        />
    }

    return (
        <Grid centered columns={2}>
            <Grid.Column>
                <Portal onClose={() => setopenPortal(false)} open={openPortal}>
                    <Segment
                        style={{
                            left: "20%",
                            position: "fixed",
                            top: "20%",
                            width: "50%",
                            zIndex: 1000,
                            fontFamily: "Barlow, sans-serif",
                        }}
                    >
                        <Header>Update Row</Header>
                        <Form>
                            {validFields && validFields.map((item, i) => {
         
                                const RowValue = rowInfo[item]
                                if (fieldValues?.[item]) {
                                    return (<Form.Field key={i}>
                                        <label>{item}</label>
                                        <SelectedLists
                                            variable={item}
                                            optionList={fieldValues?.[item]}
                                            value={RowValue}
                                        />
                                    </Form.Field>
                                    )
                                }
                                else
                                   
                                    return (
                                        <Form.Field key={i}>
                                            <label>{item}</label>
                                            <input
                                                name={item}
                                                onChange={(e) =>
                                                    ChangeRow(
                                                        item,
                                                        e.target.value
                                                    )
                                                }
                                                defaultValue={RowValue}
                                            />
                                        </Form.Field>
                                    )
                            })}
                        </Form>

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 20,
                            }}
                        >
                            <StyledButtonGrey
                                onClick={() => setopenPortal(false)}
                            >
                                Cancel
                            </StyledButtonGrey>
                            <StyledButton onClick={UpdateRow}>
                                Update
                            </StyledButton>
                        </div>
                    </Segment>
                </Portal>
            </Grid.Column>
        </Grid>
    );
};

export default Update;


const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2d50e2;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-left: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;

const StyledButtonGrey = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #595959;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #595959;
        color: #595959;
    }
`;
