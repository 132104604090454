import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_DEBRIEF_ANALYTICS,
  SG_GET_DEBRIEF_SUMMARY,
  UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from "semantic-ui-react";

const List = ({ reload }) => {
  // employee_record_value
  const dispatch = useDispatch();

  //   const [resetPageLoad, setResetPageLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_DEBRIEF_SUMMARY,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reload]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_ANALYTICS,
    });
  }, [dispatch]);

  const { get_debrief_summary, get_debrief_analytics } = useSelector(
    (state) => ({
      get_debrief_summary: state.debrief_summary,
      get_debrief_analytics: state.debrief_analytics,
    }),
    shallowEqual
  );

  console.log(get_debrief_summary);

  useEffect(() => {
    setResults(get_debrief_summary?.debrief_summary?.results);
    setPage(get_debrief_summary?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_debrief_summary?.debrief_summary?.count /
          get_debrief_summary?.debrief_summary?.page_size
      )
    );
  }, [get_debrief_summary, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    console.log(value.activePage);

    dispatch({
      type: UPDATE_DEBRIEF_SUMMARY_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_debrief_summary?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_debrief_summary]);

//   console.log(
//     get_debrief_analytics?.debrief_analytics?.[0]?.params?.ml_operations
//   );

  return (
    <Container>
      {/* <SectionDiv>
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm} /> 

            </SectionDiv>
  */}
      <SectionDiv>
        <p>
          The list of Question Sort Order:{" "}
          {JSON.stringify(
            get_debrief_analytics?.debrief_analytics?.[0]?.params?.ml_operations
          )}
        </p>
      </SectionDiv>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>{results && <RowCard rows={results} />}</Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
