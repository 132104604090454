import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import OrgList from "./OrgList"
import Details from './Details';
import { Header } from 'semantic-ui-react'
import { CreateDemoResponses } from "WebConfig"

function DemoSurvey() {

  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Create Demo Survey Response
        <Header.Subheader>
          You can only create demo responses for
          Specific Survey listed here and in specific environments
          right now it {CreateDemoResponses ?
            "is enabled" : " not enabled"}

        </Header.Subheader>
      </Header>
      {CreateDemoResponses && <Routes>
        {/* <Route path="/" element={<List />} /> */}
        <Route path="/" element={<OrgList />} />
        <Route path="organization/:id" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        {/* <Route path="organization/:id" element={<Details />} /> */}
      </Routes>
      }
    </>
  );
}

export default DemoSurvey;
