import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_SURVEY_COMPLIANCES, UPDATE_SURVEY_COMPLIANCE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({hideOrgSelect}) => {

    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        // if the response is ok 200
        dispatch({
            type: SG_GET_SURVEY_COMPLIANCES,
            payload: `page=${page}${organizationId?`&organization=${organizationId}`:''}`,
        });
   
        setLoading(true)
    }, [dispatch, page, organizationId,
        resetPageLoad]);

    const { get_survey_compliance,
        get_selectedOrg } = useSelector(
        (state) => ({
            get_survey_compliance: state.surveycompliance,
            get_selectedOrg: state.selectedOrg,
        }),
        shallowEqual
    );

    useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
		// setResetPageLoad(!resetPageLoad)
	}, [get_selectedOrg]);


    useEffect(() => {
        setResults(get_survey_compliance?.survey_compliances?.results);
        setPage(get_survey_compliance?.pageNumber)
        setTotalPages(Math.ceil(get_survey_compliance?.survey_compliances?.count / get_survey_compliance?.survey_compliances?.page_size)) 
        if (get_survey_compliance?.survey_compliances?.count % get_survey_compliance?.survey_compliances?.page_size) {
            setTotalPages(Math.ceil(get_survey_compliance?.survey_compliances?.count / get_survey_compliance?.survey_compliances?.page_size))   
        }
        
    }, [get_survey_compliance, page]);


    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_SURVEY_COMPLIANCE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    // console.log(get_survey_compliance)

    return (

        <Container>
            
          
            {!hideOrgSelect &&
            <OrganizationSelect
				setResetPageLoad={setResetPageLoad}  />
            }
            

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results.map((result) => (
                        <RowCard row={result} key={result?.id} />
                    ))}
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`