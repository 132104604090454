import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";
import { Card, Icon, Header } from 'semantic-ui-react'
import { items } from "./List"


function Settings() {
    const [userAuth, setUserAuth] = useState(false)

    const { get_auth } = useSelector(
        (state) => ({
            get_auth: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        setUserAuth(get_auth)
    }, [get_auth]);


    const [checkForStaff, setCheckForStaff] = useState(false);
    const IsStaff = useSelector((state) => state.auth?.is_staff, shallowEqual)
  
    useEffect(() => {
      if (typeof IsStaff == "boolean") {
        // variable is a boolean
        if (IsStaff) {
          setCheckForStaff(true)
        }
        // console.log("IsStaff Boolean")
      }
      if (typeof IsStaff == "string") {
        // variable is a boolean
        if (IsStaff === 'true') {
          setCheckForStaff(true)
        }
        // console.log("IsStaff Boolean")
      }
  
    }, [IsStaff]);


    return (
        <>
            <Header as='h2' style={{ marginTop: "50px" }}>
                Your Settings
                <Header.Subheader>
                    Your Login related information
                </Header.Subheader>
            </Header>
            <Card.Group>
                <Card fluid>
                    <Card.Content header={`${userAuth?.employee?.first_name} ${userAuth?.employee?.last_name} - ${userAuth?.employee?.email}`} />
                    <Card.Content description={`User Id = ${userAuth?.user_id} Employee Id = ${userAuth?.employee_id} Organization Id = ${userAuth?.organization_id}`} />
                    <Card.Content extra>
                        <Icon name='user' />You are in {userAuth?.organization_list?.length} Organizations
                    </Card.Content>
                </Card>
                <Card fluid color={checkForStaff ? "green" : 'red'} header={`Is Staff = ${checkForStaff}`} />
                <Card fluid color='orange' 
                    header={`Organization ${userAuth?.organization?.name}`}
                    description={`
                    Timezone = ${userAuth?.organization?.time_zone}`}
                />
            </Card.Group>

            <Routes>
                {items.filter(tt => tt.isStaff <= checkForStaff).map((item, i) => <Route path={item.path} element={item.element} key={i} />)}
            </Routes>
        </>
    );
}

export default Settings;


